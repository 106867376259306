import { FastField, FastFieldProps } from 'formik';
import React, { FunctionComponent } from 'react';

import { FieldDateChange, FieldDateTime } from '../../../common/fields';
import FieldDateTimePlaceholder from '../../../common/fields/FieldDateTimePlaceholder';
import { StaticLabel } from '../../../common/labels';
import WysiwygPopUpShortCodesContent from '../../../common/wysiwyg/WysiwygPopUpShortCodesContent';
import { useTemplateUI } from '../useTemplateUI';
import { errorsToString, isPlaceholder, defaultDateFallback } from './helper';
import {
  PkpassInputSettingsPopUp,
  usePkpassInputSettingsPopup,
} from './PkpassInputSettingsPopUp';
import IconButton from '@material-ui/core/IconButton';
import { SettingsIcon } from '../../../common/icons';

type ValueType =
  | {
      type: 'string';
      value: string;
      fallback?: string;
    }
  | undefined;

interface PkpassDateInputProps<T> extends PkpassDateProps {
  field: FastFieldProps<T>;
}

const PkpassDateInput: FunctionComponent<PkpassDateInputProps<ValueType>> = (
  props: PkpassDateInputProps<ValueType>
) => {
  const templateUI = useTemplateUI();

  const { field, label, helpText, name, required } = props;

  const error = errorsToString(field.form.errors, [name]);
  const value = field.field.value ? field.field.value.value : undefined;
  const object = field.field.value;
  const hasFallback = object && isPlaceholder('date', object);
  const fallback = field.field.value ? field.field.value.fallback : undefined;

  const getFallback = (v: string) => {
    const h = isPlaceholder('date', { value: v, type: 'string' });
    if (h) {
      return fallback || defaultDateFallback;
    }
    return fallback;
  };

  const handleChangeDate = (d: { name: string; value: string | undefined }) => {
    const val = d.value;
    const newValue = val
      ? {
          fallback: getFallback(val),
          type: 'string',
          value: val,
        }
      : undefined;
    field.form.setFieldValue(name, newValue);
  };

  const handleChangeFallbackDate = (d: FieldDateChange) => {
    const newValue = {
      fallback: d.value,
      type: 'string',
      value: value,
    };
    field.form.setFieldValue(name, newValue);
  };

  const handleSelectShortCode = (shortCode: string) => {
    field.form.setFieldValue(name, {
      fallback: getFallback(shortCode),
      type: 'string',
      value: shortCode,
    });
  };

  const { anchorEl, handleClose, handleOpen } = usePkpassInputSettingsPopup();

  return (
    <>
      <FieldDateTimePlaceholder
        label={label}
        helpText={helpText}
        name={name}
        value={value}
        error={error}
        onChange={handleChangeDate}
        required={required}
        endAdornment={
          <IconButton
            onClick={handleOpen}
            disabled={!hasFallback && templateUI.dateShortCodes.length === 0}
          >
            <SettingsIcon style={{ height: '0.7em', width: '0.7em' }} />
          </IconButton>
        }
      />
      <PkpassInputSettingsPopUp anchorEl={anchorEl} onClose={handleClose}>
        <FieldDateTime
          label={
            <span>
              {label} {StaticLabel.Fallback}
            </span>
          }
          name={name}
          value={fallback}
          onChange={handleChangeFallbackDate}
        />
        {templateUI.dateShortCodes.length > 0 && (
          <WysiwygPopUpShortCodesContent
            shortCodes={templateUI.dateShortCodes}
            onSelect={handleSelectShortCode}
          />
        )}
      </PkpassInputSettingsPopUp>
    </>
  );
};

export interface PkpassDateProps {
  name: string;
  label: JSX.Element;
  helpText: JSX.Element;
  required?: boolean;
}

export const PkpassDate = (props: PkpassDateProps): JSX.Element => {
  return (
    <FastField name={props.name}>
      {(field: FastFieldProps) => <PkpassDateInput {...props} field={field} />}
    </FastField>
  );
};
