import React from 'react';

import { useCacheResource } from '../../hooks';
import { ResourceTypes } from '../../utils/services/api';
import { Label, LabelProps } from '../../components/common/labels';

export const PkpassCertificateLabel = (props: LabelProps): JSX.Element => {
  const { data, loading } = useCacheResource(
    ResourceTypes.CERTIFICATE,
    props.resourceId
  );
  return <Label {...props} instance={data} loading={loading} />;
};
