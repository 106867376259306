import React from 'react';

import ImageIcon from '@material-ui/icons/Image';

import logo from '../../assets/logo.svg';
import { ImageTypes } from '../../types/Template';
import { getPreviewSize, ImagePreviewProps } from './ImagePreview';
import TriggerButton from './TriggerButton';

export default function EmptyImage(props: ImagePreviewProps): JSX.Element {
  const { onClick, imageType, passStyle, triggerButton, disabled } = props;

  const size = getPreviewSize(imageType, passStyle, undefined);

  let className = '';
  if (!disabled) {
    className = 'designerHoverItem';
  }

  let content = (
    <div
      className={`${className} emptyPreview`}
      style={{
        alignItems: 'center',
        cursor: disabled ? 'default' : 'pointer',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        ...size,
      }}
    >
      <ImageIcon />
    </div>
  );

  if (imageType === ImageTypes.icon) {
    content = (
      <img
        className={`${className}`}
        alt='presentation'
        src={logo}
        style={{ cursor: disabled ? undefined : 'pointer', marginRight: 10 }}
        width={29}
        height={29}
      />
    );
  }

  return (
    <div
      style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
      onClick={onClick}
      onKeyDown={onClick}
    >
      {content}
      {triggerButton && <TriggerButton />}
    </div>
  );
}
