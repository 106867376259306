import { getIn, setIn } from 'formik';
import { set, unset } from 'lodash';

import {
  PkpassField,
  PkpassFieldsType,
  Template,
} from '../../../../types/Template';
import { getPassStyle } from './passStyle';

export const addField = (
  t: Template,
  fieldsType: PkpassFieldsType,
  field: PkpassField
): Template => {
  const passStyle = getPassStyle(t);
  const path = `jsonTemplate.${passStyle}.properties.${fieldsType}`;

  const fields = getIn(t, `${path}.items`, []);

  fields.push(field);
  t = setIn(t, path, {
    items: fields,
    type: 'array',
  });
  return t;
};

export const switchField = (
  t: Template,
  fieldsType: PkpassFieldsType,
  fieldIndex: number,
  droppedFieldsType: PkpassFieldsType,
  droppedIndex: number
): Template => {
  const passStyle = getPassStyle(t);

  if (droppedFieldsType === fieldsType) {
    const fields = getIn(
      t,
      `jsonTemplate.${passStyle}.properties.${fieldsType}.items`
    );
    const movedField = fields[droppedIndex];
    fields.splice(droppedIndex, 1);
    fields.splice(fieldIndex, 0, movedField);
    set(t, `jsonTemplate.${passStyle}.properties.${fieldsType}`, {
      items: fields,
      type: 'array',
    });
  }
  if (droppedFieldsType !== fieldsType) {
    const fromFields = getIn(
      t,
      `jsonTemplate.${passStyle}.properties.${droppedFieldsType}.items`
    );
    const fields = getIn(
      t,
      `jsonTemplate.${passStyle}.properties.${fieldsType}.items`
    );
    const movedField = fromFields[droppedIndex];
    fromFields.splice(droppedIndex, 1);
    fields.splice(fieldIndex, 0, movedField);
    set(t, `jsonTemplate.${passStyle}.properties.${fieldsType}`, {
      items: fields,
      type: 'array',
    });
    set(t, `jsonTemplate.${passStyle}.properties.${droppedFieldsType}`, {
      items: fromFields,
      type: 'array',
    });
  }

  return t;
};

export const fieldRemove = (
  t: Template,
  fieldsType: PkpassFieldsType,
  fieldIndex: number
): Template => {
  const passStyle = getPassStyle(t);
  const originalFileds: PkpassField[] = getIn(
    t,
    `jsonTemplate.${passStyle}.properties.${fieldsType}.items`,
    []
  );

  if (originalFileds.length > 1) {
    originalFileds.splice(fieldIndex, 1);
    set(
      t,
      `jsonTemplate.${passStyle}.properties.${fieldsType}.items`,
      originalFileds
    );
  } else {
    unset(t, `jsonTemplate.${passStyle}.properties.${fieldsType}`);
  }
  return JSON.parse(JSON.stringify(t));
};
