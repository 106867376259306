import './CheckerBoardImage.css';

import React from 'react';

export default function CheckerBoardImage(
  props:
    | {
        src?: string;
        height: number;
        width?: number;
      }
    | {
        src?: string;
        height?: number;
        width: number;
      }
) {
  const { height, width, ...rest } = props;
  return (
    <img
      className='checkerBoard'
      {...rest}
      style={{
        height: height ? height : 'auto',
        width: width ? width : 'auto',
      }}
      alt={''}
    />
  );
}
