import React from 'react';
import { FormattedMessage } from 'react-intl';

import { ImageTypes } from '../../types/Template';
import messages from './messages';

export default function ImageLabel(props: { imageType: ImageTypes }) {
  if (messages[props.imageType]) {
    return <FormattedMessage {...messages[props.imageType]} />;
  }

  return (
    <FormattedMessage
      id='ImageLabel.unknowImageType'
      defaultMessage='unknow image type {imageType}'
      values={{ imageType: props.imageType }}
    />
  );
}
