import { useFormikContext } from 'formik';
import { get } from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { FieldTextInput, FieldTextInputData } from '../../common/fields';
import { StaticLabel } from '../../common/labels';
import { Template } from '../../../types/Template';
export const TemplateNameFormInput = (): JSX.Element => {
  const { errors, values, handleBlur, setFieldValue } = useFormikContext<
    Template
  >();

  const handleChange = (d: FieldTextInputData) => {
    setFieldValue(d.name, d.value);
  };

  return (
    <FieldTextInput
      required={true}
      label={StaticLabel.Name}
      name='name'
      onChange={handleChange}
      onBlur={handleBlur}
      value={values.name}
      error={get(errors, 'name')}
      helpText={
        <FormattedMessage
          id={'TemplateNameFormInput.helpText'}
          defaultMessage={
            'Type the name of the template. Use ideally unique name for better identification. This „name“ see only admin and It is not shown for clients.'
          }
        />
      }
    />
  );
};
