import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import ButtonGroup from '@material-ui/core/ButtonGroup';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import { CancelButton, DoneButton } from '../../components/common/buttons';
import {
  FieldNumberInput,
  FieldNumberInputData,
} from '../../components/common/fields';
import Form from '../../components/common/Form';
import { EditIcon } from '../../components/common/icons';
import { LabelProject, LabelUser } from '../../components/common/labels';
import AccessRights, { AccessRightsList } from '../../constants/accessRights';
import {
  accessRightsDescriptionMessages,
  accessRightsMessages,
  messages,
} from './messages';
import useOpen from '../../hooks/useOpen';
import useNotification from '../../hooks/useNotification';
import CheckBox from '@material-ui/core/Checkbox';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useIntl } from 'react-intl';

interface Props {
  disabled?: boolean;
  projectId: string;
  userId: string;
  accessRights: number;
  saveRights: (
    projectId: string,
    userId: string,
    accessRights: number | null
  ) => void;
}

const useStyles = makeStyles(
  createStyles({
    numberInput: {
      '& input::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
      '& input::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
    },
  })
);

export function ProjectUserRightsInput(props: Props): JSX.Element {
  const { projectId, userId } = props;
  const [pending, setPending] = useState(false);
  const { addSuccessNotification, addErrorNotification } = useNotification();
  const [accessRights, setAccessRights] = useState<number>(props.accessRights);
  const [preModalOpenAccessRights, setPreModalOpenAccessRights] = useState<
    number
  >(props.accessRights);
  const intl = useIntl();
  const classes = useStyles();
  const open = useOpen(false);

  const onChangeAccessForProject = (value: number, checked: boolean) => {
    const operator = checked ? -1 : 1;

    if (accessRights === undefined) {
      setAccessRights(0);
    }
    const shifted = accessRights + operator * value;
    setAccessRights(parseInt(`${shifted}`, 10));
  };

  const handleChangeAccessNumberProject = (event: FieldNumberInputData) => {
    setAccessRights(parseInt(event.value as string, 10));
  };

  const submitData = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    await save(true);
  };

  const handleBlur = async (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    e.preventDefault();
    e.stopPropagation();
    await save();
  };

  const save = async (ignoreSameValue?: boolean) => {
    setPending(true);
    if (props.accessRights !== accessRights || ignoreSameValue) {
      try {
        await props.saveRights(projectId, userId, accessRights);
        addSuccessNotification(
          intl.formatMessage(
            messages.removeUserProjectAccessSuccessNotification
          )
        );
      } catch (e) {
        addErrorNotification(
          intl.formatMessage(messages.removeUserProjectAccessErrorNotification)
        );
      }
    }

    setPending(false);
  };

  const openModal = () => {
    setPreModalOpenAccessRights(accessRights);
    open.handleOpen();
  };

  const cancelModal = () => {
    setAccessRights(preModalOpenAccessRights);
    open.handleClose();
  };

  const submitModal = async () => {
    await save();
    open.handleClose();
  };

  return (
    <div>
      <Dialog open={open.open} maxWidth={'xl'}>
        <DialogTitle>
          <LabelProject resourceId={projectId} />
          &nbsp;&nbsp;&nbsp;&nbsp;
          <LabelUser resourceId={userId} />
        </DialogTitle>
        <DialogContent>
          <Table style={{ paddingLeft: 20, paddingRight: 20 }} size={'small'}>
            <TableBody>
              {AccessRightsList.map((value: AccessRights) => {
                // tslint:disable-next-line: no-bitwise
                const shifted = accessRights & value;
                const checked = shifted === value && accessRights !== undefined;
                const handleChange = () => {
                  onChangeAccessForProject(value, checked);
                };

                return (
                  <TableRow key={value}>
                    <TableCell width={20}>
                      <CheckBox
                        size={'small'}
                        name={`${value}`}
                        onChange={handleChange}
                        checked={checked}
                      />
                    </TableCell>
                    <TableCell width={200}>
                      <FormattedMessage {...accessRightsMessages[value]} />
                    </TableCell>
                    <TableCell width={600}>
                      <FormattedMessage
                        {...accessRightsDescriptionMessages[value]}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <CancelButton onClick={cancelModal} />
          <DoneButton onClick={submitModal} />
        </DialogActions>
      </Dialog>

      <Form onSubmit={submitData}>
        <ButtonGroup>
          <FieldNumberInput
            disabled={props.disabled || pending}
            name='accessRightsNumber'
            value={accessRights !== undefined ? accessRights : ''}
            onChange={handleChangeAccessNumberProject}
            onBlur={handleBlur}
            InputProps={{
              className: classes.numberInput,
              inputProps: { min: 0 },
            }}
            endAdornment={
              <InputAdornment position='end'>
                <IconButton
                  onClick={openModal}
                  edge='end'
                  disabled={props.disabled || pending}
                >
                  <EditIcon />
                </IconButton>
              </InputAdornment>
            }
          />
        </ButtonGroup>
      </Form>
    </div>
  );
}
