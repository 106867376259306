import React from 'react';
import { NavLink } from 'react-router-dom';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import { YOURPASS_VERSION } from '../../../../../../../../utils/services/environment';

const useStyles = makeStyles(() =>
  createStyles({
    item: {
      textDecoration: 'none',
      color: 'rgba(255,255,255,0.6)',
      '&:hover': {
        color: '#FFF',
        '.MuiSvgIcon-root': {
          color: '#FFF',
        },
      },
      '& .MuiSvgIcon-root': {
        color: 'rgba(255,255,255,0.6)',
      },

      '& .MuiListItemSecondaryAction-root': {
        color: 'rgba(255,255,255,0.6)',
      },
    },
  })
);

const Version = () => {
  const classes = useStyles();

  return (
    <NavLink className={classes.item} to={'/about'}>
      {YOURPASS_VERSION}
    </NavLink>
  );
};

Version.propTypes = {};

export default Version;
