import React, {
  FormEvent,
  forwardRef,
  KeyboardEvent,
  MouseEvent,
  useRef,
  useState,
} from 'react';



import ButtonGroup from '@material-ui/core/ButtonGroup';
import { createStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import { JsonSchemaFieldProps } from '../jsonSchema/';
import { FieldLabel } from '../fields';
import WysiwygPopUpEmoji from './WysiwygPopUpEmoji';
import WysiwygPopUpLink from './WysiwygPopUpLink';
import WysiwygPopUpShortCodes from './WysiwygPopUpShortCodes';

function debounce(cb: any, duration: number) {
  let timer:any;
  return (...args: any[]) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      cb(...args);
    }, duration);
  };
}

export interface WysiwygProps {
  name: string;
  onChange?: (value: string) => void;
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  label?: React.ReactNode;
  helpText?: React.ReactNode;
  disabled?: boolean;
  required?: boolean;
  readOnly?: boolean;
  placeholder?: string;
  error?: string;
  bottomControls?: React.ReactNode;
  type?: 'text';
  multiline?: boolean;
  autoFocus?: boolean;
  shortCode: boolean;
  shortCodes?: JsonSchemaFieldProps[];
  link: boolean;
  value?: string;
  defaultValue?: string;
  style?: React.CSSProperties;
  prefix?: JSX.Element;
}

const insertString = (
  val: string | undefined,
  inStr: string | undefined,
  start: number | null,
  end: number | null
): string => {
  const s = start ? start : 0;
  const e = end ? end : 0;

  if (!val) {
    return inStr || '';
  }
  if (!inStr) {
    return val;
  }
  // insert
  if (start === end) {
    const arr = val.split('');
    arr.splice(s, 0, inStr);
    return arr.join('');
  }
  // replace selection
  return val.substring(0, s) + inStr + val.substring(e);
};

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      position: 'relative',
    },
    controlsContainer: {
      position: 'absolute',
      zIndex: 200,
      padding: 8,
      top: 16,
    },
    bottomControlsContainer: {
      position: 'absolute',
      bottom: 0,
      padding: 8,
      width: '100%',
    },
    formControl: {
      width: '100%',
      '& .MuiInputBase-root': {
        alignItems: 'flex-start',
        paddingTop: 44,
        paddingBottom: 44,
      },
      '& input': {},
      '& textArea': {},
      '& .MuiInputLabel-outlined': {
        marginTop: 28,
      },
      '& .MuiInputLabel-focused': {
        marginTop: 0,
      },
      '& .MuiInputLabel-shrink': {
        marginTop: 0,
      },
    },
  })
);

const WysiwigForwarded = forwardRef<HTMLInputElement | HTMLTextAreaElement>(
  function WysiwigForwardedFce(props: any, ref: any) {
    const theme = useTheme();
    const classes = useStyles(theme);
    const [selection, setSelection] = useState<{
      start: number;
      end: number;
      text: string;
    }>({
      start: props.defaultValue ? props.defaultValue.length : 0,
      end: props.defaultValue ? props.defaultValue.length : 0,
      text: '',
    });

    const [innerValue,setInnerValue] = useState(props.value)
    const denouncedChange = debounce((str: string)=>{
      props.onChange(str);
      // console.log("debounced change: " + str)
      // console.log("inner value: " + innerValue)
    }, 750)


    const handleSelectedText = (
      event:
        | MouseEvent<HTMLInputElement | HTMLTextAreaElement>
        | FormEvent<HTMLInputElement | HTMLTextAreaElement>
        | KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
      setSelectionElement(event.currentTarget);
    };

    // Store selected
    const setSelectionElement = (
      el: HTMLInputElement | HTMLTextAreaElement
    ) => {
      setSelection({
        start: el.selectionStart != null ? el.selectionStart : 0,
        end: el.selectionEnd != null ? el.selectionEnd : 0,
        text: el.value.substring(
          el.selectionStart != null ? el.selectionStart : 0,
          el.selectionEnd != null ? el.selectionEnd : 0
        ),
      });
    };


    const onInsert = (str: string) => {
      const el = ref?.current;
      if (el) {
        const output = insertString(
          el.value,
          str,
          selection.start,
          selection.end
        );
        el.value = output;
        el.innerHTML = output;
        props.onChange(output);
        setInnerValue(output)

        setTimeout(() => {
          /*
          // set new selection position
          if (selection.end === selection.start) {
            // set cursor after inserted text
            el.selectionStart = selection.start + str.length;
            el.selectionEnd = selection.start + str.length;
          } else {
            // select inserted text
            el.selectionStart = selection.start;
            el.selectionEnd = selection.start + str.length;
          }*/

          // set new selection position
          el.selectionStart = selection.start + str.length;
          el.selectionEnd = selection.start + str.length;

          setSelectionElement(el);
          // set focus
          el.focus();
          // send change event
          // TODO - event triggering onChange handler is not tested
          const evt = document.createEvent('HTMLEvents');
          evt.initEvent('change', false, true);
          el.dispatchEvent(evt);
          // console.log('dispatched event');
        }, 10);
      }
    };

    return (
      <div className={classes.root}>
        <ButtonGroup
          size='small'
          className={classes.controlsContainer}
          aria-label='small outlined button group'
        >
          {props.shortCode &&
            props.shortCodes &&
            props.shortCodes.length > 0 && (
              <WysiwygPopUpShortCodes
                onSelect={onInsert}
                shortCodes={props.shortCodes}
              />
            )}
          <WysiwygPopUpEmoji onSelect={onInsert} />
          {props.link && (
            <WysiwygPopUpLink
              onSelect={onInsert}
              selectedText={selection.text}
            />
          )}
        </ButtonGroup>
        <TextField
          inputRef={ref}
          //   autoFocus={props.autoFocus}
          multiline={props.multiline}
          id={props.name}
          variant='outlined'
          margin='dense'
          className={classes.formControl}
          required={props.required}
          disabled={props.disabled}
          error={!!props.error}
          label={<FieldLabel label={props.label} helpText={props.helpText} />}
          name={props.name}
          value={innerValue}
          onChange={(e: FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            // console.log('TextField onChange', e.currentTarget.value);
           
            denouncedChange(e.currentTarget.value);
            setInnerValue(e.currentTarget.value);
          }}
          onBlur={props.onBlur}
          inputProps={{
            onChange: (
              e: FormEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => {
              // console.log('inputProps onChange', e.currentTarget.value);
              handleSelectedText(e);
              denouncedChange(e.currentTarget.value);
              setInnerValue(e.currentTarget.value);
             
            },
            onKeyUp: handleSelectedText,
            onMouseUp: handleSelectedText,
          }}
          InputProps={{
            readOnly: props.readOnly,
          }}
          type={props.type || 'text'}
          helperText={props.error}
          rows={props.multiline ? 5 : 1}
          rowsMax={20}
        />
        <div className={classes.bottomControlsContainer}>
          {props.bottomControls}
        </div>
      </div>
    );
  }
);

export const Wysiwyg = (p: WysiwygProps) => {
  const ref = useRef<HTMLInputElement | HTMLTextAreaElement>(null);
  return <WysiwigForwarded {...p} ref={ref} />;
};

Wysiwyg.defaultProps = {
  emoji: true,
  link: false,
  multiline: true,
  shortCode: true,
  shortCodes: [],
};
