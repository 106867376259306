import { useFormikContext, getIn } from 'formik';
import React from 'react';
import { useIntl } from 'react-intl';

import {
  passStyles,
  PkpassPassStyleTypes,
  Template,
} from '../../../../../../types/Template';
import { changePassStyle, getPassStyle } from '../../../helpers/passStyle';
import pkpassMessages from '../../../pkpass.messages';
import boardingPass from './boardingPass.svg';
import coupon from './coupon.svg';
import { defaultPasses } from './defaultPasses';
import eventTicket from './eventTicket.svg';
import generic from './generic.svg';
import storeCard from './storeCard.svg';

const images: { [index: string]: any } = {
  [PkpassPassStyleTypes.BoardingPass]: boardingPass,
  [PkpassPassStyleTypes.Coupon]: coupon,
  [PkpassPassStyleTypes.EventTicket]: eventTicket,
  [PkpassPassStyleTypes.Generic]: generic,
  [PkpassPassStyleTypes.StoreCard]: storeCard,
};

export default function PassStyleSwitcher(props: { isNew?: boolean }) {
  const intl = useIntl();

  const { isNew } = props;
  const { setFieldValue, values } = useFormikContext<Template>();

  const handleChangePassStyle = (newPassStyle: PkpassPassStyleTypes) => {
    if (isNew) {
      setFieldValue('jsonTemplate', {
        ...defaultPasses[newPassStyle],
        organizationName: getIn(values, 'jsonTemplate.organizationName'),
      });
    } else {
      const newValues = changePassStyle(values, newPassStyle);
      setFieldValue('jsonTemplate', newValues.jsonTemplate);
    }
  };

  const passStyle = getPassStyle(values);

  return (
    <div
      style={{
        alignItems: 'flex-start',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        paddingBottom: 15,
        paddingTop: 25,
        width: '100%',
      }}
    >
      {passStyles.map((key: PkpassPassStyleTypes, index: number) => {
        const handle = (
          event:
            | React.KeyboardEvent<HTMLDivElement>
            | React.MouseEvent<HTMLDivElement, MouseEvent>
        ) => {
          event.preventDefault();
          handleChangePassStyle(key);
        };
        const selected = passStyle === key;

        return (
          <div
            role='button'
            tabIndex={index}
            key={key}
            style={{
              borderRadius: 2,
              cursor: 'pointer',
              display: 'block',
              marginLeft: 'auto',
              marginRight: 'auto',
              position: 'relative',
            }}
            onClick={handle}
            onKeyDown={handle}
          >
            <img
              src={images[key]}
              alt='presentation'
              style={{
                filter: !selected ? 'opacity(0.5) grayscale(1)' : undefined,
                height: 'auto',
                width: 54,
              }}
            />
            <div
              style={{
                color: 'white',
                fontSize: 10,
                left: 2,
                lineHeight: '10px',
                position: 'absolute',
                right: 2,
                textAlign: 'center',
                top: 44,
                wordBreak: 'break-word',
                zIndex: 10,
              }}
            >
              {intl.formatMessage(pkpassMessages[key])}
            </div>
          </div>
        );
      })}
    </div>
  );
}
