import { FormikProps } from 'formik';
import React from 'react';

import {
  FieldAutocompleteProject,
  FieldSwitch,
  FieldSwitchChange,
  FieldTextInput,
  FieldTextInputData,
} from '../../components/common/fields';
import Form from '../../components/common/Form';

type Props = FormikProps<NewProjectFormInterface>;

export interface NewProjectFormInterface {
  name?: string;
  description?: string;
  parentId?: string;
  active?: boolean;
}

export function ProjectCreateForm(props: Props): JSX.Element {
  const handleParentChange = (value: string | undefined) => {
    props.setFieldValue('parentId', value);
  };

  const handleChange = (event: FieldSwitchChange | FieldTextInputData) => {
    props.setFieldValue(event.name, event.value);
  };

  const { values } = props;

  return (
    <Form>
      <FieldTextInput
        key='form_name'
        name='name'
        label='Name'
        onChange={handleChange}
        value={values.name}
      />
      <FieldTextInput
        multiline
        key='form_desc'
        name='description'
        label='Description'
        onChange={handleChange}
        value={values.description}
      />

      <FieldSwitch
        key='form_active'
        name='active'
        label='Active'
        onChange={handleChange}
        checked={values.active}
      />
      {values.parentId && (
        <FieldAutocompleteProject
          value={values.parentId || undefined}
          label='Parent project'
          name='parentId'
          onChange={handleParentChange}
          disabled={true}
          multiple={false}
        />
      )}
    </Form>
  );
}
