import { useFormikContext } from 'formik';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles';

import { Button, CreateButton, NextButton } from '../../../common/buttons';
import { VerticalStepperControls } from '../../../common/stepper';
import { Template } from '../../../../types/Template';

interface Props {
  stepper: VerticalStepperControls;
  stepCount: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    actionsContainer: {
      marginBottom: theme.spacing(2),
    },
  })
);

export function Actions(p: Props) {
  const theme = useTheme();
  const classes = useStyles(theme);
  const formik = useFormikContext<Template>();
  const next = async () => {
    const errors = await formik.validateForm();
    if (Object.keys(errors).length === 0) {
      p.stepper.next();
    }
  };
  const prev = () => {
    p.stepper.prev();
  };
  const skip = () => {
    p.stepper.setStep(p.stepCount - 1);
  };

  return (
    <div className={classes.actionsContainer}>
      {formik.dirty && formik.isValid && (
        <Button onClick={skip} className={classes.button}>
          <FormattedMessage
            id='Template.wizard.actions.skip.all.steps'
            defaultMessage='Skip all steps'
          />
        </Button>
      )}

      <Button
        onClick={prev}
        disabled={p.stepper.step === 0}
        className={classes.button}
      >
        <FormattedMessage
          id='Template.wizard.actions.previous'
          defaultMessage='Previous'
        />
      </Button>
      {p.stepper.step !== p.stepCount - 1 && (
        <NextButton
          onClick={next}
          disabled={!formik.isValid}
          className={classes.button}
        />
      )}

      {p.stepper.step === p.stepCount - 1 && (
        <CreateButton onClick={formik.submitForm} className={classes.button} />
      )}
    </div>
  );
}
