import { FormikProps } from 'formik';
import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles';

import {
  FieldStaticProject,
  FieldSwitch,
  FieldSwitchChange,
  FieldTextInput,
  FieldTextInputData,
} from '../../components/common/fields';
import Form from '../../components/common/Form';
import useViewer from '../../hooks/useViewer';
import { Project } from './types';
import { ProjectUserRights } from './ProjectUserRights';

type ProjectFormProps = FormikProps<Project>;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        marginBottom: theme.spacing(2),
      },
    },
  })
);

export const ProjectEditForm: FunctionComponent<ProjectFormProps> = (
  props: ProjectFormProps
) => {
  const handleChange = (event: FieldSwitchChange | FieldTextInputData) => {
    props.setFieldValue(event.name, event.value);
  };
  const theme = useTheme();
  const classes = useStyles(theme);
  const viewer = useViewer();

  const { values } = props;
  return (
    <div className={classes.root}>
      {viewer && viewer.canEditProject(values.id) && (
        <Card>
          <CardHeader
            title={
              <FormattedMessage
                id='ProjectForm.details.title'
                defaultMessage={'Project details'}
              />
            }
          />
          <CardContent>
            <Form>
              <FieldTextInput
                name='id'
                label='Id'
                value={values.id}
                disabled={true}
              />
              <FieldTextInput
                name='name'
                label='Name'
                onChange={handleChange}
                value={values.name}
              />
              <FieldTextInput
                multiline={true}
                name='description'
                label='Description'
                onChange={handleChange}
                value={values.description}
              />
              <FieldSwitch
                name='active'
                label='Active'
                onChange={handleChange}
                checked={values.active}
              />
              {
                /* TODO maybe only for admin show there dropdown to change parent project*/
                values.parentId && (
                  <FieldStaticProject
                    title='Parent'
                    projectId={values.parentId}
                  />
                )
              }
            </Form>
          </CardContent>
        </Card>
      )}
      {viewer && viewer.canManageUsersOnProject(values.id) && (
        <ProjectUserRights {...props} />
      )}
    </div>
  );
};
