import clsx from 'clsx';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';

import { makeStyles, Theme } from '@material-ui/core/styles';
import Assessment from '@material-ui/icons/Assessment';
import CardMembership from '@material-ui/icons/CreditCard';
import ImageIcon from '@material-ui/icons/Image';
import Palette from '@material-ui/icons/Palette';
import Folder from '@material-ui/icons/Camera';
import PeopleIcon from '@material-ui/icons/People';
import Security from '@material-ui/icons/Security';

import Logo from '../../../../../../assets/header_logo.svg';
import useViewer from '../../../../../../hooks/useViewer';
import { SidebarNav } from './components';
import Divider from './components/Divider';
import Profile from './components/Profile';
import Version from './components/SidebarNav/Version';
import SchoolIcon from '@material-ui/icons/School';
import { DOC_API_URL } from '../../../../../../utils/services/environment';
import { PkpassIcon, GooglePayIcon } from '../../../../icons';

const useStyles = makeStyles((theme: Theme) => ({
  logoImage: {
    height: 36,
  },
  nav: {
    margin: theme.spacing(1, 0),
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  head: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
    padding: theme.spacing(1, 2),
  },
  divider: {
    padding: theme.spacing(0, 2),
  },
}));

export interface PageInterface {
  title: React.ReactNode;
  icon?: React.ReactNode;
  href: string;
  target?: '_blank';
  component?: React.ElementType;
}

export interface SectionInterface {
  expandable: boolean;
  title: React.ReactNode;
  icon?: React.ReactNode;
  pages: PageInterface[];
}

const Sidebar = (props: any) => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const viewer = useViewer();
  const sections: SectionInterface[] = [];

  if (viewer && viewer.showPassMenu()) {
    const corePages = [];

    if (viewer.showTemplateList()) {
      const p = [
        {
          icon: <PkpassIcon />,
          href: '/passes/design/pkpass',
          title: (
            <FormattedMessage
              id='VerticalMenu.designs.pkpass'
              defaultMessage='Pkpass'
            />
          ),
        },
      ];

      if (viewer.isAdmin) {
        p.push({
          icon: <GooglePayIcon />,
          href: '/passes/design/googlepay',
          title: (
            <FormattedMessage
              id='VerticalMenu.designs.google.pay'
              defaultMessage='Google Pay'
            />
          ),
        });
      }

      sections.push({
        icon: <Palette />,
        title: (
          <FormattedMessage id='VerticalMenu.designs' defaultMessage='Design' />
        ),
        expandable: true,
        pages: p,
      });
    }

    if (viewer.showTemplateList()) {
      corePages.push({
        href: '/passes/template',
        icon: <Folder />,
        title: (
          <FormattedMessage
            id='VerticalMenu.templates'
            defaultMessage='Templates'
          />
        ),
      });
    }

    if (viewer.showPassList()) {
      corePages.push({
        href: '/passes/pass',
        icon: <CardMembership />,
        title: (
          <FormattedMessage id='VerticalMenu.pass' defaultMessage='Passes' />
        ),
      });
    }

    if (viewer.showImagesList()) {
      corePages.push({
        href: '/passes/image',
        icon: <ImageIcon />,
        title: (
          <FormattedMessage id='VerticalMenu.image' defaultMessage='Images' />
        ),
      });
    }

    if (viewer.showCertificateList()) {
      corePages.push({
        href: '/passes/certificate',
        icon: <Security />,
        title: (
          <FormattedMessage
            id='VerticalMenu.certificate'
            defaultMessage='Certificates'
          />
        ),
      });
    }

    sections.push({
      pages: corePages,
      title: <FormattedMessage id='Sidebar.core' defaultMessage='Core' />,
      expandable: false,
    });
  }

  if (viewer && viewer.showIdentityManagementMenu()) {
    const idmPages = [];

    if (viewer.showIdentityProjects()) {
      idmPages.push({
        href: '/identity/project',
        title: (
          <FormattedMessage
            defaultMessage='Projects'
            id='VerticalMenu.identity.project'
          />
        ),
      });
    }
    if (viewer.showIdentityUsers()) {
      idmPages.push({
        href: '/identity/user',
        title: (
          <FormattedMessage
            defaultMessage='Users'
            id='VerticalMenu.identity.user'
          />
        ),
      });
    }

    sections.push({
      icon: <PeopleIcon />,
      pages: idmPages,
      expandable: true,
      title: (
        <FormattedMessage
          defaultMessage='Identity'
          id='VerticalMenu.identity'
        />
      ),
    });
  }

  if (viewer && viewer.showReportMenu()) {
    sections.push({
      icon: <Assessment />,
      expandable: true,
      pages: [
        {
          href: '/report/passcount',
          title: (
            <FormattedMessage
              id='VerticalMenu.report.passcount'
              defaultMessage='Passes count'
            />
          ),
        },
        {
          href: '/report/passinwalletoverview',
          title: (
            <FormattedMessage
              id='VerticalMenu.report.passes.registered.in.wallet'
              defaultMessage='Passes registered in wallet'
            />
          ),
        },
        {
          href: '/report/passatleastonceregisterinwalletoverview',
          title: (
            <FormattedMessage
              id='VerticalMenu.report.passes.registered.in.wallet'
              defaultMessage='Passes registered in wallet'
            />
          ),
        },

        {
          href: '/report/passdownloadrequestbytagsoverview',
          title: (
            <FormattedMessage
              id='VerticalMenu.report.downloads.by.tags'
              defaultMessage='Downloads of pass by tags'
            />
          ),
        },

        {
          href: '/report/passpreviewrequestbytagsoverview',
          title: (
            <FormattedMessage
              id='VerticalMenu.report.previews.by.tags'
              defaultMessage='Previews of pass by tags'
            />
          ),
        },
        {
          href: '/report/activecardsoverview',
          title: (
            <FormattedMessage
              id='VerticalMenu.report.active.pass'
              defaultMessage='Active pass overview'
            />
          ),
        },
        {
          href: '/report/links',
          title: (
            <FormattedMessage
              id='VerticalMenu.report.links'
              defaultMessage='Links'
            />
          ),
        },
      ],
      title: <FormattedMessage id='Sidebar.reports' defaultMessage='Reports' />,
    });
  }

  sections.push({
    pages: [
      {
        href: DOC_API_URL || '',
        icon: <SchoolIcon />,
        title: (
          <FormattedMessage defaultMessage='Docs' id='VerticalMenu.docs' />
        ),
        target: '_blank',
        component: 'a',
      },
    ],
    title: <FormattedMessage id='Sidebar.more' defaultMessage='More' />,
    expandable: false,
  });

  return (
    <div {...rest} className={clsx(classes.root)}>
      <div className={classes.head}>
        <RouterLink to='/'>
          <img alt='Logo' src={Logo} className={classes.logoImage} />
        </RouterLink>
        <Version />
      </div>
      <SidebarNav className={classes.nav} sections={sections} />
      <div className={classes.divider}>
        <Divider />
      </div>
      <Profile />
    </div>
  );
};

Sidebar.propTypes = {};

export default Sidebar;
