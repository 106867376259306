import React from 'react';
import { Doughnut } from '../common/charts';

import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { getColorForIndex } from '../../constants/colors';
import { getTagName } from './BasePassRequestByTagsOverview';

// TODO - remove any types
export default function BasePassRequestByTagsOverviewTotal(props: any) {
  const { data } = props;

  const dounughtData = {
    labels: [] as string[],
    datasets: [
      {
        data: [] as number[],
        backgroundColor: [] as string[],
      },
    ],
  };
  data.total.forEach((i: { tag: string; count: number }, index: number) => {
    dounughtData.labels.push(getTagName(i.tag));
    dounughtData.datasets[0].data.push(i.count);
    dounughtData.datasets[0].backgroundColor.push(getColorForIndex(index));
  });

  return (
    <Grid container={true}>
      <Grid item={true} xs={6}>
        <div>
          <h3>Total tags count</h3>
          <Doughnut data={dounughtData} />
        </div>
      </Grid>
      <Grid item={true} xs={6}>
        <h3>Total tags count data table</h3>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Tag</TableCell>
              <TableCell>Count</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.total.map((v: any) => (
              <TableRow key={getTagName(v.tag)}>
                <TableCell>{getTagName(v.tag)}</TableCell>
                <TableCell>{v.count}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  );
}
