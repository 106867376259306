import React, { useState } from 'react';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import { ProjectUserRightsInput } from './ProjectUserRightsInput';
import { DeleteButton } from '../../components/common/buttons';
import useNotification from '../../hooks/useNotification';
import { messages } from './messages';
import { useIntl } from 'react-intl';
import { useCacheResource } from '../../hooks';
import { ResourceTypes } from '../../utils/services/api';
import { User } from '../../types/User';
import useResource from '../../hooks/useResource';

interface Props {
  key: string;
  projectId: string;
  userId: string;
  accessRights: number;
  saveRights: (
    projectId: string,
    userId: string,
    accessRights: number | null
  ) => void;
}

export function ProjectUserRightsRow(props: Props): JSX.Element {
  const { key, projectId, accessRights, userId, saveRights } = props;
  const { addSuccessNotification, addErrorNotification } = useNotification();
  const [pending, setPending] = useState(false);
  const proj = useCacheResource(ResourceTypes.PROJECT, projectId);
  const user = useResource<User>(ResourceTypes.USER, userId);

  const intl = useIntl();
  const handleRemove = async () => {
    setPending(true);
    try {
      await saveRights(projectId, userId, null);
      addSuccessNotification(
        intl.formatMessage(messages.removeUserProjectAccessSuccessNotification)
      );
    } catch (e) {
      addErrorNotification(
        intl.formatMessage(messages.removeUserProjectAccessErrorNotification)
      );
    }
    setPending(false);
  };

  return (
    <TableRow key={key}>
      <TableCell>{user.data?.email || userId}</TableCell>
      <TableCell>
        <ProjectUserRightsInput
          projectId={projectId}
          accessRights={accessRights}
          userId={userId}
          saveRights={saveRights}
          disabled={pending}
        />
      </TableCell>
      <TableCell>
        <DeleteButton
          disabled={pending || proj.loading || user.loading}
          size='small'
          onDelete={handleRemove}
          confirmText={intl.formatMessage(messages.removeUserMessage, {
            user: user.data?.email,
            project: proj.data?.name || projectId,
          })}
        />
      </TableCell>
    </TableRow>
  );
}
