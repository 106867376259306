import React from 'react';
import { FormattedMessage } from 'react-intl';

import Currencies from '../../../../../../../../constants/currencyCodes';
import { PkpassSelect } from '../../../../../inputs';

const currencyOptions = Object.keys(Currencies).map((c) => ({
  key: c,
  text: c,
  value: c,
}));

export default function FieldTypeCurrency(): JSX.Element {
  return (
    <PkpassSelect
      options={currencyOptions}
      helpText={
        <FormattedMessage
          id='pkpass.field.currencyCode.helpText'
          defaultMessage='ISO 4217 currency code for the field’s value.'
        />
      }
      name={`properties.currencyCode`}
      label={
        <FormattedMessage
          id='pkpass.field.currencyCode.label'
          defaultMessage='Currency code'
        />
      }
    />
  );
}
