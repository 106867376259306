import React, { CSSProperties } from 'react';

import Grid from '@material-ui/core/Grid';

export default function StepGrid(props: {
  col1?: JSX.Element;
  col2?: JSX.Element;
}) {
  return (
    <Grid container={true}>
      <Grid item={true}>
        <div style={columnStyle}>{props.col1}</div>
      </Grid>
      <Grid item={true}>
        <div style={columnStyle}>{props.col2}</div>
      </Grid>
    </Grid>
  );
}

const columnStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  padding: 20,
};
