import PropTypes from 'prop-types';
import React, { FunctionComponent } from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { Main as MainLayout } from '../components/common/layouts';
import { RouteComponentProps } from 'react-router';

export const RouteWithLayout: FunctionComponent<RouteProps> = (
  props: RouteProps
) => {
  const { component, ...rest } = props;

  const Component = component as
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>;

  const render = (matchProps: any) => (
    <MainLayout>
      <Component {...matchProps} />
    </MainLayout>
  );

  return <Route {...rest} render={render} />;
};

RouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  path: PropTypes.string,
  exact: PropTypes.bool,
};
