import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { TransitionProps } from '@material-ui/core/transitions';

interface EditDrawerProps {
  content: React.ReactNode;
  open: boolean;
  onClose?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    dialog: {
      position: 'absolute',
      width: '100vw',
      [theme.breakpoints.up('md')]: {
        width: 'calc(100vw - 240px)',
        marginLeft: 240,
      },
    },
  })
);

const Transition = React.forwardRef(function Transition(
  p: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
): JSX.Element {
  return <Slide direction='left' ref={ref} {...p} timeout={1000} />;
});

export default function EditDrawer(props: EditDrawerProps): JSX.Element {
  const classes = useStyles();

  return (
    <Dialog
      fullScreen
      open={props.open}
      TransitionComponent={Transition}
      className={classes.dialog}
      // onExited={props.onClose}
      onClose={props.onClose}
    >
      {props.content}
    </Dialog>
  );
}
