import AccountTree from '@material-ui/icons/AccountTree';
import Add from '@material-ui/icons/Add';
import AnnouncementOutlined from '@material-ui/icons/AnnouncementOutlined';
import Apple from '@material-ui/icons/Apple';
import Bluetooth from '@material-ui/icons/Bluetooth';
import Close from '@material-ui/icons/Close';
import CloudDownload from '@material-ui/icons/CloudDownload';
import CloudUpload from '@material-ui/icons/CloudUpload';
import Code from '@material-ui/icons/Code';
import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import EmojiEmotions from '@material-ui/icons/EmojiEmotions';
import Error from '@material-ui/icons/Error';
import NoteAdd from '@material-ui/icons/NoteAdd';
import GetApp from '@material-ui/icons/GetApp';
import Help from '@material-ui/icons/Help';
import Image from '@material-ui/icons/Image';
import InsertDriveFile from '@material-ui/icons/InsertDriveFile';
import Language from '@material-ui/icons/Language';
import Link from '@material-ui/icons/Link';
import List from '@material-ui/icons/List';
import LocationOn from '@material-ui/icons/LocationOn';
import Lock from '@material-ui/icons/Lock';
import Loyalty from '@material-ui/icons/Loyalty';
import Person from '@material-ui/icons/Person';
import Remove from '@material-ui/icons/Remove';
import Save from '@material-ui/icons/Save';
import Search from '@material-ui/icons/Search';
import Settings from '@material-ui/icons/Settings';
import Storage from '@material-ui/icons/Storage';
import Web from '@material-ui/icons/Web';
import { GoogleIcon } from './Google';

export const PassIcon = Loyalty;
export const EditIcon = Edit;
export const DeleteIcon = Delete;
export const RemoveIcon = Remove;
export const AddIcon = Add;
export const IBeaconIcon = Bluetooth;
export const GpsLocationIcon = LocationOn;
export const SearchIcon = Search;
export const SaveIcon = Save;
export const HelpIcon = Help;
export const UploadIcon = CloudUpload;
export const GooglePayIcon = GoogleIcon;
export const PkpassIcon = Apple;
export const PreviewIcon = Web;
export const CodeIcon = Code;
export const ErrorIcon = Error;
export const DynamicDataIcon = Storage;
export const ImageIcon = Image;
export const DistributionIcon = Link;
export const CloseIcon = Close;
export const FallbackIcon = AnnouncementOutlined;
export const ShortCodeIcon = NoteAdd;
export const ListIcon = List;
export const TreeIcon = AccountTree;
export const FileIcon = InsertDriveFile;
export const ExportIcon = GetApp;
export const SettingsIcon = Settings;
export const DownloadIcon = CloudDownload;
export const EmojiIcon = EmojiEmotions;
export const LanguageIcon = Language;
export const PasswordIcon = Lock;
export const UserIcon = Person;
export const LinkIcon = Link;
