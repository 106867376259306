/* eslint-disable */
//@ts-nocheck
// source: naming.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.yourpass.naming.v1.Record', null, global);
goog.exportSymbol('proto.yourpass.naming.v1.RecordKind', null, global);
goog.exportSymbol('proto.yourpass.naming.v1.SubscribeRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yourpass.naming.v1.SubscribeRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.yourpass.naming.v1.SubscribeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yourpass.naming.v1.SubscribeRequest.displayName =
    'proto.yourpass.naming.v1.SubscribeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yourpass.naming.v1.Record = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.yourpass.naming.v1.Record, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yourpass.naming.v1.Record.displayName =
    'proto.yourpass.naming.v1.Record';
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.yourpass.naming.v1.SubscribeRequest.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.yourpass.naming.v1.SubscribeRequest.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.yourpass.naming.v1.SubscribeRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.yourpass.naming.v1.SubscribeRequest.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        since:
          (f = msg.getSince()) &&
          google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yourpass.naming.v1.SubscribeRequest}
 */
proto.yourpass.naming.v1.SubscribeRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yourpass.naming.v1.SubscribeRequest();
  return proto.yourpass.naming.v1.SubscribeRequest.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yourpass.naming.v1.SubscribeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yourpass.naming.v1.SubscribeRequest}
 */
proto.yourpass.naming.v1.SubscribeRequest.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new google_protobuf_timestamp_pb.Timestamp();
        reader.readMessage(
          value,
          google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader
        );
        msg.setSince(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yourpass.naming.v1.SubscribeRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.yourpass.naming.v1.SubscribeRequest.serializeBinaryToWriter(
    this,
    writer
  );
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yourpass.naming.v1.SubscribeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.naming.v1.SubscribeRequest.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getSince();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};

/**
 * optional google.protobuf.Timestamp since = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.yourpass.naming.v1.SubscribeRequest.prototype.getSince = function () {
  return /** @type{?proto.google.protobuf.Timestamp} */ (jspb.Message.getWrapperField(
    this,
    google_protobuf_timestamp_pb.Timestamp,
    1
  ));
};

/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.yourpass.naming.v1.SubscribeRequest} returns this
 */
proto.yourpass.naming.v1.SubscribeRequest.prototype.setSince = function (
  value
) {
  return jspb.Message.setWrapperField(this, 1, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.yourpass.naming.v1.SubscribeRequest} returns this
 */
proto.yourpass.naming.v1.SubscribeRequest.prototype.clearSince = function () {
  return this.setSince(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yourpass.naming.v1.SubscribeRequest.prototype.hasSince = function () {
  return jspb.Message.getField(this, 1) != null;
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.yourpass.naming.v1.Record.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.yourpass.naming.v1.Record.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.yourpass.naming.v1.Record} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.yourpass.naming.v1.Record.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        kind: jspb.Message.getFieldWithDefault(msg, 1, 0),
        id: jspb.Message.getFieldWithDefault(msg, 2, ''),
        projectId: jspb.Message.getFieldWithDefault(msg, 3, ''),
        name: jspb.Message.getFieldWithDefault(msg, 4, ''),
        changeTime:
          (f = msg.getChangeTime()) &&
          google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
        flags: jspb.Message.getFieldWithDefault(msg, 6, 0),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yourpass.naming.v1.Record}
 */
proto.yourpass.naming.v1.Record.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yourpass.naming.v1.Record();
  return proto.yourpass.naming.v1.Record.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yourpass.naming.v1.Record} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yourpass.naming.v1.Record}
 */
proto.yourpass.naming.v1.Record.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {!proto.yourpass.naming.v1.RecordKind} */ (reader.readEnum());
        msg.setKind(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setProjectId(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setName(value);
        break;
      case 5:
        var value = new google_protobuf_timestamp_pb.Timestamp();
        reader.readMessage(
          value,
          google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader
        );
        msg.setChangeTime(value);
        break;
      case 6:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setFlags(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yourpass.naming.v1.Record.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.yourpass.naming.v1.Record.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yourpass.naming.v1.Record} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.naming.v1.Record.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getKind();
  if (f !== 0.0) {
    writer.writeEnum(1, f);
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
  f = message.getProjectId();
  if (f.length > 0) {
    writer.writeString(3, f);
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(4, f);
  }
  f = message.getChangeTime();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getFlags();
  if (f !== 0) {
    writer.writeInt32(6, f);
  }
};

/**
 * optional RecordKind kind = 1;
 * @return {!proto.yourpass.naming.v1.RecordKind}
 */
proto.yourpass.naming.v1.Record.prototype.getKind = function () {
  return /** @type {!proto.yourpass.naming.v1.RecordKind} */ (jspb.Message.getFieldWithDefault(
    this,
    1,
    0
  ));
};

/**
 * @param {!proto.yourpass.naming.v1.RecordKind} value
 * @return {!proto.yourpass.naming.v1.Record} returns this
 */
proto.yourpass.naming.v1.Record.prototype.setKind = function (value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};

/**
 * optional string id = 2;
 * @return {string}
 */
proto.yourpass.naming.v1.Record.prototype.getId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};

/**
 * @param {string} value
 * @return {!proto.yourpass.naming.v1.Record} returns this
 */
proto.yourpass.naming.v1.Record.prototype.setId = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional string project_id = 3;
 * @return {string}
 */
proto.yourpass.naming.v1.Record.prototype.getProjectId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''));
};

/**
 * @param {string} value
 * @return {!proto.yourpass.naming.v1.Record} returns this
 */
proto.yourpass.naming.v1.Record.prototype.setProjectId = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};

/**
 * optional string name = 4;
 * @return {string}
 */
proto.yourpass.naming.v1.Record.prototype.getName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ''));
};

/**
 * @param {string} value
 * @return {!proto.yourpass.naming.v1.Record} returns this
 */
proto.yourpass.naming.v1.Record.prototype.setName = function (value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};

/**
 * optional google.protobuf.Timestamp change_time = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.yourpass.naming.v1.Record.prototype.getChangeTime = function () {
  return /** @type{?proto.google.protobuf.Timestamp} */ (jspb.Message.getWrapperField(
    this,
    google_protobuf_timestamp_pb.Timestamp,
    5
  ));
};

/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.yourpass.naming.v1.Record} returns this
 */
proto.yourpass.naming.v1.Record.prototype.setChangeTime = function (value) {
  return jspb.Message.setWrapperField(this, 5, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.yourpass.naming.v1.Record} returns this
 */
proto.yourpass.naming.v1.Record.prototype.clearChangeTime = function () {
  return this.setChangeTime(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yourpass.naming.v1.Record.prototype.hasChangeTime = function () {
  return jspb.Message.getField(this, 5) != null;
};

/**
 * optional int32 flags = 6;
 * @return {number}
 */
proto.yourpass.naming.v1.Record.prototype.getFlags = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};

/**
 * @param {number} value
 * @return {!proto.yourpass.naming.v1.Record} returns this
 */
proto.yourpass.naming.v1.Record.prototype.setFlags = function (value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};

/**
 * @enum {number}
 */
proto.yourpass.naming.v1.RecordKind = {
  RECORD_KIND_UNKNOWN: 0,
  RECORD_KIND_PROJECT: 1,
  RECORD_KIND_CERTIFICATE: 2,
  RECORD_KIND_TEMPLATE: 3,
};

goog.object.extend(exports, proto.yourpass.naming.v1);
