import React from 'react';
import { FormattedMessage } from 'react-intl';

import { SaveIcon } from '../icons';
import { Button, ButtonProps } from './Button';

export const SaveButton = (props: ButtonProps): JSX.Element => {
  return (
    <Button type='button' color='primary' {...props} startIcon={<SaveIcon />}>
      <FormattedMessage id={'SaveButton.button.text'} defaultMessage={'Save'} />
    </Button>
  );
};
