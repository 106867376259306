import React from 'react';

import { DataGridColumns, Fields } from '../../components/common/dataGrid';
import DataGridResources from '../../components/common/dataGrid/DataGridResources';
import { StaticLabel } from '../../components/common/labels';
import { ResourceTypes } from '../../utils/services/api';
import { ProjectListItem } from './types';
import { ProjectListActions } from './ProjectListActions';
import { ProjectLabelWithDrawer } from './ProjectLabelWithDrawer';

const filterProps = [
  {
    columnName: 'name',
    name: 'name',
    type: 'like',
  },
];

export function ProjectListTable(props: { onCreate: () => void }): JSX.Element {
  const fields: Fields<ProjectListItem> = [
    { key: 'name', title: StaticLabel.Name },
    {
      key: 'parentId',
      title: StaticLabel.Parent,
      render(item: ProjectListItem) {
        return item.parentId ? (
          <ProjectLabelWithDrawer resourceId={item.parentId} />
        ) : null;
      },
    },
    { key: 'active', title: 'Active' },
    {
      key: DataGridColumns.Actions,
      title: StaticLabel.Actions,
      render(item: ProjectListItem, listLoad: () => void) {
        return (
          <ProjectListActions
            item={item}
            listLoad={listLoad}
            resource={ResourceTypes.PROJECT}
            onCreate={props.onCreate}
          />
        );
      },
    },
  ];

  return (
    <DataGridResources
      resource={ResourceTypes.PROJECT}
      filterProps={filterProps}
      filterOptions={{ name: true }}
      fields={fields}
      actionsWidth={170}
    />
  );
}
