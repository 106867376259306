import { FormikErrors } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  ButtonProps,
  CancelButton,
  CreateButton,
  ResetButton,
  UploadButton,
} from '../common/buttons';
import ImageUploadDialogContent from './ImageUploadDialogContent';
import useViewer from '../../hooks/useViewer';
import { ResourceTypes } from '../../utils/services/api';
import { Image, imagesConfig } from '../../types/Image';
import { ImageTypes } from '../../types/Template';
import { ImageFormInterface } from './ImageForm';
import messages from './messages';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { useCreateFormik } from '../common/formik/useCreateFormik';

export interface ImageCreateProps {
  projectId?: string;
  type?: ImageTypes;
  buttonProps?: ButtonProps;
  onCreate?: (image: Image) => void;
}

const ImageUploadDialog = (props: ImageCreateProps) => {
  const viewer = useViewer();
  const intl = useIntl();

  const defaultImage = {
    name: '',
    projectId: props.projectId,
    type: props.type || ImageTypes.strip,
  };

  const validate = (values: ImageFormInterface) => {
    const errors: FormikErrors<ImageFormInterface> = {};

    const imageType = props.type || values.type;
    if (values.url && imageType && values.width && values.height) {
      const imageDef = imagesConfig[imageType];
      const sizeErrors = imageDef.validation(values.width, values.height);
      if (sizeErrors.length > 0) {
        errors.url = JSON.stringify(sizeErrors);
      }
    }

    if (values.mimeType && values.mimeType !== 'image/png') {
      errors.mimeType = intl.formatMessage(
        messages.IMAGE_VALIDATION_UNSUPORTED_FILE,
        {
          mimeType: values.mimeType,
          name: values.name,
        }
      );
    }

    if (!values.projectId) {
      errors.projectId = intl.formatMessage(messages.MissingProject);
    }

    if (Object.keys(errors).length > 0) {
      return errors;
    }

    return undefined;
  };

  const handleOnCreate = (i: unknown) => {
    if (props.onCreate !== undefined) {
      props.onCreate(i as Image);
    }
  };

  const {
    open,
    handleSubmitClick,
    handleCancel,
    formik,
    handleReset,
  } = useCreateFormik<ImageFormInterface>({
    resource: ResourceTypes.IMAGE,
    onCreate: handleOnCreate,
    initialValues: defaultImage,
    validateOnBlur: false,
    validateOnChange: false,
    validate,
  });

  if (!(viewer && viewer.canManageImages())) {
    return null;
  }

  return (
    <>
      <UploadButton {...props.buttonProps} onClick={open.handleOpen} />
      <Dialog open={open.open} maxWidth={'md'} fullWidth={true}>
        <DialogTitle>
          <FormattedMessage
            id='ImageCreate.title'
            defaultMessage='Create new image'
          />
        </DialogTitle>
        <DialogContent>
          <ImageUploadDialogContent
            {...formik}
            projectId={props.projectId}
            type={props.type}
          />
        </DialogContent>

        <DialogActions>
          <CancelButton onClick={handleCancel} />
          <ResetButton onClick={handleReset} />
          <CreateButton onClick={handleSubmitClick} />
        </DialogActions>
      </Dialog>
    </>
  );
};

ImageUploadDialog.propTypes = {
  projectId: PropTypes.string,
  type: PropTypes.string,
};

export default ImageUploadDialog;
