import React from 'react';

import useViewer from '../../../hooks/useViewer';
import { TemplateListItem } from '../../../types/Template';
import { LinkEditIconButton } from '../../common/buttons';

const resource = 'design';

export default function DesignListItemActions(props: {
  item: TemplateListItem;
}): JSX.Element {
  const { item } = props;
  const viewer = useViewer();
  const canRead = viewer && viewer.canReadTemplateOnProject(item.projectId);
  const canUpdate = viewer && viewer.canUpdateTemplateOnProject(item.projectId);

  return (
    <LinkEditIconButton
      path={`/passes/${resource}/pkpass/${item.id}`}
      disabled={!(canRead || canUpdate)}
    />
  );
}
