import React from 'react';
import {
  PkpassLocalizedString,
  PkpassLocalizedStringData,
} from './PkpassLocalizedString';

type ValueType =
  | {
      type: 'string';
      value?: string;
      fallback?: string;
    }
  | undefined;

export interface PkpassStringData {
  name: string;
  value: ValueType;
}

export interface PkpassStringProps {
  name: string;
  label: JSX.Element;
  helpText: JSX.Element;
  required?: boolean;
  disabled?: boolean;
  multiline?: boolean;
  onChange?: (data: PkpassStringData) => void;
  placeholder?: string;
}

export const PkpassString = (props: PkpassStringProps): JSX.Element => {
  const { onChange, ...r } = props;
  const handleChange = (d: PkpassLocalizedStringData) => {
    if (onChange) {
      onChange(d as PkpassStringData);
    }
  };

  return (
    <PkpassLocalizedString
      {...r}
      onChange={handleChange}
      localized={false}
      multiline={props.multiline === true}
    />
  );
};
