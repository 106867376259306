import { set } from 'lodash';
import React from 'react';

import DynamicDataEditorAddModal from './JsonSchemaEditorAddDialog';
import { DynamicDataEditorProps } from './JsonSchemaEditor';

export default function JsonSchemaEditorAdd(p: DynamicDataEditorProps) {
  const handleSubmit = (name: string, formData: any, required: boolean) => {
    set(p.jsonSchema, `properties.${name}`, formData);

    const requireIds: string[] = (p.jsonSchema.required as string[]) || [];
    if (required) {
      requireIds.push(name);
    }
    set(p.jsonSchema, `required`, [...requireIds]);

    const d = JSON.parse(JSON.stringify(p.jsonSchema));
    p.onChange(d);
  };

  return (
    <DynamicDataEditorAddModal
      onSubmit={handleSubmit}
      jsonSchema={p.jsonSchema}
    />
  );
}
