import React, { Fragment } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Icon from '@material-ui/icons/Delete';

import useOpen from '../../../hooks/useOpen';
import { Button, ButtonProps } from './Button';
import { CancelButton } from './CancelButton';
import { ConfirmButton } from './ConfirmButton';

const messages = defineMessages({
  defaultConfirmText: {
    defaultMessage: 'Resource will be deleted. Are you sure?',
    id: 'DeleteButton.confirm.default.text',
  },
});

interface DeleteButtonProps extends ButtonProps {
  onDelete: () => void;
  confirmText?: string;
}

export const DeleteButton = (props: DeleteButtonProps): JSX.Element => {
  const { onDelete, confirmText, ...btnProps } = props;
  const { open, handleClose, handleOpen } = useOpen(false);
  const intl = useIntl();
  const handleConfirm = () => {
    handleClose();
    onDelete();
  };

  return (
    <Fragment>
      <Button
        {...btnProps}
        onClick={handleOpen}
        type='button'
        startIcon={<Icon />}
      >
        <FormattedMessage
          id={'DeleteButton.button.text'}
          defaultMessage={'Delete'}
        />
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          <FormattedMessage
            id='DeleteButton.duplicate.dialog.title'
            defaultMessage='Remove'
          />
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            {confirmText || intl.formatMessage(messages.defaultConfirmText)}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <CancelButton onClick={handleClose} />
          <ConfirmButton onClick={handleConfirm} autoFocus={true} />
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};
