import { getIn } from 'formik';
import React from 'react';

import { LabelGPS } from '../../../../common/labels';
import { PkpassLocation } from '../../../../../types/Template';

export default function LocationLabel(props: {
  location: PkpassLocation;
}): JSX.Element {
  const lat =
    parseFloat(getIn(props.location, 'properties.latitude.value')) ||
    parseFloat(getIn(props.location, 'properties.latitude.fallback')) ||
    0;
  const lng =
    parseFloat(getIn(props.location, 'properties.longitude.value')) ||
    parseFloat(getIn(props.location, 'properties.latitude.fallback')) ||
    0;

  return <LabelGPS lat={lat} lng={lng} link={true} />;
}
