import React from 'react';
import { FormattedMessage } from 'react-intl';

import barcodeImage128 from '../../../../assets/pkpass/barcodeImage_128.svg';
import barcodeImageAztec from '../../../../assets/pkpass/barcodeImage_aztec.svg';
import barcodeImagePdf417 from '../../../../assets/pkpass/barcodeImage_pdf417.svg';
import barcodeImageQr from '../../../../assets/pkpass/barcodeImage_qr.svg';
import { PkpassBarcodeTypes } from '../../../../types/Template';
import pkpassMessages from '../pkpass.messages';

const barcodeFallbackValues: BarcodeDefinition = {
  [PkpassBarcodeTypes.PKBarcodeFormatAztec]: {
    img: barcodeImageAztec,
    isSquared: true,
  },
  [PkpassBarcodeTypes.PKBarcodeFormatPDF417]: {
    img: barcodeImagePdf417,
    isSquared: false,
  },
  [PkpassBarcodeTypes.PKBarcodeFormatQR]: {
    img: barcodeImageQr,
    isSquared: true,
  },
};

const barcodeTypesValues: BarcodeDefinition = {
  ...barcodeFallbackValues,
  [PkpassBarcodeTypes.PKBarcodeFormatCode128]: {
    img: barcodeImage128,
    isSquared: true,
  },
};

interface BarcodeDefinition {
  [key: string]: { img: string; isSquared: boolean };
}

function getValues(values: BarcodeDefinition) {
  return Object.keys(values).map((key: string) => {
    const type = key as PkpassBarcodeTypes;
    return {
      // image: { avatar: false, src: values[type].img },
      key,
      text: <FormattedMessage {...pkpassMessages[type]} />,
      value: key,
    };
  });
}

export const barcodeTypes = barcodeTypesValues;
export const barcodeTypeOptions = getValues(barcodeTypesValues);
export const barcodeFallbackOptions = getValues(barcodeFallbackValues);
