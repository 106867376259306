import React, { PropsWithChildren, useRef } from 'react';

import { ColorsDialog } from './colors/ColorsDialog';
import DesignerBackSide from './layout/DesignerBackSide';
import DesignerFrontSide from './layout/DesignerFrontSide';
import PassStyleSwitcher from './passStyle/switcher/PassStyleSwitcher';
import BoardingPassIconPopUp from './passStyle/BoardingPassIconPopUp';
import { useTemplateUI } from '../useTemplateUI';
import { usePreview } from '../usePreview';
import { ControlLayoutProps } from './layout/ControlLayout';

function Column(props: PropsWithChildren<unknown>) {
  return <div style={styles.column}>{props.children}</div>;
}

const Designer = (props: ControlLayoutProps): JSX.Element => {
  const ref = useRef<HTMLDivElement>(null);
  const templateUI = useTemplateUI();

  const {
    pass,
    images,
    onLogoTextClick,
    onBoardingPassIconClick,
    onBarcodeClick,
    onImageClick,
    handleFieldDnD,
    handleClickField,
  } = usePreview();

  console.log('Designer render');
  return (
    <div style={styles.row}>
      <Column>
        <h2 style={styles.header}>Appearance</h2>
        <div style={styles.spacer} />
        <div ref={ref}>
          <DesignerFrontSide
            pass={pass}
            images={images}
            language={templateUI.templatePreviewLanguage}
            scanDisabled={true}
            onFieldClick={handleClickField}
            onFieldDnD={handleFieldDnD}
            onLogoTextClick={onLogoTextClick}
            onBoardingPassIconClick={onBoardingPassIconClick}
            onBarcodeClick={onBarcodeClick}
            onImageClick={onImageClick}
            disableFields={props.disableFields}
            disableImages={props.disableImages}
            disableBarcodes={props.disableBarcodes}
          />
        </div>
        <div style={{ paddingTop: 20 }}>
          <PassStyleSwitcher />
          <ColorsDialog key='passColor' />
        </div>
      </Column>
      <Column>
        <h2 style={styles.header}>Content</h2>
        <div style={styles.spacer} />
        <DesignerBackSide
          pass={pass}
          images={images}
          language={templateUI.templatePreviewLanguage}
          scanDisabled={true}
          onFieldClick={handleClickField}
          onFieldDnD={handleFieldDnD}
        />
      </Column>
      <BoardingPassIconPopUp anchorEl={ref.current} />
    </div>
  );
};
export default Designer;

const styles = {
  column: {
    marginLeft: 50,
    marginRight: 50,
    paddingBottom: 40,
    position: 'relative' as const,
    width: 320,
  },
  header: { margin: 0 },
  info: {
    height: 20,
    paddingBottom: 20,
    paddingTop: 8,
    textAlign: 'center' as const,
  },

  row: {
    display: 'flex' as const,
    flexDirection: 'row' as const,
    flexFlow: 'row wrap',
    justifyContent: 'center',
  },
  spacer: { height: 20 },
  subHeader: { paddingBottom: 10 },

  warnText: { color: 'red', cursor: 'pointer' },
};
