import { FormikProps } from 'formik';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { CreateButton, NextButton } from '../common/buttons';
import { FieldAutocompleteTemplate } from '../common/fields';
import Form from '../common/Form';
import {
  useVerticalStepper,
  VerticalStepItem,
  VerticalStepper,
} from '../common/stepper';
import Loader from '../common/loader/Loader';
import useResource from '../../hooks/useResource';
import { ResourceTypes } from '../../utils/services/api';
import { Template } from '../../types/Template';
import PassForm from './PassEditForm';
import PassFormInterface from './PassFormInterface';

type Props = FormikProps<PassFormInterface>;

function PassPassModalFormRender(props: Props & { templateId: string }) {
  const { values } = props;
  const { data, loading } = useResource<Template>(
    ResourceTypes.TEMPLATE,
    props.templateId
  );

  if (loading) {
    return <Loader />;
  }
  if (!data) {
    return <span>{`${values.templateId}`}</span>;
  }
  return <PassForm {...props} template={data} />;
}

export default function PassCreateForm(props: Props): JSX.Element {
  const { values, submitForm, isValid } = props;
  const stepper = useVerticalStepper();
  const handleChangeTemplate = (value: string | undefined) => {
    props.setFieldValue('templateId', value);
  };

  const steps: VerticalStepItem[] = [
    {
      title: (
        <FormattedMessage
          id='PassPassModalForm.step0.title'
          defaultMessage='Select template'
        />
      ),
      subtitle: (
        <FormattedMessage
          id='PassPassModalForm.step0.infoText'
          defaultMessage='For create new pass please select template. After select template you can see full form.'
        />
      ),
      content: (
        <Form>
          <FieldAutocompleteTemplate
            name={'templateId'}
            onChange={handleChangeTemplate}
            value={values.templateId}
            multiple={false}
          />
        </Form>
      ),
      actions: (
        <NextButton disabled={!values.templateId} onClick={stepper.next} />
      ),
    },
    {
      title: (
        <FormattedMessage
          id='PassPassModalForm.step1.title'
          defaultMessage='Set pass properties'
        />
      ),
      subtitle: (
        <FormattedMessage
          id='PassPassModalForm.step1.infoText'
          defaultMessage='Please fill pass dynamic data and parts to define pass.'
        />
      ),
      content: (
        <PassPassModalFormRender
          {...props}
          templateId={values.templateId || ``}
        />
      ),
      actions: (
        <CreateButton disabled={!isValid} onClick={submitForm}></CreateButton>
      ),
    },
  ];

  return <VerticalStepper activeStep={stepper.step} steps={steps} />;
}
