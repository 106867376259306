import React, { FunctionComponent } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';

import {
  LanguageIcon,
  PasswordIcon,
  SettingsIcon,
  UserIcon,
} from '../../components/common/icons';
import Page from '../../components/common/page/Page';
import FormTab, { TabOption } from '../../components/common/Tabs';
import UserChangePassword from '../../components/profile/ProfileChangePassword';
import ProfileLocale from '../../components/profile/ProfileLocale';
import Profile from '../../components/profile/Profile';
import ProfileSettings from '../../components/profile/ProfileSettings';

const messages = defineMessages({
  userTabChangePassword: {
    defaultMessage: 'Change password',
    id: 'Profile.tab.change.password',
  },
  userTabLocale: {
    defaultMessage: 'Locale & Language',
    id: 'Profile.tab.locale',
  },
  userTabProfile: {
    defaultMessage: 'Profile',
    id: 'Profile.tab.profile',
  },
  userTabSettings: {
    defaultMessage: 'Settings',
    id: 'Profile.tab.settings',
  },
});

export const ProfilePage: FunctionComponent = () => {
  const intl = useIntl();
  const tabOptions: TabOption[] = [
    {
      content: <Profile />,
      icon: <UserIcon />,
      key: 't1Profile',
      name: intl.formatMessage(messages.userTabProfile),
    },
    {
      content: <UserChangePassword />,
      icon: <PasswordIcon />,
      key: 't2Password',
      name: intl.formatMessage(messages.userTabChangePassword),
    },
    {
      content: <ProfileLocale />,
      icon: <LanguageIcon />,
      key: 'tLocale',
      name: intl.formatMessage(messages.userTabLocale),
    },
    {
      content: <ProfileSettings />,
      icon: <SettingsIcon />,
      key: 'tSettings',
      name: intl.formatMessage(messages.userTabSettings),
    },
  ];

  return (
    <Page
      content={
        <FormTab
          tabOptions={tabOptions}
          formName='USER_TAB'
          defaultTab='t1Profile'
        />
      }
      title={<FormattedMessage defaultMessage='Settings' id='UserPage.title' />}
    />
  );
};
