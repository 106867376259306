import { useFormikContext, FormikProps } from 'formik';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles';

import { Template } from '../../../types/Template';
import TemplatePreviewLanguageSelect from '../../template/TemplatePreviewLanguageSelect';
import Apps from './designer/apps/Apps';
import Designer from './designer/Designer';
import PkpassAdditionalOptions from './PkpassAdditionalOptions';
import { PkpassIdentification } from './PkpassIdentification';
import { RelevanceKeys } from './relevance/RelevanceKeys';
import Box from '@material-ui/core/Box';
import { useTemplateUI } from './useTemplateUI';
import { TemplateUIContextInterface } from './TemplateUIContext';
import isEqual from 'react-fast-compare';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      margin: theme.spacing(1),
    },
    cardContent: {
      paddingTop: 0,
    },
  })
);

function DesignPkpassT(props: FormikProps<Template>): JSX.Element {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <>
      <Card className={classes.card}>
        <CardHeader
          title={
            <FormattedMessage
              id='PkpassForm.designer.title'
              defaultMessage='Designer'
            />
          }
          action={
            <TemplatePreviewLanguageSelect languages={props.values.languages} />
          }
        />
        <CardContent className={classes.cardContent}>
          <Designer
            disableFields={false}
            disableImages={false}
            disableBarcodes={false}
          />
        </CardContent>
      </Card>

      <Box p={2} />
      <Card className={classes.card}>
        <CardHeader
          title={
            <FormattedMessage
              id='PkpassForm.relevance.title'
              defaultMessage='Relevance'
            />
          }
        />
        <CardContent>
          <RelevanceKeys />
        </CardContent>
      </Card>
      <Box p={2} />
      <Card className={classes.card}>
        <CardHeader
          title={
            <FormattedMessage
              id='PkpassForm.additional.title'
              defaultMessage='Additional'
            />
          }
        />
        <CardContent>
          <PkpassAdditionalOptions />
        </CardContent>
      </Card>
      <Box p={2} />
      <PkpassIdentification />
      <Box p={2} />
      <Card className={classes.card}>
        <CardHeader
          title={
            <FormattedMessage
              id='PkpassForm.associatedApps.title'
              defaultMessage='Associated Apps'
            />
          }
        />
        <CardContent>
          <Apps {...props} />
        </CardContent>
      </Card>
    </>
  );
}

const MemonizedDesignPkpass = React.memo(
  function memonizedDesignPkpass(
    props: TemplateUIContextInterface & FormikProps<Template>
  ) {
    return <DesignPkpassT {...props} />;
  },
  (p, n) => {
    const changedTemplate = isEqual(p.values, n.values);
    return changedTemplate;
  }
);

export default function DesignPkpass() {
  const templateUI = useTemplateUI();
  const formik = useFormikContext<Template>();
  return <MemonizedDesignPkpass {...templateUI} {...formik} />;
}
