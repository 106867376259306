import React from 'react';
import { FormattedMessage } from 'react-intl';

import { FieldSelectData, FieldSelectLanguage } from '../common/fields';
import { useTemplateUI } from '../design/pkpass/useTemplateUI';

export default function TemplatePreviewLanguageSelect(props: {
  languages: string[];
}) {
  const templateUI = useTemplateUI();
  const languages = props.languages;
  const previewLanguage = templateUI.templatePreviewLanguage;

  const languageChange = (event: FieldSelectData) => {
    templateUI.uiSetTemplatePreviewLanguage(`${event.value}`);
  };

  return (
    <FieldSelectLanguage
      label={
        <FormattedMessage
          id='PkpassForm.preview.language'
          defaultMessage='Language'
        />
      }
      onChange={languageChange}
      value={previewLanguage}
      languages={languages}
      name='previewLanguage'
    />
  );
}
