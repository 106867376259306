import { FormikProps } from 'formik';
import React from 'react';

import { ResetButton } from '../buttons';

export default function FormikResetButton(props: FormikProps<any>) {
  const handleReset = () => {
    props.setSubmitting(true);
    props.resetForm(props.initialValues);
    setTimeout(() => {
      props.setSubmitting(false);
    }, 500);
  };

  return <ResetButton onClick={handleReset} disabled={!props.dirty} />;
}
