import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Button, ButtonProps } from './Button';

export const PreviousButton = (props: ButtonProps) => {
  return (
    <Button type='button' {...props}>
      <FormattedMessage
        id={'PreviousButton.button.text'}
        defaultMessage={'Previous'}
      />
    </Button>
  );
};
