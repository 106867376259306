import { FormikProps } from 'formik';
import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';

import { ResetButton, SaveButton } from '../../common/buttons';
import { ResourceDeleteButton } from '../../common/ResourceDeleteButton';
import useViewer from '../../../hooks/useViewer';
import { ResourceTypes } from '../../../utils/services/api';
import { Template } from '../../../types/Template';

type Props = FormikProps<Template>;

const DesignEditControls = (props: Props) => {
  const { values, dirty } = props;
  const viewer = useViewer();

  const handleReset = () => {
    props.resetForm({ values: props.initialValues });
  };

  const projectId = values.projectId;
  const canUpdate = viewer && viewer.canUpdateTemplateOnProject(projectId);
  const canDelete = viewer && viewer.canDeleteTemplateOnProject(projectId);

  return (
    <Fragment>
      {canUpdate && (
        <SaveButton
          color='primary'
          onClick={props.submitForm}
          disabled={!dirty}
          tooltip={
            <FormattedMessage
              id='DesignEditControls.save.tooltip'
              defaultMessage='Saves the current changes and creates a new version of the design. Use the push button to update existing passes.'
            />
          }
        />
      )}

      <ResetButton
        onClick={handleReset}
        disabled={!dirty}
        tooltip={
          <FormattedMessage
            id='DesignEditControls.reset.tooltip'
            defaultMessage='Sets the design to its original state before any changes were made.'
          />
        }
      />

      {canDelete && (
        <ResourceDeleteButton
          resource={ResourceTypes.TEMPLATE}
          resourceId={values.id}
          tooltip={
            <FormattedMessage
              id='DesignEditControls.delete.tooltip'
              defaultMessage='Performs irreversible deletion of the design (no longer offered in the console). Passes will be retained.'
            />
          }
        />
      )}
    </Fragment>
  );
};

export default DesignEditControls;
