import PropTypes from 'prop-types';
import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import Grid from '@material-ui/core/Grid';

import { Button } from '../../../../../common/buttons';
import { CancelButton } from '../../../../../common/buttons/CancelButton';
import Dialog from '../../../../../common/dialog';
import { OpenInterface } from '../../../../../../hooks/useOpen';
import AppStoreAddForm from './AppStoreAddForm';
import AppStoreAddSearchForm from './AppStoreAddSearchForm';
import Divider from '@material-ui/core/Divider';

interface AppStoreAddModalProps {
  onAdd: (id: number) => void;
}

const AppStoreAddModal: FunctionComponent<AppStoreAddModalProps> = (
  props: AppStoreAddModalProps
): JSX.Element => {
  const renderActions = (open: OpenInterface) => {
    return <CancelButton onClick={open.handleClose} />;
  };

  const renderContent = (open: OpenInterface) => {
    const handleAdd = (id: number) => {
      props.onAdd(id);
      open.handleClose();
    };

    return (
      <Grid container={true}>
        <Grid item={true} xs={7}>
          <AppStoreAddSearchForm onAdd={handleAdd} />
        </Grid>
        <Grid item={true} xs={1}>
          <Divider orientation={'vertical'} />
        </Grid>
        <Grid item={true} xs={4}>
          <AppStoreAddForm onAdd={handleAdd} />
        </Grid>
      </Grid>
    );
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'lg'}
      title={
        <FormattedMessage
          id='AppStoreAddModal.modal.header'
          defaultMessage='Add Application'
        />
      }
      trigger={
        <Button color='primary' variant={'contained'}>
          <FormattedMessage
            id='AppStoreAddModal.add.button'
            defaultMessage='Add App'
          />
        </Button>
      }
      renderActions={renderActions}
      renderContent={renderContent}
    />
  );
};

AppStoreAddModal.propTypes = {
  onAdd: PropTypes.func.isRequired,
};

export default AppStoreAddModal;
