import { FormikProps } from 'formik';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import { JsonSchemaForm } from '../common/jsonSchema';
import {
  FieldAutocompleteTemplate,
  FieldCheckbox,
  FieldCheckboxChange,
  FieldDateChange,
  FieldDateTime,
  FieldEntityTimestamps,
  FieldJSON,
  FieldSelectData,
} from '../common/fields';
import Form from '../common/Form';
import {
  CodeIcon,
  DistributionIcon,
  DynamicDataIcon,
  EditIcon,
  GooglePayIcon,
  ImageIcon,
} from '../common/icons';
import { StaticLabel } from '../common/labels';
import FormTab, { TabOption } from '../common/Tabs';
import UrlWithTags from '../common/UrlWithTags';
import { Images } from '../../types/Image';
import { Template } from '../../types/Template';
import { getPassStyle } from '../design/pkpass/helpers/passStyle';
import PassFormInterface from './PassFormInterface';
import PassImages from './PassImages';
import SaveToGooglePay from './PassSaveToGooglePay';
import useViewer from '../../hooks/useViewer';

interface Props extends FormikProps<PassFormInterface> {
  template: Template;
}

export default function PassEditForm(props: Props) {
  const { values, setFieldValue, setValues, setFieldTouched, template } = props;
  const viewer = useViewer();
  const [previewLanguage, setPreviewLanguage] = useState(template.languages[0]);

  useEffect(() => {
    moment.locale('en');
  });

  const handleChangePreviewLanguage = (e: FieldSelectData) => {
    setPreviewLanguage(e.value as string);
  };

  const handleDynamicDataChange = (
    dynamicData: Record<string, unknown> | undefined
  ) => {
    setFieldValue('dynamicData', dynamicData);
  };

  const handleVoided = (event: FieldCheckboxChange) => {
    setFieldValue('voided', event.value);
  };

  const handleTemplateChange = (value: string | undefined) => {
    setFieldValue('templateId', value);
  };

  const handleExpirationDateChange = (v: FieldDateChange) => {
    if (typeof v.value !== 'undefined' && v.value !== null) {
      setFieldValue('expirationDate', v.value);
    } else {
      setFieldValue('expirationDate', undefined);
    }
  };

  const handleChangeJSON = (p: { value: unknown }) => {
    setValues(p.value as PassFormInterface);
    setFieldTouched('id', true);
  };

  const setImages = (value: Images) => {
    setFieldValue('dynamicImages', value);
    setFieldTouched('dynamicImages', true);
  };

  const schema = template.dynamicDataJsonSchema;
  schema.type = 'object';

  const tabOptions: TabOption[] = [
    {
      content: (
        <Card>
          <CardContent>
            <Form key={0}>
              <FieldAutocompleteTemplate
                onChange={handleTemplateChange}
                value={values.templateId}
                multiple={false}
                name={'templateId'}
              />

              <FieldDateTime
                name='expirationDate'
                label={StaticLabel.Expiration}
                value={values.expirationDate}
                onChange={handleExpirationDateChange}
              />

              <FieldCheckbox
                name={'voided'}
                label='Voided'
                checked={values.voided}
                onChange={handleVoided}
              />

              <FieldEntityTimestamps data={values} />
            </Form>
          </CardContent>
        </Card>
      ),
      icon: <EditIcon />,
      key: 'general',
      name: StaticLabel.General,
    },
    {
      content: (
        <Card>
          <CardContent>
            {' '}
            <JsonSchemaForm
              schema={schema}
              onChange={handleDynamicDataChange}
              formData={values.dynamicData}
              uiSchema={{}}
            />
          </CardContent>
        </Card>
      ),
      icon: <DynamicDataIcon />,
      key: 'dynamicData',
      name: StaticLabel.DynamicData,
    },
  ];
  if (values.id && values.url) {
    tabOptions.push({
      content: (
        <Card>
          <CardContent>
            <UrlWithTags urlBase={values.url} />
          </CardContent>{' '}
        </Card>
      ),
      icon: <DistributionIcon />,
      key: 'url',
      name: StaticLabel.Url,
    });
  }
  if (template && template.jsonTemplate) {
    tabOptions.push({
      content: (
        <Card>
          <CardContent>
            <PassImages
              passStyle={getPassStyle(template)}
              previewLanguage={previewLanguage}
              languages={template.languages}
              images={values.dynamicImages as Images}
              projectId={template.projectId}
              onChange={setImages}
              onChangeLanguage={handleChangePreviewLanguage}
            />
          </CardContent>
        </Card>
      ),
      icon: <ImageIcon />,
      key: 'dynamicImages',
      name: StaticLabel.DynamicImages,
    });
  }

  if (viewer.isAdmin) {
    tabOptions.push({
      content: (
        <Card>
          <CardContent>
            <FieldJSON
              onChange={handleChangeJSON}
              label={StaticLabel.JSON}
              name='json'
              value={values}
            />
          </CardContent>
        </Card>
      ),
      icon: <CodeIcon />,
      key: 'json',
      name: StaticLabel.JSON,
    });
  }

  if (template.saveToGooglePayObjectTemplate) {
    tabOptions.push({
      content: (
        <Card>
          <CardContent>
            <SaveToGooglePay pass={values} />
          </CardContent>
        </Card>
      ),
      error: false,
      icon: <GooglePayIcon />,
      key: 'saveToGooglePay',
      name: 'Save to Google Pay',
    });
  }

  return (
    <FormTab tabOptions={tabOptions} formName='pass' defaultTab='general' />
  );
}
