import { isArray } from 'lodash';
import React, { ChangeEvent } from 'react';
import { FormattedMessage } from 'react-intl';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

import usePagination from '../../../hooks/usePagination';
import { DataGridCellObject } from '../dataGrid/';
import { BulkResultItem } from './types';

interface Props {
  results: BulkResultItem[];
}

export default function BulkResultTable(props: Props) {
  const { setPage, page, data, setPageSize, pageSize } = usePagination(
    props.results,
    5
  );

  const handlePageChange = (e: any, m: number) => {
    setPage(m);
  };

  return (
    <TableContainer>
      <Table size='small'>
        <TableHead>
          <TableRow>
            <TableCell>
              <FormattedMessage
                id='BulkResultTable.status.code'
                defaultMessage='Status code'
              />
            </TableCell>
            <TableCell>
              <FormattedMessage
                id='BulkResultTable.status.message'
                defaultMessage='Status message'
              />
            </TableCell>
            <TableCell>
              <FormattedMessage
                id='BulkResultTable.data'
                defaultMessage='Data'
              />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item: any, index: number) => (
            <TableRow key={`${index}`}>
              <TableCell>{item.status.code}</TableCell>
              <TableCell>
                {item &&
                item.status &&
                item.status.messages &&
                isArray(item.status.messages) ? (
                  <List>
                    {item.status.messages.map((m: string, i: number) => {
                      return <ListItem key={`${i}`}>{m}</ListItem>;
                    })}
                  </List>
                ) : (
                  `${
                    item.status.messages ||
                    (item.status.code === 201 ? 'created' : undefined) ||
                    (item.status.code === 200 ? 'updated' : undefined)
                  }`
                )}
              </TableCell>
              <TableCell>
                <DataGridCellObject data={data[index]} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <TablePagination
        component='div'
        count={-1}
        rowsPerPage={pageSize}
        page={page - 1}
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        onChangeRowsPerPage={(
          event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
        ) => {
          setPageSize(event.target.value);
        }}
        onChangePage={handlePageChange}
      />
    </TableContainer>
  );
}
