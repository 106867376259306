import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import ResourcePage from '../../../components/common/page/ResourcePage';
import { ResourcePageProps } from '../../types';
import DesignEditDrawer from '../../../components/design/googlepay/DesignGooglePayEditDrawer';
import DesignList from '../../../components/design/googlepay/DesignGooglePayList';

export const DesignGooglePayPage: FunctionComponent<ResourcePageProps> = (
  props: ResourcePageProps
) => {
  return (
    <ResourcePage
      {...props}
      DrawerComponent={DesignEditDrawer}
      content={<DesignList autoLoad={!props.match.params.id} />}
      controls={<span />}
      title={
        <FormattedMessage
          id='DesignGooglePayPage.title'
          defaultMessage='Google Pay for passes design'
        />
      }
      onCloseDrawer={() => {
        props.history.push('/passes/design/googlepay');
      }}
    />
  );
};
