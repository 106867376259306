import React from 'react';
// eslint-disable-next-line import/order
import AceEditor from 'react-ace';
import 'ace-builds/src-min-noconflict/mode-json';
import 'ace-builds/src-min-noconflict/theme-textmate';
// eslint-disable-next-line import/order

import Link from '@material-ui/core/Link';
import Popover from '@material-ui/core/Popover';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

export const DataGridCellObject = (props: { data: any }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <Link onClick={handleClick}>
        Object {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
      </Link>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom',
        }}
        transformOrigin={{
          horizontal: 'center',
          vertical: 'top',
        }}
      >
        <div style={{ width: 400, margin: 20 }}>
          <AceEditor
            style={{ width: 400, height: 600 }}
            placeholder='Placeholder Text'
            mode='json'
            theme='textmate'
            fontSize={10}
            showPrintMargin={false}
            showGutter={false}
            highlightActiveLine={false}
            readOnly={true}
            value={JSON.stringify(props.data, null, 2)}
            wrapEnabled={true}
            setOptions={{
              enableBasicAutocompletion: true,
              enableLiveAutocompletion: true,
              enableSnippets: true,
              showLineNumbers: false,

              tabSize: 2,
              useWorker: true,
              maxLines: 1000,
            }}
          />
        </div>
      </Popover>
    </div>
  );
};
