import React, { Fragment, useState } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';

import { isValidHref } from '../../../utils/validation/helper';
import { AddButton } from '../buttons';
import { FieldLabel, FieldTextInput, FieldTextInputData } from '../fields';
import Form from '../Form';
import { LinkIcon } from '../icons';
import { WysiwygPopUp } from './WysiwygPopUp';

const messages = defineMessages({
  hrefPlaceholder: {
    defaultMessage: 'Please fill link href',
    id: 'WysiwigLink.form.href.placeholder',
  },
  titlePlaceholder: {
    defaultMessage: 'Please fill link title',
    id: 'WysiwigLink.form.title.placeholder',
  },
});

interface Props {
  onSelect: (value: string) => void;
  selectedText: string;
}

function useFieldText(defaultValue: string) {
  const [value, setValue] = useState<string | undefined>(defaultValue);

  const onChange = (event: FieldTextInputData) => {
    setValue(event.value);
  };

  return { value, onChange, setValue };
}

export default function WysiwygPopUpLink(props: Props): JSX.Element {
  const href = useFieldText('');
  const text = useFieldText('');
  const intl = useIntl();

  const valid = text.value && text.value.length > 0 && isValidHref(href.value);

  const handleOpen = () => {
    if (props.selectedText) {
      if (isValidHref(props.selectedText)) {
        href.setValue(props.selectedText);
        text.setValue('');
      } else {
        text.setValue(props.selectedText);
        href.setValue('');
      }
    } else {
      text.setValue('');
      href.setValue('');
    }
  };

  return (
    <WysiwygPopUp
      onOpen={handleOpen}
      icon={<LinkIcon style={{ height: '0.7em', width: '0.7em' }} />}
      renderContent={(handleClose: () => void) => {
        const handleSubmit = () => {
          props.onSelect(`<a href="${href.value}">${text.value}</a>`);
          text.setValue('');
          href.setValue('');
          handleClose();
        };
        return (
          <Fragment>
            <Form style={{ width: 320 }}>
              <FieldLabel
                label={
                  <FormattedMessage
                    id='WysiwigLink.title'
                    defaultMessage='Link'
                  />
                }
                helpText={
                  <FormattedMessage
                    id='WysiwigLink.helpText'
                    defaultMessage='Fill form and click to add to create link.'
                  />
                }
              />
            </Form>

            <Form>
              <FieldTextInput
                name={'title'}
                {...text}
                placeholder={intl.formatMessage(messages.titlePlaceholder)}
                label={
                  <FormattedMessage
                    id='WysiwigLink.form.title.title'
                    defaultMessage='Title'
                  />
                }
              />

              <FieldTextInput
                name={'href'}
                {...href}
                placeholder={intl.formatMessage(messages.hrefPlaceholder)}
                label={
                  <FormattedMessage
                    id='WysiwigLink.form.href.title'
                    defaultMessage='URL'
                  />
                }
                helpText={
                  <FormattedMessage
                    id='WysiwigLink.form.href.helpText'
                    defaultMessage='mailto:some@special.cz  or tel:1234567 or http(s)://yourpass.eu'
                  />
                }
              />
            </Form>
            <AddButton onClick={handleSubmit} disabled={!valid} />
          </Fragment>
        );
      }}
    />
  );
}
