import React from 'react';
import { FormattedMessage } from 'react-intl';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Alert from '@material-ui/lab/Alert';

import { getDynamicDataNames } from '../helper';
import { StaticLabel } from '../../labels';
import JsonSchemaEditorAdd from './JsonSchemaEditorAdd';
import { JsonSchemaEditorRow } from './JsonSchemaEditorRow';

export type DynamicDataEditorProps = {
  jsonSchema: Record<string, unknown>;
  onChange: (d: Record<string, unknown>) => void;
};

export const DynamicDataEditorTitle = (
  <FormattedMessage
    id='DynamicDataEditor.title'
    defaultMessage='Dynamic properties'
  />
);

export const JsonSchemaEditor = (
  props: DynamicDataEditorProps
): JSX.Element => {
  const properties = getDynamicDataNames(props.jsonSchema);
  return (
    <Card>
      <CardContent>
        {properties.length > 0 && (
          <Table size='small'>
            <TableHead>
              <TableRow>
                <TableCell>{StaticLabel.UniqueName}</TableCell>
                <TableCell>{StaticLabel.Type}</TableCell>
                <TableCell>{StaticLabel.Title}</TableCell>
                <TableCell>{StaticLabel.Description}</TableCell>
                <TableCell>
                  <FormattedMessage
                    id='DynamicDataEditor.Advanced'
                    defaultMessage='Advanced'
                  />
                </TableCell>
                <TableCell style={{ width: 40 }} />
              </TableRow>
            </TableHead>
            <TableBody>
              {properties.map((name: string) => {
                return (
                  <JsonSchemaEditorRow key={name} name={name} {...props} />
                );
              })}
            </TableBody>
          </Table>
        )}

        {properties.length === 0 && (
          <Alert severity={'info'}>
            <FormattedMessage
              id='Template.DynamicDataEditor.NoProperties.Title'
              defaultMessage='No dynamic properties are defined.'
            />
          </Alert>
        )}
      </CardContent>
      <CardActions>
        <JsonSchemaEditorAdd {...props} />
      </CardActions>
    </Card>
  );
};
