import React, { FunctionComponent, ReactNode } from 'react';

import InputAdornment from '@material-ui/core/InputAdornment';

import { CopyToClipboardButton } from '../buttons';
import { FieldTextInput } from './index';

interface FieldTextInputCopyProps {
  value: string;
  name?: string;
  label: ReactNode;
}
export const FieldTextInputCopy: FunctionComponent<FieldTextInputCopyProps> = (
  props: FieldTextInputCopyProps
) => {
  return (
    <FieldTextInput
      name={props.name || 'copy'}
      label={props.label}
      value={props.value}
      readOnly={true}
      endAdornment={
        <InputAdornment position='end'>
          <CopyToClipboardButton textToCopy={props.value} />
        </InputAdornment>
      }
    />
  );
};
