import { useFormikContext } from 'formik';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import Grid from '@material-ui/core/Grid';
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles';

import { FieldTextInput } from '../../../../../../common/fields';
import Form from '../../../../../../common/Form';
import {
  PkpassField,
  PkpassFieldsType,
} from '../../../../../../../types/Template';
import {
  getFieldType,
  PkpassFieldTypes,
} from '../../../../../../../utils/validation/fieldType';
import { PkpassWysiwygLocalizedString } from '../../../../inputs';
import ChangeMessageInput from '../../../../inputs/ChangeMessageInput';
import FieldAlignmentDropdown from './FieldAlignmentDropDown';
import FieldExample from './FieldExample';
import FieldTypeCurrency from './fieldType/FieldTypeCurrency';
import FieldTypeDateAdvanced from './fieldType/FieldTypeDateAdvanced';
import FieldTypeDateValue from './fieldType/FieldTypeDateValue';
import FieldTypeNumberAdvanced from './fieldType/FieldTypeNumberAdvanced';
import FieldTypeNumberValue from './fieldType/FieldTypeNumberValue';
import FieldTypeTextValue from './fieldType/FieldTypeTextValue';
import FieldTypeDropdown from './FieldTypeDropdown';

interface Props {
  languages: string[];
  fieldsType: PkpassFieldsType;
}

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    column: {
      flexGrow: 1,
      justifyContent: 'center',
      display: 'flex',
    },
  })
);

export default function FieldForm(props: Props): JSX.Element {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { fieldsType, languages } = props;
  const formikProps = useFormikContext<PkpassField>();
  const fieldType = getFieldType(formikProps.values);

  return (
    <Grid container={true} justify={'center'}>
      <Grid item={true} className={classes.column}>
        <Form>
          <PkpassWysiwygLocalizedString
            languages={languages}
            label={
              <FormattedMessage
                id='pkpass.field.label.label'
                defaultMessage='Label'
              />
            }
            helpText={
              <FormattedMessage
                id='pkpass.field.label.helpText'
                defaultMessage='Label text for the field.'
              />
            }
            multiline={false}
            name={`properties.label`}
          />
          {fieldType === PkpassFieldTypes.Text && (
            <FieldTypeTextValue
              {...formikProps}
              languages={languages}
              isBackfields={fieldsType === PkpassFieldsType.BackFields}
            />
          )}
          {fieldType === PkpassFieldTypes.Date && <FieldTypeDateValue />}

          {(fieldType === PkpassFieldTypes.Number ||
            fieldType === PkpassFieldTypes.Currency) && (
            <FieldTypeNumberValue />
          )}

          <ChangeMessageInput
            languages={languages}
            {...formikProps}
            label={
              <FormattedMessage
                id='pkpass.field.changeMessage.label'
                defaultMessage='Change message'
              />
            }
            helpText={
              <FormattedMessage
                id='pkpass.field.changeMessage.helpText'
                defaultMessage='Format string for the alert text that is displayed when the pass is updated. The format string must contain the escape %@, which is replaced with the field’s new value. For example, “Gate changed to %@.” If you don’t specify a change message, the user isn’t notified when the field changes.'
              />
            }
            name={`properties.changeMessage`}
          />

          <FieldExample field={formikProps.values} fieldType={fieldType} />
        </Form>
      </Grid>
      <Grid item={true} className={classes.column}>
        <Form>
          <FieldTextInput
            disabled={true}
            required={true}
            name={'key'}
            value={formikProps.values.properties.key.value}
            label={
              <FormattedMessage
                id='PkpassField.edit.header'
                defaultMessage='Field key'
              />
            }
          />
          <FieldTypeDropdown
            fieldsType={fieldsType}
            {...formikProps}
            key='passTypeDropdown'
          />
          {fieldsType !== PkpassFieldsType.BackFields &&
            fieldsType !== PkpassFieldsType.PrimaryFields && (
              <FieldAlignmentDropdown />
            )}
          {fieldType === PkpassFieldTypes.Date && <FieldTypeDateAdvanced />}
          {fieldType === PkpassFieldTypes.Number && <FieldTypeNumberAdvanced />}
          {fieldType === PkpassFieldTypes.Currency && <FieldTypeCurrency />}
        </Form>
      </Grid>
    </Grid>
  );
}
