import React from 'react';
import { PkpassPreview, PkpassPreviewProps } from '@yourpass/react-lib';
import { ControlLayout, ControlLayoutProps } from './ControlLayout';

export default function DesignerFrontSide(
  props: PkpassPreviewProps & ControlLayoutProps
): JSX.Element {
  return (
    <div style={{ position: 'relative', width: 320, height: 450 }}>
      <PkpassPreview {...props} />
      <ControlLayout {...props} />
    </div>
  );
}
