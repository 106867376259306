import { FormikProps } from 'formik';
import PropTypes from 'prop-types';
import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import EditDrawerInnerLayout from '../common/drawers/EditDrawerInnerLayout';
import FormikEditDrawer from '../common/formik/FormikEditDrawer';
import { ResourceTypes } from '../../utils/services/api';
import { User } from '../../types/User';
import UserForm from './UserForm';

interface UserEditDrawerProps {
  id: string;
  open: boolean;
  onClose: () => void;
}

const UserEditDrawer: FunctionComponent<UserEditDrawerProps> = (
  props: UserEditDrawerProps
): JSX.Element => {
  const Edit: FunctionComponent<FormikProps<User>> = (
    fp: FormikProps<User>
  ) => {
    return (
      <EditDrawerInnerLayout
        onClose={props.onClose}
        title={
          <FormattedMessage id='UserEdit.title' defaultMessage='Edit user' />
        }
        content={<UserForm {...fp} />}
        controls={<div />}
      />
    );
  };

  return (
    <FormikEditDrawer
      onClose={props.onClose}
      resource={ResourceTypes.USER}
      id={props.id}
      open={props.open}
      Component={Edit}
    />
  );
};

UserEditDrawer.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default UserEditDrawer;
