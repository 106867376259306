import moment from 'moment';
import React, { useState } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';

import fetch from '../../utils/services/fetch';
import { Button, CopyToClipboardButton } from './buttons';
import {
  FieldAutocompleteCreatable,
  FieldAutocompleteCreatableChange,
} from './fields';
import Form from './Form';
import { DownloadIcon } from './icons';
import Link from '@material-ui/core/Link';

const messages = defineMessages({
  tagsPlaceholder: {
    defaultMessage: 'Select predefined tags or define own tags',
    id: 'UrlWithTags.tags.placeholder',
  },
  tagsLabel: {
    id: 'UrlWithTags.tags.label',
    defaultMessage: 'Select tags or write new to append',
  },
  tagsHelpText: {
    id: 'UrlWithTags.tags.helpText',
    defaultMessage:
      'Select predefined tags or type own tagname and press enter.',
  },
});

const toDataURL = (url: string) => {
  return fetch(url)
    .then((response) => {
      return response.blob();
    })
    .then((blob) => {
      return URL.createObjectURL(blob);
    });
};

const defaultOptions = [
  'billboard',
  'email',
  'entrance',
  'facebook',
  'fancard',
  'flyer',
  'google',
  'instagram',
  'leaflet',
  'qrcode',
  'social',
  'twitter',
  'web',
  'wechat',
];

export default function UrlWithTags(props: { urlBase: string }) {
  const [tags, setTags] = useState<string[]>([]);
  const intl = useIntl();

  const handleChange = (e: FieldAutocompleteCreatableChange) => {
    setTags(e.value || []);
  };

  const u = new URL(props.urlBase);
  tags.forEach((t) => {
    u.searchParams.append('tag', t);
  });

  const url = `${props.urlBase}${u.search}`;

  u.searchParams.append('format', 'qrcode');

  const qrCodeUrl = `${props.urlBase}${u.search}`;

  const download = (dataUrl: string, extension: string) => {
    const a = document.createElement('a');
    a.href = dataUrl;
    a.download = `${moment().format('YYYY-MM-DD_hh-mm')}.${extension}`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const saveSvg = async () => {
    const dataUrl = await toDataURL(qrCodeUrl);
    download(dataUrl, 'svg');
  };

  const savePng = async () => {
    const img = new Image();
    img.setAttribute('crossOrigin', 'anonymous');
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      if (ctx) {
        const width = 600;
        const height = 600;
        canvas.width = width;
        canvas.height = height;
        ctx.drawImage(img, 0, 0, width, height);
        const dataUrl = canvas.toDataURL('image/png');
        download(dataUrl, 'png');
      }
    };
    img.src = qrCodeUrl;
  };

  return (
    <div>
      <h4>
        <FormattedMessage id='UrlWithTags.tags' defaultMessage='Tags' />
      </h4>
      <Form>
        <FieldAutocompleteCreatable
          name={'tags'}
          options={defaultOptions}
          onChange={handleChange}
          value={[]}
          label={intl.formatMessage(messages.tagsLabel)}
          placeholder={intl.formatMessage(messages.tagsPlaceholder)}
          helpText={intl.formatMessage(messages.tagsHelpText)}
        />
      </Form>

      <h4>
        <FormattedMessage id='UrlWithTags.link.url' defaultMessage='URL' />
      </h4>
      <p>
        <Link href={url} target='blank'>
          {url}
          <CopyToClipboardButton textToCopy={url} />
        </Link>
      </p>

      <h4>
        <FormattedMessage
          id='UrlWithTags.qrcode.label'
          defaultMessage='URL in QR Code'
        />
      </h4>
      <p>
        <Link href={qrCodeUrl} target='blank'>
          {qrCodeUrl}
          <CopyToClipboardButton textToCopy={qrCodeUrl} />
        </Link>
      </p>
      <p>
        <img alt={qrCodeUrl} src={qrCodeUrl} style={{ width: 200 }} />
      </p>

      <Button onClick={saveSvg} startIcon={<DownloadIcon />}>
        <FormattedMessage
          id='UrlWithTags.qrcode.downlad.svg'
          defaultMessage='SVG'
        />
      </Button>
      <Button onClick={savePng} startIcon={<DownloadIcon />}>
        <FormattedMessage
          id='UrlWithTags.qrcode.downlad.png'
          defaultMessage='PNG'
        />
      </Button>
    </div>
  );
}
