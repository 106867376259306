import React from 'react';
import { FormattedMessage } from 'react-intl';

import Page from '../../components/common/page/Page';
import { withReport } from '../../context/';
import BasePassRequestByTagsOverview from '../../components/report/BasePassRequestByTagsOverview';

const Report = withReport(BasePassRequestByTagsOverview, 'download');

export const PassDownloadRequestByTagsOverviewPage = (): JSX.Element => {
  return (
    <Page
      content={<Report />}
      title={
        <FormattedMessage
          id='PassDownloadRequestByTagsOverviewPage.title'
          defaultMessage='Downloads pass by tags'
        />
      }
    />
  );
};
