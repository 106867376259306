import React, { Fragment } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton, { IconButtonProps } from '@material-ui/core/IconButton';
import Icon from '@material-ui/icons/Delete';

import useOpen from '../../../hooks/useOpen';
import { CancelButton } from './CancelButton';
import { ConfirmButton } from './ConfirmButton';

const messages = defineMessages({
  defaultConfirmText: {
    defaultMessage: 'Resource will be deleted. Are you sure?',
    id: 'DeleteButton.confirm.default.text',
  },
});

interface ListDeleteButtonProps extends IconButtonProps {
  onDelete: () => void;
  confirmText?: string;
  noLabel: boolean;
}

export const ListDeleteButton = (props: ListDeleteButtonProps): JSX.Element => {
  const { open, handleClose, handleOpen } = useOpen(false);
  const intl = useIntl();

  const handleConfirm = () => {
    handleOpen();
    props.onDelete();
  };

  const { onDelete, confirmText, noLabel, ...btnProps } = props;
  return (
    <Fragment>
      <IconButton
        onClick={handleOpen}
        color='secondary'
        type='button'
        focusRipple={false}
        {...btnProps}
      >
        <Icon />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          <FormattedMessage
            id='DeleteButton.confirm.dialog.title'
            defaultMessage='Delete'
          />
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            {props.confirmText ||
              intl.formatMessage(messages.defaultConfirmText)}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <CancelButton onClick={handleClose} />
          <ConfirmButton onClick={handleConfirm} autoFocus={true} />
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};
