import { useEffect, useState, useReducer } from 'react';

import { count, ResourceTypes } from '../utils/services/api';
import { API_SERVICE_TIMEOUT } from '../utils/services/environment';
import useFilter from './useFilter';
import { Action, asyncReducer, ActionType, State } from './asyncReducer';

export default function useResourcesCount(
  resource: ResourceTypes,
  opts: { query: any }
) {
  const reducer = (state: State<number>, action: Action<number>) =>
    asyncReducer<number>(state, action);

  const [state, dispatch] = useReducer(reducer, {
    loading: true,
    data: null,
    error: null,
  });

  const { filter } = useFilter();
  const [iterator, setIterator] = useState(0);

  useEffect(() => {
    const abortController = new AbortController();
    const t = setTimeout(() => {
      if (!abortController.signal.aborted) {
        abortController.abort();
      }
    }, API_SERVICE_TIMEOUT);
    const fetchData = async () => {
      dispatch({ type: ActionType.PENDING });
      try {
        const c = await count(
          resource,
          { query: opts.query },
          { signal: abortController.signal }
        );

        dispatch({ type: ActionType.SUCCESS, payload: c });
      } catch (e) {
        dispatch({ type: ActionType.FAILURE, payload: e });
      }
    };
    fetchData().finally(() => {
      clearTimeout(t);
    });

    return function cancel() {
      if (!abortController.signal.aborted) {
        abortController.abort();
      }
    };
  }, [resource, opts.query, iterator]);

  const onSubmit = () => {
    setIterator(iterator + 1);
  };

  return { ...state, onSubmit, filter };
}
