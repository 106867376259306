import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Button, ButtonProps } from './Button';

export const NextButton = (props: ButtonProps) => {
  return (
    <Button type='button' color='primary' variant='contained' {...props}>
      <FormattedMessage id={'NextButton.button.text'} defaultMessage={'Next'} />
    </Button>
  );
};
