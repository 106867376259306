import React, { PropsWithChildren, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import Loader from '../../components/common/loader/Loader';
import { CACHE_WARNING_TIMEOUT } from '../../utils/services/environment';

import { Viewer } from '../../types/Viewer';
import { DB } from './db';
import { createDB } from './createDb';
import { CacheSubscribeStream } from './CacheSubscribeStream';

export interface CacheContextInterface {
  db: DB | null;
}

export const CacheContext = React.createContext<CacheContextInterface>({
  db: null,
});

export function CacheContextProvider(
  props: PropsWithChildren<{ viewer: Viewer }>
): JSX.Element {
  const [initialize, setInitialize] = useState(true);
  const [warnLimit, setWarnLimit] = useState(false);
  const [db, setDb] = useState<DB | null>(null);
  const [apiError, setApiError] = useState<Error | null>(null);
  const [abortController] = useState<AbortController>(new AbortController());
  const [initNumber, setInitNumber] = useState<number>(0);
  const { viewer } = props;

  useEffect(() => {
    let stream: null | CacheSubscribeStream = null;
    const init = async () => {
      setWarnLimit(false);
      setInitialize(() => true);
      setApiError(null);
      try {
        const t = setTimeout(() => {
          setWarnLimit(true);
        }, CACHE_WARNING_TIMEOUT);
        const db = await createDB(viewer, abortController);
        const since = await db.getLatestChange();
        stream = new CacheSubscribeStream(db, since);
        setDb(() => db);
        clearTimeout(t);
      } catch (e) {
        setApiError(e);
      }
    };
    init().finally(() => {
      setInitialize(() => false);
    });

    return () => {
      if (stream) {
        stream.cancel();
      }
    };
  }, [initNumber, setInitialize, abortController, viewer]);

  const handleInit = () => {
    setInitNumber(() => initNumber + 1);
  };

  if (apiError) {
    return (
      <div style={styles.container}>
        <h1>
          <FormattedMessage
            id='CacheContext.error.header'
            defaultMessage='Failed to load user account and code lists'
          />
        </h1>
        <p style={styles.infoText}>
          <b>
            <FormattedMessage
              id='CacheContext.error.message'
              defaultMessage='Error message:'
            />
          </b>
          {apiError.message}
        </p>
        <button onClick={handleInit}>
          <FormattedMessage
            id='CacheContext.error.action.try.again'
            defaultMessage='Try again'
          />
        </button>
      </div>
    );
  }

  if (initialize) {
    if (warnLimit) {
      return (
        <div style={styles.container}>
          <Loader />
          <h1>
            <FormattedMessage
              id='CacheContext.long.header'
              defaultMessage='Loading is suspiciously long'
            />
          </h1>
          <p style={styles.infoText}>
            <FormattedMessage
              id='CacheContext.long.limited.infoText'
              defaultMessage='Please be patient, the system is most likely overloaded and connections to administration console are limited.'
            />
          </p>
          <p style={styles.infoText}>
            <FormattedMessage
              id='CacheContext.long.critical.infoText'
              defaultMessage='Critical functions (pass creation, pass preview, ...) are in normal mode.'
            />
          </p>
        </div>
      );
    }
    return (
      <div style={styles.container}>
        <Loader />
        <h1>
          <FormattedMessage
            id='CacheContext.loading.header'
            defaultMessage='Loading user account and code lists'
          />
        </h1>
      </div>
    );
  }

  return (
    <CacheContext.Provider
      value={{
        db,
      }}
    >
      {props.children}
    </CacheContext.Provider>
  );
}

const styles = {
  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column' as const,
    height: '100vh',
    justifyContent: 'center',
    width: '100%',
  },
  infoText: {
    maxWidth: '50%',
  },
};
