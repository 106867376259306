import React from 'react';
import { FormattedMessage } from 'react-intl';

import { FieldSelect, FieldSelectData, FieldSelectOption } from '../fields';
import { FilterElementProps } from './Filter';

interface Props extends FilterElementProps {
  value: string | undefined;
  onChange: (e: FieldSelectData) => void;
}

const options: FieldSelectOption[] = [
  {
    key: 'd',
    text: <FormattedMessage defaultMessage='Daily' id='FilterInterval.daily' />,
    value: 'daily',
  },
  {
    key: 'w',
    text: (
      <FormattedMessage defaultMessage='Weekly' id='FilterInterval.weekly' />
    ),
    value: 'weekly',
  },
  {
    key: 'm',
    text: (
      <FormattedMessage defaultMessage='Monthly' id='FilterInterval.monthly' />
    ),
    value: 'monthly',
  },
];

export default function FilterInterval(props: Props) {
  const handleChange = (event: FieldSelectData) => {
    if (props.onChange) {
      props.onChange({
        name: props.name,
        value: event.value as string,
      });
    }
  };

  return (
    <FieldSelect
      name='interval'
      label={
        props.label || (
          <FormattedMessage
            id='FilterInterval.label'
            defaultMessage='Interval'
          />
        )
      }
      helpText={props.helpText}
      options={options}
      value={props.value}
      onChange={handleChange}
    />
  );
}
