import { Field, FieldProps } from 'formik';
import React from 'react';

import { FieldTextInput, FieldTextInputData } from '../../../common/fields';
import { StaticLabel } from '../../../common/labels';
import WysiwygPopUpShortCodesContent from '../../../common/wysiwyg/WysiwygPopUpShortCodesContent';
import { useTemplateUI } from '../useTemplateUI';
import {
  errorsToString,
  isPlaceholder,
  numberRegexp,
  defualtNumberFallback,
} from './helper';
import {
  PkpassInputSettingsPopUp,
  usePkpassInputSettingsPopup,
} from './PkpassInputSettingsPopUp';
import { numberValueSchema } from '../../../../utils/validation/helper';
import IconButton from '@material-ui/core/IconButton';
import { SettingsIcon } from '../../../common/icons';

export interface NumberWithFallbackProps {
  name: string;
  label: React.ReactNode;
  helpText: React.ReactNode;
  required?: boolean;
}

export const PkpassNumberWithFallbackProps = (
  props: NumberWithFallbackProps & { field: FieldProps }
): JSX.Element => {
  const templateUI = useTemplateUI();

  const { field, label, helpText, name, required } = props;
  const value = field.field.value ? field.field.value.value : undefined;
  const object = field.field.value;
  const hasFallback = object && isPlaceholder('number', object);
  const fallback = field.field.value ? field.field.value.fallback : undefined;
  let localError;
  try {
    numberValueSchema.validateSync(object);
  } catch (e) {
    localError = e.message;
  }
  const error = errorsToString(field.form.errors, [name]) || localError;

  const getFallback = (v: string | number) => {
    const h = isPlaceholder('number', { value: v, type: 'string' });
    if (h) {
      return fallback || defualtNumberFallback;
    }
    return fallback;
  };

  const change = (str: string | undefined): void => {
    let newValue;

    if (!!str && str !== '') {
      let allowedChars: string | number = `${str.replace(/,/g, '.')}`;
      const isNumberV = !!allowedChars.match(numberRegexp);
      if (isNumberV && allowedChars.slice(-1) !== '.') {
        allowedChars = Number(allowedChars);
      }
      newValue = {
        value: allowedChars,
        fallback: getFallback(allowedChars),
        type: 'number',
      };
    }
    field.form.setFieldValue(name, newValue);
  };

  const handleChange = (event: FieldTextInputData): void => {
    change(event.value);
  };

  const handleOnBlur = (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    change(event.target.value);
    field.field.onBlur(event);
  };

  const handleChangeFallback = (event: FieldTextInputData): void => {
    let newValue;
    if (event.value && event.value !== '') {
      let allowedChars: string | number = `${event.value.replace(/,/g, '.')}`;
      const isNumberV = !!allowedChars.match(numberRegexp);
      if (isNumberV && allowedChars.slice(-1) !== '.') {
        allowedChars = Number(allowedChars);
      }
      newValue = {
        fallback: allowedChars,
        type: 'number',
        value: value,
      };
    } else {
      newValue = {
        fallback: undefined,
        type: 'number',
        value: value,
      };
    }
    field.form.setFieldValue(name, newValue);
  };

  const handleSelectShortCode = (shortCode: string): void => {
    field.form.setFieldValue(name, {
      fallback: getFallback(shortCode),
      type: 'number',
      value: shortCode,
    });
  };
  const { anchorEl, handleClose, handleOpen } = usePkpassInputSettingsPopup();
  return (
    <>
      <FieldTextInput
        required={required}
        label={label}
        error={error}
        name={name}
        helpText={helpText}
        value={value}
        onChange={handleChange}
        onBlur={handleOnBlur}
        endAdornment={
          <IconButton
            onClick={handleOpen}
            disabled={!hasFallback && templateUI.numberShortCodes.length === 0}
          >
            <SettingsIcon style={{ height: '0.7em', width: '0.7em' }} />
          </IconButton>
        }
      />
      <PkpassInputSettingsPopUp anchorEl={anchorEl} onClose={handleClose}>
        <FieldTextInput
          required={true}
          name={name}
          label={
            <span>
              {label} {StaticLabel.Fallback}
            </span>
          }
          value={fallback !== undefined ? `${fallback}` : undefined}
          onChange={handleChangeFallback}
        />
        {templateUI.numberShortCodes.length > 0 && (
          <WysiwygPopUpShortCodesContent
            shortCodes={templateUI.numberShortCodes}
            onSelect={handleSelectShortCode}
          />
        )}
      </PkpassInputSettingsPopUp>
    </>
  );
};

export const PkpassNumber = (props: NumberWithFallbackProps): JSX.Element => {
  return (
    <Field name={props.name}>
      {(field: FieldProps) => (
        <PkpassNumberWithFallbackProps {...props} field={field} />
      )}
    </Field>
  );
};
