import { NewImage } from '../../types/Image';

export const getImageProportion = (
  url: string
): Promise<{ width: number; height: number }> => {
  return new Promise((resolve, reject) => {
    const img = document.createElement('img');
    img.onload = () => {
      // `naturalWidth`/`naturalHeight` aren't supported on <IE9. Fallback to normal width/height
      // The natural size is the actual image size regardless of rendering.
      // The 'normal' width/height are for the **rendered** size.
      const width = img.naturalWidth || img.width;
      const height = img.naturalHeight || img.height;
      // Do something with the width and height
      return resolve({ width, height });
    };

    img.onerror = () => {
      return reject(new Error('Cannot read image proportion'));
    };
    img.src = url;
  });
};

export const getMimeTypeFromContent = (blob: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onloadend = (e: any) => {
      const arr = new Uint8Array(e.target.result).subarray(0, 4);
      let header = '';
      arr.forEach((i) => {
        header += i.toString(16);
      });
      switch (header) {
        case '89504e47':
          resolve('image/png');
          break;
        case '47494638':
          resolve('image/gif');
          break;
        case 'ffd8ffe0':
        case 'ffd8ffe1':
        case 'ffd8ffe2':
          resolve('image/jpeg');
          break;
        default:
          reject('unknown type'); // Or you can use the blob.type as fallback
          break;
      }
      // Check the file signature against known types
    };
    fileReader.onerror = reject;
    fileReader.onabort = reject;
    fileReader.readAsArrayBuffer(blob);
  });
};

export const resizeImage = (url: any, maxWidth: number, maxHeight: number) => {
  return new Promise((resolve, reject) => {
    const img = document.createElement('img');
    img.onload = () => {
      // `naturalWidth`/`naturalHeight` aren't supported on <IE9. Fallback to normal width/height
      // The natural size is the actual image size regardless of rendering.
      // The 'normal' width/height are for the **rendered** size.
      const width = img.naturalWidth || img.width;
      const height = img.naturalHeight || img.height;
      // Do something with the width and height

      const canvas = document.createElement('canvas');

      let newWidth = width;
      let newHeight = height;

      if (newWidth > newHeight) {
        if (newWidth > maxWidth) {
          newHeight *= maxWidth / newWidth;
          newWidth = maxWidth;
        }
      } else {
        if (height > maxHeight) {
          newWidth *= maxHeight / height;
          newHeight = maxHeight;
        }
      }
      canvas.width = newWidth;
      canvas.height = newHeight;

      const ctx = canvas.getContext('2d');
      if (ctx) {
        ctx.drawImage(img, 0, 0, newWidth, newHeight);
        return resolve(canvas.toDataURL('image/png'));
      }
      return resolve(url);
    };

    img.onerror = () => {
      return reject(new Error('Cannot read image proportion'));
    };
    img.src = url;
  });
};

export const convertImageFileToImage = (file: File): Promise<NewImage> => {
  return new Promise((resolve, reject) => {
    try {
      const reader = new FileReader();
      reader.addEventListener('load', async () => {
        const { width, height } = await getImageProportion(
          reader.result as string
        );
        const mimeType: string = await getMimeTypeFromContent(file);
        resolve({
          height,
          mimeType,
          name: file.name,
          url: reader.result ? reader.result.toString() : '',
          width,
        });
      });
      reader.readAsDataURL(file);
    } catch (e) {
      reject(e);
    }
  });
};
