import { Field, FieldProps, FormikProps, getIn } from 'formik';
import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import {
  getColorDynamicDataFields,
  getNumberDynamicDataFields,
  getDateDynamicDataFields,
  getTextDynamicDataFields,
} from '../helper';
import { JsonSchemaProperty } from '../form/JsonSchemaProperty';
import {
  FieldAutocompleteCreatable,
  FieldAutocompleteCreatableChange,
  FieldCheckboxChange,
  FieldNumberInputData,
  FieldSelect,
  FieldSelectData,
  FieldTextInput,
  FieldTextInputData,
} from '../../fields';
import Form from '../../Form';
import { StaticLabel } from '../../labels';
import {
  descHelpText,
  patternHelpText,
  titleHelpText,
  typeHelpText,
  uniqueNameHelpText,
} from '../../../template/dynamicData/mesages';
import { JsonSchemaFieldProps, JsonSchemaTypes } from '../types';

const options = Object.keys(JsonSchemaTypes).map((key) => ({
  key: key.toLocaleLowerCase(),
  text: key,
  value: key.toLocaleLowerCase(),
}));

export default function JsonSchemaEditorAddDialogForm(
  p: FormikProps<JsonSchemaProperty> & { jsonSchema: Record<string, unknown> }
): JSX.Element {
  const handleChange = (
    event:
      | FieldNumberInputData
      | FieldAutocompleteCreatableChange
      | FieldSelectData
      | FieldTextInputData
      | FieldCheckboxChange
  ) => {
    p.setFieldValue(event.name, event.value);
  };

  const enumOptions = p.values.enum;

  function validateName(value: string) {
    let error;
    const shortcodes: string[] = [];
    getColorDynamicDataFields(p.jsonSchema).forEach(
      (sc: JsonSchemaFieldProps) => {
        shortcodes.push(sc.name);
      }
    );
    getNumberDynamicDataFields(p.jsonSchema).forEach(
      (sc: JsonSchemaFieldProps) => {
        shortcodes.push(sc.name);
      }
    );
    getDateDynamicDataFields(p.jsonSchema).forEach(
      (sc: JsonSchemaFieldProps) => {
        shortcodes.push(sc.name);
      }
    );
    getTextDynamicDataFields(p.jsonSchema).forEach(
      (sc: JsonSchemaFieldProps) => {
        shortcodes.push(sc.name);
      }
    );

    if (shortcodes.indexOf(value) >= 0) {
      error = `Property ${value} already exits`;
    }
    return error;
  }

  const renderName = (props: FieldProps<any>) => (
    <FieldTextInput
      label={StaticLabel.UniqueName}
      value={props.field.value || ''}
      onChange={handleChange}
      name={'name'}
      helpText={uniqueNameHelpText}
      required={true}
      error={getIn(p.errors, 'name')}
    />
  );

  return (
    <Grid container={true}>
      <Grid item={true} xs={12} md={6}>
        <Typography variant='h5'>
          <FormattedMessage
            id='Template.DynamicDataEditorForm.JsonSchemaProperties'
            defaultMessage='Property definition'
          />
        </Typography>
        <Form>
          <Field name='name' validate={validateName} render={renderName} />

          <FieldSelect
            value={p.values.type}
            options={options}
            name={'type'}
            onChange={handleChange}
            label={StaticLabel.Type}
            helpText={typeHelpText}
            required={true}
            error={getIn(p.errors, 'type')}
          />

          {/*<FormFieldCheckbox
                      label={StaticLabel.Required}
                      checked={required}
                      options={options}
                      name={'required'}
                      onChange={handleChangeRequired}
                      toggle={true}
                    />*/}

          {p.values.type === JsonSchemaTypes.String && (
            <Fragment>
              <FieldTextInput
                label={StaticLabel.Pattern}
                value={p.values.pattern || ''}
                name={'pattern'}
                onChange={handleChange}
                helpText={patternHelpText}
              />
              <FieldSelect
                label={StaticLabel.Format}
                value={p.values.format}
                name={'format'}
                onChange={handleChange}
                options={[
                  {
                    key: 'no',
                    text: 'Empty format',
                    value: undefined,
                  },
                  {
                    key: 'date-time',
                    text: 'date-time',
                    value: 'date-time',
                  },
                  {
                    key: 'email',
                    text: 'email',
                    value: 'email',
                  },
                  {
                    key: 'uri',
                    text: 'uri',
                    value: 'uri',
                  },
                ]}
                placeholder='select format'
                multiple={false}
              />
              <FieldAutocompleteCreatable
                label={'enum'}
                value={(p.values.enum as string[]) || []}
                name={'enum'}
                onChange={handleChange}
                options={enumOptions}
                placeholder='Add new enum values'
              />
            </Fragment>
          )}

          {/* (p.values.type === JsonSchemaTypes.Number ||
            p.values.type === JsonSchemaTypes.Integer) && (
            <Fragment>
              <FieldNumberInput
                label={StaticLabel.Maximum}
                value={p.values.maximum}
                name={'maximum'}
                onChange={handleChange}
              />

              <FieldNumberInput
                label={StaticLabel.Maximum}
                value={p.values.minimum}
                name={'minimum'}
                onChange={handleChange}
              />

              <FieldCheckbox
                label='exclusiveMaximum'
                checked={p.values.exclusiveMaximum}
                name={'exclusiveMaximum'}
                onChange={handleChange}
              />

              <FieldCheckbox
                label='exclusiveMinimum'
                checked={p.values.exclusiveMinimum}
                name={'exclusiveMinimum'}
                onChange={handleChange}
              />
            </Fragment>
          ) */}
        </Form>
      </Grid>
      <Grid item={true} xs={12} md={6}>
        <Typography variant='h5'>
          <FormattedMessage
            id='Template.DynamicDataEditorForm.PassEditForm'
            defaultMessage='Pass form definition'
          />
        </Typography>
        <Form>
          <FieldTextInput
            label={StaticLabel.Title}
            helpText={titleHelpText}
            error={getIn(p.errors, 'title', undefined)}
            value={p.values.title || ''}
            name={'title'}
            onChange={handleChange}
            required={true}
          />
          <FieldTextInput
            label={StaticLabel.Description}
            value={p.values.description || ''}
            name={'description'}
            onChange={handleChange}
            multiline={true}
            helpText={descHelpText}
          />
        </Form>
      </Grid>
    </Grid>
  );
}
