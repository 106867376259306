import React, { useState, useEffect } from 'react';
import { useTemplateUI } from './useTemplateUI';
import { IMAGE_BASE_URL } from './../../../utils/services/environment';
import { useFormikContext } from 'formik';
import { Template } from './../../../types/Template';
import {
  ImageTypes,
  PkpassFieldsType,
  PkpassField,
  jsonTemplateToPassJson,
  imagesIdtoURL,
  JsonTemplate,
} from '@yourpass/react-lib';
import { switchField } from './helpers/field';

export const usePreview = () => {
  const templateUI = useTemplateUI();
  const formik = useFormikContext<Template>();

  const [state, setState] = useState({
    pass: jsonTemplateToPassJson(
      formik.values.jsonTemplate as JsonTemplate,
      templateUI.templatePreviewLanguage
    ),

    images: imagesIdtoURL(
      formik.values.images || {},
      IMAGE_BASE_URL,
      templateUI.templatePreviewLanguage
    ),
  });

  useEffect(() => {
    setState({
      pass: jsonTemplateToPassJson(
        formik.values.jsonTemplate as JsonTemplate,
        templateUI.templatePreviewLanguage
      ),

      images: imagesIdtoURL(
        formik.values.images || {},
        IMAGE_BASE_URL,
        templateUI.templatePreviewLanguage
      ),
    });
  }, [
    templateUI.templatePreviewLanguage,
    formik.values.jsonTemplate,
    formik.values.images,
  ]);

  // const pass = jsonTemplateToPassJson(
  //   formik.values.jsonTemplate as JsonTemplate,
  //   templateUI.templatePreviewLanguage
  // );

  // const images = imagesIdtoURL(
  //   formik.values.images || {},
  //   IMAGE_BASE_URL,
  //   templateUI.templatePreviewLanguage
  // );

  const handleClickField = (
    data: {
      fieldsType: PkpassFieldsType;
      fieldIndex: number;
      field: PkpassField;
    },
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    event.preventDefault();
    event.stopPropagation();
    templateUI.uiSetTemplateForceOpenField({
      key: data.field.key,
      type: data.fieldsType,
    });
  };

  const handleFieldDnD = (
    type: PkpassFieldsType,
    index: number,
    targetType: PkpassFieldsType,
    targetIndex: number
  ) => {
    formik.setFieldValue(
      'jsonTemplate',
      switchField(formik.values, type, index, targetType, targetIndex)
        .jsonTemplate
    );
  };

  const onImageClick = (imageType: ImageTypes, event: React.MouseEvent) => {
    templateUI.uiSetTemplateForceOpenImage(imageType);
    event.preventDefault();
    event.stopPropagation();
  };
  const onBarcodeClick = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();

    templateUI.uiSetTemplateForceOpenBarcode(true);
  };

  const onBoardingPassIconClick = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();

    templateUI.uiSetTemplateForceOpenBoardingPassIcon(true);
  };

  const onLogoTextClick = (event: React.MouseEvent) => {
    alert(`TODO clicked to logotext`);
    event.preventDefault();
    event.stopPropagation();
  };

  return {
    ...state,
    onLogoTextClick,
    onBoardingPassIconClick,
    onBarcodeClick,
    onImageClick,
    handleFieldDnD,
    handleClickField,
  };
};
