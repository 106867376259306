import React from 'react';

import { FieldStatic } from '../common/fields';
import Form from '../common/Form';
import { CORE_API_URL } from '../../utils/services/environment';
import PassFormInterface from './PassFormInterface';

const PassSaveToGooglePay = (props: { pass: PassFormInterface }) => {
  const { pass } = props;
  return (
    <Form>
      <FieldStatic
        name='savetogooglepay'
        label='Link to Save to Google Pay object'
        value={
          <a
            href={`${CORE_API_URL}/v1/pass/${pass.id}/savetogooglepay`}
            rel='noopener noreferrer'
          >
            {`${CORE_API_URL}/v1/pass/${pass.id}/savetogooglepay`}
          </a>
        }
      />
      <FieldStatic
        name='savetogooglepayjwt'
        label='Link to JWT token to add in to Save to Google Pay'
        value={
          <a
            href={`${pass.url}?format=savetogooglepayjwt`}
            target='_blank'
            rel='noopener noreferrer'
          >
            {`${pass.url}?format=savetogooglepayjwt`}
          </a>
        }
      />
    </Form>
  );
};

export default PassSaveToGooglePay;
