import { getIn, useFormikContext } from 'formik';
import React from 'react';
import { PkpassField, Template } from '../../../../../../../types/Template';

import FieldFormikDialog from './FieldFormikDialog';
import { fieldRemove } from '../../../../helpers/field';
import { getPassStyle } from '../../../../helpers/passStyle';
import { useTemplateUI } from '../../../../useTemplateUI';

export default function FieldEditDialog(): JSX.Element {
  const formik = useFormikContext<Template>();
  const templateUI = useTemplateUI();
  const selected = templateUI.field;

  if (!selected || !formik) {
    return <span />;
  }
  const fieldsPath = `jsonTemplate.${getPassStyle(formik.values)}.properties.${
    selected.type
  }`;
  let fieldIndex = -1;

  if (!formik) {
    return <span />;
  }

  const { values, setFieldValue } = formik;

  const fields = getIn(values, `${fieldsPath}.items`, []);
  fields.forEach((f: PkpassField, i: number) => {
    if (f.properties.key.value === selected.key) {
      fieldIndex = i;
    }
  });
  if (fieldIndex < 0) {
    return <span />;
  }

  const onRemove = () => {
    setFieldValue(
      'jsonTemplate',
      fieldRemove(values, selected.type, fieldIndex).jsonTemplate
    );
  };
  const fieldPath = `${fieldsPath}.items.${fieldIndex}`;
  const field: PkpassField = getIn(values, fieldPath);

  const handleClose = () => {
    templateUI.uiSetTemplateForceOpenField(null);
  };

  const handleSubmit = (f: PkpassField) => {
    setFieldValue(fieldPath, f);
    handleClose();
  };

  const handleRemove = (_f: PkpassField) => {
    onRemove();
    handleClose();
  };

  return (
    <FieldFormikDialog
      fieldsType={selected.type}
      field={field}
      languages={values.languages}
      onClose={handleClose}
      onSubmit={handleSubmit}
      onRemove={handleRemove}
    />
  );
}
