import React from 'react';
import { FormattedMessage } from 'react-intl';

import { FieldSet } from '../../../../../../common/fields';
import { PkpassField } from '../../../../../../../types/Template';
import { PkpassFieldTypes } from '../../../../../../../utils/validation/fieldType';

interface Props {
  field: PkpassField;
  fieldType: PkpassFieldTypes;
}

function FieldExample(props: Props) {
  const { fieldType } = props;
  if (fieldType === PkpassFieldTypes.Text) {
    return null;
  }
  let content;
  switch (fieldType) {
    // case PkpassFieldTypes.Number:
    //   content = (
    //     <FieldPreviewNumber
    //       value={getIn(field, ['properties', 'value', 'value']) || 100.05}
    //       style={getIn(field, ['properties', 'numberStyle', 'value'])}
    //     />
    //   );
    //   break;
    // case PkpassFieldTypes.Currency:
    //   content = (
    //     <FieldPreviewValueCurrency
    //       value={getIn(field, ['properties', 'value', 'value']) || 100}
    //       currency={getIn(field, ['properties', 'currencyCode', 'value'])}
    //     />
    //   );
    //   break;
    // case PkpassFieldTypes.Date:
    //   content = (
    //     <FieldPreviewValueDate
    //       value={
    //         getIn(field, ['properties', 'value', 'value']) &&
    //         !isPlaceholder('date', getIn(field, ['properties', 'value']))
    //           ? getIn(field, ['properties', 'value', 'value'])
    //           : '1987-08-18T22:00:00.000Z'
    //       }
    //       dateStyle={getIn(field, ['properties', 'dateStyle', 'value'])}
    //       timeStyle={getIn(field, ['properties', 'timeStyle', 'value'])}
    //     />
    //   );
    //   break;
    default:
      content = null;
  }

  return (
    <FieldSet
      label={
        <FormattedMessage id='FieldExample' defaultMessage='Dynamic preview' />
      }
    >
      {content}
    </FieldSet>
  );
}

export default FieldExample;
