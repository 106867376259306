import XLSX from 'xlsx';
import { set } from 'lodash';

const processXLS = (results: unknown[]) => {
  const rows: unknown[] = [];
  results.forEach((line: any) => {
    const pass: any = {};
    Object.keys(line).forEach((key: string) => {
      set(pass, key, line[key]);
    });
    rows.push({
      data: {
        dynamicData: pass.dynamicData,
        dynamicImages: pass.dynamicImages,
        templateId: pass.templateId,
      },
      id: pass.id,
      method: pass.id ? 'PUT' : 'POST',
    });
  });
  return rows;
};

const fileToBatch = async (f: File) => {
  const workbook = await readFile(f);
  const ws = workbook.Sheets[workbook.SheetNames[0]];
  const wsRows = XLSX.utils.sheet_to_json(ws);
  const r = processXLS(wsRows);
  return r;
};

export default fileToBatch;

const rABS =
  typeof FileReader !== 'undefined' &&
  FileReader.prototype &&
  FileReader.prototype.readAsBinaryString;

function readFile(f: File): Promise<XLSX.WorkBook> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e: ProgressEvent<FileReader>) => {
      if (e !== null && e.target != null) {
        let data = e.target.result;
        let wb;
        let arr;
        const readtype = {
          type: rABS ? ('binary' as const) : ('base64' as const),
        };
        if (!rABS) {
          arr = fixdata(data as ArrayBuffer);
          data = btoa(arr);
        }

        const doIt = () => {
          try {
            wb = XLSX.read(data, readtype);
            resolve(wb);
          } catch (e) {
            reject(e);
          }
        };
        doIt();
      }
    };
    if (rABS) {
      reader.readAsBinaryString(f);
    } else {
      reader.readAsArrayBuffer(f);
    }
  });
}

function fixdata(data: ArrayBuffer) {
  let o = '';
  let l = 0;
  const w = 10240;
  for (; l < data.byteLength / w; ++l) {
    o += String.fromCharCode.apply(
      null,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      new Uint8Array(data.slice(l * w, l * w + w))
    );
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  o += String.fromCharCode.apply(null, new Uint8Array(data.slice(o.length)));
  return o;
}
