import React from 'react';
import TemplateTooltip from './DesignPkpassTooltip';
import PreviewIcon from '@material-ui/icons/Visibility';

const TemplateListItemPreview = (props: { id: string }) => {
  return (
    <TemplateTooltip id={props.id}>
      <PreviewIcon fontSize='small' />
    </TemplateTooltip>
  );
};

export default TemplateListItemPreview;
