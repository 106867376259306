import React from 'react';

import { default as MuiDivider } from '@material-ui/core/Divider';
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    divider: {
      margin: theme.spacing(1, 0),
      backgroundColor: 'rgba(255,255,255, 0.6)',
    },
  })
);

const Divider = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  return <MuiDivider className={classes.divider} />;
};

export default Divider;
