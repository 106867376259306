import React, { FunctionComponent } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';

import Grid from '@material-ui/core/Grid';

import useTimezone from '../../../hooks/useTimezone';
import { FieldSet } from './FieldSet';
import { FieldStatic } from './FieldStatic';

const keys = ['createdAt', 'updatedAt', 'deletedAt'];

const messages: any = defineMessages({
  createdAt: {
    defaultMessage: 'Created at',
    id: 'FieldEntityTimestamps.createdAt',
  },
  deletedAt: {
    defaultMessage: 'Deleted at',
    id: 'FieldEntityTimestamps.deletedAt',
  },
  updatedAt: {
    defaultMessage: 'Updated at',
    id: 'FieldEntityTimestamps.updatedAt',
  },
});

export interface FieldEntityTimestamps {
  data: any;
}

export const FieldEntityTimestamps: FunctionComponent<FieldEntityTimestamps> = (
  props: FieldEntityTimestamps
) => {
  const { data } = props;
  const intl = useIntl();

  const { formatFullDateTime } = useTimezone();
  return (
    <FieldSet
      label={
        <FormattedMessage
          id={'FieldEntityTimestamps.title'}
          defaultMessage={'Timestamps'}
        />
      }
    >
      <Grid container={true}>
        {keys.map((key) => (
          <Grid key={key} item={true} style={{ padding: 16 }}>
            <FieldStatic
              name={'key'}
              key={key}
              label={intl.formatMessage(messages[key])}
              value={data[key] ? formatFullDateTime(data[key]) : null}
            />
          </Grid>
        ))}
      </Grid>
    </FieldSet>
  );
};
