import React from 'react';

import { ListIcon, TreeIcon } from '../../components/common/icons';
import Tabs, { TabOption } from '../../components/common/Tabs';
import useViewer from '../../hooks/useViewer';
import { ProjectListTable } from './ProjectListTable';
import { ProjectListTree } from './ProjectListTree';

export const ProjectList = (props: { onCreate: () => void }): JSX.Element => {
  const viewer = useViewer();

  if (!(viewer && viewer.isAdmin)) {
    return <ProjectListTable {...props} />;
  }

  const options: TabOption[] = [
    {
      content: <ProjectListTable {...props} />,
      icon: <ListIcon />,
      key: 'table',
    },
    {
      content: <ProjectListTree />,
      icon: <TreeIcon />,
      key: 'tree',
    },
  ];

  return (
    <Tabs formName='ProjectList' defaultTab={'table'} tabOptions={options} />
  );
};
