import React, { Fragment, useEffect, useState } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { isString } from 'lodash';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Toolbar from '@material-ui/core/Toolbar';

import LanguageCodes from '../../constants/languageCodes';
import {
  Image as ImageT,
  ImageListItem,
  ImageReference,
  TranslatedImageReference,
} from '../../types/Image';
import { ImageTypes } from '../../types/Template';
import ImageUploadDialog from './ImageUploadDialog';
import { Button, CancelButton, ResetButton } from '../common/buttons';
import {
  FieldSelect,
  FieldSelectData,
  FieldSwitch,
  FieldSwitchChange,
} from '../common/fields';
import { StaticLabel } from '../common/labels';
import { ListParams, ResourceTypes } from '../../utils/services/api';
import useResources from '../../hooks/useResources';
import { prepareFilter } from '../../utils/services/filter';
import { FilterProps } from '../../types/common/filter';
import ImageSelectionGrid from './ImageSelectionGrid';

const filterProps: FilterProps[] = [
  {
    columnName: 'projectId',
    name: 'project',
    type: 'in',
  },
  {
    columnName: 'type',
    name: 'imageType',
    type: 'in',
  },
];

const messages = defineMessages({
  imageEnableTranslations: {
    defaultMessage: 'Enable translations',
    id: 'image.enable.translation.label',
  },
});

export interface ImageSelectDialogProps {
  imageType: ImageTypes;
  previewColor?: string;
  projectId: string;
  image: ImageReference;
  previewLanguage: string;
  languages: string[];
  onChange: (image: ImageReference) => void;
  onChangeLanguage: (event: FieldSelectData) => void;
  open: boolean;
  handleClose: () => void;
}

const ImageSelectDialog = (props: ImageSelectDialogProps): JSX.Element => {
  const {
    open,
    onChangeLanguage,
    imageType,
    languages,
    previewLanguage,
    onChange,
    projectId,
    handleClose,
  } = props;
  const intl = useIntl();
  const [image, setImage] = useState<ImageReference>(props.image);
  const canBeTranslated = imageType !== 'icon';

  const language = previewLanguage;
  let selectedId = image;
  if (selectedId && !isString(selectedId)) {
    selectedId = selectedId[language];
  }

  const [opts, setOpts] = useState<ListParams>({
    query: {},
  });

  /* images list */
  const images = useResources<ImageListItem>(ResourceTypes.IMAGE, opts);
  useEffect(() => {
    setOpts({
      query: prepareFilter(
        {
          imageType: [imageType],
          project: [projectId],
        },
        filterProps
      ),
    });
  }, [imageType, projectId]);

  /* image translation state */
  const [isTranslated, setIsTranslated] = useState(
    !(typeof image === 'undefined' || typeof image === 'string')
  );

  const handleRemoveImage = () => {
    let value: string | TranslatedImageReference | undefined;
    if (image && isTranslated && canBeTranslated && typeof image != 'string') {
      value = image;
      delete value[language];
      const keys = Object.keys(value);
      if (keys.length === 0) {
        value = undefined;
      }
    }
    onChange(value);
    handleClose();
  };

  const handleImageChange = (value: ImageReference) => {
    if (typeof value === 'object') {
      setImage({ ...value });
      setIsTranslated(true);
    } else {
      setImage(value);
      setIsTranslated(false);
    }
  };

  const handleChange = (data: ImageT) => {
    let value: ImageReference = data.id;
    if (isTranslated && canBeTranslated) {
      value = image;
      if (!value || isString(value)) {
        value = {};
      }
      value[previewLanguage] = data.id;
    }
    handleImageChange(value);
  };

  const handleAdd = (data: ImageT) => {
    images.addItem(data as ImageListItem);
    handleChange(data);
  };

  const handleSubmit = () => {
    onChange(image);
    handleClose();
  };

  const handleSwitchTranslate = (event: FieldSwitchChange) => {
    if (event.value) {
      handleImageChange({ [previewLanguage]: image as string });
    } else if (image && previewLanguage) {
      const defImage: ImageReference =
        typeof image === 'object' ? image[previewLanguage] : undefined;
      handleImageChange(defImage);
    } else {
      handleImageChange(undefined);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth='lg' fullWidth={true}>
      <DialogTitle>
        <Toolbar>
          <div style={{ flexGrow: 1 }}>
            <FormattedMessage
              id='image.dialog.header'
              defaultMessage='Select or upload the {imageType} image'
              values={{ imageType }}
            />
          </div>

          {(canBeTranslated || isTranslated) && (
            <Fragment>
              <FieldSwitch
                name={'isTranslated'}
                disabled={!canBeTranslated}
                label={intl.formatMessage(messages.imageEnableTranslations)}
                checked={isTranslated}
                onChange={handleSwitchTranslate}
              />
              <div style={{ width: 16 }} />
              <FieldSelect
                label={StaticLabel.Language}
                name='language'
                value={previewLanguage}
                onChange={onChangeLanguage}
                disabled={!isTranslated}
                options={languages.map((lng) => ({
                  key: lng,
                  text: LanguageCodes[lng],
                  value: lng,
                }))}
              />
            </Fragment>
          )}
        </Toolbar>
      </DialogTitle>
      <ImageSelectionGrid
        selectedId={selectedId as string}
        handleChange={handleChange}
        {...images}
      />
      <DialogActions>
        <CancelButton type='button' onClick={handleClose} />
        <ResetButton type='button' onClick={handleRemoveImage} />
        <ImageUploadDialog
          buttonProps={{ color: 'primary' }}
          projectId={projectId}
          type={imageType}
          onCreate={handleAdd}
        />
        <Button color='primary' onClick={handleSubmit}>
          {StaticLabel.Ok}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ImageSelectDialog;
