import PropTypes from 'prop-types';
import React, { FunctionComponent } from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { useTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete, {
  AutocompleteRenderInputParams,
} from '@material-ui/lab/Autocomplete';

import {
  Props,
  useStyles,
  getOptionLabel,
  parseValue,
  renderOption,
  parseMultipleValue,
  renderOptionMultiple,
  ListItem as FieldAutocompleteListItem,
} from './FieldAutocomplete';
import LANGUAGE_CODES from '../../../constants/languageCodes';
import { FieldAutocompleteListboxComponent } from './FieldAutocompleteListboxComponent';

export const FieldAutocompleteLanguage: FunctionComponent<Props<string>> = (
  props: Props<string>
): JSX.Element => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const languagesOptions = Object.keys(LANGUAGE_CODES);

  const opts: FieldAutocompleteListItem[] = languagesOptions.map(
    (t: string) => ({
      id: t as string,
      name: LANGUAGE_CODES[t],
    })
  );

  const handleChange = (
    event: React.ChangeEvent<unknown>,
    value: FieldAutocompleteListItem | null
  ) => {
    if (!props.multiple) {
      props.onChange(value ? value.id : undefined);
    }
  };

  const handleChangeMultiple = (
    event: React.ChangeEvent<unknown>,
    value: FieldAutocompleteListItem[] | null
  ) => {
    if (props.multiple) {
      props.onChange(
        value ? value.map((p: FieldAutocompleteListItem) => p.id) : []
      );
    }
  };

  function renderInput(params: AutocompleteRenderInputParams) {
    return (
      <TextField
        {...params}
        variant='outlined'
        label={props.label || 'Language'}
        placeholder='Type language'
        required={props.required}
        error={!!props.error}
        margin='dense'
      />
    );
  }

  return (
    <FormControl className={classes.formControl} margin={'dense'}>
      {!props.multiple && (
        <Autocomplete
          disabled={props.disabled}
          options={opts}
          // disableCloseOnSelect={true}
          // title={props.label}
          getOptionLabel={getOptionLabel}
          renderOption={renderOption}
          renderInput={renderInput}
          value={parseValue(opts, props.value)}
          onChange={handleChange}
          onBlur={props.onBlur}
          disableClearable={true}
          size='small'
          ListboxProps={{
            itemSize: 32,
          }}
          ListboxComponent={
            FieldAutocompleteListboxComponent as React.ComponentType<
              React.HTMLAttributes<HTMLElement>
            >
          }
        />
      )}

      {props.multiple && (
        <Autocomplete
          size='small'
          limitTags={2}
          multiple={true}
          disabled={props.disabled}
          options={opts}
          // title={props.label}
          renderOption={renderOptionMultiple}
          renderInput={renderInput}
          value={parseMultipleValue(opts, props.value)}
          onChange={handleChangeMultiple}
          getOptionLabel={getOptionLabel}
          onBlur={props.onBlur}
          ListboxProps={{
            itemSize: 32,
          }}
          ListboxComponent={
            FieldAutocompleteListboxComponent as React.ComponentType<
              React.HTMLAttributes<HTMLElement>
            >
          }
        />
      )}
      <FormHelperText error={true}>{props.error}</FormHelperText>
    </FormControl>
  );
};

FieldAutocompleteLanguage.propTypes = {
  label: PropTypes.node,
  required: PropTypes.bool,
  error: PropTypes.string,
};
