import { FormikProps, getIn, setIn } from 'formik';
import React from 'react';

import { PkpassField } from '../../../../../../../../types/Template';
import {
  PkpassWysiwygLocalizedString,
  WysiwygLocalizedStringInputWithFallbackData,
} from '../../../../../inputs';
import valueHelper from './valueHelper';
import { unset } from 'lodash';

function attributedValueToValue(attributedValue: string) {
  if (attributedValue && attributedValue.includes('<a href=')) {
    return attributedValue
      .replace(/(<a href="([^"]+)">([^<]+)<\/a>)/g, '$3: $2')
      .replace(/(<a href='([^']+)'>([^<]+)<\/a>)/g, '$3: $2');
  }
  return attributedValue;
}

interface Props extends FormikProps<PkpassField> {
  languages: string[];
  isBackfields: boolean;
}

const FieldTypeTextValue = (props: Props): JSX.Element => {
  const { languages, isBackfields, ...formikProps } = props;
  const { values, setValues } = props;

  const handleAttributedValue = (
    data: WysiwygLocalizedStringInputWithFallbackData
  ) => {
    const attributedValue = data.value;

    let value = getIn(values, `properties.value.value`);
    let fallback = getIn(values, `properties.value.fallback`);
    let equalsAttributedValueAndValue = true;
    let field = values;
    if (attributedValue) {
      if (
        attributedValue &&
        (attributedValue.value || attributedValue.value === '') &&
        typeof attributedValue.value !== 'string'
      ) {
        // all translations for current state

        if (!value.set) {
          value = {};
        }

        const translations = attributedValue.value as { [key: string]: string };
        languages.map((lang: string) => {
          const av = translations[lang] as string;
          // TODO fallback
          const v = attributedValueToValue(av);
          value[lang] = v;

          if (v !== av) {
            equalsAttributedValueAndValue = false;
          }

          return value;
        });
      } else {
        value = attributedValueToValue(attributedValue.value as string);
        fallback = attributedValueToValue(attributedValue.fallback as string);
        equalsAttributedValueAndValue = value === attributedValue.value;
      }
    } else {
      value = undefined;
    }

    // If value is deep eauals attributed value, set only value
    if (equalsAttributedValueAndValue) {
      field = setIn(field, 'properties.value', {
        fallback,
        type: 'string',
        value,
      });
      unset(field, 'properties.attributedValue');
    } else {
      field = setIn(field, 'properties.value', {
        fallback,
        type: 'string',
        value,
      });
      field = setIn(field, 'properties.attributedValue', attributedValue);
    }
    setValues(field);
  };

  if (isBackfields) {
    const hasAV = getIn(formikProps.values, `properties.attributedValue`);
    const editPath = `properties.${hasAV ? 'attributedValue' : 'value'}`;
    return (
      <PkpassWysiwygLocalizedString
        languages={languages}
        key={`edit`}
        {...formikProps}
        required={true}
        name={editPath}
        errorPaths={
          isBackfields
            ? ['properties.value', 'properties.attributedValue']
            : undefined
        }
        {...valueHelper}
        multiline={true}
        onChange={handleAttributedValue}
        link={true}
      />
    );
  }
  return (
    <PkpassWysiwygLocalizedString
      languages={languages}
      required={true}
      multiline={false}
      name={`properties.value`}
      {...valueHelper}
    />
  );
};

export default FieldTypeTextValue;
