export const placeHolderRegexp = /^.*#\{.+\}.*$/;
export const placeHolderRegexpIgnorePIDAndTID = /#\{[^pt][^\\}]*\}|#\{[^}][^i][^}]*\}|#\{[^}]{2}[^d]\}|#\{[^}]{4,}\}/;
export const numberRegexp = /^-?\d*\.?\d*$/;
export const dateRegexp = /^\\d{4}-\\d\\d-\\d\\dT\\d\\d:\\d\\d:\\d\\d(\\.\\d+)?(([+-]\\d\\d:\\d\\d)|Z)?$/;
export const colorRegexp = /^rgb\((25[0-5]|2[0-4][0-9]|1[0-9]?[0-9]?|[1-9][0-9]?|[0-9]), ?(25[0-5]|2[0-4][0-9]|1[0-9]?[0-9]?|[1-9][0-9]?|[0-9]), ?(25[0-5]|2[0-4][0-9]|1[0-9]?[0-9]?|[1-9][0-9]?|[0-9])\)$/;

export function isPlaceholder(
  type: string,
  value: { type: string; value: unknown }
): boolean {
  if (value && value.value && typeof value.value === 'string') {
    // if is string type, it can contains #{pid} and #{tid} as verified values
    // TODO - be care-full date is also string type
    if (value.type !== 'string') {
      return !!value.value.match(placeHolderRegexp);
    }
    if (type === 'date' || type === 'color' || type === 'number') {
      return !!value.value.match(placeHolderRegexp);
    }
    return !!value.value.match(placeHolderRegexpIgnorePIDAndTID);
  }

  if (value && value.value && typeof value.value === 'object') {
    const keys = Object.keys(value.value);
    const v = value.value as Record<string, string>;
    for (const key of keys) {
      if (v[key] && !!v[key].match(placeHolderRegexp)) {
        return true;
      }
    }
  }

  return false;
}

export function isNumber(value: unknown): boolean {
  if (value && typeof value === 'string') {
    return !!value.match(numberRegexp);
  }
  if (value === 0 || (value && typeof value === 'number')) {
    return true;
  }

  return false;
}

export function isDate(datestr: string | undefined): boolean {
  if (!datestr) {
    return false;
  }
  return !!((d: Date | string) => {
    return d !== 'Invalid Date' && d instanceof Date && !isNaN(d.getTime());
  })(new Date(datestr));
}

export function isColor(datestr: string | undefined): boolean {
  if (!datestr) {
    return false;
  }
  return datestr.match(colorRegexp) !== null;
}
