import React, { useEffect, useRef, useState, Fragment } from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import DialogContent from '@material-ui/core/DialogContent';
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';

import { ImageListItem } from '../../types/Image';
import { LabelBytes } from '../common/labels';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      flexGrow: 1,
      maxWidth: 200 * 6,
    },
    media: {
      height: 78,
      maxWidth: 200,
      backgroundSize: 'contain',
    },
    iconPlaceholder: {
      height: 29,
      width: 24,
    },
    name: {
      fontSize: '0.7em',
      maxWidth: 200 - theme.spacing(2) * 2,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    card: {
      width: 200,
      height: 180,
      margin: 5,
    },
    dimens: {
      float: 'right',
      fontSize: '0.6em',
    },
    bytes: {
      float: 'left',
      fontSize: '0.6em',
    },
  })
);

const PAGE_SIZE = 42;

interface Props {
  data: ImageListItem[];
  loading: boolean;
  selectedId?: string;
  handleChange: (item: any) => void;
}

export default function ImageSelectionGrid(props: Props) {
  const [max, setMax] = useState(PAGE_SIZE);
  const elementRef: any = useRef(null);

  useEffect(() => {
    const element = elementRef.current;
    const handleScroll = () => {
      if (
        element &&
        element.scrollHeight - element.scrollTop === element.clientHeight
      ) {
        setMax(max + PAGE_SIZE);
      }
    };

    element.addEventListener('scroll', handleScroll);
    return () => {
      element.removeEventListener('scroll', handleScroll);
    };
  }, [max, setMax]);

  const { data, selectedId } = props;
  const theme = useTheme();
  const classes = useStyles(theme);

  let l: ImageListItem[] = [];
  if (data) {
    const to = Math.min(max, data.length);
    l = data.slice(0, to);
  }

  return (
    <Fragment>
      {props.loading && (
        <DialogContent>
          <LinearProgress />
        </DialogContent>
      )}
      <DialogContent className={classes.root} ref={elementRef}>
        {!props.loading &&
          l.map((item: ImageListItem) => {
            const handleClick = () => {
              props.handleChange(item);
            };
            return (
              <Card
                className={classes.card}
                onClick={handleClick}
                key={item.id}
                raised={item.id === selectedId}
              >
                <CardHeader
                  title={<div className={classes.name}>{item.name}</div>}
                  action={
                    item.id === selectedId ? (
                      <CheckIcon htmlColor='green' />
                    ) : (
                      <div className={classes.iconPlaceholder} />
                    )
                  }
                />
                <div className={'checkerBoard'}>
                  <CardMedia className={classes.media} image={item.url} />
                </div>
                <CardContent>
                  <span>
                    <span className={classes.bytes}>
                      <LabelBytes bytes={item.fileSize} />
                    </span>
                    <span className={classes.dimens}>
                      {item.width}x{item.height}
                    </span>
                  </span>
                </CardContent>
              </Card>
            );
          })}
      </DialogContent>
    </Fragment>
  );
}
