import { ImageTypes } from '../../types/Template';

export enum FilterActionTypes {
  FILTER_SET_VALUE = 'FILTER_SET_VALUE',
}

export enum IntervalTypes {
  DAYLY = 'daily',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
}

export type FilterDate = Date | undefined;

export type FilterImages = ImageTypes[];

export type FilterInterval = IntervalTypes;

export type FilterString = string | undefined;

export type FilterBoolean = boolean | undefined;

export type FilterIds = string[];

export type FilterValues =
  | FilterDate
  | FilterImages
  | FilterInterval
  | FilterString
  | FilterIds;

export enum FilterKeyTypes {
  CREATED_AT_FROM = 'createdAtFrom',
  CREATED_AT_TO = 'createdAtTo',
  FROM = 'from',
  TO = 'to',
  DATE = 'date',
  INTERVAL = 'interval',
  IMAGE_TYPE = 'imageType',
  PROJECT = 'project',
  TEMPLATE = 'template',
  DISPLAY_NAME = 'displayName',
  DYNAMIC_DATA = 'dynamicData',
  DEVICES = 'devices',
  NAME = 'name',
  EMAIL = 'email',
  PASS_TYPE_ID = 'passTypeId',
  ID = 'id',
  VOIDED = 'voided',
  WITH_DELETED = 'withDeleted',
}

export interface FilterState {
  [FilterKeyTypes.CREATED_AT_FROM]: FilterDate;
  [FilterKeyTypes.CREATED_AT_TO]: FilterDate;
  [FilterKeyTypes.FROM]: FilterDate;
  [FilterKeyTypes.TO]: FilterDate;
  [FilterKeyTypes.DATE]: FilterDate;
  [FilterKeyTypes.INTERVAL]: FilterInterval;
  [FilterKeyTypes.IMAGE_TYPE]: FilterImages;
  [FilterKeyTypes.PROJECT]: FilterIds;
  [FilterKeyTypes.TEMPLATE]: FilterIds;
  [FilterKeyTypes.DYNAMIC_DATA]: FilterString;
  [FilterKeyTypes.DEVICES]: FilterString;
  [FilterKeyTypes.DISPLAY_NAME]: FilterString;
  [FilterKeyTypes.NAME]: FilterString;
  [FilterKeyTypes.EMAIL]: FilterString;
  [FilterKeyTypes.PASS_TYPE_ID]: FilterString;
  [FilterKeyTypes.ID]: FilterString;
  [FilterKeyTypes.VOIDED]: FilterBoolean;
  [FilterKeyTypes.WITH_DELETED]: FilterBoolean;
}
