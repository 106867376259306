import { object } from 'yup';

import { PkpassPassStyleTypes } from '../../types/Template';
import {
  colorValueSchema,
  dateValueSchema,
  localizableStringValueSchema,
  numberValueSchema,
  stringValueSchema,
} from './helper';
import { barcodesSchema } from './jsonTemplate.barcode';
import { beaconsSchema } from './jsonTemplate.iBeacon';
import { locationsSchema } from './jsonTemplate.location';
import {
  boardingPassStyleSchema,
  passStyleSchema,
} from './jsonTemplate.passStyle';

export const jsonTemplateSchema = object()
  .shape({
    backgroundColor: colorValueSchema,
    barcodes: barcodesSchema,
    beacons: beaconsSchema,
    description: localizableStringValueSchema.default(undefined),
    foregroundColor: colorValueSchema,
    groupingIdentifier: stringValueSchema.default(undefined),
    labelColor: colorValueSchema,
    locations: locationsSchema,
    maxDistance: numberValueSchema,
    relevantDate: dateValueSchema,
    [PkpassPassStyleTypes.BoardingPass]: boardingPassStyleSchema.default(
      undefined
    ),
    [PkpassPassStyleTypes.Coupon]: passStyleSchema.default(undefined),
    [PkpassPassStyleTypes.EventTicket]: passStyleSchema.default(undefined),
    [PkpassPassStyleTypes.Generic]: passStyleSchema.default(undefined),
    [PkpassPassStyleTypes.StoreCard]: passStyleSchema.default(undefined),

    organizationName: localizableStringValueSchema.required(),
  })
  .test({
    name: 'PassTypesSchema',
    test(value: any) {
      // TODO - check there one of passtype keys

      return true;
    },
  });
