import { useFormikContext } from 'formik';
import React from 'react';
import { Template } from '../../../../types/Template';
import { useTemplateUI } from '../useTemplateUI';
import ImageSelectDialog from '../../../image/ImageSelectDialog';
import { ImageReference } from '../../../../types/Image';
import { FieldSelectData } from '../../../common/fields';

export const EditImageDialog = (): JSX.Element | null => {
  const templateUI = useTemplateUI();
  const formikProps = useFormikContext<Template>();

  if (!templateUI.image) {
    return null;
  }

  const handleImageChange = (ir: ImageReference) => {
    formikProps.setFieldValue(`images.${templateUI.image}`, ir);
  };

  const onLanguageChange = (event: FieldSelectData) => {
    templateUI.uiSetTemplatePreviewLanguage(`${event.value}`);
  };

  const images = formikProps.values.images || {};

  return (
    <ImageSelectDialog
      image={images[templateUI.image]}
      imageType={templateUI.image}
      languages={formikProps.values.languages}
      onChange={handleImageChange}
      onChangeLanguage={onLanguageChange}
      open={true}
      handleClose={() => {
        templateUI.uiSetTemplateForceOpenImage(null);
      }}
      previewLanguage={templateUI.templatePreviewLanguage}
      projectId={formikProps.values.projectId}
    />
  );
};
