import React from 'react';
import { animated, useSpring } from 'react-spring/web.cjs'; // web.cjs is required for IE 11 support

import Collapse from '@material-ui/core/Collapse';
import {
  createStyles,
  fade,
  makeStyles,
  Theme,
  withStyles,
} from '@material-ui/core/styles';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { TransitionProps } from '@material-ui/core/transitions';
import TreeItem, { TreeItemProps } from '@material-ui/lab/TreeItem';
import TreeView from '@material-ui/lab/TreeView';

import { LinkEditIconButton } from '../../components/common/buttons';
import Loader from '../../components/common/loader/Loader';
import useResources from '../../hooks/useResources';
import useViewer from '../../hooks/useViewer';
import { ResourceTypes } from '../../utils/services/api';
import { ProjectListItem } from './types';

interface Project {
  id: string;
  name: string;
  description?: string;
  active: boolean;
  parentId: string | null;
  children: Project[];
}

const createTree = (
  items: ProjectListItem[],
  id: string | null = null
): Project[] => {
  return items
    .filter((item: ProjectListItem) => item.parentId === id)
    .sort((a: ProjectListItem, b: ProjectListItem) =>
      a.name.localeCompare(b.name)
    )
    .map((item: ProjectListItem) => ({
      ...item,
      children: createTree(items, item.id),
    }));
};

function MinusSquare(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon fontSize='inherit' style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d='M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z' />
    </SvgIcon>
  );
}

function PlusSquare(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon fontSize='inherit' style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d='M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z' />
    </SvgIcon>
  );
}

function CloseSquare(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon
      className='close'
      fontSize='inherit'
      style={{ width: 14, height: 14 }}
      {...props}
    >
      {/* tslint:disable-next-line: max-line-length */}
      <path d='M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z' />
    </SvgIcon>
  );
}

function TransitionComponent(props: TransitionProps): JSX.Element {
  const style = useSpring({
    from: { opacity: 0, transform: 'translate3d(20px,0,0)' },
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
    },
  });

  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  );
}

const StyledTreeItem = withStyles((theme: Theme) =>
  createStyles({
    group: {
      borderLeft: `1px dashed ${fade(theme.palette.text.primary, 0.4)}`,
      marginLeft: 7,
      paddingLeft: 18,
    },
    iconContainer: {
      '& .close': {
        opacity: 0.3,
      },
    },
  })
)((props: TreeItemProps) => (
  <TreeItem {...props} TransitionComponent={TransitionComponent} />
));

const useStyles = makeStyles(
  createStyles({
    root: {
      flexGrow: 1,
    },
  })
);

export function ProjectListTree(): JSX.Element {
  const classes = useStyles();
  const { data, loading } = useResources<ProjectListItem>(
    ResourceTypes.PROJECT
  );

  if (loading) {
    return <Loader />;
  }
  const tree: Project[] = createTree(data);

  return (
    <TreeView
      className={classes.root}
      defaultExpanded={['1']}
      defaultCollapseIcon={<MinusSquare />}
      defaultExpandIcon={<PlusSquare />}
      defaultEndIcon={<CloseSquare />}
    >
      {tree.map((sp: Project) => (
        <ProjectLeaf key={sp.id} project={sp} />
      ))}
    </TreeView>
  );
}

function ProjectLeaf(props: { project: Project }) {
  const viewer = useViewer();
  return (
    <StyledTreeItem
      nodeId={props.project.id}
      label={
        <span>
          {props.project.name}
          <LinkEditIconButton
            path={`/identity/project/${props.project.id}`}
            disabled={
              !(
                viewer &&
                (viewer.canEditProject(props.project.id) ||
                  viewer.canManageUsersOnProject(props.project.id))
              )
            }
          />
        </span>
      }
    >
      {props.project.children.map((sp: Project) => (
        <ProjectLeaf key={sp.id} project={sp} />
      ))}
    </StyledTreeItem>
  );
}
