import { getIn } from 'formik';

import { Template } from '../../../../types/Template';
import { isPlaceholder } from '../../../../utils/validation/jsonTemplateHelper';

export const getColor = (
  template: Template,
  type: string
): string | undefined => {
  const property = getIn(template, ['jsonTemplate', type]);
  if (property) {
    if (isPlaceholder('color', property)) {
      return property.fallback;
    }
    return property.value;
  }
  return undefined;
};

export const getForegroundColor = (template: Template): string => {
  return getColor(template, 'foregroundColor') || 'rgb(255, 255, 255)';
};

export const getBackgroundColor = (template: Template): string => {
  return getColor(template, 'backgroundColor') || 'rgba(0, 0, 0)';
};

export const getLabelColor = (template: Template): string => {
  return getColor(template, 'labelColor') || 'rgb(255, 255, 255)';
};
