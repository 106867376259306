import useLocalStorage from './useLocalStorage';

interface HookProps {
  tab: string | null;
  setTab: (t: string) => void;
}

export default function useTabs(tabName: string): HookProps {
  const { data, setData } = useLocalStorage<any>('tabs');

  const setTab = (value: string) => {
    const newData = { ...data, [tabName]: value };
    setData(newData);
  };

  return { tab: data && data[tabName], setTab };
}
