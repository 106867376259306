import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  spacer: {
    height: 30,
    width: '100%',
  },
});

export default function Spacer() {
  const classes = useStyles();

  return <div className={classes.spacer} />;
}
