import React from 'react';

import { LabelValueTitle, StaticLabel } from '../../../../common/labels';
import { PkpassBeacon } from '../../../../../types/Template';

export default function BeaconLabel(props: {
  beacon: PkpassBeacon;
}): JSX.Element {
  return (
    <div>
      {props.beacon.properties.proximityUUID && (
        <LabelValueTitle
          link={true}
          label={StaticLabel.ProximityUUID}
          value={`${props.beacon.properties.proximityUUID.value}`}
        />
      )}
      {props.beacon.properties.major && (
        <LabelValueTitle
          link={true}
          label={StaticLabel.Major}
          value={`${props.beacon.properties.major.value}`}
        />
      )}
      {props.beacon.properties.minor && (
        <LabelValueTitle
          link={true}
          label={StaticLabel.Minor}
          value={`${props.beacon.properties.minor.value}`}
        />
      )}
    </div>
  );
}
