import React, { FunctionComponent } from 'react';

import LANGUAGE_CODES from '../../../constants/languageCodes';
import { StaticLabel } from '../labels';
import {
  FieldSelect,
  FieldSelectOption,
  FieldSelectProps,
} from './FieldSelect';

export type FieldSelectLanguageProps = FieldSelectProps & {
  languages?: string[];
};

export const FieldSelectLanguage: FunctionComponent<FieldSelectLanguageProps> = (
  props: FieldSelectLanguageProps
) => {
  const { languages } = props;
  const languagesOptions = languages || Object.keys(LANGUAGE_CODES);

  const opts: FieldSelectOption[] = languagesOptions.map((t: string) => ({
    key: t as string,
    text: LANGUAGE_CODES[t],
    value: t as string,
  }));

  return <FieldSelect label={StaticLabel.Language} {...props} options={opts} />;
};
