import React from 'react';
import { FormattedMessage } from 'react-intl';

import Tooltip from '@material-ui/core/Tooltip';

import { TabOption } from '../../common/Tabs';
import { Template } from '../../../types/Template';
import { PkpassTemplatePreview } from '@yourpass/react-lib';
import {
  Button,
  Grid,
  CardHeader,
  Card,
  CardActions,
  CardContent,
} from '@material-ui/core';
import { useHistory } from 'react-router';
import Palette from '@material-ui/icons/Palette';
import DesignPkpassEditDrawerButton from '../../design/pkpass/DesignPkpassEditDrawerButton';
import useViewer from '../../../hooks/useViewer';
import { IMAGE_BASE_URL } from '../../../utils/services/environment';
import { Template as ReactLibTemplate } from '@yourpass/react-lib/dist/js/types';
import { FormikEditResourceRenderProps } from '../../common/formik/FormikEditResource';

const Content = (
  props: FormikEditResourceRenderProps<Template>
): JSX.Element => {
  const history = useHistory();
  const viewer = useViewer();
  return (
    <Grid container={true} spacing={3}>
      <Grid item={true} md={6} xs={12}>
        <Card>
          <CardHeader
            title='Pkpass'
            secondary='Please select design for Pkpass'
          />
          <CardContent>
            <PkpassTemplatePreview
              template={props.values as ReactLibTemplate}
              language={props.values.languages[0]}
              imageBaseUrl={IMAGE_BASE_URL}
            />
          </CardContent>
          <CardActions>
            <DesignPkpassEditDrawerButton
              resourceId={props.values.id}
              onClose={() => {
                return props.refresh();
              }}
              disabled={props.dirty}
            />
          </CardActions>
        </Card>
      </Grid>
      {viewer && viewer.isAdmin && (
        <Grid item={true} md={6} xs={12}>
          <Card>
            <CardHeader
              title='Google pay'
              secondary='Please select design for Google Pay'
            />
            <CardContent>
              <pre>
                {JSON.stringify(props.values.saveToGooglePayObjectTemplate)}
              </pre>
            </CardContent>
            <CardActions>
              <Button
                disabled={true}
                onClick={() => {
                  history.push('/passes/design/googlepay/' + props.values.id);
                }}
              >
                Edit
              </Button>
            </CardActions>
          </Card>
        </Grid>
      )}
    </Grid>
  );
};

export default function DesignTab(
  props: FormikEditResourceRenderProps<Template>
): TabOption {
  return {
    content: <Content {...props} />,
    error: false,
    icon: <Palette />,
    key: 'design',
    name: (
      <Tooltip
        title={
          <FormattedMessage
            id='Template.tabs.designs'
            defaultMessage='Set basic designs for template.'
          />
        }
      >
        <span>Design</span>
      </Tooltip>
    ),
  };
}
