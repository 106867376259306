import { Formik, FormikHelpers, FormikProps } from 'formik';
import PropTypes from 'prop-types';
import React, { Fragment, FunctionComponent, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import Button from '@material-ui/core/Button';

import {
  CancelButton,
  DeleteButton,
  DoneButton,
} from '../../../../common/buttons';
import Dialog from '../../../../common/dialog';
import { GpsLocationIcon } from '../../../../common/icons';
import { OpenInterface } from '../../../../../hooks/useOpen';
import { getLocation } from '../../../../../utils/services/location';
import { PkpassLocation } from '../../../../../types/Template';
import { locationSchema } from '../../../../../utils/validation/jsonTemplate.location';
import LocationForm from './LocationForm';
import { messages } from './messages';

const defaultValue: PkpassLocation = {
  properties: {
    latitude: {
      type: 'number',
      value: 50.03701957237512,
    },
    longitude: {
      type: 'number',
      value: 14.492720613491883,
    },
  },
  type: 'object',
};

interface LocationModalProps {
  initialValues?: PkpassLocation;
  onChange: (location: PkpassLocation) => void;
  onRemove?: () => void;
  trigger?: React.ReactNode;
  radius?: number;
}

const LocationDialog: FunctionComponent<LocationModalProps> = (
  props: LocationModalProps
) => {
  const [gpsLoading, setGpsLoading] = useState(false);
  const intl = useIntl();

  const handleSubmit = (
    b: PkpassLocation,
    actions: FormikHelpers<PkpassLocation>
  ) => {
    // handleClose();
    props.onChange(b);
    if (!props.initialValues) {
      actions.resetForm({ values: defaultValue });
    }
  };

  const header = props.initialValues ? (
    <FormattedMessage
      id='LocationModal.header'
      defaultMessage='Edit location'
    />
  ) : (
    <FormattedMessage
      id='LocationNewModal.add.title'
      defaultMessage='New location'
    />
  );

  return (
    <Formik
      initialValues={props.initialValues || defaultValue}
      onSubmit={handleSubmit}
      validationSchema={locationSchema}
      validateOnMount={locationSchema.isValidSync(
        props.initialValues || defaultValue
      )}
    >
      {(p: FormikProps<PkpassLocation>) => {
        const renderActions = (open: OpenInterface) => {
          const useCurrentGPS = async () => {
            setGpsLoading(true);
            const position = await getLocation();
            if (position.coords.altitude) {
              p.setFieldValue('properties.altitude', {
                type: 'number',
                value: position.coords.altitude,
              });
            }
            if (position.coords.latitude) {
              p.setFieldValue('properties.latitude', {
                type: 'number',
                value: position.coords.latitude,
              });
            }
            if (position.coords.longitude) {
              p.setFieldValue('properties.longitude', {
                type: 'number',
                value: position.coords.longitude,
              });
            }
            setGpsLoading(false);
          };

          const handleSubmit = async () => {
            await p.submitForm();
            open.handleClose();
          };

          return (
            <Fragment>
              {props.onRemove && (
                <DeleteButton
                  confirmText={intl.formatMessage(
                    messages.deleteConfirmationText
                  )}
                  onDelete={() => {
                    props.onRemove && props.onRemove();
                    open.handleClose();
                  }}
                />
              )}

              <CancelButton onClick={open.handleClose} />
              <Button
                onClick={useCurrentGPS}
                disabled={gpsLoading}
                startIcon={<GpsLocationIcon />}
              >
                <FormattedMessage
                  id='Locations.add.by.gps.title.'
                  defaultMessage='Use current position'
                />
              </Button>
              <DoneButton
                disabled={!p.isValid}
                color='primary'
                onClick={handleSubmit}
              />
            </Fragment>
          );
        };

        return (
          <Dialog
            trigger={props.trigger}
            title={header}
            content={<LocationForm {...p} radius={props.radius} />}
            renderActions={renderActions}
            fullWidth={true}
            maxWidth={'lg'}
          />
        );
      }}
    </Formik>
  );
};

LocationDialog.propTypes = {
  initialValues: PropTypes.any,
  onRemove: PropTypes.func,
};

export default LocationDialog;
