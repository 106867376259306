import { Moment } from 'moment-timezone';
import React, { FunctionComponent } from 'react';

import FormControl from '@material-ui/core/FormControl';
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles';
import { KeyboardDateTimePicker } from '@material-ui/pickers';

import { FieldDateChange } from './FieldDate';
import { FieldLabel } from './FieldLabel';

interface Props {
  name: string;
  label?: React.ReactNode;
  placeholder?: string;
  onChange: (d: FieldDateChange) => void;
  disabled?: boolean | undefined;
  required?: boolean | undefined;
  helpText?: React.ReactNode;
  value?: Date | string | undefined | null;
  error?: string;
}

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      '& > *': {
        margin: 0,
      },
      minWidth: 120,
      '& .MuiOutlinedInput-adornedStart': {
        paddingLeft: 0,
        '& .MuiSvgIcon-root': {
          width: '0.8em',
          height: '0.8em',
        },
      },
    },
  })
);

export const FieldDateTime: FunctionComponent<Props> = (props: Props) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { disabled, name, value, onChange } = props;

  const handleChange = (date: Moment | null, text?: string | null) => {
    if (onChange) {
      onChange({
        name,
        value: date ? date.toDate() : undefined,
        textValue: text,
      });
    }
  };

  return (
    <FormControl
      disabled={props.disabled}
      className={classes.formControl}
      margin='dense'
    >
      <KeyboardDateTimePicker
        variant='dialog'
        inputVariant='outlined'
        disabled={disabled}
        error={!!props.error}
        id='date-picker-dialog'
        label={<FieldLabel label={props.label} helpText={props.helpText} />}
        format='DD-MM-yyyy HH:mm'
        value={value === undefined ? null : value}
        onChange={handleChange}
        InputProps={{ margin: 'dense' }}
        InputAdornmentProps={{ position: 'start' }}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        helperText={props.error}
        clearable={true}
      />
    </FormControl>
  );
};
