import { useEffect, useState } from 'react';

export default function usePagination(arr: any[], size = 5) {
  const [pageSize, setPageSize] = useState(size);
  const [data, setData] = useState(arr.slice(0, pageSize));
  const [page, setPage] = useState(0);

  const pages = Math.ceil(arr.length / pageSize) - 1;

  const nextPage = () => {
    setPageValue(page + 1);
  };

  const prevPage = () => {
    setPageValue(page - 1);
  };

  const setPageValue = (n: number) => {
    setPage(n);
    setData(arr.slice(n * pageSize, n * pageSize + pageSize));
  };

  useEffect(() => {
    setData(arr.slice(0, pageSize));
    setPage(0);
  }, [arr, pageSize]);

  return {
    data,
    nextPage: page < pages ? nextPage : undefined,
    page: page + 1,
    pages: pages + 1,
    prevPage: page > 0 ? prevPage : undefined,
    setPage,
    setPageSize: (d: number | string) => {
      setPageSize(typeof d === 'string' ? Number.parseInt(d, 10) : d);
    },
    pageSize,
  };
}
