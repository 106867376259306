import React, { useEffect } from 'react';

import pjson from '../../../../package.json';
import useViewer from '../../../hooks/useViewer';
import { defineMessages, useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { FormikEditResourceRenderProps } from './FormikEditResource';
import debounce from 'lodash.debounce';
import { UnregisterCallback } from 'history';

interface Props<T> extends FormikEditResourceRenderProps<T> {
  id: string;
  children: (p: FormikEditResourceRenderProps<T>) => React.ReactNode;
}

const messages = defineMessages({
  routingWarning: {
    defaultMessage:
      'You have unsaved work. Are you sure you want to leave the page?',
    id: 'routing.change.route.warning',
  },
});

function FormikLocalStorage<T extends { updatedAt?: string }>(
  props: Props<T>
): JSX.Element {
  const { id, children, refresh, ...formik } = props;
  const { dirty, values, setValues } = formik;
  const viewer = useViewer();
  const viewerId = `${viewer && viewer.id}`;
  const key = `resId_${id}_userId_${viewerId}_${pjson.version}`;
  const intl = useIntl();
  const history = useHistory();

  useEffect(() => {
    const stored = localStorage.getItem(key);
    if (stored) {
      const parsed = JSON.parse(stored);
      if (
        values.updatedAt &&
        new Date(parsed.updatedAt).getTime() >=
          new Date(values.updatedAt).getTime()
      ) {
        setValues(parsed);
      } else {
        localStorage.removeItem(key);
      }
    }
  }, [key, setValues, values.updatedAt]);

  const store = debounce((values: unknown) => {
    localStorage.setItem(key, JSON.stringify(values));
  }, 300);

  useEffect(() => {
    if (dirty) {
      store(values);
    } else {
      localStorage.removeItem(key);
    }
  }, [values, key, dirty, store]);

  // detect route change if form is changed
  useEffect(() => {
    let clear: UnregisterCallback | null = null;
    const routingWarning = intl.formatMessage(messages.routingWarning);
    if (dirty) {
      clear = history.block(routingWarning);
    }

    window.onbeforeunload = (e: BeforeUnloadEvent) => {
      if (dirty) {
        e.returnValue = routingWarning;
        return routingWarning;
      } else {
        return null;
      }
    };
    return () => {
      window.onbeforeunload = null;
      if (clear) {
        clear();
      }
    };
  }, [intl, dirty, history]);

  const handleRefresh = () => {
    localStorage.removeItem(key);
    return refresh();
  };

  return <>{children({ ...formik, refresh: handleRefresh })}</>;
}

export default FormikLocalStorage;
