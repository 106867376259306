import React from 'react';
import { Helmet } from 'react-helmet';

const PageHeader = () => {
  const str = /* (dirty ? `* ` : ``) + */ `Yourpass console `;
  return (
    <div>
      <Helmet>
        <title>{str}</title>
      </Helmet>
    </div>
  );
};

export default PageHeader;
