import React from 'react';

import Grid from '@material-ui/core/Grid';

import ImageLabel from '../image/ImageLabel';
import ImageSelect, { ImageSelectProps } from './ImageSelect';

const PassImage = (props: ImageSelectProps) => {
  const { imageType } = props;
  return (
    <Grid container={true} spacing={3}>
      <Grid item={true} xs={3}>
        <ImageLabel imageType={imageType} />
      </Grid>
      <Grid item={true} xs={6}>
        <ImageSelect {...props} />
      </Grid>
    </Grid>
  );
};
export default PassImage;
