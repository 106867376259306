import { FormikProps } from 'formik';
import { get } from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { FieldTextInput } from '../../common/fields';
import Form from '../../common/Form';
import { Template } from '../../../types/Template';

type Props = FormikProps<Template>;

export default function PreviewPageForm(props: Props) {
  const { errors, values, handleChange, handleBlur } = props;

  return (
    <Form>
      <FieldTextInput
        label={
          <FormattedMessage
            id='template.preview.page.logo.url.title'
            defaultMessage='Logo URL'
          />
        }
        helpText={
          <FormattedMessage
            id='template.preview.page.logo.url.helpText'
            defaultMessage='URL address to logo image which will be displayed in preview page.'
          />
        }
        error={get(errors, 'previewLogoUrl')}
        name='previewLogoUrl'
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.previewLogoUrl}
      />
    </Form>
  );
}
