import { FormikProps } from 'formik';
import React, { Component } from 'react';
import { defineMessages, injectIntl, WrappedComponentProps } from 'react-intl';

import Button from '@material-ui/core/Button';

import { ChangeEvent, FieldTextArea } from '../../common/fields';
import Form from '../../common/Form';
import {
  InjectedNotificationProps,
  withNotification,
} from '../../../context/NotificationContext';
import { templateUpdateSaveToGooglePay } from '../../../utils/services/api';
import { Template } from '../../../types/Template';

const messages = defineMessages({
  buttonUpdateAllPass: {
    defaultMessage: 'Update all google pay objects',
    id: 'SaveToGooglePayTab.update.button',
  },
  template: {
    defaultMessage: 'Google Pay object template',
    id: 'SaveToGooglePayTab.template.label',
  },
});

interface Props
  extends FormikProps<Template>,
    InjectedNotificationProps,
    WrappedComponentProps {}

class SaveToGooglePayForm extends Component<Props> {
  public handleChange = (event: ChangeEvent) => {
    this.props.setFieldValue(
      'saveToGooglePayObjectTemplate',
      event.target.value
    );
  };

  public handleSaveToGooglePayUpdate = async () => {
    const { values, addNotification } = this.props;
    try {
      await templateUpdateSaveToGooglePay(values.id);
      addNotification({
        level: 'success',
        message: `Passes for template ${values.id} updates save to google pay objects`,
        title: 'Template update Save to Google Pay success',
      });
    } catch (e) {
      addNotification({
        level: 'error',
        message: e.message,
        title: 'Template update Save to Google Pay failure',
      });
    }
  };

  public render() {
    const { values, intl, dirty, handleChange } = this.props;

    return (
      <Form>
        <FieldTextArea
          label={intl.formatMessage(messages.template)}
          onChange={handleChange}
          value={values.saveToGooglePayObjectTemplate}
          name='saveToGooglePayObjectTemplate'
        />
        <Button onClick={this.handleSaveToGooglePayUpdate} disabled={dirty}>
          {intl.formatMessage(messages.buttonUpdateAllPass)}
        </Button>
      </Form>
    );
  }
}

export default withNotification(injectIntl(SaveToGooglePayForm));
