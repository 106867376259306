import { FormikErrors, FormikProps, FormikHelpers, Formik } from 'formik';
import React, { Fragment, ReactNode } from 'react';

import { CancelButton, CreateButton, ResetButton } from '../buttons';
import Drawer from '../drawers/Drawer';
import EditDrawerInnerLayout from '../drawers/EditDrawerInnerLayout';
import useOpen from '../../../hooks/useOpen';
import { ResourceTypes, create } from '../../../utils/services/api';

import useNotification from '../../../hooks/useNotification';
import { OnCreateProps } from '../../../types';

interface Props<T> extends OnCreateProps {
  resource: ResourceTypes;
  initialValues: T;
  validateOnBlur?: boolean;
  validateOnChange?: boolean;
  trigger?: ReactNode;
  validate?: (
    values: T
  ) => void | Record<string, unknown> | Promise<FormikErrors<T>>;
  validationSchema?: any;
  renderForm: (p: FormikProps<T>) => ReactNode;
  renderActions?: (p: FormikProps<T>) => ReactNode;
  title: ReactNode;
}

function FormikCreateDrawer<T>(props: Props<T>): JSX.Element {
  const open = useOpen(false);

  const {
    initialValues,
    trigger,
    validate,
    validationSchema,
    resource,
    onCreate,
  } = props;

  const { addErrorNotification } = useNotification();
  const handleSubmit = async (values: T, formikHelpers: FormikHelpers<T>) => {
    formikHelpers.setSubmitting(true);
    try {
      const r: T = await create(resource, values);
      onCreate(r, resource);
      open.handleClose();
    } catch (e) {
      addErrorNotification(`Create ${resource} failure, because: ${e.message}`);
    }
    formikHelpers.setSubmitting(false);
  };

  return (
    <Fragment>
      <span onClick={open.handleOpen}>{trigger || <CreateButton />}</span>
      <Drawer
        open={open.open}
        onClose={open.handleClose}
        content={
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validate={validate}
            validationSchema={validationSchema}
          >
            {(fp: FormikProps<T>) => {
              const handleCancel = () => {
                fp.resetForm();
                open.handleClose();
              };

              const handleReset = () => {
                fp.resetForm();
              };

              const handleSubmitClick = () => {
                fp.submitForm();
              };

              return (
                <EditDrawerInnerLayout
                  onClose={open.handleClose}
                  content={props.renderForm(fp)}
                  controls={
                    props.renderActions ? (
                      props.renderActions(fp)
                    ) : (
                      <span>
                        <CancelButton onClick={handleCancel} />
                        <ResetButton onClick={handleReset} />
                        <CreateButton onClick={handleSubmitClick} />
                      </span>
                    )
                  }
                  title={props.title}
                />
              );
            }}
          </Formik>
        }
      />
    </Fragment>
  );
}

export default FormikCreateDrawer;
