import { FormikProps } from 'formik';
import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import { CreateButton } from '../../components/common/buttons';
import FormikCreateDrawer from '../../components/common/formik/FormikCreateDrawer';
import { ResourceTypes } from '../../utils/services/api';
import {
  ProjectCreateForm,
  NewProjectFormInterface,
} from './ProjectCreateForm';

interface ProjectCreateDrawerProps {
  parentId: string | undefined;
  disabled: boolean;
  onCreate: (p: unknown) => void;
}

export const ProjectCreateDrawer: FunctionComponent<ProjectCreateDrawerProps> = (
  props: ProjectCreateDrawerProps
): JSX.Element => {
  const trigger = (
    <CreateButton disabled={props.disabled}>
      {!props.parentId ? (
        <FormattedMessage
          id='ProjectNewModal.create.project'
          defaultMessage='New project'
        />
      ) : (
        <FormattedMessage
          id='ProjectNewModal.create.subproject'
          defaultMessage='New subproject'
        />
      )}
    </CreateButton>
  );

  const renderForm = (p: FormikProps<NewProjectFormInterface>) => (
    <ProjectCreateForm {...p} />
  );

  return (
    <FormikCreateDrawer
      onCreate={props.onCreate}
      title={
        <FormattedMessage
          id='ProjectNewModal.modal.title'
          defaultMessage='New project'
        />
      }
      initialValues={
        {
          active: true,
          description: '',
          name: '',
          parentId: props.parentId,
        } as NewProjectFormInterface
      }
      resource={ResourceTypes.PROJECT}
      trigger={trigger}
      renderForm={renderForm}
    />
  );
};
