const dynamicDataJsonSchema = {
  properties: {},
  title: 'DynamicData',
  type: ['object', 'null'],
};

const defaultTemplate: any = {
  distributable: false,
  dynamicDataJsonSchema,
  images: {},
  jsonSchema: undefined,
  jsonTemplate: {
    storeCard: {
      type: 'object',
      properties: {},
    },
    labelColor: {
      type: 'string',
      value: 'rgb(255,255,255)',
    },
    backgroundColor: {
      type: 'string',
      value: 'rgb(0,0,0)',
    },
    foregroundColor: {
      type: 'string',
      value: 'rgb(255,255,255)',
    },
  },
  languages: [],
};

export default defaultTemplate;
