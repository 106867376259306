import { array, object } from 'yup';

import {
  arrayTypeSchema,
  numberValueSchema,
  objectTypeSchema,
  stringValueSchema,
} from './helper';

export const locationSchema = object().shape({
  properties: object({
    altitude: numberValueSchema,
    latitude: numberValueSchema.required(),
    longitude: numberValueSchema.required(),
    relevantText: stringValueSchema,
  }),
  type: objectTypeSchema,
});

export const locationsSchema = object()
  .shape({
    items: array().of(locationSchema).min(0).max(10),
    type: arrayTypeSchema,
  })
  .default(undefined);
