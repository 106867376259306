import React from 'react';
import { FieldJSON } from '../common/fields';
import Form from '../common/Form';
import { CodeIcon, ErrorIcon } from '../common/icons';
import Tabs, { TabOption } from '../common/Tabs';
import { Template } from '../../types/Template';
import { createTemplateDistributionTab } from './distribution/TemplateDistributionFormField';
import DynamicDataTab from './dynamicData/DynamicDataTab';
import GeneralTab from './general/GeneralTab';
import DesignTab from './design/DesignTab';
import createPreviewPageTab from './previewPage/createPreviewPageTab';
import useViewer from '../../hooks/useViewer';
import { FormikEditResourceRenderProps } from '../common/formik/FormikEditResource';

export const FORM_NAME = 'template';

const TemplateEditTabs = (
  props: FormikEditResourceRenderProps<Template>
): JSX.Element => {
  const { values, errors, touched, dirty, setValues, setTouched } = props;

  const viewer = useViewer();

  const handleChangeJSON = (data: { name: string; value: unknown }) => {
    setValues(data.value as Template);
    setTouched({ id: true });
  };

  const tabOptions: TabOption[] = [
    GeneralTab(props),
    DynamicDataTab(props),
    createTemplateDistributionTab(props),
    DesignTab(props),
  ];

  if (viewer && viewer.isAdmin) {
    tabOptions.push(createPreviewPageTab(props));
    tabOptions.push({
      content: (
        <Form>
          <FieldJSON onChange={handleChangeJSON} name='json' value={values} />
        </Form>
      ),
      icon: <CodeIcon />,
      key: 'json',
      name: 'Json',
    });
    tabOptions.push({
      content: (
        <div>
          <h3>Errors</h3>
          <pre>{JSON.stringify(errors, null, 2)}</pre>
          <h3>Touched</h3>
          <pre>{JSON.stringify(touched, null, 2)}</pre>
          <h3>Dirty</h3>
          <pre>{JSON.stringify(dirty, null, 2)}</pre>
        </div>
      ),
      error: !errors,
      icon: <ErrorIcon />,
      key: 'error',
      name: 'Error',
    });
  }

  return (
    <Tabs tabOptions={tabOptions} formName={FORM_NAME} defaultTab='pkpass' />
  );
};

export default TemplateEditTabs;
