import React from 'react';
import { FormattedMessage } from 'react-intl';

import { FieldSelect, FieldSelectData, FieldSelectOption } from '../fields';
import { FilterElementProps } from './Filter';

interface Props extends FilterElementProps {
  value: boolean | undefined;
  onChange: (e: FieldSelectData) => void;
}

const options: FieldSelectOption[] = [
  {
    key: '-',
    text: '-',
    value: '',
  },
  {
    key: 'true',
    text: <FormattedMessage id='FilterVoided.yes' defaultMessage='Yes' />,
    value: `true`,
  },
  {
    key: 'eq',
    text: <FormattedMessage id='FilterVoided.yes' defaultMessage='No' />,
    value: `false`,
  },
];

export default function FilterVoided(props: Props) {
  const handleChange = (event: FieldSelectData) => {
    if (props.onChange) {
      const strValue = event.value as string;
      let value;
      if (strValue === 'true') {
        value = true;
      } else if (strValue === 'false') {
        value = false;
      }
      props.onChange({ name: props.name, value: value });
    }
  };

  return (
    <FieldSelect
      name='voided'
      disabled={props.disabled}
      label={props.label}
      helpText={props.helpText}
      options={options}
      value={props.value}
      onChange={handleChange}
    />
  );
}
