import React from 'react';

import {
  PkpassWysiwygLocalizedString,
  WysiwygLocalizedStringInputWithFallbackData,
} from './PkpassWysiwygLocalizedString';
import { PkpassStringData } from './PkpassString';

type ValueType =
  | {
      type: 'string';
      value?: string | { [key: string]: string | undefined };
      fallback?: string;
    }
  | undefined;

export interface PkpassLocalizedStringData {
  name: string;
  value: ValueType;
}

export interface PkpassLocalizedStringProps {
  name: string;
  label: JSX.Element;
  helpText: JSX.Element;
  required?: boolean;
  multiline?: boolean;
  onChange?: (data: PkpassLocalizedStringData) => void;
  languages?: string[];
  link?: boolean;
  disabled?: boolean;
  placeholder?: string;
  errorPaths?: string[];
  localized?: boolean;
}

export const PkpassLocalizedString = (
  props: PkpassLocalizedStringProps
): JSX.Element => {
  const { onChange, ...r } = props;
  const handleChange = (d: WysiwygLocalizedStringInputWithFallbackData) => {
    if (onChange) {
      onChange(d as PkpassStringData);
    }
  };
  return (
    <PkpassWysiwygLocalizedString
      {...r}
      onChange={handleChange}
      link={false}
      multiline={props.multiline === true}
    />
  );
};
