import { array, object, string } from 'yup';

import { PkpassBarcodeTypes } from '../../types/Template';
import {
  arrayTypeSchema,
  objectTypeSchema,
  stringTypeSchema,
  stringValueSchema,
} from './helper';

export const barcodeFormatStringSchema = string().oneOf([
  PkpassBarcodeTypes.PKBarcodeFormatQR,
  PkpassBarcodeTypes.PKBarcodeFormatPDF417,
  PkpassBarcodeTypes.PKBarcodeFormatAztec,
  PkpassBarcodeTypes.PKBarcodeFormatCode128,
]);

export const barcodeFormatSchema = object().shape({
  type: stringTypeSchema.required(),
  value: barcodeFormatStringSchema.required(),
});

export const barcodeSchema = object().shape({
  properties: object({
    altText: stringValueSchema,
    format: barcodeFormatSchema.required(),
    message: stringValueSchema.required(),
    messageEncoding: stringValueSchema.required(),
  }).required(),
  type: objectTypeSchema.required(),
});

export const barcodesSchema = object()
  .shape({
    items: array().of(barcodeSchema).min(1).max(2),
    type: arrayTypeSchema,
  })
  .default(undefined);
