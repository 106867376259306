import React, { FunctionComponent, ReactNode } from 'react';

import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';

import { FieldLabel } from './FieldLabel';

export interface FieldSetProps {
  label?: ReactNode;
  helpText?: ReactNode;
  children: ReactNode;
  error?: string;
}

export const FieldSet: FunctionComponent<FieldSetProps> = (
  props: FieldSetProps
) => {
  return (
    <FormControl component='fieldset' margin='dense'>
      <FormLabel component='legend'>
        <FieldLabel label={props.label} helpText={props.helpText} />
      </FormLabel>
      <FormGroup>{props.children}</FormGroup>
      <FormHelperText error={true}>{props.error}</FormHelperText>
    </FormControl>
  );
};
