import React from 'react';
import { withTheme, FormProps } from '@rjsf/core';
import { Theme as MaterialUITheme } from '@rjsf/material-ui';
const Form = withTheme(MaterialUITheme);

export default function JsonSchemaFormUI(props: FormProps<any>) {
  return (
    <Form {...props}>
      <div>
        <button type='submit' style={{ display: 'none' }}>
          Submit
        </button>
      </div>
    </Form>
  );
}
