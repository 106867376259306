import React from 'react';

import { DataGridColumns, Fields } from '../common/dataGrid';
import DataGridResources from '../common/dataGrid/DataGridResources';
import { StaticLabel } from '../common/labels';
import useViewer from '../../hooks/useViewer';
import { ResourceTypes } from '../../utils/services/api';
import { TemplateListItem } from '../../types/Template';
import TemplateListItemActions from './TemplateListItemActions';
import { ProjectLabelWithDrawer } from '../../domain';
import { FilterProps } from '../../types/common/filter';

const fields: Fields<TemplateListItem> = [
  { key: 'name', title: StaticLabel.Name },
  {
    key: 'projectId',
    title: StaticLabel.Project,
    render(item: TemplateListItem) {
      return <ProjectLabelWithDrawer resourceId={item.projectId} />;
    },
  },
  { key: 'createdAt', title: StaticLabel.CreatedAt, type: 'date' },
  { key: 'updatedAt', title: StaticLabel.UpdatedAt, type: 'date' },
  {
    key: DataGridColumns.Actions,
    title: StaticLabel.Actions,
    render(item: TemplateListItem) {
      return <TemplateListItemActions item={item} />;
    },
  },
];

const filterProps: FilterProps[] = [
  {
    columnName: 'projectId',
    name: 'project',
    type: 'inUuid',
  },
  {
    columnName: 'name',
    name: 'name',
    type: 'like',
  },
];

const TemplateList = (props: { autoLoad?: boolean }): JSX.Element => {
  const viewer = useViewer();

  const componentProps = {
    fields,
    filterOptions: { project: true, name: true },
    filterProps,
  };

  return (
    <DataGridResources
      resource={ResourceTypes.TEMPLATE}
      {...componentProps}
      autoLoad={props.autoLoad}
      export={!!(viewer && viewer.isAdmin)}
    />
  );
};

export default TemplateList;
