import React from 'react';
import { FormattedMessage } from 'react-intl';

import Card from '@material-ui/core/Card';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Page from '../../components/common/page/Page';
import { withReport } from '../../context/';
import { ProjectLabelWithDrawer } from '../../domain/project';

const serviceType = 'activity';

interface ActiveCardItem {
  projectId: string;
  active: number;
  updates: number;
  registrations: number;
}

function ActiveCardsOverviewContent(props: { data: ActiveCardItem[] }) {
  const { data } = props;
  return (
    <Card>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <FormattedMessage
                id='ActiveCardsOverview.project'
                defaultMessage='Project'
              />
            </TableCell>
            <TableCell>
              <FormattedMessage
                id='ActiveCardsOverview.active.title'
                defaultMessage='Number of active cards'
              />
            </TableCell>
            <TableCell>
              <FormattedMessage
                id='ActiveCardsOverview.registration.title'
                defaultMessage='Number of card registrations'
              />
            </TableCell>
            <TableCell>
              <FormattedMessage
                id='ActiveCardsOverview.updates.title'
                defaultMessage='Number of card updates'
              />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item: ActiveCardItem) => (
            <TableRow key={item.projectId}>
              <TableCell>
                <ProjectLabelWithDrawer resourceId={item.projectId} />
              </TableCell>
              <TableCell>{item.active}</TableCell>
              <TableCell>{item.registrations}</TableCell>
              <TableCell>{item.updates}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Card>
  );
}

const Report = withReport(ActiveCardsOverviewContent, serviceType);

export const ActiveCardsOverviewPage = (): JSX.Element => {
  return (
    <Page
      content={<Report />}
      title={
        <FormattedMessage
          id='ActiveCardsOverviewPage.title'
          defaultMessage='Usage of links on passes'
        />
      }
    />
  );
};
