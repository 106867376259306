import { useFormikContext } from 'formik';
import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles';

import { getInvertColor } from '../../../../../utils/services/color';
import { PkpassColor, Template } from '../../../../../types/Template';
import {
  getBackgroundColor,
  getForegroundColor,
  getLabelColor,
} from '../../helpers/color';
import {
  BackgroundColorLabel,
  ForegroundColorLabel,
  LabelColorLabel,
} from './ColorsForm';
import FormikDialog from '../../../../common/formik/FormikDialog';
import ColorsForm from './ColorsForm';
import IconButton from '@material-ui/core/IconButton';
import { EditIcon } from '../../../../common/icons';
import useOpen from '../../../../../hooks/useOpen';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    itemRoot: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      fontSize: 12,
      paddingRight: 8,
    },
    itemRootColoredBox: {
      border: '1px solid',
      height: 12,
      marginRight: 5,
      width: 12,
    },
    previewRoot: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      cursor: 'pointer',
      '&:hover': {
        opacity: 0.7,
      },
    },
    icon: {
      height: '0.8em',
      width: '0.8em',
    },
  })
);

export function ColorsDialog(): JSX.Element {
  const { values, setFieldValue } = useFormikContext<Template>();
  const oi = useOpen(false);
  const theme = useTheme();
  const classes = useStyles(theme);

  const renderItem = (label: JSX.Element, c: string) => {
    return (
      <div className={classes.itemRoot}>
        <div
          className={classes.itemRootColoredBox}
          style={{
            background: c,
            borderColor: getInvertColor(c),
          }}
        />
        {label}
      </div>
    );
  };

  const onChange = (v: {
    foregroundColor: PkpassColor;
    labelColor: PkpassColor;
    backgroundColor: PkpassColor;
  }) => {
    setFieldValue('jsonTemplate.foregroundColor', v.foregroundColor);
    setFieldValue('jsonTemplate.labelColor', v.labelColor);
    setFieldValue('jsonTemplate.backgroundColor', v.backgroundColor);
  };

  return (
    <Fragment>
      <div className={classes.previewRoot} onClick={oi.handleOpen}>
        {renderItem(BackgroundColorLabel, getBackgroundColor(values))}
        {renderItem(ForegroundColorLabel, getForegroundColor(values))}
        {renderItem(LabelColorLabel, getLabelColor(values))}
        <IconButton size={'small'}>
          <EditIcon className={classes.icon} />
        </IconButton>
      </div>
      <FormikDialog
        title={
          <FormattedMessage
            id='pkpass.colors.dialog.title'
            defaultMessage={'Edit colors'}
          />
        }
        isOpen={oi.open}
        onClose={oi.handleClose}
        onSubmit={onChange}
        content={<ColorsForm />}
        initialValues={{
          foregroundColor: values.jsonTemplate.foregroundColor,
          labelColor: values.jsonTemplate.labelColor,
          backgroundColor: values.jsonTemplate.backgroundColor,
        }}
      />
    </Fragment>
  );
}
