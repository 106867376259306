import React from 'react';

const B = 1024;

function formatBytes(bytes: number | undefined) {
  if (!bytes) {
    return '';
  }

  const i: number = Math.floor(Math.log(bytes) / Math.log(B));
  return (
    (!bytes && '0 LabelBytes') ||
    `${(bytes / B ** i).toFixed(2)} ${
      ['LabelBytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'][i]
    }`
  );
}

export const LabelBytes = (props: {
  bytes: number | undefined;
}): JSX.Element => {
  return <span>{formatBytes(props.bytes)}</span>;
};
