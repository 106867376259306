import React from 'react';
import { FormattedMessage } from 'react-intl';

import Icon from '@material-ui/icons/Add';

import { Button, ButtonProps } from './Button';

export const CreateButton = (props: ButtonProps): JSX.Element => {
  return (
    <Button
      type='button'
      color='primary'
      variant='contained'
      {...props}
      startIcon={<Icon />}
    >
      {props.children || (
        <FormattedMessage
          id={'CreateButton.button.text'}
          defaultMessage={'Create'}
        />
      )}
    </Button>
  );
};
