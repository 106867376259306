import React from 'react';
import { FormattedMessage } from 'react-intl';

import DesignerBackSide from '../../../design/pkpass/designer/layout/DesignerBackSide';
import HelpAnimation from '../HelpAnimation';
import StepGrid from '../StepGrid';
import { useTemplateUI } from '../../../design/pkpass/useTemplateUI';
import { usePreview } from '../../../design/pkpass/usePreview';

export const StepBackSide = (): JSX.Element => {
  const { templatePreviewLanguage } = useTemplateUI();
  const { pass, images, handleClickField, handleFieldDnD } = usePreview();

  return (
    <StepGrid
      col1={
        <div style={{ position: 'relative', width: 320, height: 450 }}>
          <HelpAnimation style={{ top: -50, left: 60 }} />
          <DesignerBackSide
            pass={pass}
            images={images}
            language={templatePreviewLanguage}
            scanDisabled={true}
            onFieldClick={handleClickField}
            onFieldDnD={handleFieldDnD}
          />
        </div>
      }
      col2={
        <FormattedMessage
          id='TemplateNewModalContent.StepBackSide.Layout.ReadMore.Title.Add'
          defaultMessage='To add new field, click to green button. To edit or delete a field, click on the field.'
        />
      }
    />
  );
};
