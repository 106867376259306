import { MixedSchema, object } from 'yup';

import { joinPaths, localizableStringValueSchema } from './helper';

const avToHtml = (htmlString: string) => {
  const div = document.createElement('div');
  // the space on start is prevent to "<a" or "<d" resolve html element
  div.innerHTML = ` ` + htmlString;
  return div;
};

export const validateAttributedValue = (av: string): string | null => {
  if (av) {
    if (typeof av !== 'string') {
      return `"${av}" is not string`;
    }

    const node = avToHtml(av);
    if (node) {
      // @ts-ignore
      for (const n of node.childNodes) {
        switch (n.nodeType) {
          case Node.ELEMENT_NODE: {
            if (n.nodeName === 'A') {
              const nodeMap: NamedNodeMap = n.attributes;
              for (let i = 0; i < nodeMap.length; i++) {
                const attr: Attr | null = nodeMap.item(i);
                if (attr) {
                  if ('href' !== attr.name) {
                    return `Link contains unsupported attribute "${attr.name}".`;
                  }
                }
              }
              break;
            }
            // return `Contains unsupported element node: ${n.nodeName}`;
            return `Contains unsupported HTML.`;
          }
          case Node.TEXT_NODE: {
            // ok - console.log(n.nodeValue);
            break;
          }
          default: {
            // return `Contains unsupported node type: ${n.nodeName}`;
            return `Contains unsupported HTML.`;
          }
        }
      }
    }
  }
  return null;
};

export const validateValue = (av: string): string | null => {
  if (av) {
    if (typeof av !== 'string') {
      return `"${av}" is not string`;
    }
    const node = avToHtml(av);
    if (node) {
      if (
        !(
          node.childNodes.length === 1 &&
          node.childNodes[0].nodeType === Node.TEXT_NODE
        )
      ) {
        return `Contains unsupported HTML.`;
      }
    }
  }
  return null;
};

export const textAttributedValueSchema: MixedSchema = object().test({
  name: 'textAttributedValueSchema',
  test(value: {
    value: string | { [key: string]: string };
    fallback: string | undefined;
    type: string;
  }) {
    try {
      localizableStringValueSchema.validateSync(value, { context: this });
    } catch (e) {
      return this.createError({
        message: e.message,
        path: joinPaths(this.path, e.path),
      });
    }

    if (value) {
      if (typeof value.value === 'string') {
        const res = validateAttributedValue(value.value);
        if (res) {
          return this.createError({
            message: res,
            path: joinPaths(this.path, 'value'),
          });
        }
      } else {
        const languages = Object.keys(value.value);
        for (const lang of languages) {
          const res = validateAttributedValue(value.value[lang]);
          if (res) {
            this.createError({
              message: res,
              path: joinPaths(this.path, `value.${lang}`),
            });
          }
        }
      }

      if (value.fallback) {
        const res = validateAttributedValue(value.fallback);
        if (res) {
          return this.createError({
            message: res,
            path: joinPaths(this.path, 'fallback'),
          });
        }
      }
    }

    return true;
  },
});

export const textValueSchema: MixedSchema = object().test({
  name: 'textValueSchema',
  test(value: {
    value: string | { [key: string]: string };
    fallback: string | undefined;
    type: string;
  }) {
    try {
      localizableStringValueSchema.validateSync(value, { context: this });
    } catch (e) {
      return this.createError({
        message: e.message,
        path: joinPaths(this.path, e.path),
      });
    }

    if (value) {
      if (typeof value.value === 'string') {
        const res = validateValue(value.value);
        if (res) {
          return this.createError({
            message: res,
            path: joinPaths(this.path, 'value'),
          });
        }
      } else {
        const languages = Object.keys(value.value);
        for (const lang of languages) {
          const res = validateValue(value.value[lang]);
          if (res) {
            this.createError({
              message: res,
              path: joinPaths(this.path, `value.${lang}`),
            });
          }
        }
      }

      if (value.fallback) {
        const res = validateValue(value.fallback);
        if (res) {
          return this.createError({
            message: res,
            path: joinPaths(this.path, 'fallback'),
          });
        }
      }
    }

    return true;
  },
});
