import { useEffect, useState } from 'react';

import {
  list,
  listExport as listExportComplete,
  count as listCount,
  ResourceTypes,
} from '../utils/services/api';
import { API_SERVICE_TIMEOUT } from '../utils/services/environment';
import { prepareFilter } from '../utils/services/filter';
import { List, Order } from '../types/common/list';

export interface ResourcePagedOptions<T> {
  filterProps?: Array<{
    name: string;
    type: string;
    columnName: string;
  }>;
  order?: Order;
  orderBy?: string;
  limit?: number;
  page?: number;
  filter: any;
  autoLoad?: boolean;
  canBeDeleted?: (item: T) => boolean;
}

interface State<T> {
  data: T[];
  error?: Error;
  selected: { [key: number]: boolean };
  filterProps: Array<{
    name: string;
    type: string;
    columnName: string;
  }>;
  order: Order;
  orderBy: string;
  limit: number;
  page: number;
}

export interface ResourcePagedHookProps<T> extends State<T> {
  nextPage: (() => void) | null;
  prevPage: (() => void) | null;
  listLoad: () => void;
  listSetOrder: (orderBy: string, order: Order) => void;
  onFilterSubmit: () => void;
  listSetLimit: (limit: number) => void;
  listExport: (
    exportHeaders: string[] | undefined,
    callback: (p: { current: number; total: number }) => void | undefined
  ) => Promise<void>;
  count: () => Promise<number>;
  selectRow?: (index: number, value: boolean) => void;
  unselect?: () => void;
  selectAllRows?: (value: boolean) => void;
  selected: { [key: number]: boolean };
  loading: boolean;
  refreshCount: number;
  autoLoad?: boolean;
}

type PagedState<T> = {
  loading: boolean;
  data: T[];
  selected: { [key: number]: boolean };
  page: number;
  limit: number;
  order: Order;
  orderBy: string;
  query: any;
  refreshCount: number;
};

export default function Paged<T>(
  resource: ResourceTypes,
  opts: ResourcePagedOptions<T>
) {
  const filterProps = opts.filterProps || [];
  const [state, setState] = useState<PagedState<T>>({
    loading: true,
    data: [],
    selected: {},
    page: opts.page || 1,
    limit: opts.limit || 10,
    order: opts.order || Order.DESC,
    orderBy: opts.orderBy || 'updatedAt',
    query: prepareFilter(opts.filter, filterProps),
    refreshCount: 0,
  });

  const listSetOrder = (ob: string, o: Order) => {
    setState((s) => ({
      ...s,
      order: o,
      orderBy: ob,
      refreshCount: s.refreshCount + 1,
    }));
  };

  const listExport = async (
    exportHeaders: string[] | undefined,
    callback: (p: { current: number; total: number }) => void | undefined
  ) => {
    const newOpts = {
      limit: state.limit,
      order: state.order,
      orderBy: state.orderBy,
      page: state.page,
      query: state.query,
      resource,
      exportHeaders,
    };
    return listExportComplete(newOpts, callback);
  };

  const count = async () => {
    const newOpts = {
      query: state.query,
      resource,
    };
    return await listCount(resource, newOpts);
  };

  const selectRow = (index: number, value: boolean) => {
    const element = state.data[index];
    if (opts.canBeDeleted && opts.canBeDeleted(element)) {
      const newSelected = { ...state.selected, [index]: value };
      setState((s) => ({ ...s, selected: newSelected }));
    }
  };

  const unselect = () => {
    setState((s) => ({ ...s, selected: {} }));
  };

  const selectAllRows = (value: boolean) => {
    const newSelected: { [key: number]: boolean } = {};
    state.data.forEach((element: T, index: number) => {
      if (opts.canBeDeleted && opts.canBeDeleted(element)) {
        newSelected[index] = value;
      }
    });
    setState((s) => ({ ...s, selected: newSelected }));
  };

  const onFilterSubmit = () => {
    setState((s) => ({
      ...s,
      page: 1,
      query: prepareFilter(opts.filter, filterProps),
      refreshCount: s.refreshCount + 1,
    }));
  };

  const listSetLimit = (l: number) => {
    setState((s) => ({ ...s, limit: l, refreshCount: s.refreshCount + 1 }));
  };

  const prevPage =
    state.page > 1
      ? () => {
          setState((s) => ({
            ...s,
            page: s.page - 1,
            refreshCount: s.refreshCount + 1,
          }));
        }
      : null;

  const nextPage =
    state.data.length > 0
      ? () => {
          setState((s) => ({
            ...s,
            page: s.page + 1,
            refreshCount: s.refreshCount + 1,
          }));
        }
      : null;

  const refresh = async () => {
    setState((s) => ({ ...s, refreshCount: s.refreshCount + 1 }));
  };

  useEffect(() => {
    const abortController = new AbortController();
    const r = async () => {
      setState((s) => ({ ...s, loading: true }));
      const o = {
        limit: state.limit,
        order: state.order,
        orderBy: state.orderBy,
        page: state.page,
        query: state.query,
      };
      const t = setTimeout(() => {
        abortController.abort();
      }, API_SERVICE_TIMEOUT);
      try {
        if (!(state.refreshCount === 0 && opts.autoLoad === false)) {
          const response: List<T> = await list<T>(resource, o, {
            signal: abortController.signal,
          });

          if (response.data === null) {
            response.data = [] as T[];
          }
          setState((s) => ({ ...s, loading: false, data: response.data }));
        }
      } catch (e) {
        setState((s) => ({ ...s, loading: false }));
      }
      clearTimeout(t);
    };

    r();

    return function cancel() {
      abortController.abort();
    };
  }, [
    state.refreshCount,
    state.page,
    state.limit,
    state.order,
    state.orderBy,
    resource,
    state.query,
    opts.autoLoad,
  ]);

  return {
    ...state,
    filterProps,
    listExport,
    count,
    listLoad: refresh,
    listSetLimit,
    listSetOrder,
    nextPage,
    onFilterSubmit,
    prevPage,
    unselect: opts.canBeDeleted ? unselect : undefined,
    selectAllRows: opts.canBeDeleted ? selectAllRows : undefined,
    selectRow: opts.canBeDeleted ? selectRow : undefined,
    autoLoad: opts.autoLoad,
  };
}
