import PropTypes from 'prop-types';
import React, { FunctionComponent } from 'react';
import { ResourceTypes } from '../../../utils/services/api';
import { Props } from './FieldAutocomplete';

import { FieldAutocompleteCacheResource } from './FieldAutocompleteCacheResource';
import { StaticLabel } from '../labels';

export const FieldAutocompleteCertificate: FunctionComponent<Props<string>> = (
  props: Props<string>
): JSX.Element => {
  return (
    <FieldAutocompleteCacheResource
      {...props}
      label={props.label || StaticLabel.Certificate}
      resource={ResourceTypes.CERTIFICATE}
    />
  );
};

FieldAutocompleteCertificate.propTypes = {
  label: PropTypes.node,
  required: PropTypes.bool,
  error: PropTypes.string,
  helpText: PropTypes.element,
};
