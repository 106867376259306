import React, { Component } from 'react';

import pjson from '../../../package.json';
import { FilterKeyTypes, FilterState, IntervalTypes } from './types';
import { Viewer } from '../../types/Viewer';

const defaultFrom = new Date();
defaultFrom.setDate(defaultFrom.getDate() - 30);
defaultFrom.setHours(0, 0, 0, 0);
const defaultTo = new Date();
defaultTo.setHours(23, 59, 59, 999);

const initialState: FilterState = {
  [FilterKeyTypes.CREATED_AT_FROM]: undefined,
  [FilterKeyTypes.CREATED_AT_TO]: undefined,
  [FilterKeyTypes.FROM]: defaultFrom,
  [FilterKeyTypes.TO]: defaultTo,
  [FilterKeyTypes.DATE]: defaultTo,
  [FilterKeyTypes.INTERVAL]: IntervalTypes.DAYLY,
  [FilterKeyTypes.IMAGE_TYPE]: [],
  [FilterKeyTypes.PROJECT]: [],
  [FilterKeyTypes.TEMPLATE]: [],
  [FilterKeyTypes.DYNAMIC_DATA]: undefined,
  [FilterKeyTypes.DEVICES]: undefined,
  [FilterKeyTypes.DISPLAY_NAME]: undefined,
  [FilterKeyTypes.NAME]: undefined,
  [FilterKeyTypes.EMAIL]: undefined,
  [FilterKeyTypes.PASS_TYPE_ID]: undefined,
  [FilterKeyTypes.ID]: undefined,
  [FilterKeyTypes.VOIDED]: undefined,
  [FilterKeyTypes.WITH_DELETED]: undefined,
};

export interface FilterContextInterface {
  filter: FilterState;
  filterSet(property: { name: FilterKeyTypes; value: any }): void;
}

export const FilterContext = React.createContext<FilterContextInterface>({
  filter: initialState,
  filterSet(property: { name: FilterKeyTypes; value: any }) {
    throw new Error('AppContext method is not defined');
  },
});

export class FilterContextProvider extends Component<
  { viewer: Viewer },
  { filter: FilterState }
> {
  constructor(props: { viewer: Viewer }) {
    super(props);

    const readedState = localStorage.getItem(
      `filter_${this.props.viewer.id}_${pjson.version}`
    );

    const filter: FilterState = readedState
      ? (JSON.parse(readedState) as FilterState)
      : initialState;

    this.state = {
      filter,
    };
    this.filterSet = this.filterSet.bind(this);
  }

  public componentDidUpdate() {
    localStorage.setItem(
      `filter_${this.props.viewer.id}_${pjson.version}`,
      JSON.stringify(this.state.filter)
    );
  }

  public filterSet(property: { name: FilterKeyTypes; value: any }) {
    this.setState((prevState: { filter: FilterState }) => {
      return {
        filter: {
          ...prevState.filter,
          [property.name]: property.value,
        },
      };
    });
  }

  public render() {
    const c = {
      filter: this.state.filter,
      filterSet: this.filterSet,
    };
    return (
      <FilterContext.Provider value={c}>
        {this.props.children}
      </FilterContext.Provider>
    );
  }
}

export interface InjectedFilterProps {
  filter: FilterState;
}

export const FilterConsumer = FilterContext.Consumer;

export function withFilter<P extends InjectedFilterProps>(
  WrappedComponent: React.ComponentType<P>
): React.ComponentType<Pick<P, Exclude<keyof P, keyof InjectedFilterProps>>> {
  return function WithFilterProvider(
    props: Pick<P, Exclude<keyof P, keyof InjectedFilterProps>>
  ) {
    return (
      <FilterContext.Consumer>
        {(context: FilterContextInterface) => (
          <WrappedComponent {...(props as P)} filter={context.filter} />
        )}
      </FilterContext.Consumer>
    );
  };
}

export type InjectedFilterSetProps = FilterContextInterface;

export function withFilterSet<P extends InjectedFilterSetProps>(
  WrappedComponent: React.ComponentType<P>
): React.ComponentType<
  Pick<P, Exclude<keyof P, keyof InjectedFilterSetProps>>
> {
  return function WithFilterSetProvider(
    props: Pick<P, Exclude<keyof P, keyof InjectedFilterSetProps>>
  ) {
    return (
      <FilterContext.Consumer>
        {(context: FilterContextInterface) => (
          <WrappedComponent {...(props as P)} {...context} />
        )}
      </FilterContext.Consumer>
    );
  };
}
