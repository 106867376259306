import { getIn } from 'formik';
import React from 'react';
import { useIntl } from 'react-intl';

import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';

import { DeleteButton } from '../../../../common/buttons';
import { PkpassLocation } from '../../../../../types/Template';
import LocationDialog from './LocationDialog';
import LocationLabel from './LocationLabel';
import { messages } from './messages';

interface Props {
  radius?: number;
  onRemove: () => void;
  onChange: (l: PkpassLocation) => void;
  location: PkpassLocation;
}

const LocationListItem = (props: Props): JSX.Element => {
  const { location, onChange, radius, onRemove } = props;
  const intl = useIntl();
  return (
    <ListItem>
      <ListItemText
        primary={
          <LocationDialog
            trigger={
              <span>
                <LocationLabel location={location} />
              </span>
            }
            initialValues={location}
            onChange={onChange}
            onRemove={onRemove}
            radius={radius}
          />
        }
        secondary={getIn(location, 'properties.relevantText.value')}
      />
      <ListItemSecondaryAction>
        <DeleteButton
          onDelete={onRemove}
          confirmText={intl.formatMessage(messages.deleteConfirmationText)}
        />
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default LocationListItem;
