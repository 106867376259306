import React from 'react';
import { FormattedMessage } from 'react-intl';

import Icon from '@material-ui/icons/Check';

import { Button, ButtonProps } from './Button';

export const ConfirmButton = (props: ButtonProps): JSX.Element => {
  return (
    <Button type='button' color='secondary' {...props} startIcon={<Icon />}>
      <FormattedMessage
        id={'ConfirmButton.button.text'}
        defaultMessage={'Confirm'}
      />
    </Button>
  );
};
