import './FieldColor.css';

import React, {
  Fragment,
  FunctionComponent,
  ReactNode,
  useRef,
  useState,
} from 'react';
import { ChromePicker, ColorResult } from 'react-color';

import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import ColorLensIcon from '@material-ui/icons/ColorLens';

import useOpen from '../../../hooks/useOpen';
import { getInvertColor } from '../../../utils/services/color';

const useStyles = makeStyles(() =>
  createStyles({
    formControl: {
      minWidth: 120,
    },
  })
);

export interface ColorData {
  name: string;
  value: string | undefined;
}

interface Props {
  error?: string;
  name: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>, data: ColorData) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  value: string | undefined;
  label?: ReactNode;
  helpText?: ReactNode;
  required?: boolean;
  disabled?: boolean;
  endAdornment?: ReactNode;
}

export const FieldColor: FunctionComponent<Props> = (props: Props) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const inputEl: any = useRef(null);
  const containerEl: any = useRef(null);
  const { open, handleClose, handleOpen } = useOpen(false);
  const [skipBlur, setSkipBlur] = useState(false);

  const onChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange(event, {
      name: event.target.name,
      value: event.target.value,
    });
  };

  const handleChange = (
    color: ColorResult,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { r, g, b } = color.rgb;
    props.onChange(event, {
      name: props.name,
      value: `rgb(${r},${g},${b})`,
    });
    if (inputEl.current !== null) {
      inputEl.current.focus();
    }
    handleOpen();
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (!skipBlur) {
      if (props.onBlur) {
        props.onBlur(e);
      }
      handleClose();
    }
  };

  const handleSkipBlur = () => {
    setSkipBlur(true);
  };

  const handleUnSkipBlur = () => {
    setSkipBlur(false);
  };

  const handleClick = () => {
    handleOpen();
    if (null !== inputEl.current) inputEl.current.focus();
  };

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    e.preventDefault();
    handleOpen();
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.keyCode === 27 || e.keyCode === 13) {
      handleClose();
      if (inputEl.current !== null) {
        inputEl.current.blur();
      }
    }
  };

  const { value } = props;

  const id = open ? 'color-popper' : undefined;
  return (
    <Fragment>
      <TextField
        variant='outlined'
        className={classes.formControl}
        required={props.required}
        disabled={props.disabled}
        error={!!props.error}
        label={<FieldColorLabel label={props.label} value={props.value} />}
        ref={containerEl}
        id={`color--${props.name}`}
        name={props.name}
        type='text'
        value={props.value ? props.value : ''}
        inputRef={inputEl}
        onChange={onChangeInput}
        onBlur={handleBlur}
        onFocus={handleFocus}
        onKeyDown={handleKeyDown}
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <IconButton onClick={handleClick}>
                <ColorLensIcon aria-describedby={id} />
              </IconButton>
            </InputAdornment>
          ),
          endAdornment: props.endAdornment ? (
            <InputAdornment position='end'>{props.endAdornment}</InputAdornment>
          ) : undefined,
        }}
        helperText={props.error}
        margin='dense'
      />
      <Popper
        id={id}
        open={open}
        anchorEl={containerEl.current}
        transition={true}
        placement='bottom-start'
        style={{ zIndex: 12000 }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper>
              <div
                onMouseEnter={handleSkipBlur}
                onMouseLeave={handleUnSkipBlur}
              >
                <ChromePicker
                  styles={{
                    default: {
                      picker: {
                        borderRadius: '2px',
                        boxShadow: 'none',
                        boxSizing: 'initial',
                        fontFamily: 'Menlo',
                      },
                    },
                  }}
                  disableAlpha={true}
                  color={value || ''}
                  onChangeComplete={handleChange}
                />
              </div>
            </Paper>
          </Fade>
        )}
      </Popper>
    </Fragment>
  );
};

interface FieldColorLabelProps {
  label: ReactNode;
  value: string | undefined;
}
const FieldColorLabel: FunctionComponent<FieldColorLabelProps> = (
  props: FieldColorLabelProps
) => {
  if (props.value && /^rgb\(\d+,\d+,\d+\)$/.test(props.value)) {
    const innerColor = props.value;
    const borderColor = getInvertColor(props.value);
    return (
      <Grid container={true}>
        <Grid item={true}>{props.label}</Grid>
        <Grid item={true}>
          <div
            style={{
              marginLeft: 10,
              width: 16,
              height: 16,
              backgroundColor: innerColor,
              border: `1px solid ${borderColor}`,
              borderRadius: 6,
            }}
          />
        </Grid>
      </Grid>
    );
  }
  return <span>{props.label}</span>;
};
