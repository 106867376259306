import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router';

import ResourcePage from '../../../components/common/page/ResourcePage';
import useViewer from '../../../hooks/useViewer';
import { ResourcePageProps } from '../../types';
import ImageEditDrawer from '../../../components/image/ImageEditDrawer';
import ImageList from '../../../components/image/ImageList';
import { ImageBulkDrawer } from '../../../components/image/bulk';

export const ImagePage: FunctionComponent<ResourcePageProps> = (
  props: ResourcePageProps
) => {
  const viewer = useViewer();
  const history = useHistory();

  return (
    <ResourcePage
      {...props}
      DrawerComponent={ImageEditDrawer}
      content={<ImageList />}
      controls={
        viewer && viewer.canManageImages() ? <ImageBulkDrawer /> : undefined
      }
      title={
        <FormattedMessage id='ImageListPage.title' defaultMessage='Images' />
      }
      onCloseDrawer={() => {
        history.push('/passes/image');
      }}
    />
  );
};
