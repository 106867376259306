import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import useResource from '../../../hooks/useResource';
import { ResourceTypes } from '../../../utils/services/api';
import { CircularProgress } from '@material-ui/core';
import { PkpassTemplatePreview } from '@yourpass/react-lib';
import { Template } from '../../../types/Template';
import { IMAGE_BASE_URL } from '../../../utils/services/environment';

const TemplateTooltip = (
  props: React.PropsWithChildren<{ id: string }>
): JSX.Element => {
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <Tooltip
      open={open}
      onClose={handleClose}
      onOpen={handleOpen}
      title={<Content open={open} id={props.id} />}
    >
      <div>{props.children}</div>
    </Tooltip>
  );
};

const Content = (props: { id: string; open: boolean }) => {
  // TODO add aborting in use effect
  const { loading, data } = useResource<Template>(
    ResourceTypes.TEMPLATE,
    props.id
  );

  let content = <CircularProgress />;

  if (!(loading || data === null)) {
    content = (
      <div style={{ transform: 'scale(0.7)' }}>
        <PkpassTemplatePreview
          template={data as any}
          language={data.languages[0] as string}
          imageBaseUrl={IMAGE_BASE_URL}
        />
      </div>
    );
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          width: 224,
          height: 415,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {content}
      </div>
      <p style={{ textAlign: 'center' }}>{props.id}</p>
    </div>
  );
};

export default TemplateTooltip;
