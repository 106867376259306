import React from 'react';
import { FormattedMessage } from 'react-intl';

import Android from '@material-ui/icons/Android';
import Apple from '@material-ui/icons/Apple';

import androidImage from '../../../../../assets/pkpass/content_system_area_android.png';
import iosImage from '../../../../../assets/pkpass/content_system_area_ios.png';
import { EditDescription } from '../EditDescription';
import HelpPopUp from '../../../../common/HelpPopUp';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { PkpassContent, PkpassContentProps } from '@yourpass/react-lib/dist/js';
import AddFieldButton from '../fields/AddFieldButton';
import { PkpassFieldsType } from '../../../../../types/Template';
import { ControlLayoutProps } from './ControlLayout';

const useStyle = makeStyles(
  createStyles({
    root: {
      height: 450,
      width: 320,
      position: 'relative',
    },

    systemAreaContainer: {
      paddingTop: 6,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },

    column: {
      alignItems: 'center' as const,
      display: 'flex',
      flexDirection: 'column' as const,
      justifyContent: 'center' as const,
      width: '100%',
    },
    container: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: 320,
      flexDirection: 'column' as const,
      alignItems: 'center' as const,
      display: 'flex' as const,
      height: 100,
      justifyContent: 'center' as const,
      zIndex: 2000,
    },
    img: { height: 150, marginTop: 10, width: 'auto' },
    row: {
      alignItems: 'center' as const,
      display: 'flex' as const,
      flexDirection: 'row' as const,
      justifyContent: 'center' as const,
      marginBottom: 24,
      width: '100%',
    },
  })
);

export default function DesignerBackSide(
  props: PkpassContentProps & ControlLayoutProps
): JSX.Element {
  const classes = useStyle();
  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <EditDescription />
        <div className={classes.systemAreaContainer}>
          <Typography variant={'body2'}>
            <FormattedMessage
              id='Pkpass.designer.content.system.area.label'
              defaultMessage='System area'
            />
          </Typography>
          <HelpPopUp
            style={{
              margin: 0,
              paddingLeft: 4,
              height: '0.8em',
              width: '0.8em',
            }}
            content={
              <div>
                <h3>
                  <FormattedMessage
                    id='Pkpass.designer.content.system.area.label'
                    defaultMessage='System area'
                  />
                </h3>
                <FormattedMessage
                  id='Pkpass.designer.content.system.area.infoText'
                  defaultMessage='Use this area to display system information and digital card operations. Its appearance may vary depending on the wallet used. The following pictures show a sample of iOS and android wallets.'
                />
                <div className={classes.row}>
                  <div className={classes.column}>
                    <Apple />

                    <img
                      src={iosImage}
                      className={classes.img}
                      alt='ios system area sample'
                    />
                  </div>
                  <div className={classes.column}>
                    <Android />

                    <img
                      src={androidImage}
                      className={classes.img}
                      alt='android system area sample'
                    />
                  </div>
                </div>
              </div>
            }
          />
        </div>
      </div>
      <PkpassContent {...props} />
      {props.disableFields !== true && (
        <div
          style={{
            position: 'absolute',
            bottom: -20,
            right: 146,
          }}
        >
          <AddFieldButton fieldsType={PkpassFieldsType.BackFields} />
        </div>
      )}
    </div>
  );
}
