import React, { ReactNode, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import IconButton from '@material-ui/core/IconButton';
import MUILink from '@material-ui/core/Link';
import { EditIcon } from '../icons';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import { OpenInterface } from '../../../hooks';
import { StaticLabel } from '../labels';

export interface LinkEditIconButtonProps {
  path: string;
  disabled?: boolean;
}

export const LinkEditIconButton = (
  props: LinkEditIconButtonProps
): JSX.Element => {
  const { path, disabled } = props;
  const history = useHistory();

  const handleClick = (e: React.MouseEvent<HTMLSpanElement>) => {
    if (disabled !== true) {
      if (e.button === 0) {
        history.replace(path);
      } else if (e.button === 1) {
        window.open(path);
      }
      e.preventDefault();
      e.stopPropagation();
    }
  };

  return (
    <Link to={path} onClick={handleClick}>
      <IconButton onMouseDown={handleClick} disabled={disabled}>
        <EditIcon />
      </IconButton>
    </Link>
  );
};

export interface LabelEditLinkChildrenProps {
  onClose: () => void;
}

export interface LabelEditLinkProps {
  oi: OpenInterface;
  path: string;
  disabled?: boolean;
  tooltip?: React.ReactNode;
  title: React.ReactNode;
  children: (p: LabelEditLinkChildrenProps) => ReactNode;
}

export const LabelEditLink = (props: LabelEditLinkProps): JSX.Element => {
  const { oi, path, title, tooltip, disabled } = props;

  const history = useHistory();
  const [prev, setPrev] = useState(history.createHref(history.location));

  const handleOpen = (e: React.MouseEvent<HTMLSpanElement>) => {
    if (disabled !== true) {
      setPrev(history.createHref(history.location));
      window.history.replaceState(null, '', path);
      oi.handleOpen();
    }
    e.preventDefault();
    e.stopPropagation();
  };

  const handleClose = () => {
    oi.handleClose();
    window.history.replaceState(null, '', prev);
  };

  return (
    <>
      <MUILink component={Link} onClick={handleOpen} to={path}>
        {tooltip && (
          <Tooltip title={tooltip}>
            <span>{title}</span>
          </Tooltip>
        )}
        {!tooltip && <span>{title}</span>}
      </MUILink>
      {props.children({ onClose: handleClose })}
    </>
  );
};

export interface EditLinkButtonChildrenProps {
  onClose: () => void;
}

export interface EditLinkButtonProps {
  oi: OpenInterface;
  path: string;
  disabled?: boolean;
  tooltip?: React.ReactNode;
  children: (p: EditLinkButtonChildrenProps) => ReactNode;
}

export const EditLinkButton = (props: EditLinkButtonProps): JSX.Element => {
  const { oi, path, tooltip, disabled } = props;

  const history = useHistory();
  const [prev, setPrev] = useState(history.createHref(history.location));

  const handleOpen = (e: React.MouseEvent<HTMLSpanElement>) => {
    if (disabled !== true) {
      setPrev(history.createHref(history.location));
      window.history.replaceState(null, '', path);
      oi.handleOpen();
    }
    e.preventDefault();
    e.stopPropagation();
  };

  const handleClose = () => {
    oi.handleClose();
    window.history.replaceState(null, '', prev);
  };

  return (
    <>
      {tooltip && (
        <Tooltip title={tooltip}>
          <Button component={Link} to={path} onClick={handleOpen}>
            {StaticLabel.Edit}
          </Button>
        </Tooltip>
      )}
      {!tooltip && (
        <Button component={Link} to={path} onClick={handleOpen}>
          {StaticLabel.Edit}
        </Button>
      )}
      {props.children({ onClose: handleClose })}
    </>
  );
};
