import { FastField, FastFieldProps } from 'formik';
import React from 'react';

import { FieldCheckbox, FieldCheckboxChange } from '../../../common/fields';
import { errorsToString } from './helper';
import { ColorWithFallbackProps } from './PkpassColor';

type ValueType =
  | {
      type: 'boolean';
      value: boolean;
    }
  | undefined;

interface PkpassBooleanInput<T> extends PkpassBooleanProps {
  field: FastFieldProps<T>;
}

const PkpassBooleanInput = (
  props: PkpassBooleanInput<ValueType>
): JSX.Element => {
  const { field, label, helpText, name } = props;
  const error = errorsToString(field.form.errors, [name]);
  const value = field.field.value ? field.field.value.value : undefined;

  const handleChange = (event: FieldCheckboxChange) => {
    const newValue = { value: event.value, type: 'boolean' };
    field.form.setFieldValue(name, newValue);
  };

  return (
    <FieldCheckbox
      label={label}
      helpText={helpText}
      name={name}
      error={error}
      checked={value}
      onChange={handleChange}
    />
  );
};

export interface PkpassBooleanProps {
  name: string;
  label: JSX.Element;
  helpText: JSX.Element;
  required?: boolean;
}

export const PkpassBoolean = (props: ColorWithFallbackProps): JSX.Element => {
  return (
    <FastField name={props.name}>
      {(field: FastFieldProps) => (
        <PkpassBooleanInput {...props} field={field} />
      )}
    </FastField>
  );
};
