import React from 'react';
import { defineMessage, FormattedMessage } from 'react-intl';

import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles';

import { PkpassColor } from '../../inputs';
import FormattedHTMLMessage from '../../../../common/FormattedHTMLMessage';

export const BackgroundColorLabel = (
  <FormattedMessage
    id='pkpass.backgroundColor.title'
    defaultMessage='Background'
  />
);

export const LabelColorLabel = (
  <FormattedMessage id='pkpass.labelColor.title' defaultMessage='Label' />
);

export const ForegroundColorLabel = (
  <FormattedMessage
    id='pkpass.foregroundColor.title'
    defaultMessage='Foreground'
  />
);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        padding: theme.spacing(1),
        marginBottom: theme.spacing(1),
        width: '100%',
      },
      maxWidth: 320,
    },
  })
);

export default function ColorsForm(): JSX.Element {
  const theme = useTheme();
  const classes = useStyles(theme);
  return (
    <form className={classes.root}>
      <PkpassColor
        name='backgroundColor'
        label={BackgroundColorLabel}
        helpText={
          <FormattedHTMLMessage
            descriptor={defineMessage({
              id: 'pkpass.backgroundColor.helpText.html',
              defaultMessage: `<p>The background color, used for the background of the front and back of the pass. If you provide a background image, any background color is ignored.</p>
            <p>Background color of the pass, specified as an CSS-style RGB triple. For example, rgb(23, 187, 82).</p>`,
            })}
          />
        }
      />
      <PkpassColor
        name='labelColor'
        label={LabelColorLabel}
        helpText={
          <FormattedHTMLMessage
            descriptor={defineMessage({
              id: 'pkpass.labelColor.helpText.html',
              defaultMessage:
                '<p>The label color, used for the labels of fields shown on the front of the pass.</p><p>Color of the label text, specified as a CSS-style RGB triple. For example, rgb(255, 255, 255).If omitted, the label color is determined automatically.</p>',
            })}
          />
        }
      />
      <PkpassColor
        name='foregroundColor'
        label={ForegroundColorLabel}
        helpText={
          <FormattedHTMLMessage
            descriptor={defineMessage({
              id: 'pkpass.foregroundColor.helpText.html',
              defaultMessage:
                '<p>The foreground color, used for the values of fields shown on the front of the pass.</p><p>Foreground color of the pass, specified as a CSS-style RGB triple. For example, rgb(100, 10, 110).</p>',
            })}
          />
        }
      />
    </form>
  );
}
