import { useContext, useEffect, useReducer } from 'react';

import { CacheContext } from '../context/';
import {
  SupportedTypes,
  CacheResourceRecord,
} from '../context/CacheContext/db';
import { Action, ActionType, asyncReducer, State } from './asyncReducer';

export const useCacheResource = (
  resource: SupportedTypes,
  resourceId: string
): {
  loading: boolean;
  data: CacheResourceRecord | null;
  error: Error | null;
} => {
  const { db } = useContext(CacheContext);

  const reducer = (
    state: State<CacheResourceRecord>,
    action: Action<CacheResourceRecord>
  ) => asyncReducer<CacheResourceRecord>(state, action);

  const [state, dispatch] = useReducer(reducer, {
    loading: true,
    data: null,
    error: null,
  } as State<CacheResourceRecord>);

  useEffect(() => {
    let didCancel = false;

    const read = async () => {
      let d: CacheResourceRecord | null = null;
      if (db) {
        const i: unknown = await db.get(resource, resourceId);
        if (i) {
          d = i as CacheResourceRecord;
        }
      }
      if (!didCancel) {
        dispatch({ type: ActionType.SUCCESS, payload: d });
      }
    };
    read().catch((e: Error) => {
      if (!didCancel) {
        dispatch({ type: ActionType.FAILURE, payload: e });
      }
    });
    return () => {
      didCancel = true;
    };
  }, [db, resource, resourceId]);

  return state;
};

export function useCacheResources(
  resource: SupportedTypes
): {
  loading: boolean;
  data: CacheResourceRecord[];
  error: Error | null;
} {
  const { db } = useContext(CacheContext);

  const reducer = (
    state: State<CacheResourceRecord[]>,
    action: Action<CacheResourceRecord[]>
  ) => asyncReducer<CacheResourceRecord[]>(state, action);

  const [state, dispatch] = useReducer(reducer, {
    loading: true,
    data: [],
    error: null,
  } as State<CacheResourceRecord[]>);

  useEffect(() => {
    let didCancel = false;
    const read = async () => {
      if (!didCancel) {
        dispatch({ type: ActionType.PENDING });
      }
      let d: CacheResourceRecord[] = [];
      if (db) {
        const i: unknown = await db.list(resource);
        if (i) {
          d = i as CacheResourceRecord[];
        }
      }
      if (!didCancel) {
        dispatch({ type: ActionType.SUCCESS, payload: d });
      }
    };
    read().catch((e: Error) => {
      if (!didCancel) {
        dispatch({ type: ActionType.FAILURE, payload: e });
      }
    });
    return () => {
      didCancel = true;
    };
  }, [db, resource]);

  return {
    loading: state.loading,
    data: state.data ? state.data : [],
    error: state.error,
  };
}
