import { FormikProps } from 'formik';
import React from 'react';
import { Checkboard } from 'react-color/lib/components/common';

import Grid from '@material-ui/core/Grid';

import {
  FieldEntityTimestamps,
  FieldStatic,
  FieldStaticProject,
  FieldTextInput,
  FieldTextInputCopy,
  FieldTextInputData,
} from '../common/fields';
import Form from '../common/Form';
import { StaticLabel } from '../common/labels';
import { Image } from '../../types/Image';
import { ImageTypes } from '../../types/Template';

export interface ImageFormInterface {
  id?: string;
  name?: string;
  projectId?: string;
  type: ImageTypes;
  url?: string;
  width?: number;
  height?: number;
  mimeType?: string;
}

export default function ImageForm(props: FormikProps<Image>): JSX.Element {
  const { values, setFieldValue } = props;
  return (
    <Grid container={true}>
      <Grid item={true}>
        <Form>
          <FieldTextInputCopy label={StaticLabel.Id} value={values.id} />
          <FieldStaticProject projectId={values.projectId} />
          <FieldTextInput
            value={values.name}
            onChange={(d: FieldTextInputData) => {
              setFieldValue('name', d.value);
            }}
            name='name'
            label={StaticLabel.Name}
          />
          <FieldStatic
            name={'dimension'}
            label={StaticLabel.Dimension}
            value={`${values.width} x ${values.height}`}
          />
          <FieldEntityTimestamps data={values} />
        </Form>
      </Grid>
      <Grid item={true}>
        <div style={styles.previewContainerStyle}>
          <img
            alt='preview'
            src={values.url}
            style={styles.previewImageStyle}
          />
          <Checkboard />
        </div>
      </Grid>
    </Grid>
  );
}

const styles = {
  previewContainerStyle: {
    height: 320,
    position: 'relative' as const,
    width: 320,
  },
  previewImageStyle: {
    bottom: 0,
    left: 0,
    margin: 'auto',
    maxHeight: 300,
    maxWidth: 300,
    position: 'absolute' as const,
    right: 0,
    top: 0,
    zIndex: 1000,
  },
};
