import { FormikProps } from 'formik';
import { get } from 'lodash';
import React from 'react';

import OutlinedInput from '@material-ui/core/OutlinedInput';

import {
  FieldCheckbox,
  FieldCheckboxChange,
  FieldSwitchChange,
  FieldSet,
} from '../../../common/fields';
import { PkpassField } from '../../../../types/Template';
import { placeHolderRegexp } from '../../../../utils/validation/jsonTemplateHelper';
import { useTemplateUI } from '../useTemplateUI';
import { isPlaceholder } from './helper';

import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import Switch from '@material-ui/core/Switch';

import { FieldHelpText } from '../../../common/fields/FieldHelpText';
import {
  PkpassInputSettingsPopUp,
  usePkpassInputSettingsPopup,
} from './PkpassInputSettingsPopUp';
import IconButton from '@material-ui/core/IconButton';
import { SettingsIcon } from '../../../common/icons';

export interface Props extends FormikProps<PkpassField> {
  name: string;
  label: JSX.Element;
  helpText: JSX.Element;
  required?: boolean;
  textArea?: boolean;
  languages: string[];
  link?: boolean;
}

export default function ChangeMessageInput(props: Props) {
  const { values, label, helpText, name, errors, languages } = props;
  const templateUI = useTemplateUI();
  let value = get(values, `${name}.value`);
  const isSet = typeof get(values, name, undefined) !== 'undefined';
  const localized = !!value && typeof value === 'object';

  const handleChangeValue = (str: string) => {
    let newValue;
    let fallback = get(values, `${name}.fallback`);

    let newStringValue;
    if (str !== '') {
      newStringValue = str;
    }
    if (
      newStringValue &&
      newStringValue.match(placeHolderRegexp) &&
      !fallback
    ) {
      fallback = '%@';
    }

    if (localized) {
      const v = get(values, `${name}.value`);
      v[templateUI.templatePreviewLanguage] = newStringValue;
      newValue = { value: v, fallback, type: 'string' };
    } else {
      if (newStringValue) {
        newValue = {
          value: newStringValue,
          fallback,
          type: 'string',
        };
      }
    }

    props.setFieldValue(name, newValue);
  };

  const handleChangeFallback = (str: string) => {
    let newValue;
    if (str !== '') {
      newValue = {
        fallback: str,
        type: 'string',
        value: get(values, `${name}.value`),
      };
    } else {
      newValue = {
        fallback: undefined,
        type: 'string',
        value: get(values, `${name}.value`),
      };
    }
    props.setFieldValue(name, newValue);
  };

  const handleLocalizable = (
    event: FieldSwitchChange | FieldCheckboxChange
  ) => {
    const obj = get(values, name);

    if (event.value) {
      const newValue: any = {};
      if (obj) {
        languages.forEach((l: string) => {
          newValue[l] = obj.value;
        });
      }
      // if is localized and has placeholder, reset fallback
      const v = { ...obj, value: newValue };
      if (isPlaceholder('string', v)) {
        v.fallback = undefined;
      }

      props.setFieldValue(name, v);
    } else {
      let v = '';
      if (obj && obj.value) {
        v = obj.value[templateUI.templatePreviewLanguage];
      }
      props.setFieldValue(name, {
        ...obj,
        value: v,
      });
    }
  };

  const handleToggle = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    if (checked) {
      props.setFieldValue(name, { value: '%@', type: 'string' });
    } else {
      props.setFieldValue(name, undefined);
    }
  };

  const object = get(values, name);
  const error = get(errors, name, undefined);

  if (localized && value) {
    value = value[templateUI.templatePreviewLanguage];
  }

  const hasFallback = isPlaceholder('string', object) && !localized;
  const { anchorEl, handleClose, handleOpen } = usePkpassInputSettingsPopup();

  return (
    <>
      <FormControl component='fieldset' margin='dense'>
        <FormLabel component='div'>
          <div
            style={{
              flexDirection: 'row',
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              flex: 1,
              alignItems: 'center',
            }}
          >
            <div>
              <FieldHelpText helpText={helpText} /> {label}
            </div>
            <div>
              <Switch
                size='small'
                key={'changemessage'}
                name={'changemessage'}
                checked={isSet}
                onChange={handleToggle}
              />
            </div>
          </div>
        </FormLabel>
        <FormGroup>
          <PlaceholderInput
            style={hasFallback ? withFallbackStyleValue : undefined}
            value={value}
            onChange={handleChangeValue}
            disabled={!isSet}
            endAdornment={
              <IconButton onClick={handleOpen} disabled={!hasFallback}>
                <SettingsIcon style={{ height: '0.7em', width: '0.7em' }} />
              </IconButton>
            }
          />

          <FieldCheckbox
            name='localized'
            checked={localized}
            label='localized'
            onChange={handleLocalizable}
            disabled={!isSet}
          />
        </FormGroup>
        <FormHelperText error={true}>{error}</FormHelperText>
      </FormControl>
      <PkpassInputSettingsPopUp anchorEl={anchorEl} onClose={handleClose}>
        <FieldSet label='Default value'>
          <br />
          <PlaceholderInput
            style={withFallbackStyleFallback}
            value={get(values, `${name}.fallback`)}
            onChange={handleChangeFallback}
            disabled={!isSet && !hasFallback}
          />
        </FieldSet>
        {/* {templateUI.textShortCodes.length > 0 && (
                <WysiwygPopUpShortCodesContent
                  shortCodes={templateUI.textShortCodes}
                  onSelect={handleSelectShortCode}
                />
              )} */}
      </PkpassInputSettingsPopUp>
    </>
  );
}

function PlaceholderInput(props: {
  value: string;
  onChange: (s: string) => void;
  style: any;
  disabled: boolean;
  endAdornment?: React.ReactNode;
}) {
  const value = props.value || '%@';
  const s1 = value.substring(0, value.indexOf('%@'));
  const s2 = value.substring(value.indexOf('%@') + 2, value.length);

  const handleChangeS1 = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange(`${event.target.value}%@${s2}`);
  };

  const handleChangeS2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange(`${s1}%@${event.target.value}`);
  };

  return (
    <div style={{ width: '100%' }}>
      <OutlinedInput
        value={s1}
        onChange={handleChangeS1}
        disabled={props.disabled}
        style={{
          ...props.style,
          borderBottomRightRadius: 0,
          borderTopRightRadius: 0,
          width: '40%',
        }}
        placeholder={'Value prefix'}
        margin='dense'
      />
      <OutlinedInput
        value='%@'
        readOnly={true}
        disabled={props.disabled}
        margin='dense'
        style={{
          background: 'rgb(224, 225, 226, 0.3)',
          borderLeft: 'none',
          borderRadius: 0,
          borderRight: 'none',
          fontWeight: 'bold',
          paddingLeft: 3,
          paddingRight: 3,
          textAlign: 'center',
          width: '20%',
        }}
      />
      <OutlinedInput
        value={s2}
        onChange={handleChangeS2}
        disabled={props.disabled}
        style={{
          ...props.style,
          borderBottomLeftRadius: 0,
          borderTopLeftRadius: 0,
          paddingLeft: undefined,
          width: '40%',
        }}
        placeholder={'Value postfix'}
        endAdornment={props.endAdornment}
        margin='dense'
      />
    </div>
  );
}

const withFallbackStyleFallback = {
  borderTop: 'none',
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0,
  // paddingLeft: 70,
  // paddingLeftImportant: true,
};
const withFallbackStyleValue = {
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
};
