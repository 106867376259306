import React from 'react';

import PeriodIcon from '@material-ui/icons/CalendarToday';
import Total from '@material-ui/icons/Timeline';

import Tabs, { TabOption } from '../common/Tabs';
import BasePassRequestByTagsOverviewPeriod from './BasePassRequestByTagsOverviewPeriod';
import BasePassRequestByTagsOverviewTotal from './BasePassRequestByTagsOverviewTotal';

export const getTagName = (tag: string) => {
  // TODO - get string from intl
  const noTagLabel = 'without tag';
  return tag === '' ? noTagLabel : tag;
};

// TODO - remove any types
export default function BasePassRequestByTagsOverview(props: any) {
  const tabOptions: TabOption[] = [
    {
      content: <BasePassRequestByTagsOverviewPeriod {...props} />,
      icon: <PeriodIcon />,
      key: 'period',
      name: 'Period',
    },
    {
      content: <BasePassRequestByTagsOverviewTotal {...props} />,
      icon: <Total />,
      key: 'total',
      name: 'total',
    },
  ];

  return (
    <Tabs
      tabOptions={tabOptions}
      defaultTab='period'
      formName={`report_${props.reportType}`}
    />
  );
}
