import moment from 'moment-timezone';
import React from 'react';

import TextField from '@material-ui/core/TextField';
import Autocomplete, {
  AutocompleteRenderInputParams,
} from '@material-ui/lab/Autocomplete';

import Form from '../common/Form';
import { StaticLabel } from '../common/labels';
import useLocale from '../../hooks/useLocale';
import useTimezone from '../../hooks/useTimezone';

export default function ProfileLocale(): JSX.Element {
  const { timezone, timezones, setTimezone } = useTimezone();
  const { locale, locales, setLocale } = useLocale();

  const handleChange = (
    event: React.ChangeEvent<unknown>,
    data: string | null
  ) => {
    setTimezone(data || moment.tz.guess());
  };

  const handleChangeLocale = (
    event: React.ChangeEvent<unknown>,
    data: string | null
  ) => {
    setLocale(data || 'en');
  };

  return (
    <Form>
      <Autocomplete
        size='small'
        value={timezone}
        onChange={handleChange}
        id='timezone-autocoplete'
        options={timezones}
        style={{ width: 300 }}
        renderInput={(params: AutocompleteRenderInputParams) => (
          <TextField
            {...params}
            label={StaticLabel.Timezone}
            variant='outlined'
            margin='dense'
          />
        )}
      />
      <Autocomplete
        size='small'
        value={locale}
        onChange={handleChangeLocale}
        id='locale-autocoplete'
        options={locales}
        style={{ width: 300 }}
        renderInput={(params: AutocompleteRenderInputParams) => (
          <TextField
            {...params}
            label={StaticLabel.Locale}
            variant='outlined'
            margin='dense'
          />
        )}
      />
    </Form>
  );
}
