import { set, unset, isArray } from 'lodash';
import React from 'react';

import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Icon from '@material-ui/icons/Delete';

import { getDynamicDataFieldDefinition } from '../helper';

interface Props {
  name: string;
  jsonSchema: Record<string, unknown>;
  onChange: (d: Record<string, unknown>) => void;
}

export const JsonSchemaEditorRow = (props: Props): JSX.Element => {
  const { name, jsonSchema, onChange } = props;
  const removeField = () => {
    unset(jsonSchema, `properties.${name}`);

    const required: string[] | undefined = jsonSchema.required as
      | string[]
      | undefined;

    if (required && required.indexOf(name) !== -1) {
      required.splice(required.indexOf(name), 1);
      set(jsonSchema, `required`, [...required]);
    }

    unset(jsonSchema, `required.${name}`);

    onChange(jsonSchema);
  };

  const renderValue = (keyName: string, value: unknown) => {
    let content = value;
    if (isArray(value)) {
      content = value.join(', ');
    }
    return (
      <span>
        {keyName}: <strong>{`${content}`}</strong>
      </span>
    );
  };

  const def = getDynamicDataFieldDefinition(jsonSchema, name);

  /* const required =
      values.dynamicDataJsonSchema &&
      values.dynamicDataJsonSchema.required &&
      values.dynamicDataJsonSchema.required.indexOf(name) > -1;
      */

  return (
    <TableRow key={name}>
      <TableCell>{name}</TableCell>
      {/* <TableCell>{required && <Icon name={'check'} />}</TableCell> */}
      <TableCell>{`${def.type}`}</TableCell>
      <TableCell>{`${def.title}`}</TableCell>
      <TableCell>{`${def.description}`}</TableCell>
      <TableCell>
        <ul>
          {[
            'format',
            'pattern',
            'enum',
            'minimum',
            'maximum',
            'exclusiveMinimum',
            'exclusiveMaximum',
          ].map(
            (key: string) =>
              def[key] && <li key={key}>{renderValue(key, def[key])}</li>
          )}
        </ul>
      </TableCell>
      <TableCell>
        <IconButton onClick={removeField}>
          <Icon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};
