import React, { FunctionComponent, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import ResourcePage from '../../../components/common/page/ResourcePage';
import useViewer from '../../../hooks/useViewer';
import { ResourcePageProps } from '../../types';
import {
  PkpassCertificateCreateDrawer,
  PkpassCertificateEditDrawer,
  PkpassCertificateList,
} from '../../../domain/certificate';
import useNotification from '../../../hooks/useNotification';
import { useHistory } from 'react-router';

export const PkpassCertificatePage: FunctionComponent<ResourcePageProps> = (
  props: ResourcePageProps
) => {
  const viewer = useViewer();
  const [key, setKey] = useState(0);
  const { addSuccessNotification } = useNotification();
  const history = useHistory();
  return (
    <ResourcePage
      {...props}
      DrawerComponent={PkpassCertificateEditDrawer}
      content={<PkpassCertificateList key={`${key}`} />}
      controls={
        viewer && viewer.isAdmin ? (
          <PkpassCertificateCreateDrawer
            onCreate={(r: unknown) => {
              setKey(key + 1);
              addSuccessNotification(`Certificate was created`);
              const a = r as { id: string };
              if (a.id) {
                history.replace(`/passes/certificate/${a.id}`);
              }
            }}
          />
        ) : undefined
      }
      title={
        <FormattedMessage
          id='CertificateListPage.title'
          defaultMessage='Certificates'
        />
      }
      onCloseDrawer={() => {
        props.history.push('/passes/certificate');
      }}
    />
  );
};
