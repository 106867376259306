import React from 'react';
import { FormattedMessage } from 'react-intl';

import FormikCreateDrawer from '../common/formik/FormikCreateDrawer';
import { ResourceTypes } from '../../utils/services/api';
import schema from '../../utils/validation/template';
import { TemplateUIContextProvider } from '../design/pkpass/TemplateUIContext';

import WizardNavigator from './wizard/WizardNavigator';
import { FormikProps } from 'formik';
import { Template } from '../../types/Template';
import { defaultPasses } from '../design/pkpass/designer/passStyle/switcher/defaultPasses';
import { PkpassPassStyleTypes } from '../../types/Template';
import defaultTemplate from './wizard/defaultTemplate';
import { OnCreateHandlder } from '../../types';
import useNotification from '../../hooks/useNotification';
import { useHistory } from 'react-router';

export default function TemplateCreateWizardDrawer(props: {
  trigger?: JSX.Element;
  onCreate?: OnCreateHandlder;
}): JSX.Element {
  const { addSuccessNotification } = useNotification();
  const history = useHistory();
  const RenderContent = (p: FormikProps<Template>) => {
    return (
      <TemplateUIContextProvider initValues={p.values}>
        <WizardNavigator />
      </TemplateUIContextProvider>
    );
  };

  const renderActions = () => null;

  const template = {
    ...defaultTemplate,
    jsonTemplate: defaultPasses[PkpassPassStyleTypes.StoreCard],
  };

  const handleCreate = (r: unknown, resource: ResourceTypes) => {
    if (props.onCreate) {
      return props.onCreate(r, resource);
    } else {
      addSuccessNotification(`Template was created`);
      const a = r as { id: string };
      if (a.id) {
        history.replace(`/passes/template/${a.id}`);
      }
    }
  };

  return (
    <FormikCreateDrawer
      onCreate={handleCreate}
      title={
        <FormattedMessage
          id='TemplateNewModal.header'
          defaultMessage='New template'
        />
      }
      validationSchema={schema}
      resource={ResourceTypes.TEMPLATE}
      initialValues={template}
      trigger={props.trigger}
      renderForm={RenderContent}
      renderActions={renderActions}
    />
  );
}
