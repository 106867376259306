import PropTypes from 'prop-types';
import React, { FunctionComponent } from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { useTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete, {
  AutocompleteRenderInputParams,
} from '@material-ui/lab/Autocomplete';

import { useCacheResources } from '../../../hooks';
import { FieldLabel } from './FieldLabel';
import {
  Props,
  useStyles,
  getOptionLabel,
  renderOptionMultiple,
  parseValue,
  renderOption,
  parseMultipleValue,
  ListItem,
} from './FieldAutocomplete';

import { FieldAutocompleteListboxComponent } from './FieldAutocompleteListboxComponent';
import { SupportedTypes } from '../../../context/CacheContext/db';

type FieldAutocompleteCacheResourceProps = Props<string> & {
  resource: SupportedTypes;
};

export const FieldAutocompleteCacheResource: FunctionComponent<FieldAutocompleteCacheResourceProps> = (
  props: FieldAutocompleteCacheResourceProps
): JSX.Element => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const { data, loading } = useCacheResources(props.resource);

  const handleChange = (
    event: React.ChangeEvent<unknown>,
    value: ListItem | null
  ) => {
    if (!props.multiple) {
      props.onChange(value ? value.id : undefined);
    }
  };

  const handleChangeMultiple = (
    event: React.ChangeEvent<unknown>,
    value: ListItem[] | null
  ) => {
    if (props.multiple) {
      props.onChange(value ? value.map((p: ListItem) => p.id) : []);
    }
  };

  function renderInput(params: AutocompleteRenderInputParams) {
    return (
      <TextField
        {...params}
        variant={'outlined'}
        margin='dense'
        label={<FieldLabel label={props.label} helpText={props.helpText} />}
        error={!!props.error}
        placeholder={props.placeholder}
        required={props.required}
      />
    );
  }

  return (
    <FormControl
      key={`${loading}`}
      className={classes.formControl}
      variant='outlined'
      required={props.required}
      disabled={props.disabled}
    >
      {!props.multiple && (
        <Autocomplete
          size='small'
          disabled={loading || props.disabled}
          options={data}
          getOptionLabel={getOptionLabel}
          renderOption={renderOption}
          renderInput={renderInput}
          value={parseValue(data, props.value)}
          onChange={handleChange}
          onBlur={props.onBlur}
          disableClearable={true}
          ListboxComponent={
            FieldAutocompleteListboxComponent as React.ComponentType<
              React.HTMLAttributes<HTMLElement>
            >
          }
        />
      )}
      {props.multiple && (
        <Autocomplete
          size='small'
          multiple={true}
          disabled={loading || props.disabled}
          options={data}
          renderOption={renderOptionMultiple}
          renderInput={renderInput}
          value={parseMultipleValue(data, props.value)}
          onChange={handleChangeMultiple}
          getOptionLabel={getOptionLabel}
          onBlur={props.onBlur}
          ListboxComponent={
            FieldAutocompleteListboxComponent as React.ComponentType<
              React.HTMLAttributes<HTMLElement>
            >
          }
        />
      )}
      <FormHelperText error={true}>{props.error}</FormHelperText>
    </FormControl>
  );
};

FieldAutocompleteCacheResource.propTypes = {
  label: PropTypes.node,
  required: PropTypes.bool,
  error: PropTypes.string,
  helpText: PropTypes.element,
};
