import React from 'react';
import { defineMessage, FormattedMessage } from 'react-intl';

import {
  useVerticalStepper,
  VerticalStepItem,
  VerticalStepper,
} from '../../common/stepper';
import { dynamicDataHelpText } from '../dynamicData/mesages';
import {
  StepDynamicDataContent,
  StepDynamicDataActions,
} from './steps/StepDynamicData';
import {
  StepRequiredFieldsActions,
  StepRequiredFieldsContent,
} from './steps/StepRequiredFields';
import { PkpassIdentification } from '../../design/pkpass/PkpassIdentification';
import FormattedHTMLMessage from '../../common/FormattedHTMLMessage';

import {
  StepFrontSide,
  StepPassStyle,
  StepImages,
  StepBarcode,
  StepBackSide,
  Actions,
} from './design';

export default function WizardNavigator(): JSX.Element {
  const stepper = useVerticalStepper();

  const steps: VerticalStepItem[] = [
    {
      content: <StepRequiredFieldsContent />,
      subtitle: (
        <FormattedMessage
          id='Template.wizard.requiredFields.subtitle'
          defaultMessage='Required fields to create template'
        />
      ),
      helpText: (
        <FormattedMessage
          id='Template.wizard.requiredFields.helpText'
          defaultMessage='The fields are required to create a template from which individual passes are generated.'
        />
      ),
      title: (
        <FormattedMessage
          id='Template.wizard.requiredFields.title'
          defaultMessage='Essential info'
        />
      ),
      actions: <StepRequiredFieldsActions stepper={stepper} />,
    },
    {
      content: <StepDynamicDataContent />,
      subtitle: (
        <FormattedMessage
          id='Template.wizard.dynamicData.subtitle'
          defaultMessage='Definition of data used by template'
        />
      ),
      helpText: dynamicDataHelpText,
      title: (
        <FormattedMessage
          id='Template.wizard.dynamicData.title'
          defaultMessage='Dynamic data'
        />
      ),
      actions: <StepDynamicDataActions stepper={stepper} />,
    },
    {
      content: <PkpassIdentification />,
      title: (
        <FormattedMessage
          id='Template.wizard.identification.subtitle'
          defaultMessage='Identification'
        />
      ),
      helpText: (
        <FormattedMessage
          id='Template.wizard.identification.helpText'
          defaultMessage='Select certificate, set up organization name and upload/choose icon for your passes.'
        />
      ),
      subtitle: (
        <FormattedMessage
          id='Template.wizard.identification.title'
          defaultMessage='Set up certificate, organization name and icon'
        />
      ),
    },

    {
      content: <StepPassStyle />,
      title: (
        <FormattedMessage
          id='Template.wizard.passStyle.subtitle'
          defaultMessage='Pass style and colors'
        />
      ),
      helpText: (
        <FormattedMessage
          id='Template.wizard.passStyle.helpText'
          defaultMessage='Select one from layout (boarding pass, coupon, event ticket, generic, store card) and choose colors of your passes.'
        />
      ),
      subtitle: (
        <FormattedMessage
          id='Template.wizard.passStyle.title'
          defaultMessage='Set up pass color and layout'
        />
      ),
    },
    {
      content: <StepImages />,
      title: (
        <FormattedMessage
          id='Template.wizard.images.subtitle'
          defaultMessage='Images'
        />
      ),
      helpText: (
        <FormattedHTMLMessage
          descriptor={defineMessage({
            id: 'Template.wizard.images.helpText.html',
            defaultMessage: `
        <p>Images and their location is given by the layout type (pass style). Passes can include the following image types:</p>
        <ul>
        <li>The <b>background</b> image is displayed behind the entire front side of the pass. The expected dimensions are 360 x 440 pixels. The image is slightly cropped on all sides and also blurred. You can often provide an image at a smaller size. It will be scaled up, but the blurring effect will hide the details of the image. This lets you reduce the file size without users noticing the difference.</li>
        <li>The <b>footer</b> image is displayed near the barcode. The allotted space is 572 x 30 pixels.</li>
        <li>The <b>icon</b> is displayed when a notification is shown on the lock screen and by apps such as Mail when the pass is attached to an email. The icon should have dimensions of 58 x 58 pixels.</li>
        <li>The <b>logo</b> image is displayed in the top left corner of the pass beside the logo text. The allotted space is 320 x 100 pixels; in most cases it should be more narrow.</li>
        <li>The <b>strip</b> image is displayed behind the primary fields. The expected dimensions are 640 x 168-246 pixels. The allotted space is 640 x 168 pixels  for event tickets; 640 x 220 pixels  for other pass styles with a square barcode on devices with 3.5 inch screens; 640 x 246 for all other uses.</li>
        <li>The <b>thumbnail</b> image is displayed next to the fields on the front side of the pass. The allotted space is 120-180 x 120-180 pixels. The aspect ratio should be in the range of 2:3 to 3:2 or the image will be cropped.</li>
        </ul>
        `,
          })}
        />
      ),
      subtitle: (
        <FormattedMessage
          id='Template.wizard.images.title'
          defaultMessage='Upload and select images'
        />
      ),
    },
    {
      content: <StepFrontSide />,
      title: (
        <FormattedMessage
          id='Template.wizard.passStyle.subtitle'
          defaultMessage='Fields'
        />
      ),
      /*   helpText: (
        <FormattedMessage
          id="Template.wizard.passStyle.helpText"
          defaultMessage="TODO"
        />
      ),*/
      subtitle: (
        <FormattedMessage
          id='Template.wizard.passStyle.title'
          defaultMessage='Add and define data fields and their content'
        />
      ),
    },
    {
      content: <StepBarcode />,
      title: (
        <FormattedMessage
          id='Template.wizard.passStyle.subtitle'
          defaultMessage='Barcode'
        />
      ),
      /*      helpText: (
        <FormattedMessage
          id="Template.wizard.passStyle.helpText"
          defaultMessage="TODO"
        />
      ),*/
      subtitle: (
        <FormattedMessage
          id='Template.wizard.passStyle.title'
          defaultMessage='Set a barcode or 2D code to be able read content by reader'
        />
      ),
    },
    {
      content: <StepBackSide />,
      title: (
        <FormattedMessage
          id='Template.wizard.passStyle.subtitle'
          defaultMessage='Detail content'
        />
      ),
      /*      helpText: (
        <FormattedMessage
          id="Template.wizard.passStyle.helpText"
          defaultMessage="TODO"
        />
      ),*/
      subtitle: (
        <FormattedMessage
          id='Template.wizard.passStyle.title'
          defaultMessage='Add and set up content fields on pass detail'
        />
      ),
    },
    /*  {
      content: <StepConfirmationContent />,
      subtitle: (
        <FormattedMessage
          id='Template.wizard.dynamicData.subtitle'
          defaultMessage='Definition of data used by template'
        />
      ),
      helpText: dynamicDataHelpText,
      title: (
        <FormattedMessage
          id='Template.wizard.dynamicData.title'
          defaultMessage='Dynamic data'
        />
      ),
      actions: <StepConfirmationActions stepper={stepper} />,
    },*/
  ];

  steps.forEach((step) => {
    if (!step.actions) {
      step.actions = <Actions stepper={stepper} stepCount={steps.length} />;
    }
  });

  return <VerticalStepper activeStep={stepper.step} steps={steps} />;
}
