import React from 'react';

import useFilter from '../../../hooks/useFilter';
import useResourcesPaged, {
  ResourcePagedHookProps,
} from '../../../hooks/useResourcesPaged';
import { ResourceTypes } from '../../../utils/services/api';
import { FilterProps } from '../../../types/common/filter';
import { FilterOptionsProps } from '../filter/Filter';
import DataGrid, { Fields } from './DataGrid';

interface Props<T> {
  resource: ResourceTypes;
  filterProps?: FilterProps[];
  filterOptions?: FilterOptionsProps;
  fields: Fields<T>;
  export?: boolean;
  exportHeaders?: string[];
  actionsWidth?: number;
  canBeDeleted?: (item: T) => boolean;
  title?: JSX.Element | string;
  autoLoad?: boolean;
}

export default function DataGridResources<T>(props: Props<T>): JSX.Element {
  const { filter } = useFilter();
  const p: ResourcePagedHookProps<T> = useResourcesPaged<T>(props.resource, {
    filter,
    filterProps: props.filterProps,
    autoLoad: props.autoLoad,
    canBeDeleted: props.canBeDeleted,
  });

  return (
    <DataGrid
      {...p}
      resource={props.resource}
      export={props.export}
      actionsWidth={props.actionsWidth}
      fields={props.fields}
      filterOptions={props.filterOptions}
      title={props.title}
    />
  );
}
