import { useFormikContext } from 'formik';
import { get } from 'lodash';
import React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles';

import { FieldAutocompleteProject } from '../../../common/fields';
import Form from '../../../common/Form';
import { Template } from '../../../../types/Template';
import { TemplateNameFormInput } from '../../general/TemplateNameFormInput';
import { VerticalStepperControls } from '../../../common/stepper';
import { NextButton } from '../../../common/buttons';

type Props = { stepper?: VerticalStepperControls };

const messages = defineMessages({
  projectHelperText: {
    id: 'TemplateNewModal.project.help',
    defaultMessage:
      'Every project (name, access rights) is set by the administrator. Select a project this templates belongs under. If unsure, contact your administrator.',
  },
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      margin: theme.spacing(1),
      flexGrow: 1,
    },
  })
);
export function StepRequiredFieldsContent(): JSX.Element {
  const intl = useIntl();
  const { setFieldValue, values, errors } = useFormikContext<Template>();
  const handleChange = (value: string | undefined) => {
    setFieldValue('projectId', value);
  };

  const theme = useTheme();
  const classes = useStyles(theme);

  const projectIdErr = get(errors, 'projectId');

  return (
    <Card className={classes.card}>
      <CardHeader
        title={
          <FormattedMessage
            id='StepRequiredFields.essentials.form.title'
            defaultMessage='Template essentials'
          />
        }
        subheader={
          <FormattedMessage
            id='StepRequiredFields.essentials.form.subheader'
            defaultMessage='Set required fields: project, name, and supported languages.'
          />
        }
      />
      <CardContent>
        <Form>
          <FieldAutocompleteProject
            required={true}
            helpText={intl.formatMessage(messages.projectHelperText)}
            name='projectId'
            onChange={handleChange}
            value={values.projectId}
            multiple={false}
            error={projectIdErr}
          />
          <TemplateNameFormInput />
        </Form>
      </CardContent>
    </Card>
  );
}

export function StepRequiredFieldsActions({ stepper }: Props): JSX.Element {
  const { errors, validateForm } = useFormikContext<Template>();

  const projectIdErr = get(errors, 'projectId');
  const nameErr = get(errors, 'name');

  const handleNext = async () => {
    const errs = await validateForm();
    const err = get(errs, 'projectId') || get(errs, 'name');

    if (!err) {
      stepper?.next();
    }
  };

  return (
    <>
      {stepper && (
        <NextButton
          onClick={handleNext}
          disabled={!!(projectIdErr || nameErr)}
        />
      )}
    </>
  );
}
