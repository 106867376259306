export const flatten = (data: unknown): Record<string, unknown> => {
  const result: { [key: string]: unknown } = {};
  function recurse(cur: unknown, prop: string) {
    if (cur === null) {
      //skip null
    } else if (Object(cur) !== cur) {
      result[prop] = cur;
    } else if (Array.isArray(cur)) {
      const l = cur.length;
      for (let i = 0; i < l; i++) {
        recurse(cur[i], prop + '[' + i + ']');
      }
      if (l === 0) {
        result[prop] = [];
      }
    } else {
      let isEmpty = true;
      const c = cur as Record<string, unknown>;
      for (const p of Object.keys(c)) {
        isEmpty = false;
        recurse(c[p], prop ? prop + '.' + p : p);
      }
      if (isEmpty && prop) {
        // skip empty object
        // result[prop] = {};
      }
    }
  }
  recurse(data, '');
  return result;
};
