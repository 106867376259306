import { FormikProps } from 'formik';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { CreateButton } from '../common/buttons';
import FormikCreateDrawer from '../common/formik/FormikCreateDrawer';
import { useCacheResources } from '../../hooks/';
import { ResourceTypes } from '../../utils/services/api';
import PassCreateForm from './PassCreateForm';
import PassFormInterface from './PassFormInterface';
import useNotification from '../../hooks/useNotification';
import { useHistory } from 'react-router';
import { OnCreateHandlder } from '../../types';

export default function PassCreateDrawer(props: {
  onCreate?: OnCreateHandlder;
}): JSX.Element {
  const d = useCacheResources(ResourceTypes.TEMPLATE);
  const { addSuccessNotification } = useNotification();
  const history = useHistory();

  const handleCreate = (r: unknown, resource: ResourceTypes) => {
    if (props.onCreate) {
      return props.onCreate(r, resource);
    } else {
      addSuccessNotification(`Pass was created`);
      const a = r as { id: string };
      if (a.id) {
        history.replace(`/passes/pass/${a.id}`);
      }
    }
  };

  const renderForm = (p: FormikProps<PassFormInterface>) => {
    return <PassCreateForm {...p} />;
  };

  return (
    <FormikCreateDrawer
      onCreate={handleCreate}
      title={
        <FormattedMessage id='PassNewModal.title' defaultMessage='New pass' />
      }
      initialValues={{}}
      resource={ResourceTypes.PASS}
      trigger={
        <CreateButton disabled={d.data.length === 0}>
          <FormattedMessage
            id='PassListPage.Controls.newPass'
            defaultMessage='Create new pass'
          />
        </CreateButton>
      }
      renderActions={() => null}
      renderForm={renderForm}
    />
  );
}
