import React, { useState } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';

import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';

import { Button } from '../common/buttons';
import { FieldTextInput, FieldTextInputData } from '../common/fields';
import Form from '../common/Form';
import * as api from '../../utils/services/api';
import useNotification from '../../hooks/useNotification';
import { Box } from '@material-ui/core';
import { validatePassword } from './validatePassword';

const messages = defineMessages({
  changePasswordAction: {
    defaultMessage: 'Change password',
    id: 'change.pwd.action',
  },
  changePasswordFailureMessage: {
    defaultMessage:
      'Your current password is not valid or new password is not secure. New password must be between 8 and 128 characters, must contain at least one uppercase letter, one lowercase letter and at least one special character.',
    id: 'change.pwd.failure.message',
  },
  changePasswordHeader: {
    defaultMessage: 'Change password',
    id: 'change.pwd.header',
  },

  changePasswordFailureTitle: {
    defaultMessage: 'Change password failure.',
    id: 'change.pwd.failure.title',
  },
  changePasswordFailurePwdNotMatch: {
    defaultMessage: 'New passwords are not match.',
    id: 'change.pwd.failure.new.password.is.not.match',
  },
  changePasswordFailureInvalidCurrent: {
    defaultMessage: 'Current password cannot be empty.',
    id: 'change.pwd.failure.current.password.is.invalid',
  },
  changePasswordFailureInvalidNew: {
    defaultMessage:
      'Password is not secure. New password must be between 8 and 128 characters, must contain at least one uppercase letter, one lowercase letter and at least one special character.',
    id: 'change.pwd.failure.new.password.is.not.valid',
  },

  info: {
    defaultMessage:
      'Your password must be between 8 and 128 characters, must contain at least one uppercase letter, one lowercase letter and at least one special character.',
    id: 'change.pwd.info',
  },
  changePasswordSuccessText: {
    defaultMessage: 'Your password was successfully changed.',
    id: 'change.pwd.success.text',
  },
  changePasswordSuccessTitle: {
    defaultMessage: 'Password changed',
    id: 'change.pwd.success.header',
  },
  currentPwd: {
    defaultMessage: 'Current password',
    id: 'change.pwd.current.label',
  },
  newPwd: {
    defaultMessage: 'New password',
    id: 'change.pwd.new.label',
  },
  newPwdRepeat: {
    defaultMessage: 'New password again',
    id: 'change.pwd.new.again.label',
  },
});

interface State {
  currentPwd: string;
  newPwd: string;
  newPwd1: string;
  currentPwdError: null | string;
  newPasswordError: null | string;
  passwordError: null | string;
  showSuccessInfo: boolean;
}

const UserChangePassword = () => {
  const intl = useIntl();
  const { addNotification } = useNotification();
  const [state, setState] = useState<State>({
    currentPwd: '',
    newPwd: '',
    newPwd1: '',
    currentPwdError: null,
    newPasswordError: null,
    passwordError: null,
    showSuccessInfo: false,
  });
  const [pending, setPending] = useState<boolean>(false);

  const changePassword = async () => {
    setState((prevState) => ({
      ...prevState,
      passwordError: null,
      currentPwdError: null,
      newPasswordError: null,
    }));

    if (state.currentPwd == null || state.currentPwd === '') {
      setState((s) => ({
        ...s,
        currentPwdError: intl.formatMessage(
          messages.changePasswordFailureInvalidCurrent
        ),
      }));
      return;
    }

    if (!validatePassword(state.newPwd)) {
      addNotification({
        message: intl.formatMessage(messages.changePasswordFailureInvalidNew),
        level: 'error',
      });
      setState((s) => ({
        ...s,
        newPasswordError: intl.formatMessage(
          messages.changePasswordFailureInvalidNew
        ),
      }));
      return;
    }

    if (state.newPwd !== state.newPwd1) {
      addNotification({
        message: intl.formatMessage(messages.changePasswordFailurePwdNotMatch),
        level: 'error',
      });
      setState((s) => ({
        ...s,
        newPasswordError: intl.formatMessage(
          messages.changePasswordFailurePwdNotMatch
        ),
      }));
      return;
    }
    setPending(true);
    try {
      const data = await api.changePassword(state.currentPwd, state.newPwd);
      onSuccess();
      return data;
    } catch (e) {
      onFailure();
    }
    setPending(false);
  };

  const onFailure = () => {
    setState((s) => ({
      ...s,
      passwordError: intl.formatMessage(messages.changePasswordFailureMessage),
      newPasswordError: null,
      currentPwdError: null,
    }));
    addNotification({
      level: 'error',
      message: intl.formatMessage(messages.changePasswordFailureMessage),
    });
  };

  const onSuccess = () => {
    setState({
      currentPwd: '',
      currentPwdError: null,
      newPasswordError: null,
      newPwd: '',
      newPwd1: '',
      showSuccessInfo: true,
      passwordError: null,
    });
    addNotification({
      level: 'success',
      message: intl.formatMessage(messages.changePasswordSuccessText),
    });
  };

  const changeForm = (event: FieldTextInputData) => {
    setState((s) => ({
      ...s,
      [event.name]: event.value,
      showSuccessInfo: false,
    }));
  };

  return (
    <div>
      <Box p={2} />
      <Alert severity='info'>
        <FormattedMessage {...messages.info} />
      </Alert>
      <Box p={2} />

      <Form>
        <FieldTextInput
          required={true}
          error={state.currentPwdError || undefined}
          name='currentPwd'
          type='password'
          value={state.currentPwd}
          onChange={changeForm}
          placeholder={intl.formatMessage(messages.currentPwd)}
          label={intl.formatMessage(messages.currentPwd)}
        />

        <FieldTextInput
          required={true}
          error={state.newPasswordError || undefined}
          name='newPwd'
          type='password'
          onChange={changeForm}
          value={state.newPwd}
          placeholder={intl.formatMessage(messages.newPwd)}
          label={intl.formatMessage(messages.newPwd)}
        />

        <FieldTextInput
          required={true}
          error={state.newPasswordError || undefined}
          name='newPwd1'
          type='password'
          value={state.newPwd1}
          onChange={changeForm}
          placeholder={intl.formatMessage(messages.newPwdRepeat)}
          label={intl.formatMessage(messages.newPwdRepeat)}
        />

        {state.showSuccessInfo && (
          <Alert severity='success'>
            <AlertTitle>
              <FormattedMessage {...messages.changePasswordSuccessTitle} />
            </AlertTitle>
            <p>
              <FormattedMessage {...messages.changePasswordSuccessText} />
            </p>
          </Alert>
        )}
        {!!state.passwordError && (
          <Alert severity='error'>{state.passwordError}</Alert>
        )}
        <Button
          onClick={changePassword}
          color='primary'
          variant='contained'
          disabled={pending}
        >
          <FormattedMessage {...messages.changePasswordAction} />
        </Button>
      </Form>
    </div>
  );
};

export default UserChangePassword;
