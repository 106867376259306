import { FormikProps } from 'formik';
import React from 'react';

import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import CloudDownload from '@material-ui/icons/CloudDownload';
import Image from '@material-ui/icons/Image';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import Print from '@material-ui/icons/Print';

import FormikResetButton from '../common/formik/FormikResetButton';
import FormikSaveButton from '../common/formik/FormikSaveButton';
import { LinkIcon } from '../common/icons';
import { ResourceDeleteButton } from '../common/ResourceDeleteButton';
import useViewer from '../../hooks/useViewer';
import { ResourceTypes } from '../../utils/services/api';
import { Template } from '../../types/Template';
import PassFormInterface from './PassFormInterface';

interface Props extends FormikProps<PassFormInterface> {
  template: Template;
}

interface State {
  previewLanguage: string;
}

export default function PassFormControls(props: Props) {
  const [anchorEl, setAnchorEl] = React.useState<any>(null);

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { values, template } = props;
  const viewer = useViewer();
  const canUpdate = viewer && viewer.canUpdatePassOnProject(template.projectId);
  const canDelete = viewer && viewer.canDeletePassOnProject(template.projectId);

  return (
    <div>
      {canUpdate && <FormikSaveButton {...props} />}
      <FormikResetButton {...props} />
      {canDelete && values.id && (
        <ResourceDeleteButton
          resource={ResourceTypes.PASS}
          resourceId={values.id}
        />
      )}

      <IconButton
        ria-controls='simple-menu'
        aria-haspopup='true'
        onClick={handleClick}
      >
        <MoreHoriz />
      </IconButton>
      <Menu
        id='simple-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem>
          <Link href={`${values.url}?tag=console`} target='_blank'>
            <IconButton>
              <LinkIcon />
            </IconButton>
          </Link>
        </MenuItem>
        <MenuItem>
          <Link href={`${values.url}?format=pdf&tag=console`} target='_blank'>
            <IconButton>
              <Print />
            </IconButton>
          </Link>
        </MenuItem>
        <MenuItem>
          <Link
            href={`${values.url}?format=pkpass&tag=console`}
            target='_blank'
          >
            <IconButton>
              <CloudDownload />
            </IconButton>
          </Link>
        </MenuItem>
        <MenuItem>
          <Link
            href={`${values.url}?format=qrcode&tag=console`}
            target='_blank'
          >
            <IconButton>
              <Image />
            </IconButton>
          </Link>
        </MenuItem>
      </Menu>
    </div>
  );
}
