import {
  passStyles,
  PkpassPassStyleTypes,
  PkpassStyleBoardingPass,
  PkpassTransitTypes,
  Template,
} from '../../../../types/Template';
import { cloneDeep, get, unset, set } from 'lodash';

export const changePassStyle = (
  values: Template,
  newPassStyle: PkpassPassStyleTypes
): Template => {
  const valuesCopy = cloneDeep(values);

  const oldPassStyle: PkpassPassStyleTypes = getPassStyle(valuesCopy);

  if (newPassStyle !== oldPassStyle) {
    const definition = get(valuesCopy, `jsonTemplate.${oldPassStyle}`);
    unset(valuesCopy, `jsonTemplate.${oldPassStyle}`);

    if (newPassStyle === PkpassPassStyleTypes.BoardingPass) {
      set(definition, `properties.transitType`, {
        type: 'string',
        value: PkpassTransitTypes.PKTransitTypeGeneric,
      });
      valuesCopy.jsonTemplate[
        PkpassPassStyleTypes.BoardingPass
      ] = definition as PkpassStyleBoardingPass;
    } else {
      unset(valuesCopy, `jsonTemplate.${oldPassStyle}.properties.transitType`);
      valuesCopy.jsonTemplate[newPassStyle] = definition;
    }
  }

  return valuesCopy;
};

export const getPassStyle = (values: Template): PkpassPassStyleTypes => {
  const jsonTemplate = values.jsonTemplate;
  for (const passStyle of passStyles) {
    if (Object.prototype.hasOwnProperty.call(jsonTemplate, passStyle)) {
      return passStyle;
    }
  }

  return PkpassPassStyleTypes.Generic;
};
