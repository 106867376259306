import React from 'react';

import {
  getResourceRoutePrefix,
  remove,
  ResourceTypes,
} from '../../utils/services/api';
import { DeleteButton } from './buttons';
import { useHistory } from 'react-router';
import useNotification from '../../hooks/useNotification';

interface Props {
  resourceId: string;
  resource: ResourceTypes;
  disabled?: boolean;
  tooltip?: string | JSX.Element;
}

export const ResourceDeleteButton = (props: Props): JSX.Element => {
  const history = useHistory();
  const { addNotification } = useNotification();

  const { resourceId, resource, disabled, tooltip } = props;
  const handleDelete = async () => {
    try {
      const r = await remove(resource, resourceId);
      addNotification({
        level: 'success',
        message: `${resource} ${resourceId} was deleted`,
      });
      history.push(`/${getResourceRoutePrefix(resource)}/${resource}`);
      return r;
    } catch (e) {
      addNotification({
        level: 'error',
        message: `Delete ${resource} ${resourceId} failure, because: ${e.message}`,
      });
      return e;
    }
  };

  return (
    <DeleteButton
      onDelete={handleDelete}
      disabled={disabled}
      tooltip={tooltip}
    />
  );
};
