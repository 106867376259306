import React from 'react';

import { LinkEditIconButton } from '../common/buttons';
import { DataGridColumns, Fields } from '../common/dataGrid';
import DataGridActions from '../common/dataGrid/DataGridActions';
import DataGridResources from '../common/dataGrid/DataGridResources';
import { StaticLabel } from '../common/labels';
import { ResourceTypes } from '../../utils/services/api';
import { UserListItem } from '../../types/User';

const fields: Fields<UserListItem> = [
  { key: 'displayName', title: StaticLabel.Name },
  { key: 'email', title: StaticLabel.Email },
  { key: 'isAdmin', title: StaticLabel.Admin },
  { key: 'createdAt', title: StaticLabel.CreatedAt, type: 'date' },
  {
    key: DataGridColumns.Actions,
    title: StaticLabel.Actions,
    render(item: UserListItem) {
      return <Actions item={item} />;
    },
  },
];

const filterProps = [
  {
    columnName: 'displayName',
    name: 'displayName',
    type: 'like',
  },
  {
    columnName: 'email',
    name: 'email',
    type: 'like',
  },
];

function Actions(props: { item: UserListItem }) {
  return (
    <DataGridActions>
      <LinkEditIconButton path={`/identity/user/${props.item.id}`} />
    </DataGridActions>
  );
}

export default function UserList(): JSX.Element {
  return (
    <DataGridResources
      resource={ResourceTypes.USER}
      filterOptions={{ displayName: true, email: true }}
      filterProps={filterProps}
      fields={fields}
    />
  );
}
