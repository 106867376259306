import React from 'react';

import CheckerBoardImage from '../common/CheckerBoard';
import { DataGridColumns, Fields } from '../common/dataGrid';
import DataGridResources from '../common/dataGrid/DataGridResources';
import { LabelBytes, StaticLabel } from '../common/labels';

import { ProjectLabelWithDrawer } from '../../domain/';
import { ResourceTypes } from '../../utils/services/api';
import { ImageListItem } from '../../types/Image';
import ImageListActions from './ImageListActions';
import useViewer from '../../hooks/useViewer';
import { FilterProps } from '../../types/common/filter';
import Tooltip from '@material-ui/core/Tooltip';
import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    noMaxWidth: {
      maxWidth: 'none',
      margin: 0,
      padding: 0,
    },
  })
);

const ImagePreview = ({ item }: { item: ImageListItem }): JSX.Element => {
  const classes = useStyles();
  return (
    <Tooltip
      classes={{ tooltip: classes.noMaxWidth }}
      title={<img src={item.url} alt={''} />}
    >
      <span>
        <CheckerBoardImage src={item.url} width={50} />
      </span>
    </Tooltip>
  );
};

const fields: Fields<ImageListItem> = [
  {
    key: 'preview',
    title: StaticLabel.Preview,
    render(item: ImageListItem): JSX.Element {
      return <ImagePreview item={item} />;
    },
  },
  { key: 'name', title: StaticLabel.Name },
  { key: 'type', title: StaticLabel.ImageType },
  {
    key: 'fileSize',
    orderBy: 'fileSize',
    title: StaticLabel.FileSize,
    render(item: ImageListItem): JSX.Element {
      return <LabelBytes bytes={item.fileSize} />;
    },
  },
  {
    key: 'dimension',
    orderBy: 'width',
    title: StaticLabel.Dimension,
    render(item: ImageListItem): JSX.Element {
      return (
        <span>
          {item.width}x{item.height}
        </span>
      );
    },
  },
  {
    key: 'projectId',
    render(item: ImageListItem): JSX.Element {
      return <ProjectLabelWithDrawer resourceId={item.projectId} />;
    },
    title: StaticLabel.Project,
  },
  { key: 'updatedAt', title: StaticLabel.UpdatedAt, type: 'date' },
  {
    key: DataGridColumns.Actions,
    render(item: ImageListItem, listLoad: () => void): JSX.Element {
      return (
        <ImageListActions
          resource={ResourceTypes.IMAGE}
          item={item}
          listLoad={listLoad}
        />
      );
    },
    title: StaticLabel.Actions,
  },
];

const filterProps: FilterProps[] = [
  {
    columnName: 'projectId',
    name: 'project',
    type: 'inUuid',
  },
  {
    columnName: 'type',
    name: 'imageType',
    type: 'in',
  },
  {
    columnName: 'name',
    name: 'name',
    type: 'like',
  },
];

export default function ImageList(): JSX.Element {
  const viewer = useViewer();
  return (
    <DataGridResources
      title={'Images'}
      resource={ResourceTypes.IMAGE}
      filterProps={filterProps}
      filterOptions={{ project: true, name: true, imageType: true }}
      fields={fields}
      canBeDeleted={(i: ImageListItem) => {
        return !!(viewer && viewer.canManageImagesOnProject(i.projectId));
      }}
      export={true}
    />
  );
}
