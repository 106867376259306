import React from 'react';
import { defineMessage } from 'react-intl';
import FormattedHTMLMessage from '../../common/FormattedHTMLMessage';
import Alert from '@material-ui/lab/Alert';

export default function PassBulkHelp() {
  return (
    <Alert severity='info'>
      <FormattedHTMLMessage
        descriptor={defineMessage({
          id: 'PassBulkHelp.content',
          defaultMessage: `<h2>Bulk operation help</h2>
        <p>
          In case that .csv file contain column ID, result of operation will be
          “pass update” in otherwise it will be "pass create".
        </p>
        <h3>How to create new passes</h3>
        <label>
          <b>Step 1</b>
        </label>
        <p>
          Download IMPORT TEMPLATE (.csv format). You can edit, add, delete
          columns depend on your template.
          <br />
          <a href="https://yourpass.s3.amazonaws.com/Import%20template%20SAMPLE.csv" target="_blank"/>import-template.csv</a>
        </p>
        <label>
          <b>Step 2</b>
        </label>
        <p>
          Fill Template ID from which you want to create passes. You can find it
          in menu Templates -> General.
        </p>
        <label>
          <b>Step 3</b>
        </label>
        <p>
          Fill names of dynamic data placeholders. It must always start with
          prefix: „dynamicData.“ and followed by name of the placeholder. For
          example „name“ (big and small letters matter). Whole syntax is:
          „dynamicData.name“ i. If you want to add dynamic images. Use
          dynamicImages.strip (coupon, event ticket, store card) or
          dynamicImages.thumbnail (generic)
        </p>
        <label>
          <b>Step 4</b>
        </label>
        <p>Fill the document with DATA, save changes.</p>
        <label>
          <b>Step 5</b>
        </label>
        <p>Import the document and follow instructions.</p>
        <label>
          <b>Step 6</b>
        </label>
        <p>
          Check results. Operation is successful, when is result of operation
          “CREATED” ALL DATA MUST BE PRECISELY PREPARE. THERE IS NO DATA
          VERIFICATION CONTROL
        </p>
        <h3>How to update existing passes</h3>
        <b>Step 1</b>
        <p>Download from PASS menu .csv file passes, which you want to change.</p>
        <b>Step 2</b>
        <p>Make your changes in the appropriate columns and save the document.</p>
        <b>Step 3</b>
        <p>
          In menu PASSES click to BATCH, import document a follow instructions.
        </p>`,
        })}
      />
    </Alert>
  );
}
