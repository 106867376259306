import { FormikProps } from 'formik';
import { get } from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import Tooltip from '@material-ui/core/Tooltip';

import { EditIcon } from '../../common/icons';
import { StaticLabel } from '../../common/labels';
import { TabOption } from '../../common/Tabs';
import { Template } from '../../../types/Template';
import GeneralForm from './GeneralForm';

export default function (props: FormikProps<Template>): TabOption {
  const hasError = !!(
    get(props.errors, 'name') ||
    get(props.errors, 'languages') ||
    get(props.errors, 'dynamicDataJsonSchema')
  );

  return {
    content: <GeneralForm {...props} />,
    error: hasError,
    icon: <EditIcon />,
    key: 'general',
    name: (
      <Tooltip
        title={
          <FormattedMessage
            id='Template.tabs.general'
            defaultMessage='Set basic required fields for template.'
          />
        }
      >
        <span>{StaticLabel.General}</span>
      </Tooltip>
    ),
  };
}
