import React from 'react';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '40ch',
      },
      display: 'flex',
      flexDirection: 'column',
    },
  })
);

export default function Form(
  props: React.DetailedHTMLProps<
    React.FormHTMLAttributes<HTMLFormElement>,
    HTMLFormElement
  >
) {
  const classes = useStyles();
  return (
    <form
      {...props}
      className={classes.root}
      noValidate={true}
      autoComplete='off'
      style={props.style}
    />
  );
}
