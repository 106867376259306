import React, { useEffect, useState } from 'react';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import { GOOGLE_MAP_API_KEY } from '../../../utils/services/environment';
import { linkStyle } from '../../../styles';
import { LabelValueTitle, StaticLabel } from './index';

interface Props {
  lng: number;
  lat: number;
  link?: boolean;
}

interface State {
  address: string | null;
}

export const LabelGPS = (props: Props): JSX.Element => {
  const [address, setAddress] = useState(null);

  useEffect(() => {
    const abortController: AbortController = new AbortController();
    const getAddress = async () => {
      try {
        const url = `https://maps.google.com/maps/api/geocode/json?latlng=${props.lat},${props.lng}&key=${GOOGLE_MAP_API_KEY}`;
        const response = await fetch(url, {
          signal: abortController.signal,
        });
        const json: any = await response.json();
        if (json.results && json.results.length > 0) {
          setAddress(json.results[0].formatted_address);
        }
      } catch (e) {
        // ssetAddress(null);
      }
    };
    getAddress();
    return () => {
      if (!abortController.signal.aborted) {
        abortController.abort();
      }
    };
  }, [props.lat, props.lng]);

  if (address) {
    return <span style={props.link ? linkStyle : undefined}>{address}</span>;
  }

  return (
    <List>
      <ListItem>
        <ListItemText>
          <LabelValueTitle
            label={StaticLabel.Latitude}
            value={`${props.lat}`}
            link={props.link}
          />
        </ListItemText>
      </ListItem>
      <ListItem>
        <ListItemText>
          <LabelValueTitle
            label={StaticLabel.Longitude}
            value={`${props.lng}`}
            link={props.link}
          />
        </ListItemText>
      </ListItem>
    </List>
  );
};
