import React, { Fragment } from 'react';
import { defineMessage, FormattedMessage } from 'react-intl';

import { PkpassDateStyleTypes } from '../../../../../../../../types/Template';
import { PkpassBoolean, PkpassSelect } from '../../../../../inputs';
import FormattedHTMLMessage from '../../../../../../../common/FormattedHTMLMessage';

const opts = [
  {
    key: PkpassDateStyleTypes.PKDateStyleNone,
    text: PkpassDateStyleTypes.PKDateStyleNone,
    value: PkpassDateStyleTypes.PKDateStyleNone,
  },
  {
    key: PkpassDateStyleTypes.PKDateStyleShort,
    text: PkpassDateStyleTypes.PKDateStyleShort,
    value: PkpassDateStyleTypes.PKDateStyleShort,
  },
  {
    key: PkpassDateStyleTypes.PKDateStyleMedium,
    text: PkpassDateStyleTypes.PKDateStyleMedium,
    value: PkpassDateStyleTypes.PKDateStyleMedium,
  },
  {
    key: PkpassDateStyleTypes.PKDateStyleLong,
    text: PkpassDateStyleTypes.PKDateStyleLong,
    value: PkpassDateStyleTypes.PKDateStyleLong,
  },
  {
    key: PkpassDateStyleTypes.PKDateStyleFull,
    text: PkpassDateStyleTypes.PKDateStyleFull,
    value: PkpassDateStyleTypes.PKDateStyleFull,
  },
];

export default function FieldTypeDateAdvanced(): JSX.Element {
  return (
    <Fragment>
      <PkpassSelect
        options={opts}
        helpText={
          <FormattedMessage
            id='pkpass.field.timeStyle.helpText'
            defaultMessage='Style of time to display. '
          />
        }
        name={`properties.timeStyle`}
        label={
          <FormattedMessage
            id='pkpass.field.timeStyle.label'
            defaultMessage='Time style'
          />
        }
      />

      <PkpassSelect
        options={opts}
        helpText={
          <FormattedMessage
            id='pkpass.field.dateStyle.helpText'
            defaultMessage='Style of date to display.'
          />
        }
        name={`properties.dateStyle`}
        label={
          <FormattedMessage
            id='pkpass.field.dateStyle.label'
            defaultMessage='Date style'
          />
        }
      />

      <PkpassBoolean
        helpText={
          <FormattedHTMLMessage
            descriptor={defineMessage({
              id: 'pkpass.field.ignoresTimeZone.helpText',
              defaultMessage: `<p>Always display the time and date in the given time zone, not in the user’s current time zone. The default value is false.</p>
            <p>The format for a date and time always requires a time zone, even if it will be ignored. For backward compatibility with iOS 6, provide an appropriate time zone, so that the information is displayed meaningfully even without ignoring time zones.</p>
            <p>This key does not affect how relevance is calculated.</p>
            <p>Available in iOS 7.0.</p>`,
            })}
          />
        }
        name={`properties.ignoresTimeZone`}
        label={
          <FormattedMessage
            id='pkpass.field.ignoresTimeZone.label'
            defaultMessage='ignore timezone'
          />
        }
      />

      <PkpassBoolean
        helpText={
          <FormattedHTMLMessage
            descriptor={defineMessage({
              id: 'pkpass.field.isRelative.helpText',
              defaultMessage: `<p>If true, the label’s value is displayed as a relative date; otherwise, it is displayed as an absolute date. The default value is false.</p>
            <p>This key does not affect how relevance is calculated.</p>`,
            })}
          />
        }
        name={`properties.isRelative`}
        label={
          <FormattedMessage
            id='pkpass.field.isRelative.label'
            defaultMessage='is relative'
          />
        }
      />
    </Fragment>
  );
}
