import React, { FunctionComponent } from 'react';

import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import { FieldLabel } from './FieldLabel';

const useStyles = makeStyles(() =>
  createStyles({
    formControl: {
      minWidth: 252,
    },
  })
);

export interface FieldTextInputData {
  name: string;
  value?: string;
}

export interface FieldTextInputProps extends FieldTextInputData {
  onChange?: (event: FieldTextInputData) => void;
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  label?: React.ReactNode;
  helpText?: React.ReactNode;
  disabled?: boolean;
  required?: boolean;
  readOnly?: boolean;
  placeholder?: string;
  error?: string;
  endAdornment?: React.ReactNode;
  type?: 'text' | 'password';
  multiline?: boolean;
  autoFocus?: boolean;
}

export const FieldTextInput: FunctionComponent<FieldTextInputProps> = (
  props: FieldTextInputProps
) => {
  const theme = useTheme<Theme>();
  const classes = useStyles(theme);

  const handleChange = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: string | undefined;
    }>
  ) => {
    if (props.onChange) {
      props.onChange({ name: props.name, value: event.target.value });
    }
  };

  return (
    <TextField
      autoFocus={props.autoFocus}
      multiline={props.multiline}
      id={props.name}
      variant='outlined'
      className={classes.formControl}
      required={props.required}
      disabled={props.disabled}
      error={!!props.error}
      label={<FieldLabel label={props.label} helpText={props.helpText} />}
      name={props.name}
      value={props.value ? props.value : ''}
      onChange={handleChange}
      onBlur={props.onBlur}
      InputProps={{
        endAdornment: props.endAdornment,
        readOnly: props.readOnly,
      }}
      type={props.type || 'text'}
      helperText={props.error}
      margin='dense'
    />
  );
};
