import React, { PropsWithChildren, useState } from 'react';

import { cs, de, en } from '../translation.json';

const locales = ['en', 'cs', 'de'];

export interface LocaleContextInterface {
  locale: string;
  locales: string[];
  setLocale: (locale: string) => void;
  messages?: Record<string, string>;
}

export const LocaleContext = React.createContext<LocaleContextInterface>({
  locale: 'cs',
  locales,
  setLocale: (_locale: string) => {
    throw new Error('AppContext is not initialized');
  },
});

export function LocaleContextProvider(
  props: PropsWithChildren<unknown>
): JSX.Element {
  const [locale, setLocale] = useState<string>(
    localStorage.getItem('yp-console-locale') || 'en'
  );

  let messages;

  switch (locale) {
    case 'cs': {
      messages = cs;
      break;
    }
    case 'de': {
      messages = de;
      break;
    }

    case 'en': {
      messages = en;
      break;
    }
    default:
      messages = undefined;
  }

  const setLocaleOverride = (l: string) => {
    setLocale(l);
    localStorage.setItem('yp-console-locale', l);
  };

  return (
    <LocaleContext.Provider
      value={{ locale, setLocale: setLocaleOverride, locales, messages }}
    >
      {props.children}
    </LocaleContext.Provider>
  );
}
