import React from 'react';
import { useTemplateUI } from '../../useTemplateUI';
import { EditLogoText } from '../EditLogoText';
import AddFieldButton from '../fields/AddFieldButton';
import { getIn, useFormikContext } from 'formik';
import {
  ImageTypes,
  PkpassBarcodeTypes,
  PkpassFieldsType,
  PkpassPassStyleTypes,
  Template,
} from '../../../../../types/Template';
import { getPassStyle } from '../../helpers/passStyle';
import EditImageButton from '../EditImageButton';

export type ControlLayoutProps = {
  disableFields?: boolean;
  disableImages?: boolean;
  disableBarcodes?: boolean;
};

export const ControlLayout = (props: ControlLayoutProps): JSX.Element => {
  const templateUI = useTemplateUI();

  const { values } = useFormikContext<Template>();

  const passStyle = getPassStyle(values);
  const editFields = props.disableFields !== true;
  const editBarcodes = props.disableBarcodes !== true;
  const editImages = props.disableImages !== true;

  const headerFields = getIn(
    values,
    `jsonTemplate.${passStyle}.properties.${PkpassFieldsType.HeaderFields}.items`,
    []
  );

  const primaryFields = getIn(
    values,
    `jsonTemplate.${passStyle}.properties.${PkpassFieldsType.PrimaryFields}.items`,
    []
  );

  const secondaryFields = getIn(
    values,
    `jsonTemplate.${passStyle}.properties.${PkpassFieldsType.SecondaryFields}.items`,
    []
  );

  const auxiliaryFields = getIn(
    values,
    `jsonTemplate.${passStyle}.properties.${PkpassFieldsType.AuxiliaryFields}.items`,
    []
  );

  const barcodes = getIn(values, `jsonTemplate.barcodes.items`, []);

  const isSquered =
    barcodes.length === 0 ||
    barcodes[0].properties.format.value ===
      PkpassBarcodeTypes.PKBarcodeFormatAztec ||
    barcodes[0].properties.format.value ===
      PkpassBarcodeTypes.PKBarcodeFormatQR;
  const totalCount = secondaryFields.length + auxiliaryFields.length;

  const hasStripImage = !!(values.images && values.images[ImageTypes.strip]);
  const hasThumbnailImage = !!(
    values.images && values.images[ImageTypes.thumbnail]
  );
  const hasBackgroundImage = !!(
    values.images && values.images[ImageTypes.background]
  );

  return (
    <>
      {editFields && (
        <>
          <div
            style={{ position: 'absolute', top: 16, left: 40, zIndex: 1000 }}
          >
            <EditLogoText />
          </div>
          {3 > headerFields.length && (
            <div
              style={{
                position: 'absolute',
                top: 16,
                right: -18,
              }}
            >
              <AddFieldButton fieldsType={PkpassFieldsType.HeaderFields} />
            </div>
          )}

          {primaryFields.length === 0 && (
            <div
              style={{
                position: 'absolute',
                left: -18,
                top: 70,
              }}
            >
              <AddFieldButton fieldsType={PkpassFieldsType.PrimaryFields} />
            </div>
          )}

          {PkpassPassStyleTypes.BoardingPass === passStyle &&
            primaryFields.length < 2 && (
              <div
                style={{
                  position: 'absolute',
                  right: -18,
                  top: 70,
                }}
              >
                <AddFieldButton fieldsType={PkpassFieldsType.PrimaryFields} />
              </div>
            )}

          {(PkpassPassStyleTypes.StoreCard === passStyle ||
            PkpassPassStyleTypes.Coupon === passStyle) &&
            totalCount < 4 && (
              <>
                <div
                  style={{
                    position: 'absolute',
                    left: -18,
                    paddingTop: 8,
                    top: 180,
                  }}
                >
                  <AddFieldButton
                    fieldsType={PkpassFieldsType.SecondaryFields}
                  />
                </div>
                <div
                  style={{
                    position: 'absolute',
                    right: -18,
                    paddingTop: 8,
                    top: 180,
                  }}
                >
                  <AddFieldButton
                    fieldsType={PkpassFieldsType.AuxiliaryFields}
                  />
                </div>
              </>
            )}

          {PkpassPassStyleTypes.StoreCard !== passStyle &&
            PkpassPassStyleTypes.Coupon !== passStyle && (
              <>
                {secondaryFields.length < 4 && (
                  <div
                    style={{
                      position: 'absolute',
                      right: -18,
                      paddingTop: 8,
                      top:
                        PkpassPassStyleTypes.BoardingPass !== passStyle
                          ? 150
                          : 210,
                    }}
                  >
                    <AddFieldButton
                      fieldsType={PkpassFieldsType.SecondaryFields}
                    />
                  </div>
                )}
                {auxiliaryFields.length < 4 && (
                  <div
                    style={{
                      position: 'absolute',
                      right: -18,
                      paddingTop: 8,
                      top:
                        PkpassPassStyleTypes.BoardingPass !== passStyle
                          ? 210
                          : 150,
                    }}
                  >
                    <AddFieldButton
                      fieldsType={PkpassFieldsType.AuxiliaryFields}
                    />
                  </div>
                )}
              </>
            )}
        </>
      )}
      {editBarcodes && barcodes.length === 0 && (
        <div
          style={{
            position: 'absolute',
            height: 160,
            width: 130,
            bottom: 10,
            left: 95,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            backgroundColor: '#fff',
          }}
          onClick={() => {
            templateUI.uiSetTemplateForceOpenBarcode(true);
          }}
        >
          Add barcode
        </div>
      )}

      {editImages && (
        <>
          <div
            style={{
              position: 'absolute',
              top: 16,
              left: 8,
            }}
          >
            <EditImageButton
              imageType={ImageTypes.logo}
              onClick={() => {
                templateUI.uiSetTemplateForceOpenImage(ImageTypes.logo);
              }}
            />
          </div>
          {(PkpassPassStyleTypes.StoreCard === passStyle ||
            PkpassPassStyleTypes.Coupon === passStyle) && (
            <div
              style={{
                position: 'absolute',
                top: 110,
                left: 146,
              }}
            >
              <EditImageButton
                imageType={ImageTypes.strip}
                onClick={() => {
                  templateUI.uiSetTemplateForceOpenImage(ImageTypes.strip);
                }}
              />
            </div>
          )}

          {PkpassPassStyleTypes.Generic === passStyle && (
            <div
              style={{
                position: 'absolute',
                top: 95,
                right: 52,
              }}
            >
              <EditImageButton
                imageType={ImageTypes.thumbnail}
                onClick={() => {
                  templateUI.uiSetTemplateForceOpenImage(ImageTypes.thumbnail);
                }}
              />
            </div>
          )}

          {PkpassPassStyleTypes.BoardingPass === passStyle && (
            <div
              style={{
                position: 'absolute',
                top: isSquered ? 264 : 308, // TODO rectangle
                left: 146,
              }}
            >
              <EditImageButton
                imageType={ImageTypes.footer}
                onClick={() => {
                  templateUI.uiSetTemplateForceOpenImage(ImageTypes.footer);
                }}
              />
            </div>
          )}

          {PkpassPassStyleTypes.EventTicket === passStyle && (
            <>
              <div
                style={{
                  position: 'absolute',
                  top: 95,
                  left: 146,
                }}
              >
                <EditImageButton
                  imageType={ImageTypes.strip}
                  onClick={() => {
                    templateUI.uiSetTemplateForceOpenImage(ImageTypes.strip);
                  }}
                  disabled={hasBackgroundImage || hasThumbnailImage}
                />
              </div>
              <div
                style={{
                  position: 'absolute',
                  top: 95,
                  right: 52,
                }}
              >
                <EditImageButton
                  imageType={ImageTypes.thumbnail}
                  onClick={() => {
                    templateUI.uiSetTemplateForceOpenImage(
                      ImageTypes.thumbnail
                    );
                  }}
                  disabled={hasStripImage}
                />
              </div>
              <div
                style={{
                  position: 'absolute',
                  bottom: -18,
                  left: 146,
                }}
              >
                <EditImageButton
                  disabled={hasStripImage}
                  imageType={ImageTypes.background}
                  onClick={() => {
                    templateUI.uiSetTemplateForceOpenImage(
                      ImageTypes.background
                    );
                  }}
                />
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};
