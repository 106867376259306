import React from 'react';

import { useCacheResource } from '../../hooks';
import { ResourceTypes } from '../../utils/services/api';
import useOpen from '../../hooks/useOpen';
import { PkpassCertificateEditDrawer } from './PkpassCertificateEditDrawer';
import {
  LabelEditLink,
  LabelEditLinkChildrenProps,
} from '../../components/common/buttons';

const PkpassCertificateLabelWithDrawer = (props: {
  resourceId: string;
}): JSX.Element => {
  const { data, loading } = useCacheResource(
    ResourceTypes.CERTIFICATE,
    props.resourceId
  );
  const oi = useOpen(false);

  if (loading) return <span />;

  return (
    <LabelEditLink
      title={data?.name || props.resourceId}
      oi={oi}
      path={'/passes/certificate' + props.resourceId}
    >
      {(p: LabelEditLinkChildrenProps) => {
        return (
          <PkpassCertificateEditDrawer
            id={props.resourceId}
            open={oi.open}
            onClose={p.onClose}
          />
        );
      }}
    </LabelEditLink>
  );
};

export default PkpassCertificateLabelWithDrawer;
