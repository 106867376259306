import React, { Fragment } from 'react';
import { GoogleMap, LoadScript, Circle, Marker } from '@react-google-maps/api';
import { GOOGLE_MAP_API_KEY } from '../../../utils/services/environment';

export const DEFAULT_LAT = 50.0369421;
export const DEFAULT_LNG = 14.493124599999987;

interface Props {
  onChange: (lat: number, lng: number) => void;
  lat: number;
  lng: number;
  radius?: number;
}

export default function FieldGoogleMap(props: Props) {
  const { lat, lng, radius, onChange } = props;

  const onClickMap = (
    e: google.maps.MouseEvent | google.maps.IconMouseEvent
  ) => {
    onChange(e.latLng.lat(), e.latLng.lng());
  };

  const containerStyle = {
    width: '400px',
    height: '400px',
  };

  return (
    <LoadScript googleMapsApiKey={GOOGLE_MAP_API_KEY}>
      <GoogleMap
        zoom={15}
        center={{
          lat: lat || DEFAULT_LAT,
          lng: lng || DEFAULT_LNG,
        }}
        onClick={onClickMap}
        mapContainerStyle={containerStyle}
      >
        {lat !== undefined && lng !== undefined && (
          <Fragment>
            <Marker
              draggable={true}
              position={{ lat, lng }}
              onDragEnd={onClickMap}
            />
            {radius && (
              <Circle
                center={{
                  lat,
                  lng,
                }}
                radius={radius}
                options={{
                  fillColor: 'blue',
                  fillOpacity: 0.1,
                  strokeColor: 'blue',
                  strokeOpacity: 0.5,
                  strokeWeight: 1,
                }}
              />
            )}
          </Fragment>
        )}
      </GoogleMap>
    </LoadScript>
  );
}
