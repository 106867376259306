import moment from 'moment';
import { flatten } from './flatten';
import { ListComplete, ListResponse, Order } from '../../types/common/list';
import { Query } from '../../types/common/query';
import { User } from '../../types/User';
import { CORE_API_URL, LOGIN_URL } from './environment';
import fetch from './fetch';
import XLSX from 'xlsx';
import { getCsrf } from '../csrf';
import { ViewerOptions } from '../../types/Viewer';

export const API_LIMIT = 1000;
export enum ResourceTypes {
  CERTIFICATE = 'certificate',
  PROJECT = 'project',
  TEMPLATE = 'template',
  USER = 'user',
  PASS = 'pass',
  IMAGE = 'image',
}

export function forgotPassword(): void {
  const currentPath = window.location.pathname;
  const root = window.location.href.replace(
    currentPath !== '/' ? currentPath : '',
    ''
  );
  window.location.replace(`${LOGIN_URL}?redirect=${root}&reset`);
}

export function signUp(): void {
  const currentPath = window.location.pathname;
  const root = window.location.href.replace(
    currentPath !== '/' ? currentPath : '',
    ''
  );
  window.location.replace(`${LOGIN_URL}?redirect=${root}&signup`);
}

function processHttpResponse(resp: Response) {
  if (resp.status === 401) {
    window.location.replace(`${LOGIN_URL}?redirect=${window.location}`);
  }
  if (resp.status < 200 || resp.status >= 300) {
    return Promise.reject(resp);
  }

  const contentType = resp.headers.get('Content-Type');
  if (contentType) {
    if (contentType.includes('application/json')) {
      return resp.json();
    }
    if (contentType.includes('plain/text')) {
      return resp.text();
    }
    if (contentType.includes('csv')) {
      return resp.blob();
    }
  }
  return resp;
}

export function doRequest(
  method: string,
  url: RequestInfo,
  payload: any = {},
  opts?: RequestInit
) {
  const options: RequestInit = opts || {};
  options.credentials = 'include';

  const log = () => {
    console.log(`abort: ${url}`);
  };
  // comment for debug aborting request
  if (process.env.NODE_ENV !== 'production') {
    if (opts && opts.signal) {
      opts.signal.addEventListener('abort', log);
    }
  }

  if (
    (payload.constructor === Object && Object.keys(payload).length !== 0) ||
    payload instanceof Array
  ) {
    options.body = JSON.stringify(payload);
  }

  const csrf = getCsrf();
  if (csrf) {
    options.headers = { ...options.headers, 'X-Csrf-Token': csrf };
  }
  const fetchPromise = fetch(url, {
    ...options,
    method,
  }).then((r) => {
    // comment for debugging request
    if (process.env.NODE_ENV !== 'production') {
      if (opts && opts.signal) {
        opts.signal.removeEventListener('abort', log);
      }
    }
    return r;
  });

  return fetchPromise;
}

function http(
  method: string,
  url: RequestInfo,
  payload: any = {},
  opts?: RequestInit
) {
  return doRequest(method, url, payload, opts).then(processHttpResponse);
}

export function getResourceRoutePrefix(resource: string) {
  if (resource === 'user' || resource === 'project') {
    return 'identity';
  }
  return 'passes';
}

export function read(resource: ResourceTypes, id: string, opts?: RequestInit) {
  return http('GET', `${CORE_API_URL}/v1/${resource}/${id}`, undefined, opts);
}

export function create(resource: ResourceTypes, payload: any) {
  return http('POST', `${CORE_API_URL}/v1/${resource}`, payload);
}

export function update(resource: ResourceTypes, id: string, payload: any) {
  return http('PUT', `${CORE_API_URL}/v1/${resource}/${id}`, payload);
}

export function remove(resource: ResourceTypes, id: string) {
  return http('DELETE', `${CORE_API_URL}/v1/${resource}/${id}`);
}

export function list<T>(
  resource: ResourceTypes,
  opts: ListParams,
  requestOpts?: RequestInit
): Promise<ListResponse<T>> {
  const order = opts.order;
  const orderBy = opts.orderBy;
  const page = opts.page;
  const limit = opts.limit;

  const query = opts.query || { deletedAt: null };

  let resourceQuery = `${CORE_API_URL}/v1/${resource}?where=${encodeURIComponent(
    JSON.stringify(query)
  )}&page=${page || 1}&limit=${limit || 10}&order=${order || 'asc'}&orderBy=${
    orderBy || 'id'
  }`;

  resourceQuery += opts.ignoreSuppressCount ? `` : `&suppressCount`;

  return http('GET', resourceQuery, undefined, requestOpts).then((response) => {
    // replace null to empty array
    if (response.data === null) {
      response.data = [];
    }
    return response;
  });
}

export interface ListParams {
  order?: Order;
  orderBy?: string;
  page?: number;
  limit?: number;
  query: Query;
  ignoreSuppressCount?: boolean;
}

export const listComplete = async <T>(
  resource: ResourceTypes,
  opts?: ListParams,
  requestOpts?: RequestInit
) => {
  const initializedOpts: ListParams = {
    ignoreSuppressCount: opts ? opts.ignoreSuppressCount : undefined,
    limit: API_LIMIT,
    order: (opts && opts.order) || Order.ASC,
    orderBy: (opts && opts.orderBy) || 'id',
    query: opts && opts.query,
  };
  const allData: T[] = [];
  let page = 1;
  let hasMore = true;
  while (hasMore) {
    const partialResponse = await list<T>(
      resource,
      {
        page,
        ...initializedOpts,
      },
      requestOpts
    );
    partialResponse.data.forEach((i: T) => {
      allData.push(i);
    });
    if (partialResponse.data.length < API_LIMIT) {
      hasMore = false;
    }

    page = page + 1;
  }

  const response: ListComplete<T> = {
    data: allData,
  };
  return response;
};

export async function count<T>(
  resource: ResourceTypes,
  action: ListParams,
  requestOpts?: RequestInit
): Promise<number> {
  const firstPage: ListResponse<T> = await list<T>(
    resource,
    {
      ...action,
      ignoreSuppressCount: true,
      limit: 1,
      page: 1,
    },
    requestOpts
  );

  return firstPage.totalCount ? firstPage.totalCount : 0;
}

export type ProgressStats = { current: number; total: number };

export async function listExport(
  action: ListParams & {
    resource: ResourceTypes;
    exportHeaders?: string[] | undefined;
  },
  callback: (p: ProgressStats) => void | undefined
) {
  const { resource, exportHeaders, ...opts } = action;

  const totalCount = await count(resource, { query: opts.query });

  const numberOfParts = Math.ceil(totalCount / API_LIMIT);
  const exportNumber = moment(new Date()).format('YYYY-DD-MM_HH-mm');

  let i = 0;
  const allData: unknown[] = [];

  while (i < numberOfParts) {
    callback({ current: i, total: numberOfParts });
    const resourceQuery = `${CORE_API_URL}/v1/${resource}?where=${encodeURIComponent(
      JSON.stringify(opts.query)
    )}&page=${i + 1}&limit=${API_LIMIT}&order=${
      action.order || 'asc'
    }&orderBy=${action.orderBy || 'updatedAt'}&suppressCount=true`;

    const res = await http('GET', resourceQuery);
    const json = await res.json();

    if (json && json.data) {
      json.data.forEach((i: unknown) => {
        allData.push(i);
      });
    }

    i += 1;
  }

  const flatted = allData.map((item: unknown) => {
    return flatten(item);
  });

  const ws = XLSX.utils.json_to_sheet(flatted, { header: exportHeaders });
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'passes');
  XLSX.writeFile(wb, `${resource}-${exportNumber}.xlsx`);
}

export function getViewer(opts?: RequestInit): Promise<ViewerOptions> {
  return http('GET', `${CORE_API_URL}/v1/user/me`, {}, opts);
}

export function changePassword(currentPassword: string, newPassword: string) {
  return http('POST', `${CORE_API_URL}/v1/user/me/changePassword`, {
    currentPassword,
    newPassword,
  });
}

// TODO define reportTypes
export function reportFetch(serviceType: any, query: any, opts?: RequestInit) {
  const from = query.from instanceof Date ? query.from.toJSON() : query.from;
  const to = query.to instanceof Date ? query.to.toJSON() : query.to;

  const resourceQuery = `${CORE_API_URL}/v1/report/${serviceType}?where=${encodeURIComponent(
    JSON.stringify(query.where)
  )}&from=${encodeURIComponent(from)}&to=${encodeURIComponent(to)}&interval=${
    query.interval
  }`;

  return http('GET', resourceQuery, {}, opts);
}

export function templateUpdatePassesByPush(templateId: string) {
  const url = `${CORE_API_URL}/v1/template/${templateId}/push`;
  return http('GET', url, {});
}

export function templateUpdateSaveToGooglePay(templateId: string) {
  const url = `${CORE_API_URL}/v1/template/${templateId}/savetogooglepay`;
  return http('GET', url, {});
}

export type BatchItemResponse = {
  status: { code: number };
  data: unknown;
};

export async function batch(
  resource: ResourceTypes,
  payload: any[],
  callback?: (p: ProgressStats) => void | undefined
): Promise<BatchItemResponse[]> {
  const url = `${CORE_API_URL}/v1/${resource}/batch`;
  const totalCount = payload.length;

  const BATCH_SIZE = 100;
  const numberOfParts = Math.ceil(totalCount / BATCH_SIZE);
  let i = 0;

  const allData: BatchItemResponse[] = [];

  while (i < numberOfParts) {
    if (callback) {
      callback({ current: i, total: numberOfParts });
    }
    const from = i * BATCH_SIZE;
    const to = (i + 1) * BATCH_SIZE;
    const b = payload.slice(from, to);
    const json = await http('POST', url, b);

    if (json) {
      json.forEach((i: BatchItemResponse) => {
        allData.push(i);
      });
    }

    i += 1;
  }

  return allData;
}

export function setUserProjectAccessRights(
  projectId: string,
  userId: string,

  accessRights: number | null
) {
  const payload = { accessRights };
  return http(
    'POST',
    `${CORE_API_URL}/v1/project/${projectId}/user/${userId}/rights`,
    payload
  );
}

export function findUserByEmail(email: string): Promise<User> {
  return http('GET', `${CORE_API_URL}/v1/user/byEmail/${email}`);
}

export default http;
