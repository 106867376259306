import { FormikProps } from 'formik';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { CreateButton } from '../../components/common/buttons';
import FormikCreateDrawer from '../../components/common/formik/FormikCreateDrawer';
import { ResourceTypes } from '../../utils/services/api';
import {
  CertificateFormSchema,
  PkpassCertificateFormikForm,
  PkpassCertificateFormInterface,
} from './PkpassCertificateFormikForm';
import { OnCreateProps } from '../../types';

export const PkpassCertificateCreateDrawer = (
  props: OnCreateProps
): JSX.Element => {
  const renderForm = (p: FormikProps<PkpassCertificateFormInterface>) => {
    return <PkpassCertificateFormikForm formik={p} />;
  };

  return (
    <FormikCreateDrawer
      title={
        <FormattedMessage
          id='pkpass.certificate.drawer.new.title'
          defaultMessage='New certificate'
        />
      }
      initialValues={{}}
      validationSchema={CertificateFormSchema}
      resource={ResourceTypes.CERTIFICATE}
      trigger={
        <CreateButton>
          <FormattedMessage
            id='pkpass.certificate.drawer.new.trigger'
            defaultMessage='Create new certificate'
          />
        </CreateButton>
      }
      renderForm={renderForm}
      onCreate={props.onCreate}
    />
  );
};
