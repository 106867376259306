import * as chartjs from 'chart.js';
import React, { useEffect, useState } from 'react';
import { Line as LineChart } from 'react-chartjs-2';
import { LineLegend } from './Legend';

type LineChartOption = chartjs.ChartOptions;

type ChartDataFunction<T extends chartjs.ChartData> = (
  element: HTMLElement
) => T;
type ChartData<T extends chartjs.ChartData> = ChartDataFunction<T> | T;

interface Props {
  data: ChartData<chartjs.ChartData>;
  text?: string;
  withWhole?: boolean;
}

export function Line({ text, data, withWhole }: Props): JSX.Element {
  const additionalOption = withWhole
    ? { rotation: 0.75 * Math.PI, circumference: 1.5 * Math.PI }
    : {};

  const [ref, setRef] = useState<LineChart | null>(null);

  const [legend, setLegend] = useState<JSX.Element | null>(null);

  useEffect(() => {
    if (ref) {
      ref.chartInstance.generateLegend();
    }
  }, [ref, data]);

  const opts: LineChartOption = {
    legend: {
      display: false,
      position: 'bottom',
      labels: { boxWidth: 12 },
      align: 'center',
    },
    legendCallback: (chart: Chart): string => {
      setLegend(<LineLegend chart={chart} />);
      return '';
    },
    title: { text: text || '', position: 'bottom', display: false },
    ...additionalOption,
  };

  return (
    <div>
      <LineChart
        ref={(element) => setRef(element)}
        options={opts}
        data={data}
      />
      {legend}
    </div>
  );
}
