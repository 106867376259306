import React, { FunctionComponent } from 'react';

import { FieldHelpText } from './FieldHelpText';

export interface Props {
  label?: React.ReactNode;
  helpText?: React.ReactNode;
}

export const FieldLabel: FunctionComponent<Props> = (props: Props) => {
  return (
    <span>
      <FieldHelpText helpText={props.helpText} /> {props.label}
    </span>
  );
};
