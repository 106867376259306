import React, { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';

import { ImageTypes, imageTypes } from '../../../types/Template';
import messages from '../../image/messages';
import { StaticLabel } from '../labels';
import {
  FieldSelect,
  FieldSelectOption,
  FieldSelectProps,
} from './FieldSelect';

export const FieldSelectImageType: FunctionComponent<FieldSelectProps> = (
  props: FieldSelectProps
) => {
  const intl = useIntl();

  const opts: FieldSelectOption[] = imageTypes.map((t: ImageTypes) => ({
    key: t as string,
    text: intl.formatMessage(messages[t]),
    value: t as string,
  }));
  return (
    <FieldSelect label={StaticLabel.ImageType} {...props} options={opts} />
  );
};
