import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import Card from '@material-ui/core/Card';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Page from '../../components/common/page/Page';
import { withReport } from '../../context/';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';

interface LinkData {
  url: string;
  count: number;
}
interface Props {
  data: LinkData[];
}

const LinksReport = (props: Props): JSX.Element => {
  const { data } = props;
  return (
    <Fragment>
      <Card>
        <CardHeader title='Links action count' />
        <CardContent>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Link</TableCell>
                <TableCell>Count</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((v: LinkData) => (
                <TableRow key={v.url}>
                  <TableCell>{v.url}</TableCell>
                  <TableCell>{`${v.count}`}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </Fragment>
  );
};

const Report = withReport<LinkData[]>(LinksReport, 'links');

export const LinksPage = (): JSX.Element => {
  return (
    <Page
      content={<Report />}
      title={
        <FormattedMessage
          id='LinksPage.title'
          defaultMessage='Links action overview'
        />
      }
    />
  );
};
