import { FormikProps } from 'formik';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import Tooltip from '@material-ui/core/Tooltip';

import { DynamicDataIcon } from '../../common/icons';
import { TabOption } from '../../common/Tabs';
import { Template } from '../../../types/Template';
import DynamicDataForm from './DynamicDataForm';
import { dynamicDataEditorTitle } from './mesages';

export default function (props: FormikProps<Template>): TabOption {
  const hasError = false;
  return {
    content: <DynamicDataForm {...props} />,
    error: hasError,
    icon: <DynamicDataIcon />,
    key: 'dynamicData',
    name: (
      <Tooltip
        title={
          <FormattedMessage
            id='TemplateEditTabs.dynamicData.tooltip'
            defaultMessage='Manage dynamic data properties.'
          />
        }
      >
        <span>{dynamicDataEditorTitle}</span>
      </Tooltip>
    ),
  };
}
