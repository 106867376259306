import React, { PropsWithChildren, useState } from 'react';

import {
  ImageTypes,
  PkpassFieldsType,
  Template,
} from '../../../types/Template';
import FieldEditDialog from './designer/fields/field/edit/FieldEditDialog';
import BarcodeDialog from './designer/barcode/BarcodeDialog';
import { EditImageDialog } from './designer/EditImageDialog';
import {
  JsonSchemaFieldProps,
  getColorDynamicDataFields,
  getDateDynamicDataFields,
  getNumberDynamicDataFields,
  getTextDynamicDataFields,
} from '../../common/jsonSchema/';
import { useFormikContext } from 'formik';

export interface TemplateUIState {
  templatePreviewLanguage: string;
  templateListValidation: boolean;
  field: {
    key: string;
    type: PkpassFieldsType;
  } | null;
  image: ImageTypes | null;
  openBarcodes: boolean;
  openBoardingPassIcon: boolean;
}

export interface TemplateUIContextInterface extends TemplateUIState {
  uiSetTemplatePreviewLanguage(value: string): void;
  uiSetTemplateForceOpenField(
    value: { key: string; type: PkpassFieldsType } | null
  ): void;
  uiSetTemplateForceOpenBarcode(v: boolean): void;
  uiSetTemplateForceOpenBoardingPassIcon(v: boolean): void;
  uiSetTemplateForceOpenImage(v: null | ImageTypes): void;
  colorShortCodes: JsonSchemaFieldProps[];
  numberShortCodes: JsonSchemaFieldProps[];
  dateShortCodes: JsonSchemaFieldProps[];
  textShortCodes: JsonSchemaFieldProps[];
}

export const TemplateUIContext = React.createContext<
  TemplateUIContextInterface
>({
  field: null,
  image: null,
  openBarcodes: false,
  openBoardingPassIcon: false,
  templateListValidation: false,
  templatePreviewLanguage: 'en',
  colorShortCodes: [],
  numberShortCodes: [],
  dateShortCodes: [],
  textShortCodes: [],

  uiSetTemplatePreviewLanguage() {
    throw new Error('AppContext method is note defined');
  },

  uiSetTemplateForceOpenField() {
    throw new Error('AppContext method is note defined');
  },
  uiSetTemplateForceOpenBarcode() {
    throw new Error('AppContext method is note defined');
  },
  uiSetTemplateForceOpenBoardingPassIcon() {
    throw new Error('AppContext method is note defined');
  },
  uiSetTemplateForceOpenImage() {
    throw new Error('AppContext method is note defined');
  },
});

const getInitState = (t: Template) => {
  return {
    field: null,
    image: null,
    openBarcodes: false,
    openBoardingPassIcon: false,
    templateListValidation: false,
    templatePreviewLanguage: t.languages[0],
  };
};

export function TemplateUIContextProvider(
  props: PropsWithChildren<{ initValues: Template }>
): JSX.Element {
  const [state, setState] = useState<TemplateUIState>(
    getInitState(props.initValues)
  );

  const updateState = (value: unknown, propertyName: string) => {
    setState((prevState: TemplateUIState) => {
      return {
        ...prevState,
        [propertyName]: value,
      };
    });
  };

  const uiSetTemplatePreviewLanguage = (value: string) => {
    updateState(value, 'templatePreviewLanguage');
  };

  const uiSetTemplateForceOpenField = (
    f: { key: string; type: PkpassFieldsType } | null
  ) => {
    setState({
      ...state,
      field: f,
    });
  };

  const uiSetTemplateForceOpenBarcode = (f: boolean) => {
    setState({
      ...state,
      openBarcodes: f,
    });
  };

  const uiSetTemplateForceOpenBoardingPassIcon = (f: boolean) => {
    setState({
      ...state,
      openBoardingPassIcon: f,
    });
  };

  const uiSetTemplateForceOpenImage = (f: null | ImageTypes) => {
    setState({
      ...state,
      image: f,
    });
  };

  const { values } = useFormikContext<Template>();

  const c = {
    ...state,
    uiSetTemplateForceOpenField,
    uiSetTemplatePreviewLanguage,
    uiSetTemplateForceOpenBarcode,
    uiSetTemplateForceOpenBoardingPassIcon,
    uiSetTemplateForceOpenImage,
    colorShortCodes: getColorDynamicDataFields(values.dynamicDataJsonSchema),
    numberShortCodes: getNumberDynamicDataFields(values.dynamicDataJsonSchema),
    dateShortCodes: getDateDynamicDataFields(values.dynamicDataJsonSchema),
    textShortCodes: getTextDynamicDataFields(values.dynamicDataJsonSchema),
  };
  return (
    <>
      <TemplateUIContext.Provider value={c}>
        {props.children}
        <FieldEditDialog />
        <BarcodeDialog />
        <EditImageDialog />
      </TemplateUIContext.Provider>
    </>
  );
}

export interface InjectedTemplateUIProps {
  templateUI: TemplateUIContextInterface;
}
