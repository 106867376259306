import { useFormikContext } from 'formik';
import React from 'react';

import { Template } from '../../../../types/Template';
import { JsonSchemaEditor } from '../../../common/jsonSchema/edit/JsonSchemaEditor';
import { get } from 'lodash';
import { Button, NextButton } from '../../../common/buttons';
import { VerticalStepperControls } from '../../../common/stepper';
import { FormattedMessage } from 'react-intl';

export const StepDynamicDataContent = (): JSX.Element => {
  const { setFieldValue, values } = useFormikContext<Template>();

  const handleChange = (d: Record<string, unknown>) => {
    setFieldValue('dynamicDataJsonSchema', d);
  };

  return (
    <JsonSchemaEditor
      onChange={handleChange}
      jsonSchema={values.dynamicDataJsonSchema}
    />
  );
};

export const StepDynamicDataActions = (props: {
  stepper: VerticalStepperControls;
}): JSX.Element => {
  const { errors, validateForm, setErrors } = useFormikContext<Template>();

  const projectIdErr = get(errors, 'projectId');
  const nameErr = get(errors, 'name');

  const handleNext = async () => {
    const errs = await validateForm();
    const err = get(errs, 'dynamicDataJsonSchema');
    setErrors({});
    if (!err) {
      props.stepper.next();
    }
  };

  return (
    <>
      <Button onClick={props.stepper.prev}>
        <FormattedMessage
          id='Template.wizard.actions.previous'
          defaultMessage='Previous'
        />
      </Button>
      {props.stepper && (
        <NextButton
          onClick={handleNext}
          disabled={!!(projectIdErr || nameErr)}
        />
      )}
    </>
  );
};
