import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';

import { Button } from '../../common/buttons';
import Drawer from '../../common/drawers/Drawer';
import EditDrawerInnerLayout from '../../common/drawers/EditDrawerInnerLayout';
import { AddIcon } from '../../common/icons';
import useOpen from '../../../hooks/useOpen';
import { ImageBulkStepper } from './ImageBulkStepper';

export const ImageBulkDrawer = () => {
  const open = useOpen(false);

  return (
    <Fragment>
      <Button
        startIcon={<AddIcon />}
        variant={'contained'}
        color={'primary'}
        onClick={open.handleOpen}
      >
        <FormattedMessage
          id='ImageImportModal.trigger'
          defaultMessage='Create new images'
        />
      </Button>
      <Drawer
        content={
          <EditDrawerInnerLayout
            content={<ImageBulkStepper open={open} />}
            title={
              <FormattedMessage
                id='ImageImportModal.drawer.title'
                defaultMessage='Create new images'
              />
            }
            onClose={open.handleClose}
          />
        }
        open={open.open}
      />
    </Fragment>
  );
};
