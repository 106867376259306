import React from 'react';

import Tooltip from '@material-ui/core/Tooltip';
import HelpIcon from '@material-ui/icons/Help';

export default function HelpPopUp(props: {
  content: Exclude<React.ReactNode, null | undefined>;
  style?: React.CSSProperties;
}) {
  return (
    <Tooltip title={props.content}>
      <HelpIcon
        fontSize='small'
        style={{
          cursor: 'pointer',
          marginBottom: 8,
          marginLeft: 4,
          opacity: 0.25,
          ...props.style,
        }}
      />
    </Tooltip>
  );
}
