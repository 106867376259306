import React, { FunctionComponent } from 'react';

import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

import { HelpIcon } from '../icons';

export interface Props {
  helpText?: React.ReactNode;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    position: {},
    trigger: {
      color: '#adadad',
      cursor: 'pointer',
      fontSize: '1em',
      marginRight: '0.2em',
      pointerEvents: 'auto',
      zIndex: 2000,
    },
  })
);

export const FieldHelpText: FunctionComponent<Props> = (props: Props) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  if (props.helpText) {
    return (
      <Tooltip title={props.helpText} className={classes.position}>
        <HelpIcon fontSize={'small'} className={classes.trigger} />
      </Tooltip>
    );
  }
  return null;
};
