import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import { useSnackbar } from 'notistack';
import { forgotPassword, signUp } from '../utils/services/api';
import LogoTextDark from '../assets/logo-text-dark.svg';
const innerForm = {
  width: 320,
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      height: '100vh',
    },
    logo: {
      width: 160,
    },
    content: {
      flexGrow: 1,
      justifyContent: 'center',
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(3),
    },
    paper: {
      margin: theme.spacing(4),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    textField: {
      width: innerForm.width,
    },
    label: {
      margin: 0,
      fontWeight: 'bold',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      marginTop: theme.spacing(1),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      alignSelf: 'center',
    },
    submit: {
      textTransform: 'none',
      fontWeight: 'bold',
      color: '#fff',
      width: innerForm.width,
      margin: theme.spacing(3, 0, 2),
    },
    forgotPass: {
      textAlign: 'center',
      width: innerForm.width,
    },
    footer: {
      backgroundColor: '#000000',
      color: '#fff',
      textAlign: 'center',
      width: '100%',
    },
  })
);

export function Login(): JSX.Element {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [username, setUsername] = React.useState('');
  const handleUsernameChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setUsername(event.target.value as string);
  };

  const [password, setPassword] = React.useState('');
  const handlePasswordChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setPassword(event.target.value as string);
  };

  const signIn = (e: React.FormEvent) => {
    e.preventDefault();
    const xhr = new XMLHttpRequest();
    xhr.onload = () => {
      switch (xhr.status) {
        case 200:
          return window.location.reload();
        case 401:
          return enqueueSnackbar('Invalid credentials', { variant: 'error' });
        default:
          return console.error(xhr.responseText);
      }
    };
    xhr.open('POST', '/auth/login');
    xhr.setRequestHeader('Content-Type', 'application/json; charset=utf-8');
    xhr.send(JSON.stringify({ email: username, password: password }));
  };

  const handleForgotPassword = () => {
    forgotPassword();
  };

  const handleSignUp = () => {
    signUp();
  };

  return (
    <div className={classes.root}>
      <main className={classes.content}>
        <Container component='main' maxWidth='sm'>
          <div className={classes.paper}>
            <img src={LogoTextDark} className={classes.logo} alt={'logo'} />
            <Box marginY={5}>
              <Typography variant='h5'>
                <Box textAlign='center' fontWeight='fontWeightBold'>
                  Log in
                </Box>
              </Typography>
              {/* <Typography variant='subtitle1'>
                <Box textAlign='center' fontWeight='fontWeightLight'>
                  This tool allows to manage pass and provides basic statistics
                  of their usage.
                </Box>
              </Typography> */}
            </Box>
            <Box className={classes.forgotPass}>
              Need a Yourpass account?&nbsp;
              <Link onClick={handleSignUp} variant='body2'>
                Create an account
              </Link>
            </Box>
            <form className={classes.form} noValidate onSubmit={signIn}>
              <TextField
                id='username'
                name='username'
                autoComplete='username'
                label='Email'
                value={username}
                onChange={handleUsernameChange}
                variant='outlined'
                size='medium'
                fullWidth
                margin='normal'
                required
                autoFocus
                className={classes.textField}
              />

              <TextField
                id='password'
                name='password'
                label='Password'
                autoComplete='current-password'
                value={password}
                onChange={handlePasswordChange}
                variant='outlined'
                size='medium'
                fullWidth
                margin='normal'
                className={classes.textField}
                required
                type='password'
              />

              <Button
                type='submit'
                variant='contained'
                color='primary'
                fullWidth
                size='large'
                className={classes.submit}
              >
                Login
              </Button>
              <Box className={classes.forgotPass}>
                <Link onClick={handleForgotPassword} variant='body2'>
                  Forgot password?
                </Link>
              </Box>
            </form>
            {/* <div className='info-container'>
              <p className='label'>
                Get more information about{' '}
                <a href='http://yourpass.eu' target='_blank'>
                  Yourpass
                </a>
                .
              </p>
              <p className='label'>
                Need help?{' '}
                <a href='http://yourpass.eu/#contacts' target='_blank'>
                  Contact us
                </a>
                .
              </p>
            </div> */}
          </div>
        </Container>
      </main>
      <div className={classes.footer}>
        <p>
          ©2018&nbsp;All&nbsp;Rights&nbsp;Reserved. Yourpass is a registered
          trademark of YOUR&nbsp;PASS,&nbsp;s.r.o.&nbsp;Terms.
        </p>
      </div>
    </div>
  );
}
