import React from 'react';
import { FormattedMessage } from 'react-intl';

import Page from '../../components/common/page/Page';
import { withReport } from '../../context/';
import BasePassOverview from '../../components/report/BasePassOverview';

const Report = withReport(BasePassOverview, 'register_in_wallet');

export const PassInWalletOverviewPage = (): JSX.Element => {
  return (
    <Page
      content={<Report />}
      title={
        <FormattedMessage
          id='PassInWalletOverviewPage.title'
          defaultMessage='Passes registered in wallet'
        />
      }
    />
  );
};
