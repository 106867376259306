import {
  JsonTemplate,
  PkpassBarcodes,
  PkpassBarcodeTypes,
  PkpassFields,
  PkpassPassStyleTypes,
  PkpassTextAlignTypes,
  PkpassTransitTypes,
} from '../../../../../../types/Template';

const barcodes: PkpassBarcodes = {
  items: [
    {
      properties: {
        altText: {
          type: 'string',
          value: '25282452568',
        },
        format: {
          type: 'string',
          value: PkpassBarcodeTypes.PKBarcodeFormatQR,
        },

        message: {
          type: 'string',
          value: '25282452568',
        },
        messageEncoding: {
          type: 'string',
          value: 'UTF-8',
        },
      },
      type: 'object',
    },
  ],
  type: 'array',
};

const organizationName: any = undefined;

const backFields: PkpassFields = {
  items: [
    {
      properties: {
        attributedValue: {
          type: 'string',
          value: 'Value',
        },
        key: {
          type: 'string',
          value: 'dcEn-oFgP',
        },
        label: {
          type: 'string',
          value: 'Label',
        },
        value: {
          type: 'string',
          value: 'Value',
        },
      },
      type: 'object',
    },
  ],
  type: 'array',
};

export const defaultPasses: {
  [PkpassPassStyleTypes.BoardingPass]: JsonTemplate;
  [PkpassPassStyleTypes.Coupon]: JsonTemplate;
  [PkpassPassStyleTypes.EventTicket]: JsonTemplate;
  [PkpassPassStyleTypes.Generic]: JsonTemplate;
  [PkpassPassStyleTypes.StoreCard]: JsonTemplate;
} = {
  [PkpassPassStyleTypes.BoardingPass]: {
    barcodes,
    labelColor: {
      type: 'string',
      value: 'rgb(0,0,0)',
    },
    description: {
      type: 'string',
      value: 'Description',
    },
    boardingPass: {
      type: 'object',
      properties: {
        backFields,
        transitType: {
          type: 'string',
          value: PkpassTransitTypes.PKTransitTypeAir,
        },
        headerFields: {
          type: 'array',
          items: [
            {
              type: 'object',
              properties: {
                key: {
                  type: 'string',
                  value: 'r3DQL-n7x',
                },
                label: {
                  type: 'string',
                  value: 'FLIGHT',
                },
                value: {
                  type: 'string',
                  value: 'BA0860',
                },
              },
            },
            {
              type: 'object',
              properties: {
                key: {
                  type: 'string',
                  value: '1kNl5BOH',
                },
                label: {
                  type: 'string',
                  value: 'SEAT',
                },
                value: {
                  type: 'string',
                  value: '16E',
                },
                textAlignment: {
                  type: 'string',
                  value: PkpassTextAlignTypes.PKTextAlignmentLeft,
                },
              },
            },
          ],
        },
        primaryFields: {
          type: 'array',
          items: [
            {
              type: 'object',
              properties: {
                key: {
                  type: 'string',
                  value: 'rUWl6mE2W',
                },
                label: {
                  type: 'string',
                  value: 'LONDON',
                },
                value: {
                  type: 'string',
                  value: 'LHR',
                },
              },
            },
            {
              type: 'object',
              properties: {
                key: {
                  type: 'string',
                  value: 'bxStNEqD',
                },
                label: {
                  type: 'string',
                  value: 'PRAGUE',
                },
                value: {
                  type: 'string',
                  value: 'PRG',
                },
              },
            },
          ],
        },
        auxiliaryFields: {
          type: 'array',
          items: [
            {
              type: 'object',
              properties: {
                key: {
                  type: 'string',
                  value: '1pHWDzSI',
                },
                label: {
                  type: 'string',
                  value: 'AL1_Label',
                },
                value: {
                  type: 'string',
                  value: 'AF1_Value',
                },
              },
            },
            {
              type: 'object',
              properties: {
                key: {
                  type: 'string',
                  value: 'eRObvJmR',
                },
                label: {
                  type: 'string',
                  value: 'Class',
                },
                value: {
                  type: 'string',
                  value: 'Euro Traveller',
                },
                textAlignment: {
                  type: 'string',
                  value: PkpassTextAlignTypes.PKTextAlignmentRight,
                },
              },
            },
          ],
        },
        secondaryFields: {
          type: 'array',
          items: [
            {
              type: 'object',
              properties: {
                key: {
                  type: 'string',
                  value: 'YxxJmiI4Y',
                },
                label: {
                  type: 'string',
                  value: 'NAME',
                },
                value: {
                  type: 'string',
                  value: 'Mr John Cole',
                },
              },
            },
            {
              type: 'object',
              properties: {
                key: {
                  type: 'string',
                  value: 'JqV-jFTU',
                },
                label: {
                  type: 'string',
                  value: 'GROUP',
                },
                value: {
                  type: 'string',
                  value: '4',
                },
                textAlignment: {
                  type: 'string',
                  value: PkpassTextAlignTypes.PKTextAlignmentRight,
                },
              },
            },
          ],
        },
      },
    },
    backgroundColor: {
      type: 'string',
      value: 'rgb(255,255,255)',
    },
    foregroundColor: {
      type: 'string',
      value: 'rgb(0,0,0)',
    },
    organizationName,
  },
  [PkpassPassStyleTypes.Coupon]: {
    coupon: {
      type: 'object',
      properties: {
        backFields,
        headerFields: {
          type: 'array',
          items: [
            {
              type: 'object',
              properties: {
                key: {
                  type: 'string',
                  value: 'r3DQL-n7x',
                },
                label: {
                  type: 'string',
                  value: 'COUPON',
                },
                value: {
                  type: 'string',
                  value: '50 €',
                },
                textAlignment: {
                  type: 'string',
                  value: PkpassTextAlignTypes.PKTextAlignmentRight,
                },
              },
            },
          ],
        },
        primaryFields: {
          type: 'array',
          items: [
            {
              type: 'object',
              properties: {
                key: {
                  type: 'string',
                  value: 't5EtFi36',
                },
                value: {
                  type: 'string',
                  value: '50€ DISCOUNT',
                },
              },
            },
          ],
        },
        auxiliaryFields: {
          type: 'array',
          items: [
            {
              type: 'object',
              properties: {
                key: {
                  type: 'string',
                  value: 'Miadaqe9',
                },
                label: {
                  type: 'string',
                  value: 'VALID TO',
                },
                value: {
                  type: 'string',
                  value: '1.1.2021',
                },
                textAlignment: {
                  type: 'string',
                  value: PkpassTextAlignTypes.PKTextAlignmentRight,
                },
              },
            },
          ],
        },
        secondaryFields: {
          type: 'array',
          items: [
            {
              type: 'object',
              properties: {
                key: {
                  type: 'string',
                  value: 'ZKHqDHzx',
                },
                label: {
                  type: 'string',
                  value: 'PRODUCT',
                },
                value: {
                  type: 'string',
                  value: 'PS 5 PRO 1TB',
                },
              },
            },
          ],
        },
      },
    },
    barcodes,
    labelColor: {
      type: 'string',
      value: 'rgb(0,0,0)',
    },
    description: {
      type: 'string',
      value: 'Description',
    },
    backgroundColor: {
      type: 'string',
      value: 'rgb(255,255,255)',
    },
    foregroundColor: {
      type: 'string',
      value: 'rgb(0,0,0)',
    },
    organizationName,
  },
  [PkpassPassStyleTypes.EventTicket]: {
    barcodes,
    labelColor: {
      type: 'string',
      value: 'rgb(0,0,0)',
    },
    description: {
      type: 'string',
      value: 'Description',
    },
    eventTicket: {
      type: 'object',
      properties: {
        backFields,
        headerFields: {
          type: 'array',
          items: [
            {
              type: 'object',
              properties: {
                key: {
                  type: 'string',
                  value: 'r3DQL-n7x',
                },
                label: {
                  type: 'string',
                  value: 'TICKET',
                },
                value: {
                  type: 'string',
                  value: '259 €',
                },
                textAlignment: {
                  type: 'string',
                  value: PkpassTextAlignTypes.PKTextAlignmentRight,
                },
              },
            },
          ],
        },
        primaryFields: {
          type: 'array',
          items: [],
        },
        auxiliaryFields: {
          type: 'array',
          items: [
            {
              type: 'object',
              properties: {
                key: {
                  type: 'string',
                  value: '1pHWDzSI',
                },
                label: {
                  type: 'string',
                  value: 'SECTOR',
                },
                value: {
                  type: 'string',
                  value: 'B5',
                },
              },
            },
            {
              type: 'object',
              properties: {
                key: {
                  type: 'string',
                  value: 'eRObvJmR',
                },
                label: {
                  type: 'string',
                  value: 'SEAT',
                },
                value: {
                  type: 'string',
                  value: '23',
                },
                textAlignment: {
                  type: 'string',
                  value: PkpassTextAlignTypes.PKTextAlignmentLeft,
                },
              },
            },
            {
              type: 'object',
              properties: {
                key: {
                  type: 'string',
                  value: 'Miadaqe9',
                },
                label: {
                  type: 'string',
                  value: 'ENTRANCE',
                },
                value: {
                  type: 'string',
                  value: 'GATE NORTH',
                },
                textAlignment: {
                  type: 'string',
                  value: PkpassTextAlignTypes.PKTextAlignmentRight,
                },
              },
            },
          ],
        },
        secondaryFields: {
          type: 'array',
          items: [
            {
              type: 'object',
              properties: {
                key: {
                  type: 'string',
                  value: 'ZKHqDHzx',
                },
                label: {
                  type: 'string',
                  value: 'EVENT',
                },
                value: {
                  type: 'string',
                  value: 'SLAVIA : BARCELONA',
                },
              },
            },
            {
              type: 'object',
              properties: {
                key: {
                  type: 'string',
                  value: 'WErW8LEq',
                },
                label: {
                  type: 'string',
                  value: 'WHEN',
                },
                value: {
                  type: 'string',
                  value: '1.1.19 18:00',
                },
                textAlignment: {
                  type: 'string',
                  value: PkpassTextAlignTypes.PKTextAlignmentRight,
                },
              },
            },
          ],
        },
      },
    },
    backgroundColor: {
      type: 'string',
      value: 'rgb(255,255,255)',
    },
    foregroundColor: {
      type: 'string',
      value: 'rgb(0,0,0)',
    },
    organizationName,
  },
  [PkpassPassStyleTypes.Generic]: {
    generic: {
      type: 'object',
      properties: {
        backFields,
        headerFields: {
          type: 'array',
          items: [
            {
              type: 'object',
              properties: {
                key: {
                  type: 'string',
                  value: 'r3DQL-n7x',
                },
                label: {
                  type: 'string',
                  value: 'MEMBERSHIP CARD',
                },
                value: {
                  type: 'string',
                  value: 'John Cole',
                },
                textAlignment: {
                  type: 'string',
                  value: PkpassTextAlignTypes.PKTextAlignmentRight,
                },
              },
            },
          ],
        },
        primaryFields: {
          type: 'array',
          items: [
            {
              type: 'object',
              properties: {
                key: {
                  type: 'string',
                  value: 'yGIoGleb',
                },
                value: {
                  type: 'string',
                  value: 'Mgr. John Cole',
                },
              },
            },
          ],
        },
        auxiliaryFields: {
          type: 'array',
          items: [
            {
              type: 'object',
              properties: {
                key: {
                  type: 'string',
                  value: '1pHWDzSI',
                },
                label: {
                  type: 'string',
                  value: 'ID',
                },
                value: {
                  type: 'string',
                  value: '54845912',
                },
              },
            },
            {
              type: 'object',
              properties: {
                key: {
                  type: 'string',
                  value: 'Miadaqe9',
                },
                label: {
                  type: 'string',
                  value: 'SECTION',
                },
                value: {
                  type: 'string',
                  value: 'Financial',
                },
                textAlignment: {
                  type: 'string',
                  value: PkpassTextAlignTypes.PKTextAlignmentRight,
                },
              },
            },
          ],
        },
        secondaryFields: {
          type: 'array',
          items: [
            {
              type: 'object',
              properties: {
                key: {
                  type: 'string',
                  value: 'ZKHqDHzx',
                },
                label: {
                  type: 'string',
                  value: 'ROLE',
                },
                value: {
                  type: 'string',
                  value: 'Treasurer',
                },
              },
            },
            {
              type: 'object',
              properties: {
                key: {
                  type: 'string',
                  value: 'WErW8LEq',
                },
                label: {
                  type: 'string',
                  value: 'valid from - to',
                },
                value: {
                  type: 'string',
                  value: '1.1.2019 - 1.1.2022',
                },
                textAlignment: {
                  type: 'string',
                  value: PkpassTextAlignTypes.PKTextAlignmentRight,
                },
              },
            },
          ],
        },
      },
    },
    barcodes,
    labelColor: {
      type: 'string',
      value: 'rgb(0,0,0)',
    },
    description: {
      type: 'string',
      value: 'Description',
    },
    backgroundColor: {
      type: 'string',
      value: 'rgb(255,255,255)',
    },
    foregroundColor: {
      type: 'string',
      value: 'rgb(0,0,0)',
    },
    organizationName,
  },
  [PkpassPassStyleTypes.StoreCard]: {
    barcodes,
    storeCard: {
      type: 'object',
      properties: {
        backFields,
        headerFields: {
          type: 'array',
          items: [
            {
              type: 'object',
              properties: {
                key: {
                  type: 'string',
                  value: 'r3DQL-n7x',
                },
                label: {
                  type: 'string',
                  value: 'LOYALTY CARD',
                },
                value: {
                  type: 'string',
                  value: 'POINTS: 153',
                },
                textAlignment: {
                  type: 'string',
                  value: PkpassTextAlignTypes.PKTextAlignmentRight,
                },
              },
            },
          ],
        },
        primaryFields: {
          type: 'array',
          items: [],
        },
        auxiliaryFields: {
          type: 'array',
          items: [
            {
              type: 'object',
              properties: {
                key: {
                  type: 'string',
                  value: 'Miadaqe9',
                },
                label: {
                  type: 'string',
                  value: 'LEVEL',
                },
                value: {
                  type: 'string',
                  value: 'GOLD 🥇',
                },
                textAlignment: {
                  type: 'string',
                  value: PkpassTextAlignTypes.PKTextAlignmentRight,
                },
              },
            },
          ],
        },
        secondaryFields: {
          type: 'array',
          items: [
            {
              type: 'object',
              properties: {
                key: {
                  type: 'string',
                  value: 'ZKHqDHzx',
                },
                label: {
                  type: 'string',
                  value: 'name',
                },
                value: {
                  type: 'string',
                  value: 'John Cole',
                },
              },
            },
          ],
        },
      },
    },
    labelColor: {
      type: 'string',
      value: 'rgb(0,0,0)',
    },
    description: {
      type: 'string',
      value: 'Description',
    },
    backgroundColor: {
      type: 'string',
      value: 'rgb(255,255,255)',
    },
    foregroundColor: {
      type: 'string',
      value: 'rgb(208,7,7)',
    },
    organizationName,
  },
};
