import { FormikProps, useFormikContext } from 'formik';
import React, { Fragment, useState } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { Button } from '../../common/buttons';
import Loader from '../../common/loader/Loader';
import { FilterKeyTypes } from '../../../context/FilterContext';
import useResourcesCount from '../../../hooks/useResourcesCount';
import { ResourceTypes } from '../../../utils/services/api';
import { prepareFilter } from '../../../utils/services/filter';
import { Template } from '../../../types/Template';
import { JsonSchemaEditor } from '../../common/jsonSchema/';

const messages = defineMessages({
  warningText: {
    defaultMessage:
      'There are {count} passes issued by template. If you add or remove dynamic data fields, existing passes will contains default values.',
    id: 'Template.DynamicDataForm.warning.text',
  },
  warningTitle: {
    defaultMessage: 'Warning',
    id: 'Template.DynamicDataForm.warning.title',
  },
});

function DynamicDataForm(props: FormikProps<Template>): JSX.Element {
  const [open, setOpen] = useState(true);
  const intl = useIntl();
  const [query] = useState(
    prepareFilter({ template: [props.values.id] }, [
      {
        columnName: 'templateId',
        name: FilterKeyTypes.TEMPLATE,
        type: 'inUuid',
      },
    ])
  );

  const { setFieldValue, values } = useFormikContext<Template>();

  const handleChange = (d: Record<string, unknown>) => {
    setFieldValue('dynamicDataJsonSchema', d);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const passList = useResourcesCount(ResourceTypes.PASS, { query });

  if (passList.loading) {
    return <Loader />;
  }

  return (
    <Fragment>
      {(passList.data || 0) > 0 && open && (
        <Dialog open={true}>
          <DialogTitle>{intl.formatMessage(messages.warningTitle)}</DialogTitle>
          <DialogContent>
            {intl.formatMessage(messages.warningText, {
              count: passList.data,
            })}
          </DialogContent>

          <DialogActions>
            <Button onClick={handleClose}>
              <FormattedMessage
                id='Template.DynamicData.confirm.action'
                defaultMessage='Confirm'
              />
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <JsonSchemaEditor
        onChange={handleChange}
        jsonSchema={values.dynamicDataJsonSchema}
      />
    </Fragment>
  );
}

export default DynamicDataForm;
