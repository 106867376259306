import React from 'react';
import { FormattedMessage } from 'react-intl';
import DesignerFrontSide from '../../../design/pkpass/designer/layout/DesignerFrontSide';
import HelpAnimation from '../HelpAnimation';
import StepGrid from '../StepGrid';
import BarcodeDialog from '../../../design/pkpass/designer/barcode/BarcodeDialog';
import { useTemplateUI } from '../../../design/pkpass/useTemplateUI';
import { usePreview } from '../../../design/pkpass/usePreview';

export const StepBarcode = (): JSX.Element => {
  const { templatePreviewLanguage } = useTemplateUI();
  const { pass, images, onBarcodeClick } = usePreview();

  return (
    <StepGrid
      col1={
        <div style={{ position: 'relative', width: 320, height: 450 }}>
          <DesignerFrontSide
            pass={pass}
            images={images}
            language={templatePreviewLanguage}
            scanDisabled={true}
            onBarcodeClick={onBarcodeClick}
            disableFields={true}
            disableImages={true}
            disableBarcodes={false}
          />
          <BarcodeDialog />
          <HelpAnimation style={{ bottom: 0, left: 60 }} />
        </div>
      }
      col2={
        <FormattedMessage
          id='TemplateNewModalContent.StepBarcode.Layout.ReadMore.Title'
          defaultMessage='To setup barcode please click to add barcode.'
        />
      }
    />
  );
};
