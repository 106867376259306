import { defineMessages } from 'react-intl';

import { ImageValidationErrorCodes } from '../../types/Image';
import { ImageTypes } from '../../types/Template';

const messages = defineMessages({
  [ImageValidationErrorCodes.IMAGE_VALIDATION_WIDTH_EXPECTED_LESS_OR_EQUAL]: {
    defaultMessage:
      'Image width is {current}px, but it must be less or equal {expected}px.',
    id: ImageValidationErrorCodes.IMAGE_VALIDATION_WIDTH_EXPECTED_LESS_OR_EQUAL,
  },
  [ImageValidationErrorCodes.IMAGE_VALIDATION_HEIGHT_EXPECTED_LESS_OR_EQUAL]: {
    defaultMessage:
      'Image height is {current}px, but it must be less or equal {expected}px.',
    id:
      ImageValidationErrorCodes.IMAGE_VALIDATION_HEIGHT_EXPECTED_LESS_OR_EQUAL,
  },
  [ImageValidationErrorCodes.IMAGE_VALIDATION_HEIGHT_EXPECTED_MORE_OR_EQUAL]: {
    defaultMessage:
      'Image height is {current}px, but it must be more or equal {expected}px.',
    id:
      ImageValidationErrorCodes.IMAGE_VALIDATION_HEIGHT_EXPECTED_MORE_OR_EQUAL,
  },
  [ImageValidationErrorCodes.IMAGE_VALIDATION_WIDTH_EXPECTED]: {
    defaultMessage: 'Image width is {current}px, but {expected}px is expected.',
    id: ImageValidationErrorCodes.IMAGE_VALIDATION_WIDTH_EXPECTED,
  },
  [ImageValidationErrorCodes.IMAGE_VALIDATION_HEIGHT_EXPECTED]: {
    defaultMessage:
      'Image height is {current}px, but {expected}px is expected.',
    id: ImageValidationErrorCodes.IMAGE_VALIDATION_HEIGHT_EXPECTED,
  },
  [ImageValidationErrorCodes.IMAGE_VALIDATION_THUMBNAIL]: {
    defaultMessage:
      'Image dimensions ({width}x{height} px) do not satisfy these requirements: the longer side must have {max}px, while the shorter side must range from {min} to {max}px',
    id: ImageValidationErrorCodes.IMAGE_VALIDATION_THUMBNAIL,
  },
  IMAGE_VALIDATION_UNSUPORTED_FILE: {
    defaultMessage:
      'Unsuported file format {mimeType} of file {name}, please use png.',
    id: 'IMAGE_VALIDATION_UNSUPORTED_FILE',
  },
  MissingProject: {
    defaultMessage: 'Project is required',
    id: 'ImageErrors.missing.project',
  },
  [ImageTypes.background]: {
    defaultMessage: 'Background image',
    id: 'pkpass.image.background',
  },
  [ImageTypes.logo]: {
    defaultMessage: 'Logo image',
    id: 'pkpass.image.logo',
  },
  [ImageTypes.icon]: {
    defaultMessage: 'Icon image',
    id: 'pkpass.image.icon',
  },
  [ImageTypes.footer]: {
    defaultMessage: 'Footer image',
    id: 'pkpass.image.footer',
  },
  [ImageTypes.thumbnail]: {
    defaultMessage: 'Thumbnail image',
    id: 'pkpass.image.thumbnail',
  },
  [ImageTypes.strip]: {
    defaultMessage: 'Strip image',
    id: 'pkpass.image.strip',
  },
});

export const pkpassImageSizeMessages = defineMessages({
  [ImageTypes.background]: {
    defaultMessage:
      'Backround image is displayed behind the entire front side of the pass. The expected dimensions are 360 x 440 pixels. The image is slightly cropped on all sides and also blurred. You can often provide an image at a smaller size. It will be scaled up, but the blurring effect will hide the details of the image. This lets you reduce the file size without users noticing the difference.',
    id: 'pkpass.image.background.resolution',
  },
  [ImageTypes.footer]: {
    defaultMessage:
      'Footer image is displayed near the barcode. The allotted space is 572 x 30 pixels.',
    id: 'pkpass.image.footer.resolution',
  },
  [ImageTypes.icon]: {
    defaultMessage:
      'Icon is displayed when a notification is shown on the lock screen and by apps such as Mail when the pass is attached to an email. The icon should have dimensions of 58 x 58 pixels',
    id: 'pkpass.image.icon.resolution',
  },
  [ImageTypes.logo]: {
    defaultMessage:
      'Logo image is displayed in the top left corner of the pass beside the logo text. The allotted space is 320 x 100 pixels; in most cases it should be more narrow.',
    id: 'pkpass.image.logo.resolution',
  },
  [ImageTypes.strip]: {
    defaultMessage:
      'Strip image is displayed behind the primary fields. The expected dimensions are 640 x 168-246 pixels. The allotted space is 640 x 168 pixels  for event tickets; 640 x 220 pixels  for other pass styles with a square barcode on devices with 3.5 inch screens; 640 x 246 for all other uses.',
    id: 'pkpass.image.strip.resolution',
  },
  [ImageTypes.thumbnail]: {
    defaultMessage:
      'Thumbnail image is displayed next to the fields on the front side of the pass. The allotted space is 120-180 x 120-180 pixels. The aspect ratio should be in the range of 2:3 to 3:2 or the image will be cropped.',
    id: 'pkpass.image.thumbnail.resolution',
  },
});

export default messages;
