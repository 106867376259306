import { FormikProps } from 'formik';
import PropTypes from 'prop-types';
import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import EditDrawerInnerLayout from '../../components/common/drawers/EditDrawerInnerLayout';
import FormikEditDrawer from '../../components/common/formik/FormikEditDrawer';
import { ResourceTypes } from '../../utils/services/api';
import { Project } from './types';
import { ProjectEditForm } from './ProjectEditForm';
import { ProjectEditFormControls } from './ProjectEditFormControls';

interface ProjectEditDrawerProps {
  id: string;
  open: boolean;
  onClose: () => void;
}

export const ProjectEditDrawer: FunctionComponent<ProjectEditDrawerProps> = (
  props: ProjectEditDrawerProps
): JSX.Element => {
  const Edit: FunctionComponent<FormikProps<Project>> = (
    fp: FormikProps<Project>
  ) => {
    return (
      <EditDrawerInnerLayout
        onClose={props.onClose}
        title={
          <FormattedMessage
            id='ProjectEdit.title'
            defaultMessage='Edit project'
          />
        }
        content={<ProjectEditForm {...fp} />}
        controls={<ProjectEditFormControls {...fp} />}
      />
    );
  };

  return (
    <FormikEditDrawer
      onClose={props.onClose}
      resource={ResourceTypes.PROJECT}
      id={props.id}
      open={props.open}
      Component={Edit}
    />
  );
};

ProjectEditDrawer.propTypes = {
  onClose: PropTypes.func.isRequired,
};
