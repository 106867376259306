import React from 'react';
import { FormattedMessage } from 'react-intl';

import FieldFileDropzone from '../../common/fields/FieldFileDropzone';
import { convertImageFileToImage } from '../../../utils/services/image';
import { NewImage } from '../../../types/Image';

interface Props {
  onSelectFiles: (images: NewImage[]) => void;
  disabled?: boolean;
}

export default function ImageBulkDropZone(props: Props): JSX.Element {
  const handleDragAndDrop = (accepted: File[]) => {
    const process = async () => {
      const files: NewImage[] = [];
      for (const file of accepted) {
        const f: NewImage = await convertImageFileToImage(file);
        files.push(f);
      }
      props.onSelectFiles(files);
    };
    process();
  };

  return (
    <FieldFileDropzone
      disabled={props.disabled}
      multiple={true}
      onSelect={handleDragAndDrop}
      accept='image/png'
      infoText={
        <FormattedMessage
          defaultMessage='Click here or drag & drop images to this area'
          id='BatchUploadModalDropZone.helpText'
        />
      }
    />
  );
}
