import React from 'react';
import { FormattedMessage } from 'react-intl';

import DesignerFrontSide from '../../../design/pkpass/designer/layout/DesignerFrontSide';

import HelpAnimation from '../HelpAnimation';
import StepGrid from '../StepGrid';
import { useTemplateUI } from '../../../design/pkpass/useTemplateUI';
import { usePreview } from '../../../design/pkpass/usePreview';

export const StepImages = (): JSX.Element => {
  const { templatePreviewLanguage } = useTemplateUI();
  const { pass, images, onImageClick } = usePreview();

  return (
    <StepGrid
      col1={
        <div style={{ position: 'relative', width: 320, height: 450 }}>
          <HelpAnimation style={{ top: -50, left: -18 }} />
          <DesignerFrontSide
            pass={pass}
            images={images}
            language={templatePreviewLanguage}
            scanDisabled={true}
            onImageClick={onImageClick}
            disableBarcodes={true}
            disableFields={true}
          />
        </div>
      }
      col2={
        <FormattedMessage
          id='TemplateNewModalContent.StepImages.Layout.ReadMore.Title'
          defaultMessage='To select or upload new image, click to shaded area with image icon on left side.'
        />
      }
    />
  );
};
