import { FATHOM_CODE } from './utils/services/environment';

declare function fathom(...options: any): void;

interface MyWindow extends Window {
  fathom: any;
}

declare const window: MyWindow;

window.fathom =
  window.fathom ||
  function () {
    // eslint-disable-next-line prefer-rest-params
    (window.fathom.q = window.fathom.q || []).push(arguments);
  };

if (FATHOM_CODE) {
  const fathomScript = document.createElement('script') as HTMLScriptElement;
  const firstScriptTag = document.getElementsByTagName(
    'script'
  )[0] as HTMLScriptElement;
  fathomScript.async = true;
  fathomScript.src = '//fathom.ys-dev.cz/tracker.js';
  fathomScript.id = 'fathom-script';
  if (firstScriptTag.parentNode) {
    firstScriptTag.parentNode.insertBefore(fathomScript, firstScriptTag);
  }

  fathom('set', 'siteId', FATHOM_CODE);
  fathom('trackPageview');
}

export const pageview = (path: string) => {
  fathom('trackPageview', { path });
};
