import { Field, FieldProps } from 'formik';
import React, { useEffect, useState } from 'react';

import { JsonSchemaFieldProps } from '../../../common/jsonSchema';
import {
  FieldCheckboxChange,
  FieldSwitch,
  FieldTextInput,
  FieldTextInputData,
} from '../../../common/fields';
import Form from '../../../common/Form';
import { StaticLabel } from '../../../common/labels';
import { Wysiwyg } from '../../../common/wysiwyg/Wysiwyg';
import { useTemplateUI } from '../useTemplateUI';
import { errorsToString, isPlaceholder, defaultStringFallback } from './helper';
import {
  PkpassInputSettingsPopUp,
  usePkpassInputSettingsPopup,
} from './PkpassInputSettingsPopUp';
import IconButton from '@material-ui/core/IconButton';
import { SettingsIcon } from '../../../common/icons';
import { createStyles, makeStyles, useTheme } from '@material-ui/core/styles';

type ValueType =
  | {
      type: 'string';
      value?: string | { [key: string]: string | undefined };
      fallback?: string;
    }
  | undefined;

export interface WysiwygLocalizedStringInputWithFallbackData {
  name: string;
  value: ValueType;
}

export interface WysiwygLocalizedStringInputWithFallbackInputProps<T>
  extends WysiwygLocalizedStringInputWithFallbackProps {
  field: FieldProps<T>;
}
const useStyles = makeStyles(() =>
  createStyles({
    bottomControls: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingLeft: 8,
    },
  })
);
export const WysiwygLocalizedStringInputWithFallbackInput = (
  props: WysiwygLocalizedStringInputWithFallbackInputProps<ValueType>
): JSX.Element => {
  const {
    field,
    label,
    helpText,
    name,
    required,
    multiline,
    disabled,
    placeholder,
    errorPaths,
    onChange,
    languages,
    link,
  } = props;
  const templateUI = useTemplateUI();
  const theme = useTheme();
  const classes = useStyles(theme);
  const value = field.field.value ? field.field.value.value : undefined;
  const object = field.field.value;

  const isLocalized = !!value && typeof value === 'object';
  const hasFallback = object && isPlaceholder('string', object);
  const [language, setLanguage] = useState(templateUI.templatePreviewLanguage);

  useEffect(() => {
    setLanguage(templateUI.templatePreviewLanguage);
  }, [templateUI.templatePreviewLanguage]);

  const fallback = field.field.value ? field.field.value.fallback : undefined;
  const getFallback = (v: string | { [key: string]: string | undefined }) => {
    if (isPlaceholder('string', { value: v, type: 'string' })) {
      return fallback || defaultStringFallback;
    }
    return fallback;
  };

  const localizedValue: string | undefined =
    isLocalized && value && typeof value === 'object'
      ? value[language]
      : (value as string | undefined);

  const handleChange = (
    str: string
  ) => {
    // const str = event.target.value;
    let newValue: ValueType;

    let newStringValue;
    if (str !== '') {
      newStringValue = str;
    }
    if (isLocalized) {
      const v = value ? (value as { [key: string]: string | undefined }) : {};
      v[language] = newStringValue;
      newValue = { value: v, fallback: getFallback(v), type: 'string' };
    } else {
      if (newStringValue) {
        newValue = {
          value: newStringValue,
          fallback: getFallback(newStringValue),
          type: 'string',
        };
      }
    }
    field.form.setFieldValue(name, newValue);

    if (onChange) {
      onChange({ name, value: newValue });
    }
  };

  const handleChangeFallback = (e: FieldTextInputData) => {
    const v = e.value;
    let newValue: ValueType;
    if (v !== '') {
      newValue = {
        fallback: v,
        type: 'string',
        value: value,
      };
    } else {
      newValue = {
        fallback: undefined,
        type: 'string',
        value: value,
      };
    }

    field.form.setFieldValue(name, newValue);
    if (onChange) {
      onChange({ name, value: newValue });
    }
  };

  const handleLocalizable = (event: FieldCheckboxChange) => {
    const checked = event.value;

    if (checked) {
      const newValue: { [key: string]: string | undefined } = {};
      if (object) {
        languages?.forEach((l: string) => {
          newValue[l] = localizedValue;
        });
      }
      // if is localized and has placeholder, reset fallback
      const v = { fallback: fallback, type: 'string', value: newValue };
      if (isPlaceholder('string', v)) {
        v.fallback = undefined;
      }

      field.form.setFieldValue(name, v);
    } else {
      field.form.setFieldValue(name, {
        ...object,
        value: localizedValue,
      });
    }
  };

  // TODO check missing translations
  const error = errorsToString(
    field.form.errors,
    errorPaths ? errorPaths : [name]
  );

  const { anchorEl, handleClose, handleOpen } = usePkpassInputSettingsPopup();

  return (
    <>
      <Wysiwyg
        key={language}
        link={link}
        name={name}
        label={label}
        helpText={helpText}
        error={error}
        disabled={disabled}
        value={localizedValue}
        onChange={handleChange}
        multiline={multiline}
        placeholder={placeholder}
        required={required}
        shortCodes={templateUI.textShortCodes}
        bottomControls={
          <div className={classes.bottomControls}>
            <div>
              <FieldSwitch
                size='small'
                name='localized'
                checked={isLocalized}
                label='localized'
                onChange={handleLocalizable}
              />
            </div>
            <IconButton onClick={handleOpen} disabled={!hasFallback}>
              <SettingsIcon style={{ height: '0.7em', width: '0.7em' }} />
            </IconButton>
          </div>
        }
      />
      <PkpassInputSettingsPopUp anchorEl={anchorEl} onClose={handleClose}>
        <Form>
          <FieldTextInput
            label={
              <span>
                {label} {StaticLabel.Fallback}
              </span>
            }
            name={name}
            disabled={disabled || !hasFallback}
            value={fallback}
            onChange={handleChangeFallback}
            multiline={multiline}
            placeholder={placeholder}
            required={!hasFallback}
          />
        </Form>
      </PkpassInputSettingsPopUp>
    </>
  );
};

export interface WysiwygLocalizedStringInputWithFallbackProps {
  name: string;
  label: JSX.Element;
  helpText: JSX.Element;
  required?: boolean;
  multiline?: boolean;
  onChange?: (data: WysiwygLocalizedStringInputWithFallbackData) => void;
  languages?: string[];
  link?: boolean;
  disabled?: boolean;
  placeholder?: string;
  errorPaths?: string[];
  shortCodes?: JsonSchemaFieldProps[];
}

export const PkpassWysiwygLocalizedString = (
  props: WysiwygLocalizedStringInputWithFallbackProps
): JSX.Element => {
  return (
    <Field name={props.name}>
      {(field: FieldProps) => (
        <WysiwygLocalizedStringInputWithFallbackInput
          {...props}
          field={field}
        />
      )}
    </Field>
  );
};
