import { FieldArray, FieldArrayRenderProps, FormikProps, getIn } from 'formik';
import React, { Fragment } from 'react';

import List from '@material-ui/core/List';

import { Template } from '../../../../../../types/Template';
import AppStoreAddModal from './AppStoreAddModal';
import AppStoreAppDetail from './AppStoreAppDetail';
import { FormattedMessage } from 'react-intl';

export default class AppStore extends React.Component<FormikProps<Template>> {
  public render() {
    return (
      <Fragment>
        <FieldArray
          name='jsonTemplate.associatedStoreIdentifiers.items'
          render={this.renderList}
        />

        <AppStoreAddModal onAdd={this.handleAdd} />
      </Fragment>
    );
  }

  private renderList = (props: FieldArrayRenderProps) => {
    const { values } = this.props;
    return (
      <List>
        {values.jsonTemplate.associatedStoreIdentifiers &&
          values.jsonTemplate.associatedStoreIdentifiers.items.map(
            (appId: { value: number; type: 'number' }, index: number) => {
              const onRemove = () => {
                props.remove(index);
              };

              return (
                <AppStoreAppDetail
                  key={appId.value}
                  id={appId.value}
                  onRemove={onRemove}
                />
              );
            }
          )}
        {(!values.jsonTemplate.associatedStoreIdentifiers ||
          values.jsonTemplate.associatedStoreIdentifiers.items.length ===
            0) && (
          <FormattedMessage
            id='pkpass.no.associated.aps'
            defaultMessage={'No applications are selected'}
          />
        )}
      </List>
    );
  };

  private handleAdd = (id: number) => {
    const { values, setFieldValue } = this.props;
    const items = getIn(
      values,
      'jsonTemplate.associatedStoreIdentifiers.items',
      []
    );
    items.push({ value: id, type: 'number' });
    setFieldValue('jsonTemplate.associatedStoreIdentifiers', {
      items,
      type: 'array',
    });
  };
}
