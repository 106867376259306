import React, { SyntheticEvent } from 'react';
import { defineMessages, useIntl, FormattedMessage } from 'react-intl';
import Paper from '@material-ui/core/Paper';
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import FilterListIcon from '@material-ui/icons/FilterList';

import { FilterKeyTypes, FilterState } from '../../../context/FilterContext';
import useFilter from '../../../hooks/useFilter';
import { Button } from '../buttons';
import {
  FieldAutocompleteProject,
  FieldAutocompleteTemplate,
  FieldCheckbox,
  FieldCheckboxChange,
  FieldDate,
  FieldDateChange,
  FieldDateTime,
  FieldSelectData,
  FieldTextInput,
  FieldTextInputData,
} from '../fields';
import { StaticLabel } from '../labels';
import FilterDevices from './FilterDevices';
import FilterImageType from './FilterImageType';
import FilterInterval from './FilterInterval';
import FilterVoided from './FilterVoided';

export interface FilterOptionsProps {
  project?: boolean;
  template?: boolean;
  dynamicData?: boolean;
  id?: boolean;
  from?: boolean;
  to?: boolean;
  interval?: boolean;
  name?: boolean;
  passTypeId?: boolean;
  imageType?: boolean;
  email?: boolean;
  displayName?: boolean;
  createdFrom?: boolean;
  createdTo?: boolean;
  voided?: boolean;
  date?: boolean;
  devices?: boolean;
  onFilter?: () => void;
  onChange?: (state: FilterState) => void;
  disabled?: boolean;
  withDeleted?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      marginBottom: theme.spacing(2),
    },
    form: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    button: {
      marginTop: theme.spacing(1),
      padding: '6px 22px',
    },
    title: {
      marginLeft: theme.spacing(1),
    },
  })
);

const messages = defineMessages({
  status: { id: 'filter.fields.status', defaultMessage: 'Status' },
});

export default function Filter(props: FilterOptionsProps): JSX.Element {
  const { filter, filterSet } = useFilter();
  const theme = useTheme();
  const classes = useStyles(theme);
  const intl = useIntl();

  const handleChangeProject = (value: string[] | undefined) => {
    filterSet({ name: FilterKeyTypes.PROJECT, value });
    filterSet({ name: FilterKeyTypes.TEMPLATE, value: [] });
    if (props.onChange) {
      props.onChange({
        ...filter,
        [FilterKeyTypes.PROJECT]: value || [],
        [FilterKeyTypes.TEMPLATE]: [],
      });
    }
  };

  const handleChangeTemplate = (value: string[] | undefined) => {
    filterSet({ name: FilterKeyTypes.TEMPLATE, value });
    if (props.onChange) {
      props.onChange({ ...filter, [FilterKeyTypes.TEMPLATE]: value || [] });
    }
  };

  const onFilter = (e: SyntheticEvent) => {
    if (e) {
      e.preventDefault();
    }
    if (props.onFilter) {
      props.onFilter();
    }
  };

  const onChange = (
    e:
      | FieldTextInputData
      | FieldDateChange
      | FieldSelectData
      | FieldCheckboxChange
  ) => {
    filterSet({ name: e.name as FilterKeyTypes, value: e.value });
    if (props.onChange) {
      props.onChange({ ...filter, [e.name]: e.value });
    }
  };

  const { disabled } = props;
  const components: any = {};

  if (props.project) {
    components[FilterKeyTypes.PROJECT] = (
      <FieldAutocompleteProject
        key={FilterKeyTypes.PROJECT}
        name={FilterKeyTypes.PROJECT}
        multiple={true}
        onChange={handleChangeProject}
        value={filter[FilterKeyTypes.PROJECT]}
        disabled={disabled}
      />
    );
  }

  if (props.template) {
    const pfilter = {
      project: filter[FilterKeyTypes.PROJECT],
    };
    const key = filter[FilterKeyTypes.PROJECT].join('-');
    components[FilterKeyTypes.TEMPLATE] = (
      <FieldAutocompleteTemplate
        key={key}
        multiple={true}
        onChange={handleChangeTemplate}
        value={filter[FilterKeyTypes.TEMPLATE]}
        name={FilterKeyTypes.TEMPLATE}
        filter={pfilter}
        disabled={disabled}
      />
    );
  }

  if (props.from) {
    components[FilterKeyTypes.FROM] = (
      <FieldDateTime
        value={filter[FilterKeyTypes.FROM]}
        name={FilterKeyTypes.FROM}
        key={FilterKeyTypes.FROM}
        label={StaticLabel.DateFrom}
        onChange={onChange}
        disabled={disabled}
      />
    );
  }
  if (props.to) {
    components[FilterKeyTypes.TO] = (
      <FieldDateTime
        key={FilterKeyTypes.TO}
        value={filter[FilterKeyTypes.TO]}
        name={FilterKeyTypes.TO}
        label={StaticLabel.DateTo}
        onChange={onChange}
        disabled={disabled}
      />
    );
  }

  if (props.createdFrom) {
    components[FilterKeyTypes.CREATED_AT_FROM] = (
      <FieldDate
        startOfDay={true}
        key={FilterKeyTypes.CREATED_AT_FROM}
        value={filter[FilterKeyTypes.CREATED_AT_FROM]}
        name={FilterKeyTypes.CREATED_AT_FROM}
        label={StaticLabel.CreateFrom}
        onChange={onChange}
        disabled={disabled}
        helpText={
          <FormattedMessage
            id='filter.dateFrom.helpText'
            defaultMessage='For set a start of the day, please use 12:00 AM time.'
          />
        }
      />
    );
  }
  if (props.createdTo) {
    components[FilterKeyTypes.CREATED_AT_TO] = (
      <FieldDate
        startOfDay={false}
        value={filter[FilterKeyTypes.CREATED_AT_TO]}
        name={FilterKeyTypes.CREATED_AT_TO}
        key={FilterKeyTypes.CREATED_AT_TO}
        label={StaticLabel.CreateTo}
        onChange={onChange}
        disabled={disabled}
        helpText={
          <FormattedMessage
            id='filter.dateTo.helpText'
            defaultMessage='To set a end of the day, please use next day date and 12:00 AM time.'
          />
        }
      />
    );
  }

  if (props.dynamicData) {
    components[FilterKeyTypes.DYNAMIC_DATA] = (
      <FieldTextInput
        value={filter[FilterKeyTypes.DYNAMIC_DATA]}
        name={FilterKeyTypes.DYNAMIC_DATA}
        key={FilterKeyTypes.DYNAMIC_DATA}
        onChange={onChange}
        disabled={disabled}
        label={StaticLabel.DynamicData}
      />
    );
  }

  if (props.interval) {
    components[FilterKeyTypes.INTERVAL] = (
      <FilterInterval
        name={FilterKeyTypes.INTERVAL}
        key={FilterKeyTypes.INTERVAL}
        value={filter[FilterKeyTypes.INTERVAL]}
        label={StaticLabel.Interval}
        onChange={onChange}
        disabled={disabled}
      />
    );
  }

  if (props.date) {
    components[FilterKeyTypes.DATE] = (
      <FieldDateTime
        value={filter[FilterKeyTypes.DATE]}
        name={FilterKeyTypes.DATE}
        key={FilterKeyTypes.DATE}
        label={StaticLabel.Date}
        onChange={onChange}
        disabled={disabled}
      />
    );
  }

  if (props.id) {
    components[FilterKeyTypes.ID] = (
      <FieldTextInput
        value={filter[FilterKeyTypes.ID]}
        name={FilterKeyTypes.ID}
        key={FilterKeyTypes.ID}
        label={StaticLabel.Id}
        onChange={onChange}
        disabled={disabled}
      />
    );
  }

  if (props.displayName) {
    components[FilterKeyTypes.DISPLAY_NAME] = (
      <FieldTextInput
        value={filter[FilterKeyTypes.DISPLAY_NAME]}
        name={FilterKeyTypes.DISPLAY_NAME}
        key={FilterKeyTypes.DISPLAY_NAME}
        label={StaticLabel.DisplayName}
        onChange={onChange}
        disabled={disabled}
      />
    );
  }

  if (props.email) {
    components[FilterKeyTypes.EMAIL] = (
      <FieldTextInput
        value={filter[FilterKeyTypes.EMAIL]}
        name={FilterKeyTypes.EMAIL}
        key={FilterKeyTypes.EMAIL}
        label={StaticLabel.Email}
        onChange={onChange}
        disabled={disabled}
      />
    );
  }

  if (props.name) {
    components[FilterKeyTypes.NAME] = (
      <FieldTextInput
        key={FilterKeyTypes.NAME}
        value={filter[FilterKeyTypes.NAME]}
        name={FilterKeyTypes.NAME}
        label={StaticLabel.Name}
        onChange={onChange}
        disabled={disabled}
      />
    );
  }

  if (props.passTypeId) {
    components[FilterKeyTypes.PASS_TYPE_ID] = (
      <FieldTextInput
        value={filter[FilterKeyTypes.PASS_TYPE_ID]}
        name={FilterKeyTypes.PASS_TYPE_ID}
        key={FilterKeyTypes.PASS_TYPE_ID}
        label={StaticLabel.PassTypeIdentifier}
        onChange={onChange}
        disabled={disabled}
      />
    );
  }

  if (props.imageType) {
    components[FilterKeyTypes.IMAGE_TYPE] = (
      <FilterImageType
        value={filter[FilterKeyTypes.IMAGE_TYPE]}
        name={FilterKeyTypes.IMAGE_TYPE}
        key={FilterKeyTypes.IMAGE_TYPE}
        label={StaticLabel.ImageType}
        onChange={onChange}
        disabled={disabled}
      />
    );
  }

  if (props.devices) {
    components[FilterKeyTypes.DEVICES] = (
      <FilterDevices
        name={FilterKeyTypes.DEVICES}
        key={FilterKeyTypes.DEVICES}
        label={intl.formatMessage(messages.status)}
        value={filter[FilterKeyTypes.DEVICES]}
        onChange={onChange}
        disabled={disabled}
      />
    );
  }

  if (props.voided) {
    components[FilterKeyTypes.VOIDED] = (
      <FilterVoided
        name={FilterKeyTypes.VOIDED}
        key={FilterKeyTypes.VOIDED}
        label={StaticLabel.Voided}
        value={filter[FilterKeyTypes.VOIDED]}
        onChange={onChange}
        disabled={disabled}
      />
    );
  }

  if (props.withDeleted) {
    components[FilterKeyTypes.WITH_DELETED] = (
      <FieldCheckbox
        name={FilterKeyTypes.WITH_DELETED}
        key={FilterKeyTypes.WITH_DELETED}
        label={StaticLabel.ShowDeleted}
        checked={filter[FilterKeyTypes.WITH_DELETED]}
        onChange={onChange}
        disabled={disabled}
      />
    );
  }

  return (
    <Paper className={classes.root}>
      <Typography variant={'h5'} className={classes.title}>
        <FormattedMessage id='filter.title' defaultMessage='Filter' />
      </Typography>
      <form id='filter-form' onSubmit={onFilter} className={classes.form}>
        {Object.keys(components).map((key: string) => components[key])}
        <button type='submit' value='submit' style={{ display: 'none' }} />
        <Button
          size='large'
          variant='contained'
          className={classes.button}
          type='submit'
          value='submit'
          disabled={disabled}
          onClick={onFilter}
          color='primary'
          startIcon={<FilterListIcon />}
        >
          <FormattedMessage id='filter.button.label' defaultMessage='Apply' />
        </Button>
      </form>
    </Paper>
  );
}

export interface FilterElementProps {
  name: FilterKeyTypes;
  label: React.ReactNode;
  placeholder?: string;
  onChange: (e: any, data: { name: FilterKeyTypes; value: any }) => void;
  disabled: boolean | undefined;
  helpText?: React.ReactNode;
}
