import React from 'react';
import { NavLink } from 'react-router-dom';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { useTheme } from '@material-ui/core/styles';

import { PageInterface } from '../../Sidebar';
import { useStyles } from './styles';

const Page = (props: { page: PageInterface }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  if (props.page.component) {
    return (
      <ListItem
        button
        disableGutters={!!props.page.icon}
        className={classes.item}
        component={props.page.component}
        href={props.page.href}
        target={props.page.target}
        to={props.page.href}
        alignItems='flex-start'
      >
        {props.page.icon && (
          <ListItemIcon className={classes.icon}>
            {props.page.icon}
          </ListItemIcon>
        )}
        <ListItemText primary={props.page.title} />
      </ListItem>
    );
  }

  return (
    <ListItem
      button
      activeClassName={classes.active}
      disableGutters={!!props.page.icon}
      className={classes.item}
      component={NavLink}
      href={props.page.href}
      target={props.page.target}
      to={props.page.href}
      alignItems='flex-start'
    >
      {props.page.icon && (
        <ListItemIcon className={classes.icon}>{props.page.icon}</ListItemIcon>
      )}
      <ListItemText primary={props.page.title} />
    </ListItem>
  );
};

export default Page;
