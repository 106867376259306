import React from 'react';
import { FormattedMessage, defineMessage } from 'react-intl';

import Form from '../../common/Form';
import { PkpassBoolean, PkpassDate, PkpassString } from './inputs';
import FormattedHTMLMessage from '../../common/FormattedHTMLMessage';

export default function PkpassAdditionalOptions(): JSX.Element {
  return (
    <div>
      <Form>
        <PkpassDate
          label={
            <FormattedMessage
              id='pkpass.expirationDate.label'
              defaultMessage={`Expiration date`}
            />
          }
          helpText={
            <FormattedMessage
              id='pkpass.expirationDate.helpText'
              defaultMessage={`Sets the date and time when the pass expires for all passes belonging to this template.`}
            />
          }
          name='jsonTemplate.expirationDate'
        />
        <PkpassString
          name='jsonTemplate.groupingIdentifier'
          label={
            <FormattedMessage
              id='pkpass.groupingIdentifier.title'
              defaultMessage='Grouping Identifier'
            />
          }
          helpText={
            <FormattedHTMLMessage
              descriptor={defineMessage({
                id: 'pkpass.groupingIdentifier.helpText',
                defaultMessage:
                  '<i>Optional for event tickets and boarding passes; otherwise not allowed.</i><br/><br/>Identifier used to group related passes. If a grouping identifier is specified, passes with the same style, pass type identifier, and grouping identifier are displayed as a group. Otherwise, passes are grouped automatically. Use this to group passes that are tightly related, such as the boarding passes for different connections of the same trip.',
              })}
            />
          }
        />
        <PkpassBoolean
          label={
            <FormattedMessage
              id='pkpass.sharingProhibited.title'
              defaultMessage='Sharing prohibited'
            />
          }
          helpText={
            <FormattedHTMLMessage
              descriptor={defineMessage({
                id: 'pkpass.sharingProhibited.helpText',
                defaultMessage:
                  '<p>All passes belonging to this template will disable the ability to share the pass from the wallet.</p><p><b>Note: This will not prevent sharing passes at all, they still can be shared in another way (URL, email, etc.)<b/></p>',
              })}
            />
          }
          name='jsonTemplate.sharingProhibited'
        />
      </Form>
    </div>
  );
}
