import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';

import { linkStyle } from '../../../styles';
import { JsonSchemaFieldProps } from '../jsonSchema';
import { FieldLabel } from '../fields';
import Form from '../Form';

interface Props {
  shortCodes: JsonSchemaFieldProps[];
  onSelect: (shortCode: string, p: JsonSchemaFieldProps) => void;
}

export default function WysiwygPopUpShortCodesContent(
  props: Props
): JSX.Element {
  const handleSelect = (p: JsonSchemaFieldProps) => {
    return () => {
      props.onSelect(`#{${p.name}}`, p);
    };
  };

  return (
    <Fragment>
      <Form>
        <FieldLabel
          label={
            <FormattedMessage
              id='WysiwygShortCodesContent.title'
              defaultMessage='Available short codes'
            />
          }
          helpText={
            <FormattedMessage
              id='WysiwygShortCodesContent.helpText'
              defaultMessage='Click on short code to set the value of the field.'
            />
          }
        />
      </Form>

      <table>
        <tbody>
          {props.shortCodes.map((p: JsonSchemaFieldProps) => (
            <tr
              key={p.name}
              onClick={handleSelect(p)}
              style={{ cursor: 'pointer' }}
            >
              <td>
                <label>{p.title}</label>
              </td>
              <td>
                <span style={linkStyle}>{`#{${p.name}}`}</span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Fragment>
  );
}
