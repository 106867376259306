import clsx from 'clsx';
import React, { useState } from 'react';

import {
  createStyles,
  lighten,
  makeStyles,
  Theme,
} from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import { ResourcePagedHookProps } from '../../../hooks/useResourcesPaged';
import { DataGridProps } from './DataGrid';
import ExportButton from './ExportButton';
import { DeleteButton } from '../buttons';
import { batch } from '../../../utils/services/api';
import { FormattedMessage } from 'react-intl';
import useNotification from '../../../hooks/useNotification';

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    highlight:
      theme.palette.type === 'light'
        ? {
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            color: theme.palette.secondary.main,
          }
        : {
            backgroundColor: theme.palette.secondary.dark,
            color: theme.palette.text.primary,
          },
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    title: {
      flex: '1 1 100%',
    },
  })
);

function EnhancedTableToolbar<T>(
  props: DataGridProps<T> & ResourcePagedHookProps<T> & { dirty: boolean }
) {
  const classes = useToolbarStyles();

  const [loading, setLoading] = useState(false);
  const { addNotification } = useNotification();

  const { resource, selected, unselect, listLoad, data } = props;
  const numSelected = Object.keys(selected).filter(
    (k: string) => !!selected[parseInt(k, 10)]
  ).length;

  const onDelete = async () => {
    setLoading(true);
    try {
      let failure = 0;
      let success = 0;

      const ids: T[] = Object.keys(selected)
        .map((p) => parseInt(p, 10))
        .filter((p: number) => selected[p])
        .map((p: number) => data[p]);

      const response = await batch(
        resource,
        ids.map((i: any) => ({ method: 'DELETE', id: i.id }))
      );

      for (const item of response) {
        if (item.status.code === 200) {
          success = success + 1;
        } else {
          failure = failure + 1;
        }
      }
      unselect && unselect();
      listLoad();
      addNotification({
        level: failure > 0 ? 'error' : 'success',
        message:
          failure > 0 ? (
            <FormattedMessage
              id='ImageListPage.batchImages.remove.message'
              defaultMessage='Deleted: {success}, failed: {failure}'
              values={{ success, failure }}
            />
          ) : (
            <FormattedMessage
              id='ImageListPage.batchImages.remove.message'
              defaultMessage='Deleted: {success}, failed: {failure}'
              values={{ success, failure }}
            />
          ),
      });
    } catch (e) {
      addNotification({
        level: 'error',
        message: (
          <FormattedMessage
            id='ImageListPage.batchImages.remove.header.fail'
            defaultMessage='Deleting items failed'
          />
        ),
      });
    }
    setLoading(false);
  };

  if (numSelected > 0) {
    return (
      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: numSelected > 0,
        })}
      >
        <Typography
          className={classes.title}
          color='inherit'
          variant='subtitle1'
        >
          {numSelected} selected
        </Typography>

        <Tooltip title='Delete'>
          <DeleteButton
            loading={loading}
            confirmText={`${numSelected} items will be deleted. Are you sure?`}
            onDelete={onDelete}
          />
        </Tooltip>
      </Toolbar>
    );
  }

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <Typography className={classes.title} variant='h6' id='tableTitle'>
        {props.title}
      </Typography>
      <ExportButton {...props} />
    </Toolbar>
  );
}

export default EnhancedTableToolbar;
