import React, { Fragment } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Icon from '@material-ui/icons/FileCopy';

import useOpen from '../../../hooks/useOpen';
import { Button, ButtonProps } from './Button';
import { CancelButton } from './CancelButton';
import { ConfirmButton } from './ConfirmButton';

const messages = defineMessages({
  defaultConfirmText: {
    defaultMessage: 'Copy of resource will be created. Are you sure?',
    id: 'CopyButton.confirm.default.text',
  },
});

interface Props extends ButtonProps {
  onClick: () => void;
  confirmText?: string;
}

export const CopyButton = (props: Props): JSX.Element => {
  const { open, handleClose, handleOpen } = useOpen(false);
  const { confirmText, ...buttonProps } = props;
  const intl = useIntl();

  const handleConfirm = () => {
    handleClose();
    props.onClick();
  };

  return (
    <Fragment>
      <Button {...buttonProps} onClick={handleOpen} startIcon={<Icon />}>
        <FormattedMessage
          id='CopyButton.duplicate.label'
          defaultMessage='Clone'
        />
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          <FormattedMessage
            id='CopyButton.duplicate.dialog.title'
            defaultMessage='Create copy'
          />
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            {props.confirmText ||
              intl.formatMessage(messages.defaultConfirmText)}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <CancelButton onClick={handleClose} />
          <ConfirmButton onClick={handleConfirm} autoFocus={true} />
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};
