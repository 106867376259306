import { flatten } from '../../../utils/services/flatten';
import moment from 'moment';
import XLSX from 'xlsx';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { Button } from '../buttons';
import { BulkResultItem } from './types';

export const isSafari = () =>
  /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

export default function BulkResultExportButton(props: {
  results: BulkResultItem[];
  prefix: string;
  onClose: () => void;
}) {
  const [loading, setLoading] = useState(false);

  const exportResult = async () => {
    setLoading(true);
    const flatted = props.results.map((item: any) => {
      const { status, data } = item;
      return flatten({ ...data, status });
    });
    const ws = XLSX.utils.json_to_sheet(flatted);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'batch');
    const exportNumber = moment(new Date()).format('YYYY-DD-MM_HH-mm');
    XLSX.writeFile(wb, `${props.prefix}${exportNumber}.xlsx`);
    setLoading(false);
    props.onClose();
  };

  return (
    <Button
      onClick={exportResult}
      disabled={loading}
      color='primary'
      variant={'contained'}
    >
      <FormattedMessage
        id='BulkResultExportButton.button'
        defaultMessage='Download and close'
      />
    </Button>
  );
}
