import Dexie, { PromiseExtended } from 'dexie';
import { ResourceTypes } from '../../utils/services/api';
import { User } from '../../types/User';
import { isDate } from 'moment';

export type SupportedTypes =
  | ResourceTypes.TEMPLATE
  | ResourceTypes.PROJECT
  | ResourceTypes.CERTIFICATE;

export const CachedResourceTypes: SupportedTypes[] = [
  ResourceTypes.TEMPLATE,
  ResourceTypes.PROJECT,
  ResourceTypes.CERTIFICATE,
];

export type CacheResourceRecord = {
  id: string;
  name: string;
  projectId: string;
  changeTime: Date;
};

export class DB extends Dexie {
  [ResourceTypes.TEMPLATE]: Dexie.Table<CacheResourceRecord, string>;
  [ResourceTypes.PROJECT]: Dexie.Table<CacheResourceRecord, string>;
  [ResourceTypes.CERTIFICATE]: Dexie.Table<CacheResourceRecord, string>;
  [ResourceTypes.USER]: Dexie.Table<User, string>;

  constructor(name: string) {
    super(name);

    this.version(1).stores({
      templates: '&id, name, changeTime, projectId',
      projects: '&id, name, changeTime, projectId',
      certificates: '&id, name, changeTime, projectId',
    });

    this.version(2)
      .stores({
        users: '&id, displayName',
        templates: '&id, name, changeTime, projectId',
        projects: '&id, name, changeTime, projectId',
        certificates: '&id, name, changeTime, projectId',
      })
      .upgrade((tx) => {
        return tx;
      });

    this[ResourceTypes.TEMPLATE] = this.table('templates');
    this[ResourceTypes.PROJECT] = this.table('projects');
    this[ResourceTypes.CERTIFICATE] = this.table('certificates');
    this[ResourceTypes.USER] = this.table('users');
  }

  get(type: SupportedTypes | ResourceTypes.USER, id: string): PromiseExtended {
    return this[type].get(id);
  }

  list(type: SupportedTypes): PromiseExtended {
    return this[type].toArray();
  }

  bulkPut(type: SupportedTypes, items: CacheResourceRecord[]): PromiseExtended {
    return this[type].bulkPut(items);
  }

  async clearDB(): Promise<unknown[]> {
    return Promise.all(
      CachedResourceTypes.map((type: SupportedTypes) => {
        return this[type].clear();
      })
    );
  }

  async getLatestChange(): Promise<Date | null> {
    let lastChange: Date | null = null;

    const partials: (Date | null)[] = await Promise.all(
      CachedResourceTypes.map(async (t) => {
        const l = await this[t as SupportedTypes].orderBy('changeTime').last();
        return l ? l.changeTime : null;
      })
    );

    partials.forEach((i: Date | null) => {
      if (
        lastChange === null ||
        (i !== null && isDate(lastChange) && lastChange.getTime() < i.getTime())
      ) {
        lastChange = i;
      }
    });

    return lastChange;
  }
}
