import { useSnackbar } from 'notistack';
import { ReactNode } from 'react';

interface HookState {
  addNotification: (n: {
    message: ReactNode | JSX.Element;
    level?: 'success' | 'error' | 'warning' | 'info';
  }) => void;
  addSuccessNotification: (m: string | ReactNode) => void;
  addErrorNotification: (m: Error | string | ReactNode) => void;
}

export default function useNotification(): HookState {
  const { enqueueSnackbar } = useSnackbar();

  const addNotification = (n: {
    message: ReactNode | JSX.Element;
    level?: 'success' | 'error' | 'warning' | 'info';
  }) => {
    enqueueSnackbar(n.message, { variant: n.level });
  };

  const addErrorNotification = (m: string | ReactNode = 'Success') => {
    addNotification({ message: m, level: 'error' });
  };
  const addSuccessNotification = (m: string | ReactNode = 'Success') => {
    addNotification({ message: m, level: 'success' });
  };

  return { addNotification, addSuccessNotification, addErrorNotification };
}
