import React from 'react';

import {
  ButtonProps as SButtonProps,
  default as SButton,
} from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';

export interface ButtonProps extends SButtonProps {
  tooltip?: string | JSX.Element;
  loading?: boolean;
}

export const Button = (props: ButtonProps): JSX.Element => {
  const { tooltip, loading, ...btnProps } = props;
  const btn = <SButton {...btnProps} disabled={btnProps.disabled || loading} />;
  if (tooltip) {
    return (
      <Tooltip title={tooltip}>
        <span>{btn}</span>
      </Tooltip>
    );
  }
  return btn;
};
