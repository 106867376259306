import { FormikProps, getIn } from 'formik';
import { isArray } from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  FieldEntityTimestamps,
  FieldAutocompleteLanguage,
  FieldStaticProject,
  FieldTextInputCopy,
} from '../../common/fields';
import Form from '../../common/Form';
import { StaticLabel } from '../../common/labels';
import { Template } from '../../../types/Template';
import { TemplateNameFormInput } from './TemplateNameFormInput';

type Props = FormikProps<Template>;

export default class GeneralForm extends Component<Props> {
  public handleChangeLanguage = (value: string[] | undefined) => {
    const prevLangs: string[] = getIn(
      this.props.initialValues,
      'languages',
      []
    );
    if (value && isArray(value) && prevLangs.length <= value.length) {
      this.props.setFieldValue('languages', value);
    }
  };

  // public renderLabel = (item: any, index: number, defaultLabelProps: any) => {
  //   const prevLangs: string[] = getIn(
  //     this.props.initialValues,
  //     'languages',
  //     [],
  //   );
  //   // check if can be removed and then render remove icon with defualtLabelProps.onRemove
  //   const canBeDeleted = !prevLangs.includes(item.value);
  //   return (
  //     <Label
  //       color={index === 0 ? 'blue' : undefined}
  //       onClick={canBeDeleted ? defaultLabelProps.onRemove : undefined}
  //     >
  //       {item.text}
  //       {canBeDeleted && <Icon name="close" />}
  //     </Label>
  //   );
  // };

  public render() {
    const { values } = this.props;

    return (
      <Form>
        {values.id && (
          <FieldTextInputCopy label={StaticLabel.Id} value={values.id} />
        )}
        <FieldStaticProject projectId={values.projectId} />
        <TemplateNameFormInput />
        <FieldAutocompleteLanguage
          helpText={
            <FormattedMessage
              id='PassGeneralForm.languages.helpText'
              defaultMessage='Passes are multilingual, fill this field to define a set of languages into which pass (or its parts) can be translated. The first language (marked with a blue background) is the default language of the template. Languages cannot be removed, that is, they cannot be removed after saving the template.'
            />
          }
          name='languages'
          value={values.languages}
          multiple={true}
          onChange={this.handleChangeLanguage}
          // renderLabel={this.renderLabel}
        />
        <FieldEntityTimestamps data={values} />
      </Form>
    );
  }
}
