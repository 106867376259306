import * as H from 'history';
import React, { Fragment, useEffect } from 'react';
import { Router as ReactRouter } from 'react-router';
// Create history object.
import PageHeader from '../components/common/page/PageHeader';
import Routes from './Routes';
import { pageview } from '../tracker';

export const history = H.createBrowserHistory();

const Router = () => {
  useEffect(() => {
    const unlisten = history.listen((location: H.Location) => {
      pageview(location.pathname);
    });
    return () => {
      if (unlisten) {
        unlisten();
      }
    };
  });

  return (
    <Fragment>
      <PageHeader />
      <ReactRouter history={history}>
        <Routes />
      </ReactRouter>
    </Fragment>
  );
};

export default Router;
