import { getIn, useFormikContext } from 'formik';
import { get, isArray } from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles';

import {
  FieldAutocompleteCertificate,
  FieldAutocompleteLanguage,
  FieldSet,
} from '../../common/fields';
import Form from '../../common/Form';
import { ImageTypes, Template } from '../../../types/Template';
import { PkpassString } from './inputs';
import { Button } from '../../common/buttons';
import { useTemplateUI } from './useTemplateUI';

interface Props {
  className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      margin: theme.spacing(1),
    },
  })
);

export const PkpassIdentification = (props: Props): JSX.Element => {
  const theme = useTheme();
  const { setFieldValue, values, errors } = useFormikContext<Template>();
  const classes = useStyles(theme);

  const templateUI = useTemplateUI();

  const handleChange = (value: string | undefined) => {
    setFieldValue('certificateId', value);
  };

  let languageErr = get(errors, 'languages');

  if (isArray(languageErr)) {
    languageErr = languageErr.join(',');
  }

  const handleChangeLanguage = (value: string[] | undefined) => {
    setFieldValue('languages', value);
  };

  return (
    <Card className={props.className || classes.card}>
      <CardHeader
        title={
          <FormattedMessage
            id='pkpass.certificate.section.header'
            defaultMessage='Identification'
          />
        }
        subheader={
          <FormattedMessage
            id='pkpass.certificate.section.infoText'
            defaultMessage='Certificate, icon, and organization name are cached for pass in wallet. Once user add pkpass in to wallet, changes on this fields are not displayed on pass.'
          />
        }
      />
      <CardContent>
        <Form>
          <FieldAutocompleteLanguage
            helpText={
              <FormattedMessage
                id='StepRequiredFields.languages.helpText'
                defaultMessage='Passes are multilingual, fill this field to define a set of languages into which pass (or its parts) can be translated. The first language (marked with a blue background) is the default language of the template. Languages cannot be removed, that is, they cannot be removed after saving the template.'
              />
            }
            name='languages'
            value={values.languages}
            multiple={true}
            onChange={handleChangeLanguage}
            error={languageErr}
            required={true}
          />

          <FieldAutocompleteCertificate
            name='certificateId'
            required={true}
            multiple={false}
            onChange={handleChange}
            value={getIn(values, 'certificateId')}
            helpText={
              <FormattedMessage
                id='pkpass.certificate.helpText'
                defaultMessage="The certificate is used to identify the card issuer by the user's Wallet. If there are no certificate, contact your administrator for help."
              />
            }
            error={
              get(errors, 'certificateId')
                ? `${get(errors, 'certificateId')}`
                : undefined
            }
          />

          <PkpassString
            required={true}
            label={
              <FormattedMessage
                id='pkapss.organizationName.title'
                defaultMessage='Organization name'
              />
            }
            helpText={
              <FormattedMessage
                id='pkpass.organizationName.helpText'
                defaultMessage='Display name of the organization that originated and signed the pass. Organization name is displayed on the device when the notification is sent and also on the preview page when the card is displayed.'
              />
            }
            name='jsonTemplate.organizationName'
          />

          <FieldSet
            label={<FormattedMessage id='Pkpass.icon' defaultMessage='Icon' />}
            helpText={
              <FormattedMessage
                id={
                  'The icon is displayed on the mobile phone when is notification sent and also as a favicon on the preview page.'
                }
              />
            }
          >
            <Button
              onClick={() => {
                templateUI.uiSetTemplateForceOpenImage(ImageTypes.icon);
              }}
            >
              Select icon
            </Button>
          </FieldSet>
        </Form>
      </CardContent>
    </Card>
  );
};
