import md5 from 'js-md5';
import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';

import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import useLocale from '../../../../../../../../hooks/useLocale';
import useTimezone from '../../../../../../../../hooks/useTimezone';
import { Menu, MenuItem, ListItemIcon } from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { useUserContext } from '../../../../../../../../context';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      minHeight: 'fit-content',
      marginTop: theme.spacing(2),
    },
    avatar: {
      width: 40,
      height: 40,
    },
    text: {
      color: 'rgba(255,255,255, 0.7)',
      textDecoration: 'none',
      cursor: 'pointer',
      '&:hover': {
        color: 'rgba(255,255,255)',
        // textDecoration: 'underline'
      },
    },
    text2: {
      color: 'rgba(255,255,255, 0.5)',
    },
  })
);

const Profile = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const history = useHistory();
  const { viewer, signOut } = useUserContext();
  const { timezone } = useTimezone();
  const { locale } = useLocale();
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);

  if (!viewer) {
    return null;
  }

  const user = {
    name: viewer.displayName,
    avatar: `https://www.gravatar.com/avatar/${md5(viewer.email)}?d=404`,
    bio: viewer.email,
  };

  const handleProfile = () => {
    history.push('/settings');
    handleClose();
  };

  const handleLogout = () => {
    handleClose();
    signOut();
  };

  const handleOpen = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <List>
        <ListItem key={timezone} alignItems='flex-start'>
          <ListItemAvatar>
            <Avatar
              alt={viewer.displayName}
              className={classes.avatar}
              src={user.avatar}
            />
          </ListItemAvatar>
          <ListItemText
            primary={
              <div className={classes.text} onClick={handleOpen}>
                {user.name}
              </div>
            }
            secondary={
              <React.Fragment>
                <Typography
                  component='span'
                  variant='body2'
                  className={classes.text2}
                >
                  {timezone}
                </Typography>
                <br />
                <Typography
                  component='span'
                  variant='body2'
                  className={classes.text2}
                >
                  {locale}
                </Typography>
              </React.Fragment>
            }
          />
        </ListItem>
      </List>
      <Menu
        id='simple-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        // anchorPosition={{ top: 200, left: 400 }}
      >
        <MenuItem onClick={handleProfile}>
          <ListItemIcon>
            <SettingsIcon fontSize='small' />
          </ListItemIcon>
          <Typography variant='inherit'>Settings</Typography>
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <ExitToAppIcon fontSize='small' />
          </ListItemIcon>
          <Typography variant='inherit'>Logout</Typography>
        </MenuItem>
      </Menu>
    </>
  );
};

Profile.propTypes = {
  className: PropTypes.string,
};

export default Profile;
