import { FormikProps } from 'formik';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import * as Yup from 'yup';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import {
  FieldAutocompleteProject,
  FieldJSON,
  FieldTextInput,
  FieldTextInputData,
} from '../../components/common/fields';
import Form from '../../components/common/Form';
import { CodeIcon, EditIcon } from '../../components/common/icons';
import { StaticLabel } from '../../components/common/labels';
import FormTab, { TabOption } from '../../components/common/Tabs';
import useViewer from '../../hooks/useViewer';
import { PkpassCertificateFormUpload } from './PkpassCertificateFormUpload';

const FORM_NAME = 'certificate';

interface CertificateFormikFormProps {
  formik: FormikProps<PkpassCertificateFormInterface>;
}

export const CertificateFormSchema = Yup.object().shape({
  name: Yup.string().min(3, 'Too Short!').required('Required'),
  projectId: Yup.string().required('Required'),
});

export interface PkpassCertificateFormInterface {
  id?: string;
  name?: string;
  projectId?: string;
  passTypeId?: string;
}

export const PkpassCertificateFormikForm = (
  props: CertificateFormikFormProps
): JSX.Element => {
  const { formik } = props;
  const viewer = useViewer();

  const handleChangeJSON = (data: { name: string; value: unknown }) => {
    formik.setValues(data.value as PkpassCertificateFormInterface);
  };

  const handleDropdownChange = (value: string | undefined) => {
    formik.setFieldValue('projectId', value);
  };

  const handleTextChange = (value: FieldTextInputData) => {
    formik.setFieldValue(value.name, value.value);
  };

  const tabOptions: TabOption[] = [
    {
      content: (
        <Card>
          <CardContent>
            <Form>
              <FieldTextInput
                error={formik.errors.name}
                label={StaticLabel.Name}
                required={true}
                helpText={
                  <FormattedMessage
                    id={'pkpass.certificate.form.name.helpText'}
                    defaultMessage={'Name of certificate'}
                  />
                }
                name='name'
                value={formik.values.name}
                onChange={handleTextChange}
                onBlur={formik.handleBlur}
                disabled={!!formik.values.id}
              />

              <FieldAutocompleteProject
                error={formik.errors.projectId}
                disabled={!(!formik.values.id || (viewer && viewer.isAdmin))}
                name='projectId'
                onChange={handleDropdownChange}
                onBlur={formik.handleBlur}
                value={formik.values.projectId}
                multiple={false}
                required={true}
                helpText={
                  <FormattedMessage
                    id={'pkpass.certificate.form.projectId.helpText'}
                    defaultMessage={
                      'Certificate is available in selected project and his children.'
                    }
                  />
                }
              />

              {formik.values.id && (
                <FieldTextInput
                  label={StaticLabel.PassTypeIdentifier}
                  helpText={
                    <FormattedMessage
                      id='pkpass.certificate.form.passTypeIdentifier.helpText'
                      defaultMessage='Pass type identifier, as issued by Apple. The value must correspond with your signing certificate.'
                    />
                  }
                  disabled={true}
                  name='passTypeId'
                  value={formik.values.passTypeId}
                />
              )}
            </Form>

            {formik.values.id && (
              <PkpassCertificateFormUpload data={formik.values} />
            )}
          </CardContent>
        </Card>
      ),
      icon: <EditIcon />,
      key: 'form',
      name: StaticLabel.Edit,
    },
  ];

  if (viewer && viewer.isAdmin) {
    tabOptions.push({
      content: (
        <Card>
          <CardContent>
            <Form>
              <FieldJSON
                onChange={handleChangeJSON}
                label='JSON'
                name='json'
                value={formik.values}
              />
            </Form>
          </CardContent>
        </Card>
      ),
      icon: <CodeIcon />,
      key: 'json',
      name: 'Json',
    });
  }

  return (
    <FormTab tabOptions={tabOptions} formName={FORM_NAME} defaultTab='form' />
  );
};
