import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    item: {
      color: 'rgba(255,255,255,0.6)',
      paddingLeft: theme.spacing(2),
      '&:hover': {
        color: '#FFF',
        '& .MuiSvgIcon-root': {
          color: '#FFF',
        },
      },
      '& .MuiSvgIcon-root': {
        color: 'rgba(255,255,255,0.6)',
      },

      '& .MuiSvgIcon-root:hover': {
        color: '#FFF',
      },

      '& .MuiListItemSecondaryAction-root': {
        color: 'rgba(255,255,255,0.6)',
      },
    },
    active: {
      color: '#FFF',
      fontWeight: theme.typography.fontWeightMedium,
      '& .MuiSvgIcon-root': {
        color: '#FFF',
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
    icon: {
      marginTop: 2,
      minWidth: 38,
    },
    section: {
      color: 'rgba(255,255,255,0.6)',
      paddingLeft: theme.spacing(2),
      '&:hover': {
        color: '#FFF',
        '& .MuiSvgIcon-root': {
          color: '#FFF',
        },
      },
      '& .MuiSvgIcon-root': {
        color: 'rgba(255,255,255,0.6)',
      },

      '& .MuiSvgIcon-root:hover': {
        color: '#FFF',
      },

      '& .MuiListItemSecondaryAction-root': {
        color: 'rgba(255,255,255,0.6)',
      },
    },
    expandable: {
      color: 'rgba(255,255,255,0.6)',
    },

    sectionItems: {
      '& .MuiListItem-button': {
        paddingLeft: theme.spacing(4),
      },
    },
  })
);
