import { useState } from 'react';

import pjson from '../../package.json';
import useViewer from './useViewer';

const getKey = (key: string, viewerId: string): string => {
  return `yourpass_console_${key}_${viewerId}_${pjson.version}`;
};

export default function useLocalStorage<T>(
  name: string,
  defaultValue: any = null
) {
  const viewer = useViewer();
  const key = getKey(name, viewer ? viewer.id : '');

  let stored: T | null = defaultValue;
  const d = localStorage.getItem(key);
  if (d) {
    stored = JSON.parse(d);
  }
  const [data, setStateData] = useState<T | null>(stored);

  const setData = (input: any) => {
    localStorage.setItem(key, JSON.stringify(input));
    setStateData(input);
  };
  return { data, setData };
}
