import { mixed, object, string, StringSchema } from 'yup'; // for only what you need

import LANGUAGE_CODES from '../../constants/languageCodes';

const localizableObjectDef: { [key: string]: StringSchema } = {};
const locales: string[] = [];
Object.keys(LANGUAGE_CODES).forEach((c) => {
  localizableObjectDef[c] = string().strict(true);
  locales.push(c);
});

export const localeSchema = mixed().oneOf(locales);
export const localizableObjectSchema = object(localizableObjectDef);
export const localizableStringSchema = string().strict(true);

export const localizableSchema = mixed().test({
  // the validation error message
  message: 'It is not valid localizable string.',
  // unique name identifying the test
  name: 'localizableString',
  // test function, determines schema validity
  test: (value: unknown) => {
    if (value === undefined) {
      return true;
    }
    if (
      typeof value === 'string' &&
      localizableStringSchema.isValidSync(value)
    ) {
      return true;
    }
    if (
      typeof value === 'object' &&
      localizableObjectSchema.isValidSync(value)
    ) {
      return true;
    }
    return false;
  },
});
