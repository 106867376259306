import React from 'react';

import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import { LinkEditIconButton, ListDeleteButton } from '../common/buttons';
import DataGridActions, {
  DataGridActionProps,
} from '../common/dataGrid/DataGridActions';
import { LinkIcon } from '../common/icons';
import { remove, ResourceTypes } from '../../utils/services/api';
import { ImageListItem } from '../../types/Image';
import useViewer from '../../hooks/useViewer';

const ImageListActions = (props: DataGridActionProps<ImageListItem>) => {
  const { item, listLoad, resource } = props;
  const viewer = useViewer();

  const canManage = viewer && viewer.canManageImagesOnProject(item.projectId);
  const doDelete = async () => {
    await remove(ResourceTypes.IMAGE, item.id);
    await listLoad();
  };

  const [anchorEl, setAnchorEl] = React.useState<any>(null);

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget);
    event.preventDefault();
    event.stopPropagation();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <DataGridActions>
      <LinkEditIconButton
        path={`/passes/${resource}/${item.id}`}
        disabled={!canManage}
      />
      <IconButton
        ria-controls='simple-menu'
        aria-haspopup='true'
        onClick={handleClick}
      >
        <MoreHoriz />
      </IconButton>
      <Menu
        id='simple-menu'
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem>
          <ListDeleteButton
            onDelete={doDelete}
            noLabel={true}
            disabled={!canManage}
          />
        </MenuItem>
        <MenuItem>
          <Link href={item.url} target='blank'>
            <IconButton>
              <LinkIcon />
            </IconButton>
          </Link>
        </MenuItem>
      </Menu>
    </DataGridActions>
  );
};

export default ImageListActions;
