import { useSnackbar } from 'notistack';
import React, { ReactNode } from 'react';

export interface InjectedNotificationProps {
  addNotification: (notification: {
    message?: ReactNode | JSX.Element;
    title?: ReactNode | JSX.Element;
    level?: 'success' | 'error' | 'warning' | 'info';
  }) => Notification;
}

export function withNotification<P extends InjectedNotificationProps>(
  WreppedComponent: React.ComponentType<P>
): React.ComponentType<
  Pick<P, Exclude<keyof P, keyof InjectedNotificationProps>>
> {
  return function NotificationProvider(
    props: Pick<P, Exclude<keyof P, keyof InjectedNotificationProps>>
  ) {
    const { enqueueSnackbar } = useSnackbar();
    const addNotification = (n: {
      message: ReactNode | JSX.Element;
      level?: 'success' | 'error' | 'warning' | 'info';
    }) => {
      enqueueSnackbar(n.message, { variant: n.level });
    };
    return (
      <WreppedComponent {...(props as P)} addNotification={addNotification} />
    );
  };
}
