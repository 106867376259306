import { getIn, useFormikContext } from 'formik';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import Form from '../../../common/Form';
import { ILocalizable, Template } from '../../../../types/Template';
import { useTemplateUI } from '../useTemplateUI';
import { PkpassLocalizedString } from '../inputs';

import useOpen from '../../../../hooks/useOpen';
import FormikDialog from '../../../common/formik/FormikDialog';
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import { AddIcon, EditIcon } from '../../../common/icons';
import Tooltip from '@material-ui/core/Tooltip';
import TemplatePreviewLanguageSelect from '../../../template/TemplatePreviewLanguageSelect';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    iconButton: {
      color: theme.palette.getContrastText(green[500]),
      backgroundColor: green[500],
      pointerEvents: 'auto',
      marginBottom: 60,
      boxShadow:
        '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
      '&:hover': {
        backgroundColor: green[700],
      },
    },
    preview: {
      float: 'left',
      fontWeight: 'bold',
      height: 25,
      minWidth: 50,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  })
);

export const EditLogoText = (): JSX.Element | null => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const templateUI = useTemplateUI();
  const formikProps = useFormikContext<Template>();
  const oi = useOpen(false);
  let preview = getIn(formikProps.values, 'jsonTemplate.logoText.value');

  if (typeof preview === 'object') {
    preview = preview[templateUI.templatePreviewLanguage];
  }

  const onChange = (v: {
    logoText:
      | {
          type: 'string';
          value: ILocalizable;
          fallback?: string;
        }
      | undefined;
  }) => {
    formikProps.setFieldValue('jsonTemplate.logoText', v.logoText);
  };

  return (
    <>
      <Tooltip title={'Add logo text.'}>
        <IconButton
          size={'small'}
          type='button'
          onClick={oi.handleOpen}
          className={classes.iconButton}
        >
          {preview ? (
            <EditIcon fontSize={'small'} />
          ) : (
            <AddIcon fontSize={'small'} />
          )}
        </IconButton>
      </Tooltip>
      <FormikDialog
        isOpen={oi.open}
        onClose={oi.handleClose}
        onSubmit={onChange}
        content={<Content languages={formikProps.values.languages} />}
        initialValues={{
          logoText: formikProps.values.jsonTemplate.logoText,
        }}
        title={
          <div>
            Logo text
            <span style={{ float: 'right' }}>
              <TemplatePreviewLanguageSelect
                languages={formikProps.values.languages}
              />
            </span>
          </div>
        }
      />
    </>
  );
};

const Content = (props: { languages: string[] }) => {
  return (
    <Form>
      <PkpassLocalizedString
        languages={props.languages}
        label={
          <FormattedMessage
            id='pkpass.logoText.title'
            defaultMessage='Logo text'
          />
        }
        helpText={
          <FormattedMessage
            id='pkpass.logoText.helpText'
            defaultMessage='Text displayed next to the logo on the pass.'
          />
        }
        name='logoText'
      />
    </Form>
  );
};
