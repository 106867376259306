import React from 'react';

import AppBar from '@material-ui/core/AppBar';
import Container from '@material-ui/core/Container';
import Hidden from '@material-ui/core/Hidden';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
// import useScrollTrigger from '@material-ui/core/useScrollTrigger';

import PageToolbar from './PageToolbar';

export interface PageProps {
  content: JSX.Element;
  controls?: JSX.Element;
  title?: JSX.Element | string;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(1) + 64,
    },
  },
  appBar: {
    backgroundColor: '#fff',
    width: 'auto',
    left: 0,
    top: 64,
    [theme.breakpoints.up('md')]: {
      left: 240,
      top: 0,
    },
  },
  spacer: {
    height: 64 * 1 + theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      height: theme.spacing(1),
    },
    width: '100%',
  },
  title: { flexGrow: 1, color: '#000' },
  toolbar: {
    '& > *': {
      padding: theme.spacing(1),
    },
  },
  controls: {
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
      justifyContent: 'space-between',
    },
  },
  childrenContainer: {},
}));

export default function Page(props: PageProps): JSX.Element {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Hidden mdUp={true} implementation='css'>
        <AppBar className={classes.appBar} position='static' elevation={0}>
          <Toolbar className={classes.controls}>
            <Typography variant='h6' className={classes.title}>
              {props.title}
            </Typography>
            <PageToolbar>{props.controls}</PageToolbar>
          </Toolbar>
        </AppBar>
      </Hidden>
      <Hidden smDown={true} implementation='css'>
        {/* <ElevationScroll {...props}> */}
        <AppBar className={classes.appBar}>
          <Toolbar className={classes.controls}>
            <Typography variant='h6' className={classes.title}>
              {props.title}
            </Typography>
            <PageToolbar>{props.controls}</PageToolbar>
          </Toolbar>
        </AppBar>
        {/* </ElevationScroll> */}
      </Hidden>
      <div className={classes.spacer} />
      <Container className={classes.childrenContainer} maxWidth='xl'>
        {props.content}
      </Container>
    </div>
  );
}
/*
interface Props {
  window?: () => Window;
  children: React.ReactElement;
}

function ElevationScroll(props: Props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}
*/
