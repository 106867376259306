import React, { Fragment, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router';

import SendIcon from '@material-ui/icons/Send';

import {
  Button,
  CopyButton,
  RefreshButton,
  ResetButton,
  SaveButton,
} from '../common/buttons';
import { ResourceDeleteButton } from '../common/ResourceDeleteButton';
import useNotification from '../../hooks/useNotification';
import useViewer from '../../hooks/useViewer';
import {
  create,
  ResourceTypes,
  templateUpdatePassesByPush,
} from '../../utils/services/api';
import { Template } from '../../types/Template';
import { FormikEditResourceRenderProps } from '../common/formik/FormikEditResource';

const TemplateEditControls = (
  props: FormikEditResourceRenderProps<Template>
) => {
  const [pushLocked, setPushLocked] = useState(false);
  const history = useHistory();
  const { addNotification } = useNotification();
  const { values, dirty } = props;
  const viewer = useViewer();

  const handleDuplicate = async () => {
    const data = JSON.parse(JSON.stringify(props.values));
    data.name = `${data.name} - copy`;
    delete data.id;
    delete data.updatedAt;
    delete data.createdAt;
    delete data.deletedAt;

    try {
      const template = await create(ResourceTypes.TEMPLATE, data);
      addNotification({
        level: 'success',
        message: `Copy of template was created`,
      });
      history.push(`/passes/template/${template.id}`);
    } catch (e) {
      addNotification({
        level: 'error',
        message: e.message,
      });
    }
  };

  const handleReset = () => {
    props.resetForm({ values: props.initialValues });
  };

  const handlePush = async () => {
    const id = values.id;
    setPushLocked(true);
    try {
      await templateUpdatePassesByPush(id);
      addNotification({
        level: 'success',
        message: `Passes for template ${id} was notified to check updates. (by push notification)`,
      });
    } catch (e) {
      addNotification({
        level: 'error',
        message: e.message,
      });
    }
    setTimeout(() => {
      setPushLocked(false);
    }, 5000);
  };

  const projectId = values.projectId;
  const canCreate = viewer && viewer.canCreateTemplateOnProject(projectId);
  const canUpdate = viewer && viewer.canUpdateTemplateOnProject(projectId);
  const canSendPush = viewer && viewer.canUpdateTemplateOnProject(projectId);
  const canDelete = viewer && viewer.canDeleteTemplateOnProject(projectId);

  return (
    <Fragment>
      {canUpdate && (
        <SaveButton
          color='primary'
          onClick={props.submitForm}
          disabled={!dirty}
          tooltip={
            <FormattedMessage
              id='TemplateEditControls.save.tooltip'
              defaultMessage='Saves the current changes and creates a new version of the template. Use the push button to update existing passes.'
            />
          }
        />
      )}

      {canSendPush && (
        <Button
          color='secondary'
          onClick={handlePush}
          disabled={dirty || pushLocked}
          tooltip={
            <FormattedMessage
              id='TemplateEditControls.push.tooltip'
              defaultMessage='Updates existing passes (on your phone).'
            />
          }
          startIcon={<SendIcon />}
        >
          <FormattedMessage
            id='TemplateEditControls.push.label'
            defaultMessage='Push'
          />
        </Button>
      )}

      <ResetButton
        onClick={handleReset}
        disabled={!dirty}
        tooltip={
          <FormattedMessage
            id='TemplateEditControls.reset.tooltip'
            defaultMessage='Sets the template to its original state before any changes were made.'
          />
        }
      />

      <RefreshButton
        onClick={props.refresh}
        tooltip={
          <FormattedMessage
            id='TemplateEditControls.refresh.tooltip'
            defaultMessage='Reload template from database.'
          />
        }
      />

      {canCreate && (
        <CopyButton
          onClick={handleDuplicate}
          disabled={dirty}
          tooltip={
            <FormattedMessage
              id='TemplateEditControls.copy.tooltip'
              defaultMessage='A copy of the template is created.'
            />
          }
        />
      )}

      {canDelete && (
        <ResourceDeleteButton
          resource={ResourceTypes.TEMPLATE}
          resourceId={values.id}
          tooltip={
            <FormattedMessage
              id='TemplateEditControls.delete.tooltip'
              defaultMessage='Performs irreversible deletion of the template (no longer offered in the console). Passes will be retained.'
            />
          }
        />
      )}
    </Fragment>
  );
};

export default TemplateEditControls;
