import React, { FunctionComponent, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import ResourcePage from '../../../components/common/page/ResourcePage';
import useViewer from '../../../hooks/useViewer';
import { ResourcePageProps } from '../../types';
import {
  ProjectCreateDrawer,
  ProjectEditDrawer,
  ProjectList,
} from '../../../domain';

export const ProjectPage: FunctionComponent<ResourcePageProps> = (
  props: ResourcePageProps
) => {
  const viewer = useViewer();

  const [key, setKey] = useState(0);
  return (
    <ResourcePage
      {...props}
      DrawerComponent={ProjectEditDrawer}
      content={
        <ProjectList
          key={`${key}`}
          onCreate={() => {
            setKey(key + 1);
          }}
        />
      }
      controls={
        viewer && viewer.isAdmin ? (
          <ProjectCreateDrawer
            parentId={undefined}
            disabled={false}
            onCreate={() => {
              setKey(key + 1);
            }}
          />
        ) : undefined
      }
      title={
        <FormattedMessage
          id='ProjectListPage.title'
          defaultMessage='Projects'
        />
      }
      onCloseDrawer={() => {
        props.history.push('/identity/project');
      }}
    />
  );
};
