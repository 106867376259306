import React, { useCallback, useRef, useState } from 'react';
import { Checkboard } from 'react-color/lib/components/common';
import { DropEvent, DropzoneInputProps, useDropzone } from 'react-dropzone';
import { FormattedMessage } from 'react-intl';

import useNotification from '../../../hooks/useNotification';
import { Button } from '../buttons';
import { FileIcon } from '../icons';

interface BaseProps {
  accept:
    | 'image/png'
    | 'text/csv'
    | 'text/csv, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel';
  infoText: React.ReactNode;
  disabled?: boolean;
}

interface SingleProps extends BaseProps {
  multiple?: false;
  value?: string;
  onSelect: (f: File) => void;
}

interface MultipleProps extends BaseProps {
  multiple: true;
  value?: string[];
  onSelect: (files: File[]) => void;
}

type Props = SingleProps | MultipleProps;

export default function FieldFileDropzone(props: Props): JSX.Element {
  const { addNotification } = useNotification();
  const inputRef = useRef<HTMLInputElement>(null);
  const [selected, setSelected] = useState<File[]>([]);

  const onDrop = useCallback(
    (acceptedFiles: File[], rejectedFiles: File[], event: DropEvent) => {
      if (!props.disabled) {
        if (rejectedFiles.length > 0) {
          addNotification({
            level: 'error',
            message: (
              <FormattedMessage
                defaultMessage='Unsupported file format {mimeType} of file {name}, please use {accept}'
                id='Dropzone.unsupported.files.warning'
                values={{
                  accept: props.accept,
                  mimeType: rejectedFiles[0].type,
                  name: rejectedFiles[0].name,
                }}
              />
            ),
          });
          return;
        }

        if (props.multiple) {
          props.onSelect(acceptedFiles);
          setSelected(acceptedFiles);
        } else {
          props.onSelect(acceptedFiles[0]);
        }
      }
    },
    [addNotification, props]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: props.accept,
    // maxSize: 2 * 1024 * 1024,
    multiple: props.multiple,
    onDrop,
  });

  const inputProps: DropzoneInputProps = getInputProps();

  const handleClick = () => {
    if (inputRef && inputRef.current) {
      inputRef.current.click();
    }
  };

  return (
    <div>
      <div
        {...getRootProps()}
        style={isDragActive ? dragZoneStyleActive : dragZoneStyle}
        onClick={handleClick}
      >
        <input {...inputProps} ref={inputRef} />

        {props.value && !props.multiple && props.accept === 'image/png' && (
          <img alt='preview' src={props.value} style={previewImageStyle} />
        )}
        {props.value && !props.multiple && props.accept === 'image/png' && (
          <FileIcon style={previewImageStyle} />
        )}

        {!props.value && selected.length === 0 && (
          <div style={dragInfoMessageStyle}>{props.infoText}</div>
        )}
        {props.accept === 'image/png' && !props.multiple && <Checkboard />}
        {props.multiple && selected.length > 0 && (
          <>
            <div style={dragInfoMessageStyle}>
              <FormattedMessage
                id={'FieldFileDropzone'}
                defaultMessage={'Selected {count} files: '}
                values={{ count: selected.length }}
              />
            </div>
            <div style={selectedFilesStyle}>
              {selected.map((f: File) => f.name).join(', ')}
            </div>
          </>
        )}
      </div>
      <div style={{ width: 320, textAlign: 'center' }}>
        <Button onClick={handleClick} disabled={props.disabled}>
          <FormattedMessage id='Dropzone' defaultMessage={'Browse files'} />
        </Button>
      </div>
    </div>
  );
}

const dragZoneStyle = {
  alignItems: 'center' as const,
  borderColor: 'rgb(102, 102, 102)',
  borderRadius: '5px',
  borderStyle: 'dashed',
  borderWidth: '2px',
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'column' as const,
  height: 320,
  justifyContent: 'center' as const,
  position: 'relative' as const,
  width: 320,
};

const dragZoneStyleActive = { ...dragZoneStyle, borderColor: 'rgb(0,255,0)' };

const previewImageStyle = {
  bottom: 0,
  left: 0,
  margin: 'auto',
  maxHeight: 300,
  maxWidth: 300,
  position: 'absolute' as const,
  right: 0,
  top: 0,
  zIndex: 1000,
};

const dragInfoMessageStyle = {
  fontSize: 20,
  maxWidth: 200,
  textAlign: 'center' as const,
  textShadow: '0 0 4px #fff',
  zIndex: 1000,
};

const selectedFilesStyle = {
  fontSize: 12,
  maxWidth: 200,
  textAlign: 'center' as const,
  textShadow: '0 0 4px #fff',
  zIndex: 1000,
};
