import PropTypes from 'prop-types';
import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';

import EditDrawerInnerLayout from '../common/drawers/EditDrawerInnerLayout';
import FormikEditDrawer from '../common/formik/FormikEditDrawer';
import { LabelProject } from '../common/labels';
import { ResourceTypes } from '../../utils/services/api';
import { Template } from '../../types/Template';
import schema from '../../utils/validation/template';
import TemplateEditControls from './TemplateEditControls';
import TemplateEditTabs from './TemplateEditTabs';
import { FormikEditResourceRenderProps } from '../common/formik/FormikEditResource';

interface TemplateEditDrawerProps {
  id: string;
  open: boolean;
  onClose: () => void;
}

const TemplateEditDrawer: FunctionComponent<TemplateEditDrawerProps> = (
  props: TemplateEditDrawerProps
): JSX.Element => {
  const Edit: FunctionComponent<FormikEditResourceRenderProps<Template>> = (
    p: FormikEditResourceRenderProps<Template>
  ): JSX.Element => {
    return (
      <EditDrawerInnerLayout
        onClose={props.onClose}
        content={<TemplateEditTabs {...p} />}
        controls={<TemplateEditControls {...p} />}
        title={
          <span>
            <FormattedMessage
              id='TemplateEdit.title'
              defaultMessage='Edit template'
            />
            <Breadcrumbs aria-label='breadcrumb'>
              <LabelProject resourceId={p.values.projectId} />
              <span>{p.values.id}</span>
            </Breadcrumbs>
          </span>
        }
      />
    );
  };

  return (
    <FormikEditDrawer
      onClose={props.onClose}
      resource={ResourceTypes.TEMPLATE}
      id={props.id}
      validationSchema={schema}
      open={props.open}
      Component={Edit}
    />
  );
};

TemplateEditDrawer.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default TemplateEditDrawer;
