import { CSSProperties } from 'react';

export const linkStyle: CSSProperties = {
  color: '#2185D0',
  cursor: 'pointer',
};

export const errorBorderStyle: CSSProperties = {
  border: '2px solid #DB2828',
  outline: '2px solid #fff',
};

export const dropAreaStyle = {
  border: '1px solid #fbbd08',
  borderRadius: 5,
  boxShadow: '#fbbd08 0px 0px 15px',
  opacity: 0.5,
};

export default {
  link: linkStyle,
};
