import { FormikProps } from 'formik';
import PropTypes from 'prop-types';
import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import { SaveButton } from '../../components/common/buttons';
import EditDrawerInnerLayout from '../../components/common/drawers/EditDrawerInnerLayout';
import FormikEditDrawer from '../../components/common/formik/FormikEditDrawer';
import { ResourceDeleteButton } from '../../components/common/ResourceDeleteButton';
import { ResourceTypes } from '../../utils/services/api';
import {
  PkpassCertificateFormInterface,
  PkpassCertificateFormikForm,
} from './PkpassCertificateFormikForm';

const PkpassCertificateFormControls: FunctionComponent<FormikProps<
  PkpassCertificateFormInterface
>> = (props: FormikProps<PkpassCertificateFormInterface>): JSX.Element => {
  const { isSubmitting, dirty, submitForm, values } = props;
  return (
    <div>
      {!!values.id && (
        <ResourceDeleteButton
          disabled={isSubmitting}
          resource={ResourceTypes.CERTIFICATE}
          resourceId={values.id}
        />
      )}
      <SaveButton
        loading={isSubmitting}
        disabled={!dirty}
        onClick={submitForm}
      />
    </div>
  );
};

interface PkpassCerticateEditDrawerProps {
  id: string;
  open: boolean;
  onClose: () => void;
}

export const PkpassCertificateEditDrawer: FunctionComponent<PkpassCerticateEditDrawerProps> = (
  props: PkpassCerticateEditDrawerProps
): JSX.Element => {
  const Edit: FunctionComponent<FormikProps<PkpassCertificateFormInterface>> = (
    fp: FormikProps<PkpassCertificateFormInterface>
  ) => {
    return (
      <EditDrawerInnerLayout
        onClose={props.onClose}
        title={
          <FormattedMessage
            id='pkpass.certificate.drawer.edit.title'
            defaultMessage='Edit certificate'
          />
        }
        content={<PkpassCertificateFormikForm formik={fp} />}
        controls={<PkpassCertificateFormControls {...fp} />}
      />
    );
  };

  return (
    <FormikEditDrawer
      onClose={props.onClose}
      resource={ResourceTypes.CERTIFICATE}
      id={props.id}
      open={props.open}
      Component={Edit}
    />
  );
};

PkpassCertificateEditDrawer.propTypes = {
  onClose: PropTypes.func.isRequired,
};
