import React from 'react';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import useViewer from '../../hooks/useViewer';
import PassBulkDrawer from './bulk/PassBulkDrawer';
import PassCreateDrawer from './PassCreateDrawer';
import { OnCreateProps } from '../../types';

const PassListControls = (props: OnCreateProps) => {
  const viewer = useViewer();
  const canCreate = viewer && viewer.canCreatePass();
  const canUpdatePass = viewer && viewer.canUpdatePass();
  return (
    <ButtonGroup
      variant='text'
      color='primary'
      aria-label='text primary button group'
    >
      {canCreate && canUpdatePass && <PassBulkDrawer />}
      {canCreate && <PassCreateDrawer {...props} />}
    </ButtonGroup>
  );
};

export default PassListControls;
