import React, { useState } from 'react';

import Step from '@material-ui/core/Step';
import StepContent from '@material-ui/core/StepContent';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import HelpPopUp from '../HelpPopUp';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actionsContainer: {
      marginBottom: theme.spacing(2),
      '& > *': {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
      },
    },
  })
);

export interface VerticalStepperControls {
  step: number;
  next: () => void;
  prev: () => void;
  reset: () => void;
  setStep: (step: number) => void;
}

export const useVerticalStepper = (): VerticalStepperControls => {
  const [step, setStep] = useState<number>(0);

  const prev = () => {
    setStep(step > 0 ? step - 1 : 0);
  };

  const next = () => {
    setStep(step + 1);
  };

  const reset = () => {
    setStep(0);
  };

  return { step, next: next, prev: prev, reset: reset, setStep: setStep };
};

export interface VerticalStepItem {
  title: React.ReactNode;
  subtitle: React.ReactNode;
  content: React.ReactNode;
  actions?: React.ReactNode;
  helpText?: React.ReactNode;
  error?: string;
}

export const VerticalStepper = (props: {
  activeStep: number;
  steps: VerticalStepItem[];
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  return (
    <Stepper activeStep={props.activeStep} orientation='vertical'>
      {props.steps.map((step: VerticalStepItem, index: number) => (
        <Step
          key={index}
          active={props.activeStep === index}
          disabled={props.activeStep > index}
          completed={props.activeStep > index && !step.error}
        >
          <StepLabel>
            <Typography variant={'h5'}>
              {step.title}
              {step.helpText && <HelpPopUp content={step.helpText} />}
            </Typography>
            <Typography variant={'body2'}>{step.subtitle}</Typography>
          </StepLabel>
          <StepContent>
            {step.content}
            <div className={classes.actionsContainer}>{step.actions}</div>
          </StepContent>
        </Step>
      ))}
    </Stepper>
  );
};
