import React from 'react';
import { FormattedMessage } from 'react-intl';

import { FieldSelect, FieldSelectData, FieldSelectOption } from '../fields';
import { FilterElementProps } from './Filter';

interface Props extends FilterElementProps {
  value: string | undefined;
  onChange: (e: FieldSelectData) => void;
}

const options: FieldSelectOption[] = [
  {
    key: '-',
    text: '-',
    value: '',
  },
  {
    key: 'in',
    text: (
      <FormattedMessage
        id='FilterDevices.in.wallet'
        defaultMessage='In wallet'
      />
    ),
    value: 'in',
  },
  {
    key: 'not_in',
    text: (
      <FormattedMessage
        id='FilterDevices.not.in.wallet'
        defaultMessage='Not in wallet'
      />
    ),
    value: 'not_in',
  },
];

export default function FilterDevices(props: Props) {
  const handleChange = (event: FieldSelectData) => {
    if (props.onChange) {
      props.onChange({ name: props.name, value: event.value as string });
    }
  };

  return (
    <FieldSelect
      name='devices'
      disabled={props.disabled}
      label={props.label}
      helpText={props.helpText}
      options={options}
      value={props.value}
      onChange={handleChange}
    />
  );
}
