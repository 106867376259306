import { getIn } from 'formik';
import React from 'react';
import { useIntl } from 'react-intl';

import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';

import { DeleteButton } from '../../../../common/buttons';
import { PkpassBeacon } from '../../../../../types/Template';
import BeaconLabel from './BeaconLabel';
import BeaconModal from './BeaconModal';
import { messages } from './messages';

interface Props {
  onRemove: () => void;
  beacon: PkpassBeacon;
  onChange: (b: PkpassBeacon) => void;
}

const BeaconListItem = (props: Props): JSX.Element => {
  const { beacon, onChange, onRemove } = props;
  const intl = useIntl();
  return (
    <ListItem>
      <ListItemText
        style={{ color: '#2185D0', cursor: 'pointer' }}
        primary={
          <BeaconModal
            trigger={
              <span>
                <BeaconLabel beacon={beacon} />
              </span>
            }
            initialValues={beacon}
            onChange={onChange}
            onRemove={onRemove}
          />
        }
        secondary={getIn(beacon, 'properties.relevantText.value')}
      />
      <ListItemSecondaryAction>
        <DeleteButton
          onDelete={onRemove}
          confirmText={intl.formatMessage(messages.deleteConfirmationText)}
        />
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default BeaconListItem;
