import React from 'react';

import Checkbox from '@material-ui/core/Checkbox';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import { ResourcePagedHookProps } from '../../../hooks/useResourcesPaged';
import { Order } from '../../../types/common/list';
import { DataGridProps, Field } from './DataGrid';

const useStyles = makeStyles(() =>
  createStyles({
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  })
);

export default function DataGridTableHeader<T extends unknown>(
  props: DataGridProps<T> & ResourcePagedHookProps<T>
): JSX.Element {
  const classes = useStyles();

  const handleSort = (value: string, direction: 'asc' | 'desc') => {
    const { listSetOrder } = props;
    listSetOrder(value, direction === 'asc' ? Order.DESC : Order.ASC);
  };

  const handleSelect = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    if (props.selectAllRows) {
      props.selectAllRows(checked);
    }
  };

  const { orderBy, order, fields } = props;
  const selectedColumn = orderBy;
  const selectedDirection = order === Order.ASC ? 'asc' : 'desc';
  return (
    <TableHead>
      <TableRow>
        {props.selectRow && (
          <TableCell padding='checkbox'>
            <Checkbox onChange={handleSelect} />
          </TableCell>
        )}
        {fields &&
          fields.map((field: Field<T>) => {
            const Title = field.title;
            const sortable = !field.render || (field.render && field.orderBy);

            if (!sortable) {
              return <TableCell key={field.key}>{Title}</TableCell>;
            }

            const handleClick = () => {
              if (sortable) {
                handleSort(field.orderBy || field.key, selectedDirection);
              }
            };
            return (
              <TableCell key={field.key}>
                <TableSortLabel
                  active={field.key === selectedColumn}
                  direction={selectedDirection}
                  onClick={handleClick}
                >
                  {Title}
                  {orderBy === field.key ? (
                    <span className={classes.visuallyHidden}>
                      {order === 'desc'
                        ? 'sorted descending'
                        : 'sorted ascending'}
                    </span>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            );
          })}
      </TableRow>
    </TableHead>
  );
}
