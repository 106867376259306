import React, { useState, Fragment } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { ResourcePagedHookProps } from '../../../hooks';
import { Button } from '../buttons';
import { ExportIcon } from '../icons';
import { DataGridProps } from './DataGrid';
import LinearProgress from '@material-ui/core/LinearProgress';

export const MAX = 100000;

export default function ExportButton<T extends any>(
  props: DataGridProps<T> & ResourcePagedHookProps<T> & { dirty: boolean }
): JSX.Element {
  const [loading, setLoading] = useState(false);
  const [c, setCount] = useState(0);
  const [state, setState] = useState({ current: 0, total: 0 });

  const intl = useIntl();

  const exportToCsv = async () => {
    setLoading(true);
    await props.listExport(props.exportHeaders, setState);
    setLoading(false);
    setCount(0);
    setState({ current: 0, total: 0 });
  };

  const exportInit = async () => {
    setLoading(true);
    const count = await props.count();
    setLoading(false);
    setCount(count);
  };

  const handleCancel = async () => {
    setLoading(false);
    setCount(0);
  };

  if (!props.export) {
    return <span />;
  }

  return (
    <Fragment>
      <Dialog open={c > 0}>
        <DialogTitle>
          {!loading && (
            <FormattedMessage
              id='DataGrid.export.dialog.title.confirm'
              defaultMessage='Export confirmation'
            />
          )}
          {loading && (
            <FormattedMessage
              id='DataGrid.export.dialog.title.procesing'
              defaultMessage='Export is running'
            />
          )}
        </DialogTitle>
        <DialogContent style={{ width: 400 }}>
          {!loading && c <= MAX && (
            <FormattedMessage
              id='DataGrid.export.dialog.content.items'
              defaultMessage='It will export {count} items. It will need some time.'
              values={{ count: intl.formatNumber(c) }}
            />
          )}

          {!loading && c > MAX && (
            <FormattedMessage
              id='DataGrid.export.dialog.content.limit.exceeded'
              defaultMessage='It will export {count} items. You have exceeded the maximum number {max} of exported items. Specify your filter settings in more detail.'
              values={{
                count: intl.formatNumber(c),
                max: intl.formatNumber(MAX),
              }}
            />
          )}

          {loading && (
            <div style={{ width: '100%' }}>
              <LinearProgress
                variant='buffer'
                value={
                  state.total > 0 ? (state.current * 100) / state.total : 0
                }
                valueBuffer={state.total > 0 ? 100 / state.total : 100}
              />
              <p>
                {state.total === 0 && (
                  <FormattedMessage
                    id='DataGrid.export.dialog.content.preparing'
                    defaultMessage='Export is preparing'
                  />
                )}
                {state.total > 0 && (
                  <FormattedMessage
                    id='DataGrid.export.dialog.content.progress'
                    defaultMessage='{percent}% are being processed'
                    values={{
                      ...state,
                      percent: ((100 * state.current) / state.total).toFixed(0),
                    }}
                  />
                )}
              </p>
            </div>
          )}
        </DialogContent>

        <DialogActions>
          <Button onClick={handleCancel} disabled={loading}>
            <FormattedMessage
              id='DataGrid.export.dialog.cancel.action'
              defaultMessage='Cancel'
            />
          </Button>
          <Button
            onClick={exportToCsv}
            color='primary'
            variant='contained'
            disabled={loading || c > MAX}
          >
            <FormattedMessage
              id='DataGrid.export.dialog.confirm.action'
              defaultMessage='Confirm'
            />
          </Button>
        </DialogActions>
      </Dialog>

      <Button
        color={'primary'}
        disabled={loading || props.dirty}
        aria-label='export'
        onClick={exportInit}
        startIcon={<ExportIcon />}
        tooltip={
          <FormattedMessage
            id='DataGrid.export.csv.button.warning'
            defaultMessage='Exports filtered items to an excel file. The maximum number of records is {max}.'
            values={{ max: intl.formatNumber(MAX) }}
          />
        }
      >
        <FormattedMessage
          id='DataGrid.export.csv.button.title'
          defaultMessage='Export'
        />
      </Button>
    </Fragment>
  );
}
