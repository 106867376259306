import { FormikProps } from 'formik';
import React, { useState } from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import {
  FieldEntityTimestamps,
  FieldSwitch,
  FieldTextInput,
} from '../common/fields';
import Form from '../common/Form';
import { LabelProject, StaticLabel } from '../common/labels';
import Tabs, { TabOption } from '../common/Tabs';
import useViewer from '../../hooks/useViewer';
import {
  ResourceTypes,
  setUserProjectAccessRights,
} from '../../utils/services/api';
import { User } from '../../types/User';
import { messages, ProjectUserRightsInput } from '../../domain';
import useNotification from '../../hooks/useNotification';
import { DeleteButton } from '../common/buttons';
import { useIntl } from 'react-intl';
import { useCacheResource } from '../../hooks';

type UserFormProps = FormikProps<User>;

const UserForm = (props: UserFormProps): JSX.Element => {
  const viewer = useViewer();
  const { addSuccessNotification, addErrorNotification } = useNotification();
  const [pending, setPending] = useState(false);

  const saveRights = async (
    projectId: string,
    userId: string,
    accessRights: number | null
  ) => {
    setPending(true);
    try {
      await setUserProjectAccessRights(projectId, userId, accessRights);
      if (accessRights != null) {
        props.setFieldValue(`projects.${projectId}`, accessRights);
      } else {
        const projects = props.values.projects;
        delete projects[projectId];
        props.setFieldValue(`projects`, projects);
      }
      addSuccessNotification('User rights changed');
    } catch (e) {
      addErrorNotification(e);
    }
    setPending(false);
  };

  const { values } = props;

  const tabOptions: TabOption[] = [
    {
      content: (
        <Form>
          <FieldTextInput
            name='id'
            label='Id'
            value={values.id}
            readOnly={true}
          />
          <FieldTextInput
            name='displayName'
            label='Name'
            value={values.displayName}
            readOnly={true}
          />
          <FieldTextInput
            name='email'
            label='Email'
            value={values.email}
            readOnly={true}
          />
          <FieldSwitch
            disabled={true}
            name='isAdmin'
            label='Admin'
            checked={values.isAdmin}
          />
          <FieldEntityTimestamps data={values} />
        </Form>
      ),
      key: 'form',
      name: StaticLabel.User,
    },
  ];

  if (viewer && viewer.isAdmin) {
    tabOptions.push({
      content: (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{StaticLabel.Project}</TableCell>
              <TableCell>{StaticLabel.AccessRights}</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(values.projects).map((projectId: string) => (
              <TableRow key={projectId}>
                <TableCell>
                  <LabelProject resourceId={projectId} />
                </TableCell>
                <TableCell>
                  <ProjectUserRightsInput
                    projectId={projectId}
                    accessRights={values.projects[projectId] as number}
                    userId={values.id}
                    saveRights={saveRights}
                  />
                </TableCell>
                <TableCell>
                  <DeleteBtn
                    disabled={pending}
                    saveRights={saveRights}
                    user={values}
                    projectId={projectId}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ),
      key: 'accessRights',
      name: StaticLabel.AccessRights,
    });
  }

  return (
    <Tabs tabOptions={tabOptions} formName={'UserForm'} defaultTab='form' />
  );
};

const DeleteBtn = (props: {
  projectId: string;
  user: User;
  disabled: boolean;
  saveRights: (p: string, u: string, r: number | null) => void;
}): JSX.Element => {
  const intl = useIntl();
  const proj = useCacheResource(ResourceTypes.PROJECT, props.projectId);

  return (
    <DeleteButton
      disabled={props.disabled}
      size='small'
      onDelete={async () => {
        await props.saveRights(props.projectId, props.user.id, null);
      }}
      confirmText={intl.formatMessage(messages.removeUserMessage, {
        user: props.user.email,
        project: proj.data?.name,
      })}
    />
  );
};

export default UserForm;
