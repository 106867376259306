export enum JsonSchemaTypes {
  String = 'string',
  Number = 'number',
  Integer = 'integer',
  Boolean = 'boolean',
}

export interface JsonSchemaFieldProps {
  name: string;
  title?: string;
  type: JsonSchemaTypes;
  description?: string;

  // string props
  enum?: string[];
  format?: string;
  pattern?: string;

  // number integer props
  exclusiveMaximum?: boolean;
  exclusiveMinimum?: boolean;
  maximum?: number;
  minimum?: number;
}

export type JSONSchema = Record<string, unknown>;
