import {
  FieldArray,
  FieldArrayRenderProps,
  getIn,
  useFormikContext,
} from 'formik';
import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import List from '@material-ui/core/List';
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import Alert from '@material-ui/lab/Alert';

import { GpsLocationIcon } from '../../../../common/icons';
import { PkpassLocation, Template } from '../../../../../types/Template';
import LocationDialog from './LocationDialog';
import LocationListItem from './LocationListItem';

const path = 'jsonTemplate.locations.items';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(1),
    },
  })
);

export const Locations = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { values, setFieldValue, errors } = useFormikContext<Template>();

  const renderArray = (arrayHelper: FieldArrayRenderProps) => {
    const items = getIn(values, path);

    const add = (location: PkpassLocation) => {
      if (items) {
        arrayHelper.push(location);
      } else {
        const locations = {
          items: [location],
          type: 'array',
        };
        setFieldValue(`jsonTemplate.locations`, locations);
      }
    };

    const handleChange = (index: number) => {
      return (b: PkpassLocation) => {
        const name = `${path}.${index}`;
        setFieldValue(name, b);
      };
    };

    const handleRemove = (index: number) => {
      return () => {
        if (items && items.length === 1) {
          const jsonTemplate = getIn(values, `jsonTemplate`);
          delete jsonTemplate.locations;
          setFieldValue(`jsonTemplate`, jsonTemplate);
        } else {
          arrayHelper.remove(index);
        }
      };
    };
    return (
      <Fragment>
        <CardContent>
          <List>
            {items &&
              items.map((location: PkpassLocation, index: number) => {
                return (
                  <LocationListItem
                    location={location}
                    key={index}
                    onRemove={handleRemove(index)}
                    onChange={handleChange(index)}
                    radius={getIn(
                      values,
                      `jsonTemplate.maxDistance.value`,
                      undefined
                    )}
                  />
                );
              })}
          </List>
        </CardContent>
        <CardActions>
          <LocationDialog
            onChange={add}
            radius={getIn(values, `jsonTemplate.maxDistance.value`, undefined)}
            trigger={
              <Button
                startIcon={<AddIcon />}
                disabled={items && items.length >= 10}
              >
                <FormattedMessage
                  id='Locations.add.title'
                  defaultMessage='New Location'
                />
              </Button>
            }
          />
        </CardActions>
      </Fragment>
    );
  };

  const error = getIn(errors, `jsonTemplate.locations.items`, undefined);
  let errorEl = null;
  if (error && typeof error === 'string') {
    errorEl = <Alert severity='error'>{error}</Alert>;
  }
  return (
    <Card className={classes.root}>
      <CardHeader
        title={
          <FormattedMessage
            id='Template.pkpass.locations.title'
            defaultMessage='Locations'
          />
        }
        titleTypographyProps={{
          variant: 'h5',
        }}
        avatar={
          <Avatar sizes={'large'}>
            <GpsLocationIcon />
          </Avatar>
        }
        subheaderTypographyProps={{ variant: 'body1' }}
        subheader={
          <FormattedMessage
            id='Template.pkpass.locations.info'
            defaultMessage='Locations where the pass is relevant. For example, the location of your store. You can configure 10 locations.'
          />
        }
      />
      <CardContent>{errorEl}</CardContent>
      <FieldArray name={path} render={renderArray} />
    </Card>
  );
};
