import React from 'react';
import { FormattedMessage } from 'react-intl';

import Icon from '@material-ui/icons/Check';

import { Button, ButtonProps } from './Button';

export const DoneButton = (props: ButtonProps): JSX.Element => {
  return (
    <Button type='button' color='primary' {...props} startIcon={<Icon />}>
      <FormattedMessage id={'Done.button.text'} defaultMessage={'Done'} />
    </Button>
  );
};
