import React from 'react';

export interface DataGridActionProps<T> {
  item: T;
  resource: string;
  listLoad: () => void;
}

export default function DataGridActions(props: {
  children: Array<JSX.Element | null> | JSX.Element | null;
}): JSX.Element {
  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      {props.children}
    </div>
  );
}
