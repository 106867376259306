enum AccessRights {
  IMAGE_MANAGE = 2048,
  PASS_CREATE = 1,
  PASS_DELETE = 8,
  PASS_READ = 2,
  PASS_UPDATE = 4,
  PROJECT_ACCESS = 0,
  PROJECT_CREATE = 16384,
  PROJECT_UPDATE = 32768,
  STAMP_MANAGE = 4096,
  TEMPLATE_CREATE = 16,
  TEMPLATE_DELETE = 128,
  TEMPLATE_READ = 32,
  TEMPLATE_UPDATE = 64,
  // TICKET_ACCOUNTING = 256,
  // TICKET_ENTRY = 512,
  // TICKET_ESHOP_READ = 1024,
  // TICKET_PRODUCT_MANAGE = 65536,
  USER_UPDATE = 8192,
}

export const AccessRightsList: AccessRights[] = [
  AccessRights.IMAGE_MANAGE,
  AccessRights.PASS_CREATE,
  AccessRights.PASS_DELETE,
  AccessRights.PASS_READ,
  AccessRights.PASS_UPDATE,
  AccessRights.PROJECT_ACCESS,
  AccessRights.PROJECT_CREATE,
  AccessRights.PROJECT_UPDATE,
  AccessRights.STAMP_MANAGE,
  AccessRights.TEMPLATE_CREATE,
  AccessRights.TEMPLATE_DELETE,
  AccessRights.TEMPLATE_READ,
  AccessRights.TEMPLATE_UPDATE,
  // AccessRights.TICKET_ACCOUNTING,
  // AccessRights.TICKET_ENTRY,
  // AccessRights.TICKET_ESHOP_READ,
  // AccessRights.TICKET_PRODUCT_MANAGE,
  AccessRights.USER_UPDATE,
];
export default AccessRights;
