import { get } from 'lodash';
import { ValidationError } from 'yup';

export const placeHolderRegexp = /^.*#\{.+\}.*$/;
export const placeHolderRegexpIgnorePIDAndTID = /#\{[^pt][^\\}]*\}|#\{[^}][^i][^}]*\}|#\{[^}]{2}[^d]\}|#\{[^}]{4,}\}/;
export const numberRegexp = /^-?\d*\.?\d*$/;
export const dateRegexp = /^\\d{4}-\\d\\d-\\d\\dT\\d\\d:\\d\\d:\\d\\d(\\.\\d+)?(([+-]\\d\\d:\\d\\d)|Z)?$/;
export const colorRegexp = /^rgb\((25[0-5]|2[0-4][0-9]|1[0-9]?[0-9]?|[1-9][0-9]?|[0-9]), ?(25[0-5]|2[0-4][0-9]|1[0-9]?[0-9]?|[1-9][0-9]?|[0-9]), ?(25[0-5]|2[0-4][0-9]|1[0-9]?[0-9]?|[1-9][0-9]?|[0-9])\)$/;

export const isPlaceholder = (
  type: string,
  value: { type: string; value?: any; fallback?: string | number }
) => {
  if (value && value.value && typeof value.value === 'string') {
    // if is string type, it can contains #{pid} and #{tid} as verified values
    // TODO - be care-full date is also string type
    if (
      value.type !== 'string' ||
      type === 'date' ||
      type === 'color' ||
      type === 'number'
    ) {
      return !!value.value.match(placeHolderRegexp);
    }
    return !!value.value.match(placeHolderRegexpIgnorePIDAndTID);
  }

  if (value && value.value && typeof value.value === 'object') {
    const keys = Object.keys(value.value);
    for (const key of keys) {
      if (value.value[key] && !!value.value[key].match(placeHolderRegexp)) {
        return true;
      }
    }
  }

  return false;
};

export const isNumber = (value: any): boolean => {
  if (value && typeof value === 'string') {
    return !!value.match(numberRegexp);
  }
  if (value === 0 || (value && typeof value === 'number')) {
    return true;
  }

  return false;
};

export const isDate = (datestr: string | undefined): boolean => {
  if (!datestr) {
    return false;
  }
  return !!((d: any) => {
    return d !== 'Invalid Date' && !isNaN(d);
  })(new Date(datestr));
};

export const isColor = (datestr: string | undefined): boolean => {
  if (!datestr) {
    return false;
  }
  return datestr.match(colorRegexp) !== null;
};

export const errorsToString = (errors: any, paths: string[]) => {
  const errorArr = [];
  for (const name of paths) {
    const error = get(errors, name, undefined);
    if (typeof error === 'object') {
      if (error.value) {
        errorArr.push(error.value);
      }
      if (error.fallback) {
        errorArr.push(error.fallback);
      }
    }
    if (typeof error === typeof ValidationError || typeof error === 'string') {
      errorArr.push(error);
    }
  }
  if (errorArr.length > 0) {
    return errorArr.join(', ');
  }
  return undefined;
};

export const defualtNumberFallback = 0;
export const defaultStringFallback = ' ';
export const defaultDateFallback = new Date(0);
export const defaultColorFallback = 'rgb(255, 255, 255)';
