import { Query } from './query';

export interface ListComplete<T> {
  data: T[];
}

export interface List<T> extends ListComplete<T> {
  limit: number;
  page: number;
  data: T[];
}

export interface ListResponse<T> extends ListComplete<T> {
  limit: number;
  page: number;
  data: T[];
  totalCount?: number;
}

export enum Order {
  ASC = 'asc',
  DESC = 'desc',
}

export interface ListOptions {
  where?: Query;
  order?: Order;
  orderBy?: string;
  page?: number;
  limit?: number;
}
