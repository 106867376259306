import { FormikProps } from 'formik';
import React from 'react';

import FormikResetButton from '../common/formik/FormikResetButton';
import FormikSaveButton from '../common/formik/FormikSaveButton';
import { ResourceDeleteButton } from '../common/ResourceDeleteButton';
import { ResourceTypes } from '../../utils/services/api';
import { Image } from '../../types/Image';

export default function ImageFormControls(props: FormikProps<Image>) {
  return (
    <div>
      <FormikSaveButton {...props} />
      <FormikResetButton {...props} />
      <ResourceDeleteButton
        resource={ResourceTypes.IMAGE}
        resourceId={props.values.id}
      />
    </div>
  );
}
