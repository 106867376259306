import React, { useState } from 'react';

import Box from '@material-ui/core/Box';

import { FieldJSON, FieldSwitch, FieldSwitchChange } from '../../fields';
import Form from '../../Form';
import JsonSchemaFormUI from './JsonSchemaFormUI';

interface Props {
  formData: unknown;
  schema: Record<string, unknown>;
  uiSchema: Record<string, unknown>;
  onChange: (data: Record<string, unknown>) => void;
  disabled?: boolean;
}

export function JsonSchemaForm(props: Props): JSX.Element {
  const [showJson, setShowJson] = useState(false);

  const handleChange = (data: { name: string; value: any }) => {
    props.onChange(data.value);
  };

  const handleChangeForm = (v: any) => {
    props.onChange(v.formData);
  };

  const handleSwitch = (e: FieldSwitchChange) => {
    setShowJson(e.value);
  };

  const { schema, disabled } = props;
  const uiSchema = props.uiSchema ? props.uiSchema : {};
  let control = (
    <Box p={3}>
      <JsonSchemaFormUI
        disabled={disabled}
        schema={schema}
        uiSchema={uiSchema}
        onSubmit={handleChangeForm}
        onChange={handleChangeForm}
        formData={props.formData}
        liveValidate={true}
      />
    </Box>
  );

  if (showJson) {
    control = (
      <Form>
        <FieldJSON
          name='data'
          disabled={true}
          value={props.formData}
          onChange={handleChange}
        />
      </Form>
    );
  }

  return (
    <div>
      <FieldSwitch
        checked={showJson}
        name={'showJson'}
        label={'Show as json'}
        onChange={handleSwitch}
      />
      {control}
    </div>
  );
}
