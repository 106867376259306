import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import ResourcePage from '../../../components/common/page/ResourcePage';
import { ResourcePageProps } from '../../types';
import DesignEditDrawer from '../../../components/design/pkpass/DesignPkpassEditDrawer';
import DesignList from '../../../components/design/pkpass/DesignPkpassList';

export const DesignPkpassPage: FunctionComponent<ResourcePageProps> = (
  props: ResourcePageProps
) => {
  return (
    <ResourcePage
      {...props}
      DrawerComponent={DesignEditDrawer}
      content={<DesignList autoLoad={!props.match.params.id} />}
      controls={<span />}
      title={
        <FormattedMessage
          id='DesignPkpassPage.title'
          defaultMessage='Pkpass designs'
        />
      }
      onCloseDrawer={() => {
        props.history.push('/passes/design/pkpass');
      }}
    />
  );
};
