import React from 'react';
import { defineMessages, useIntl } from 'react-intl';

import FormikDialog from '../../../../../../common/formik/FormikDialog';
import {
  PkpassField,
  PkpassFieldsType,
} from '../../../../../../../types/Template';
import { fieldSchema } from '../../../../../../../utils/validation/jsonTemplate.passStyle';
import TemplatePreviewLanguageSelect from '../../../../../../template/TemplatePreviewLanguageSelect';
import FieldForm from './FieldForm';

const messages = defineMessages({
  deleteConfirm: {
    defaultMessage: 'Field will be removed from template. Are you sure?',
    id: 'Field.delete.confirm.message',
  },
});

interface Props {
  onClose: () => void;
  onRemove?: (field: PkpassField) => void;
  field: PkpassField;
  onSubmit: (field: PkpassField) => void;
  fieldsType: PkpassFieldsType;
  languages: string[];
}

export default function FieldFormikDialog(props: Props): JSX.Element {
  const { onRemove, onClose, onSubmit, field, languages, fieldsType } = props;
  const intl = useIntl();

  const handleSubmit = (f: PkpassField) => {
    onSubmit(f);
    onClose();
  };

  const handleRemove = (f: PkpassField) => {
    onRemove && onRemove(f);
    onClose();
  };

  return (
    <FormikDialog
      isOpen={true}
      onSubmit={handleSubmit}
      onClose={onClose}
      initialValues={field}
      validationSchema={fieldSchema}
      onDelete={handleRemove}
      onDeleteMessage={intl.formatMessage(messages.deleteConfirm)}
      maxWidth='xl'
      title={
        <div>
          Edit field
          <span style={{ float: 'right' }}>
            <TemplatePreviewLanguageSelect languages={languages} />
          </span>
        </div>
      }
      content={<FieldForm fieldsType={fieldsType} languages={languages} />}
    />
  );
}
