import React from 'react';
import { FormattedMessage } from 'react-intl';

import AddIcon from '@material-ui/icons/Add';

import { Button, ButtonProps } from './Button';

export const AddButton = (props: ButtonProps) => {
  return (
    <Button type='button' {...props} startIcon={<AddIcon />}>
      {props.children ? (
        props.children
      ) : (
        <FormattedMessage id={'AddButton.button.text'} defaultMessage={'Add'} />
      )}
    </Button>
  );
};
