import React from 'react';
import { FormattedMessage } from 'react-intl';

export const StaticLabel = {
  AccessRights: (
    <FormattedMessage id='Label.AccessRights' defaultMessage='Access rights' />
  ),
  Actions: <FormattedMessage id='Label.actions' defaultMessage='Actions' />,
  Admin: <FormattedMessage id='Label.admin' defaultMessage='Admin' />,
  Altitude: <FormattedMessage id='Label.Altitude' defaultMessage='Altitude' />,
  Cancel: <FormattedMessage id='Label.cancel' defaultMessage='Cancel' />,
  Certificate: (
    <FormattedMessage id='Label.certificate' defaultMessage='Certificate' />
  ),
  Confirm: <FormattedMessage id='Label.confirm' defaultMessage='Confirm' />,
  CreateFrom: (
    <FormattedMessage id='Label.CreateFrom' defaultMessage='Create from' />
  ),
  CreateTo: <FormattedMessage id='Label.CreateTo' defaultMessage='Create to' />,
  CreatedAt: <FormattedMessage id='Label.create.at' defaultMessage='Created' />,
  Date: <FormattedMessage id='Label.Date' defaultMessage='Date' />,
  DateFrom: <FormattedMessage id='Label.DateFrom' defaultMessage='Date from' />,
  DateTo: <FormattedMessage id='Label.DateTo' defaultMessage='Date to' />,
  Description: (
    <FormattedMessage id='Label.Description' defaultMessage='Description' />
  ),
  Devices: <FormattedMessage id='Label.Devices' defaultMessage='Devices' />,
  Dimension: (
    <FormattedMessage id='Label.dimmension' defaultMessage='Dimmension' />
  ),
  DisplayName: (
    <FormattedMessage id='Label.DisplayName' defaultMessage='Display name' />
  ),
  Distribution: (
    <FormattedMessage
      id='Label.distribution'
      defaultMessage='Distribution URL'
    />
  ),
  Downloads: (
    <FormattedMessage id='Label.Downloads' defaultMessage='Downloads' />
  ),
  DynamicData: (
    <FormattedMessage id='Label.dynamic.data' defaultMessage='Dynamic data' />
  ),
  DynamicImages: (
    <FormattedMessage
      id='Label.dynamic.images'
      defaultMessage='Dynamic images'
    />
  ),
  Edit: <FormattedMessage id='Label.edit' defaultMessage='Edit' />,
  Email: <FormattedMessage id='Label.email' defaultMessage='E-mail' />,
  Enum: <FormattedMessage id='Label.Enum' defaultMessage='Enum' />,
  Expiration: (
    <FormattedMessage id='Label.expiration' defaultMessage='Expiration' />
  ),
  Fallback: <FormattedMessage id='Label.fallback' defaultMessage='fallback' />,
  FileSize: (
    <FormattedMessage id='Label.file.size' defaultMessage='File size' />
  ),
  Format: <FormattedMessage id='Label.format' defaultMessage='Format' />,
  General: <FormattedMessage id='Label.General' defaultMessage='General' />,
  Id: <FormattedMessage id='Label.id' defaultMessage='Id' />,
  ImageType: (
    <FormattedMessage id='Label.image.type' defaultMessage='Image type' />
  ),
  Interval: <FormattedMessage id='Label.Interval' defaultMessage='Interval' />,
  JSON: <FormattedMessage id='Label.json' defaultMessage='JSON' />,
  Language: <FormattedMessage id='Label.language' defaultMessage='Language' />,
  Latitude: <FormattedMessage id='Label.Latitude' defaultMessage='Latitude' />,
  Longitude: (
    <FormattedMessage id='Label.Longitude' defaultMessage='Longitude' />
  ),
  Locale: <FormattedMessage id='Label.locale' defaultMessage='Locale' />,
  Major: <FormattedMessage id='Label.major' defaultMessage='Major' />,
  Maximum: <FormattedMessage id='Label.Maximum' defaultMessage='Maximum' />,
  MimeType: <FormattedMessage id='Label.mimeType' defaultMessage='MimeType' />,
  Minimum: <FormattedMessage id='Label.Minimum' defaultMessage='Minimum' />,
  Minor: <FormattedMessage id='Label.minor' defaultMessage='Minor' />,
  Name: <FormattedMessage id='Label.name' defaultMessage='Name' />,
  NewPass: <FormattedMessage id='Label.NewPass' defaultMessage='New Pass' />,
  NewTemplate: (
    <FormattedMessage id='Label.NewTemplate' defaultMessage='New Template' />
  ),
  Ok: <FormattedMessage id='Label.confirm' defaultMessage='Ok' />,
  Or: <FormattedMessage id='Label.or' defaultMessage='Or' />,
  Parent: <FormattedMessage id='Label.parent' defaultMessage='Parent' />,
  PassTypeIdentifier: (
    <FormattedMessage
      id='Label.passTypeIdentifier'
      defaultMessage='Pass Type Identifier'
    />
  ),
  Pattern: <FormattedMessage id='Label.Pattern' defaultMessage='Pattern' />,
  Pkpass: <FormattedMessage id='Label.apple.pkpass' defaultMessage='Pkpass' />,
  Preview: <FormattedMessage id='Label.preview' defaultMessage='Preview' />,
  PreviewPage: (
    <FormattedMessage id='Label.previewpage' defaultMessage='Preview page' />
  ),
  Project: <FormattedMessage id='Label.project' defaultMessage='Project' />,
  ProximityUUID: (
    <FormattedMessage
      id='Label.proximityUUID'
      defaultMessage='Proximity UUID'
    />
  ),
  RelevantText: (
    <FormattedMessage id='Label.relevantText' defaultMessage='Relevant text' />
  ),
  Required: <FormattedMessage id='Label.Required' defaultMessage='Required' />,
  Reset: <FormattedMessage id='Label.reset' defaultMessage='Reset' />,
  SaveToGooglePay: (
    <FormattedMessage
      id='Label.google.pay'
      defaultMessage='Google Pay for Passes'
    />
  ),
  ShowDeleted: (
    <FormattedMessage id='Label.show.deleted' defaultMessage='Show deleted' />
  ),
  Template: <FormattedMessage id='Label.template' defaultMessage='Template' />,
  TemplateDynamicDataJSONSchema: (
    <FormattedMessage
      id='Label.templateDynamicDataJSONSchema'
      defaultMessage='Dynamic data JSON Schema'
    />
  ),
  Timezone: <FormattedMessage id='Label.Timezone' defaultMessage='Timezone' />,
  Title: <FormattedMessage id='Label.Title' defaultMessage='Title' />,
  Type: <FormattedMessage id='Label.Type' defaultMessage='Type' />,
  UniqueName: (
    <FormattedMessage id='Label.unique.name' defaultMessage='Unique name' />
  ),
  UpdatedAt: <FormattedMessage id='Label.update.at' defaultMessage='Updated' />,
  Url: <FormattedMessage id='Label.url' defaultMessage='URL' />,
  User: <FormattedMessage id='Label.User' defaultMessage='User' />,
  Version: <FormattedMessage id='Label.Version' defaultMessage='Version' />,
  Voided: <FormattedMessage id='Label.Voided' defaultMessage='Voided' />,
};
