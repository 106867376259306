import { useState } from 'react';

export interface OpenInterface {
  open: boolean;
  handleClose: () => void;
  handleOpen: () => void;
  handleToggle: () => void;
}

export default function useOpen(initValue: boolean): OpenInterface {
  const [open, setOpen] = useState(initValue);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleToggle = () => {
    setOpen(!open);
  };

  return { open, handleClose, handleOpen, handleToggle };
}
