import React from 'react';

import { DataGridColumns, Fields } from '../../common/dataGrid';
import DataGridResources from '../../common/dataGrid/DataGridResources';
import { StaticLabel } from '../../common/labels';
import useViewer from '../../../hooks/useViewer';
import { ResourceTypes } from '../../../utils/services/api';
import { TemplateListItem } from '../../../types/Template';
import DesignListItemActions from './DesignPkpassListItemActions';
import { ProjectLabelWithDrawer } from '../../../domain/project/';
import PkpassCertificateLabelWithDrawer from '../../../domain/certificate/PkpassCertificateLabelWithDrawer';
import TemplateListItemPreview from './DesigPkpassListItemPreview';
import { FilterProps } from '../../../types/common/filter';

const fields: Fields<TemplateListItem> = [
  {
    key: 'previrew',
    title: '',
    render(item: TemplateListItem) {
      return <TemplateListItemPreview id={item.id} />;
    },
  },
  { key: 'name', title: StaticLabel.Name },
  {
    key: 'certName',
    title: StaticLabel.Certificate,
    render(item: TemplateListItem) {
      return (
        <PkpassCertificateLabelWithDrawer resourceId={item.certificateId} />
      );
    },
  },
  {
    key: 'projectId',
    title: StaticLabel.Project,
    render(item: TemplateListItem) {
      return <ProjectLabelWithDrawer resourceId={item.projectId} />;
    },
  },
  { key: 'createdAt', title: StaticLabel.CreatedAt, type: 'date' },
  { key: 'updatedAt', title: StaticLabel.UpdatedAt, type: 'date' },
  {
    key: DataGridColumns.Actions,
    title: StaticLabel.Actions,
    render(item: TemplateListItem) {
      return <DesignListItemActions item={item} />;
    },
  },
];

const filterProps: FilterProps[] = [
  {
    columnName: 'projectId',
    name: 'project',
    type: 'inUuid',
  },
  {
    columnName: 'name',
    name: 'name',
    type: 'like',
  },
];

const DesignList = (props: { autoLoad?: boolean }) => {
  const viewer = useViewer();

  const componentProps = {
    fields,
    filterOptions: { project: true, name: true },
    filterProps,
  };

  return (
    <DataGridResources
      resource={ResourceTypes.TEMPLATE}
      {...componentProps}
      autoLoad={props.autoLoad}
      export={!!(viewer && viewer.isAdmin)}
    />
  );
};

export default DesignList;
