import React from 'react';
import { FormattedMessage } from 'react-intl';

import FormikCreateDrawer from '../common/formik/FormikCreateDrawer';
import { ResourceTypes } from '../../utils/services/api';
import schema from '../../utils/validation/template';
import { TemplateUIContextProvider } from '../design/pkpass/TemplateUIContext';
import defaultTemplate from './wizard/defaultTemplate';
import { StepRequiredFieldsContent } from './wizard/steps/StepRequiredFields';
import { PkpassIdentification } from '../design/pkpass/PkpassIdentification';
import { OnCreateHandlder } from '../../types';
import useNotification from '../../hooks/useNotification';
import { useHistory } from 'react-router';

export default function TemplateCreateDrawer(props: {
  trigger?: JSX.Element;
  onCreate?: OnCreateHandlder;
}): JSX.Element {
  const { addSuccessNotification } = useNotification();
  const history = useHistory();
  const RenderContent = () => {
    return (
      <div>
        <StepRequiredFieldsContent />
        <PkpassIdentification />
      </div>
    );
  };

  const handleCreate = (r: unknown, resource: ResourceTypes) => {
    if (props.onCreate) {
      return props.onCreate(r, resource);
    } else {
      addSuccessNotification(`Template was created`);
      const a = r as { id: string };
      if (a.id) {
        history.replace(`/passes/template/${a.id}`);
      }
    }
  };

  return (
    <TemplateUIContextProvider initValues={defaultTemplate}>
      <FormikCreateDrawer
        onCreate={handleCreate}
        title={
          <FormattedMessage
            id='TemplateNewModal.header'
            defaultMessage='New template'
          />
        }
        validationSchema={schema}
        resource={ResourceTypes.TEMPLATE}
        initialValues={defaultTemplate}
        trigger={props.trigger}
        renderForm={RenderContent}
      />
    </TemplateUIContextProvider>
  );
}
