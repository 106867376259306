import moment, { Moment } from 'moment-timezone';
import React, { Fragment, useState } from 'react';

import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import { useTheme } from '@material-ui/core/styles';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import CalendarToday from '@material-ui/icons/Event';
import { DateTimePicker } from '@material-ui/pickers';

import useOpen from '../../../hooks/useOpen';
import { FieldLabel } from './index';
import { useStyles } from './FieldDateTime';

interface Props {
  name: string;
  label?: React.ReactNode;
  placeholder?: string;
  onChange: (d: { name: string; value: string | undefined }) => void;
  disabled?: boolean | undefined;
  required?: boolean | undefined;
  helpText?: React.ReactNode;
  value?: string | undefined;
  error?: string;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  endAdornment?: React.ReactNode;
}

export default function FieldDateTimePlaceholder(props: Props) {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { open, handleOpen, handleClose, handleToggle } = useOpen(false);
  const isDate = props.value ? moment(props.value).isValid() : false;
  const [value, setValue] = useState<string | undefined | null>(
    props.value
      ? isDate
        ? moment(props.value).format('MM-DD-YYYY HH:mm')
        : props.value
      : null
  );

  const handleChangeDatePicker = (date: Moment | null) => {
    const str = date ? date.toISOString() : undefined;
    setValue(date ? date.format('MM-DD-YYYY HH:mm') : undefined);
    props.onChange({
      name: props.name,
      value: str,
    });
  };

  const handleDateInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setValue(event.target.value);
  };

  const handleDateInputBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    props.onChange({
      name: props.name,
      value: value ? value : undefined,
    });
    if (props.onBlur) {
      props.onBlur(event);
    }
  };

  return (
    <Fragment>
      <TextField
        id={props.name}
        margin='dense'
        variant='outlined'
        className={classes.formControl}
        required={props.required}
        disabled={props.disabled}
        error={!!props.error}
        label={<FieldLabel label={props.label} helpText={props.helpText} />}
        name={props.name}
        value={value ? value : ''}
        onChange={handleDateInputChange}
        onBlur={handleDateInputBlur}
        InputProps={{
          margin: 'dense',
          startAdornment: (
            <InputAdornment position='start'>
              <IconButton onClick={handleToggle}>
                <CalendarToday />
              </IconButton>
            </InputAdornment>
          ),
          endAdornment: props.endAdornment ? (
            <InputAdornment position='end'>{props.endAdornment}</InputAdornment>
          ) : undefined,
        }}
        type={'text'}
        helperText={props.error}
      />
      <DateTimePicker
        open={open}
        onOpen={handleOpen}
        onClose={handleClose}
        id='dataPicker'
        value={props.value}
        disabled={false}
        format={'MM-DD-YYYY HH:mm'}
        variant='dialog'
        onChange={handleChangeDatePicker}
        TextFieldComponent={EmptyInput}
      />
    </Fragment>
  );
}

const EmptyInput = (props: TextFieldProps) => {
  return null;
};
