import React from 'react';

import useResource from '../../hooks/useResource';
import { ResourceTypes } from '../../utils/services/api';
import {
  Image,
  TranslatedImageReference,
  ImageReference,
} from '../../types/Image';
import { ImageTypes, PkpassPassStyleTypes } from '../../types/Template';
import EmptyImage from './EmptyImage';
import Preview, { PreviewProps } from './Preview';

export const getPreviewSize = (
  type: ImageTypes,
  passStyle?: PkpassPassStyleTypes,
  data?: Image | null
) => {
  const size: React.CSSProperties = {
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
  };

  if (type === ImageTypes.logo) {
    if (data && data.width && data.height) {
      size.width = parseInt(`${data.width / 2}`, 10);
      size.height = parseInt(`${data.height / 2}`, 10);
    } else {
      size.width = 160;
      size.height = 50;
    }
    size.maxWidth = 160;
    size.maxHeight = 50;
    size.float = 'left';
  } else if (type === ImageTypes.strip) {
    size.width = 320;
    size.height =
      passStyle === PkpassPassStyleTypes.Coupon ||
      passStyle === PkpassPassStyleTypes.StoreCard
        ? 122
        : 84;
    size.backgroundSize = 'cover';
    size.backgroundPosition = 'center';
  } else if (type === ImageTypes.icon) {
    size.width = 29;
    size.height = 29;
  } else if (type === ImageTypes.footer) {
    size.width = 300;
    size.height = 20;
    size.backgroundPosition = 'center';
    size.backgroundSize = 'cover';
  } else if (type === ImageTypes.thumbnail) {
    size.width = 90;
    size.height = 90;
  } else if (type === ImageTypes.background) {
    size.width = 360;
    size.height = 440;
  }
  return size;
};

export interface ImagePreviewProps extends PreviewProps {
  disabled: boolean;
  triggerButton?: boolean;
  language: string;
  image: ImageReference;
  imageType: ImageTypes;
}

function ImageResourcePreview(props: ImagePreviewProps & { id: string }) {
  const { id, imageType, passStyle } = props;
  const { loading, data } = useResource<Image>(ResourceTypes.IMAGE, id);
  if (loading) {
    return <span />;
  }
  if (!data) {
    return <span>{`${id}`}</span>;
  }

  const size = getPreviewSize(imageType, passStyle, data);
  return <Preview {...props} data={data} size={size} />;
}

const ImagePreview = (props: ImagePreviewProps) => {
  const { image, language } = props;

  const isTranslated = !(
    typeof image === 'undefined' || typeof image === 'string'
  );

  let id = null;

  if (!isTranslated && typeof image === 'string') {
    id = image;
  }

  if (isTranslated && image) {
    const imageMap = image as TranslatedImageReference;
    // use english or alphabet first fot preview if is not defined for current language
    if (imageMap[language]) {
      id = imageMap[language];
    } else if (imageMap && imageMap.en) {
      id = imageMap.en;
    } else if (image) {
      const keys = Object.keys(image).sort((a, b) => {
        return a.localeCompare(b);
      });
      if (keys.length > 0) {
        id = imageMap[keys[0]];
      }
    }
  }

  if (!id) {
    return <EmptyImage {...props} />;
  }
  return <ImageResourcePreview {...props} id={id} />;
};

export default ImagePreview;
