import './index.css';

import 'moment/locale/cs.js';
import 'moment/locale/de.js';
import 'moment/locale/uk.js';

import 'intl';
import 'intl/locale-data/jsonp/cs.js';
import 'intl/locale-data/jsonp/de.js';
import 'intl/locale-data/jsonp/en.js';

import '@formatjs/intl-relativetimeformat/polyfill';
import '@formatjs/intl-relativetimeformat/locale-data/cs';
import '@formatjs/intl-relativetimeformat/locale-data/de';
import '@formatjs/intl-relativetimeformat/locale-data/en';

import moment from 'moment';
import React from 'react';
import { IntlProvider } from 'react-intl';

import CssBaseline from '@material-ui/core/CssBaseline';
import { csCZ, deDE, enUS } from '@material-ui/core/locale';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

import { BrowserContextProvider, LocaleContextProvider } from './context';
import useLocale from './hooks/useLocale';
import Router from './routes/Router';
import themeOpts from './theme';
import { TimezoneContextProvider } from './context/TimezoneContext';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { SnackbarProvider } from 'notistack';

function RenderApp(): JSX.Element {
  const { locale, messages } = useLocale();

  moment.locale(locale);

  const theme = createMuiTheme(
    themeOpts,
    locale === 'cs' ? csCZ : locale === 'de' ? deDE : enUS
  );

  return (
    <IntlProvider locale={locale} messages={messages}>
      <ThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <TimezoneContextProvider>
          <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment}>
            <SnackbarProvider maxSnack={3}>
              <BrowserContextProvider>
                <Router />
              </BrowserContextProvider>
            </SnackbarProvider>
          </MuiPickersUtilsProvider>
        </TimezoneContextProvider>
      </ThemeProvider>
    </IntlProvider>
  );
}

export default function App(): JSX.Element {
  return (
    <LocaleContextProvider>
      <RenderApp />
    </LocaleContextProvider>
  );
}
