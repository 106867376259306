import { FormikProps, getIn } from 'formik';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import Alert from '@material-ui/lab/Alert';

import { FieldSwitch, FieldSwitchChange } from '../../common/fields';
import Form from '../../common/Form';
import { LinkIcon } from '../../common/icons';
import { StaticLabel } from '../../common/labels';
import { TabOption } from '../../common/Tabs';
import UrlWithTags from '../../common/UrlWithTags';
import { ISSUE_URL } from '../../../utils/services/environment';
import { Template } from '../../../types/Template';

type Props = FormikProps<Template>;

function TemplateDistributionForm(props: Props) {
  const handleChange = (event: FieldSwitchChange) => {
    const { setFieldValue } = props;
    setFieldValue('distributable', event.value);
  };

  const { values, dirty } = props;
  return (
    <div style={{ width: '100%', marginTop: 10, marginBottom: 10 }}>
      <Alert severity='info'>
        <FormattedMessage
          id='template.distributable.infotext'
          defaultMessage={`Distribution service provides automatic pass generation. To enable this service set the switch to the on position and save template.`}
        />
      </Alert>
      <Form>
        <FieldSwitch
          name={'distributable'}
          onChange={handleChange}
          checked={values.distributable}
          helpText={
            <FormattedMessage
              id='template.distributable.help.text'
              defaultMessage={`
              Enable the distribution URL on the template generates a URL to automatically create new passes. Each opening of this URL (or its scanning by a mobile device) creates a new pass instance. Basically, in the background there is created new pass via API pass create service and the user is redirected to this newly created pass instance.
              Please do not enable distribution URLs on templates where there is a process prior to create of a pass. Example of Templates (where do not appropriate to enable distribution URLs) are templates for purchasing tickets or creating a new stampcard.
            `}
            />
          }
          label={
            <FormattedMessage
              id='template.distributable.form.label'
              defaultMessage='Enable automatic distribution'
            />
          }
        />
      </Form>

      {getIn(values, 'distributable') && !dirty && (
        <div>
          <UrlWithTags urlBase={`${ISSUE_URL}/${props.values.id}`} />
        </div>
      )}
      {getIn(values, 'distributable') && dirty && (
        <Alert severity='warning'>
          <FormattedMessage
            id='template.distributable.warning.label'
            defaultMessage='Template changes are not saved. Please save template to get distribution URL or generate QR code.'
          />
        </Alert>
      )}
    </div>
  );
}

export default TemplateDistributionForm;

export function createTemplateDistributionTab(
  props: FormikProps<Template>
): TabOption {
  return {
    content: <TemplateDistributionForm {...props} />,
    error: false,
    icon: <LinkIcon />,
    key: 'distribution',
    name: StaticLabel.Distribution,
  };
}
