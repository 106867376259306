import React, { Fragment, useState } from 'react';
import {
  defineMessages,
  FormattedMessage,
  injectIntl,
  WrappedComponentProps,
} from 'react-intl';

import { AddButton } from '../../../../../common/buttons';
import {
  FieldTextInput,
  FieldTextInputData,
} from '../../../../../common/fields';

interface Props extends WrappedComponentProps {
  onAdd: (id: number) => void;
}

const messages = defineMessages({
  addIdPlaceholder: {
    defaultMessage: 'Add application ID',
    id: 'AppStoreAddModal.add.number.placeholder',
  },
  searchPlaceholder: {
    defaultMessage: 'Type app name and press search icon.',
    id: 'AppStoreAddModal.search.placeholder',
  },
});

function AppStoreAddForm(props: Props) {
  const [appId, setAppId] = useState('');

  const handleAddNumber = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();
    props.onAdd(parseInt(appId, 10));
    setAppId('');
    return false;
  };

  const handleAppChange = (event: FieldTextInputData) => {
    if (event.value) {
      setAppId(event.value);
    }
  };

  return (
    <Fragment>
      <h4>
        <FormattedMessage
          id='AppStoreAddModal.add.number.header'
          defaultMessage='Paste application ID'
        />
      </h4>
      <form
        onSubmit={handleAddNumber}
        style={{ display: 'flex', flexDirection: 'row' }}
      >
        <div style={{ margin: 5 }}>
          <FieldTextInput
            name='appId'
            label={
              <FormattedMessage
                id='AppStoreAddModal.add.number.label'
                defaultMessage='App store App ID'
              />
            }
            value={appId}
            onChange={handleAppChange}
            placeholder={props.intl.formatMessage(messages.addIdPlaceholder)}
          />
        </div>
        <div style={{ margin: 5 }}>
          <AddButton
            variant={'contained'}
            color={'primary'}
            type='submit'
            size={'large'}
            style={{ height: 55 }}
            disabled={!appId}
          />
        </div>
      </form>
    </Fragment>
  );
}

export default injectIntl(AppStoreAddForm);
