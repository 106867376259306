import React, { useState } from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';

import Button from '@material-ui/core/Button';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Step from '@material-ui/core/Step';
import StepContent from '@material-ui/core/StepContent';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';

import Form from '../../components/common/Form';
import { UploadIcon } from '../../components/common/icons';
import Loader from '../../components/common/loader/Loader';
import { CORE_API_URL } from '../../utils/services/environment';
import { PkpassCertificateFormInterface } from './PkpassCertificateFormikForm';
import useNotification from '../../hooks/useNotification';
import { getCsrf } from '../../utils/csrf';
import Link from '@material-ui/core/Link';

interface Props {
  data: PkpassCertificateFormInterface;
}

const messages = defineMessages({
  fileInputPlaceholder: {
    id: 'pkpass.certificate.form.name.helpText',
    defaultMessage: 'Upload new public',
  },
});

export const PkpassCertificateFormUpload = (props: Props): JSX.Element => {
  const { data } = props;
  const { addNotification } = useNotification();
  const [uploading, setUploading] = useState<boolean>(false);
  const [file, setFile] = useState<File | null>(null);
  const intl = useIntl();

  const handleUploadError = (e: Error) => {
    addNotification({
      level: 'error',
      message: (
        <FormattedMessage
          id={'pkpass.certificate.form.upload.failed.title'}
          defaultMessage='Failed to upload certificate'
          values={{ error: e }}
        />
      ),
    });
  };

  const uploadFile = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (file) {
      const xhr = new XMLHttpRequest();
      xhr.onload = () => {
        setUploading(false);
        if (xhr.status === 200) {
          addNotification({
            level: 'success',
            message: (
              <FormattedMessage
                id={'pkpass.certificate.form.upload.success.message'}
                defaultMessage='Certificate was successfully uploaded.'
              />
            ),
          });
        } else {
          handleUploadError(new Error(`${xhr.status} - ${xhr.responseText}`));
        }
      };

      xhr.onerror = () => {
        setUploading(false);
        handleUploadError(new Error(`Upload error`));
      };

      const url = `${CORE_API_URL}/v1/certificate/${data.id}/upload`;
      xhr.open('post', url, true);
      xhr.withCredentials = true;
      const csrf = getCsrf();
      if (csrf) {
        xhr.setRequestHeader('X-Csrf-Token', csrf);
      }
      const formData = new FormData(e.target as HTMLFormElement);
      setUploading(true);
      xhr.send(formData);
    } else {
      handleUploadError(new Error(`Please select file`));
    }
  };

  const handleChangeFileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e && e.target && e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  if (uploading) {
    return <Loader />;
  }

  return (
    <div>
      <h3>
        <FormattedMessage
          id={'pkpass.certificate.form.upload.header'}
          defaultMessage='Upload new certificate'
        />
      </h3>
      <Stepper orientation='vertical'>
        <Step>
          <StepLabel>
            <FormattedMessage
              id={'pkpass.certificate.form.upload.csr.title'}
              defaultMessage='Create CSR'
            />
          </StepLabel>
          <StepContent>
            <Form>
              <p>
                <FormattedMessage
                  id={'pkpass.certificate.form.upload.csr.message'}
                  defaultMessage='Create and download CSR file'
                />
              </p>

              <Button
                variant='contained'
                color='primary'
                type={'button'}
                onClick={() => {
                  window.open(
                    `${CORE_API_URL}/v1/certificate/${data.id}/csr`,
                    '_blank'
                  );
                }}
              >
                <FormattedMessage
                  id={'pkpass.certificate.form.upload.csr.action'}
                  defaultMessage=' Create CSR'
                />
              </Button>
            </Form>
          </StepContent>
        </Step>
        <Step active={true}>
          <StepLabel>
            <FormattedMessage
              id={'pkpass.certificate.form.upload.apple.title'}
              defaultMessage='Apple'
            />
          </StepLabel>
          <StepContent>
            <Form>
              <p>
                <FormattedMessage
                  id={'pkpass.certificate.form.upload.apple.message'}
                  defaultMessage='Generate new public part of certificate.'
                />
                <FormattedMessage
                  id={'pkpass.certificate.form.upload.apple.action'}
                  defaultMessage='Please visit '
                />
                <Link
                  rel='noopener noreferrer'
                  target='_blank'
                  href='https://developer.apple.com/account/ios/certificate/'
                >
                  <FormattedMessage
                    id={'pkpass.certificate.form.upload.apple.action.link'}
                    defaultMessage='Apple certificates'
                  />
                </Link>
                .
              </p>
            </Form>
          </StepContent>
        </Step>
        <Step active={true}>
          <StepLabel>
            <FormattedMessage
              id={'pkpass.certificate.form.upload.title'}
              defaultMessage='Upload certificate'
            />
          </StepLabel>
          <StepContent>
            <form
              onSubmit={uploadFile}
              action={`${CORE_API_URL}/v1/certificate/${data.id}/upload`}
              method='POST'
              encType='multipart/form-data'
            >
              <OutlinedInput
                placeholder={intl.formatMessage(messages.fileInputPlaceholder)}
                type='file'
                name='file'
                onChange={handleChangeFileInput}
                margin='dense'
              />
              <p />
              <Button
                type='submit'
                disabled={!file}
                variant='contained'
                color='primary'
                startIcon={<UploadIcon />}
              >
                <FormattedMessage
                  id={'pkpass.certificate.form.upload.action'}
                  defaultMessage='Upload'
                />
              </Button>
            </form>
          </StepContent>
        </Step>
      </Stepper>
    </div>
  );
};
