import './Loader.css';

import React from 'react';

import logo from '../../../assets/logo.svg';

export default function Loader() {
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <img
        alt='spinner'
        style={{ animation: `spin 3s linear infinite` }}
        src={logo}
        height='30'
      />
    </div>
  );
}
