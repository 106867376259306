import { Formik, FormikErrors, FormikHelpers, FormikProps } from 'formik';
import React from 'react';

import Backdrop from '@material-ui/core/Backdrop';

import useResource from '../../../hooks/useResource';
import { ResourceTypes } from '../../../utils/services/api';
import Loader from '../loader/Loader';
import FormikLocalStorage from './FormikLocalStorage';

export type FormikEditResourceRenderProps<T> = {
  refresh: () => Promise<T | null>;
} & FormikProps<T>;

interface Props<T> {
  resourceId: string;
  resource: ResourceTypes;
  validate?: (
    values: T
  ) => void | Record<string, unknown> | Promise<FormikErrors<T>>;
  validationSchema?: any;
  render: (props: FormikEditResourceRenderProps<T>) => any;
}

export default function FormikEditResource<T extends Record<string, unknown>>(
  props: Props<T>
): JSX.Element {
  const { resourceId, resource, render, validate, validationSchema } = props;
  const { data, loading, update, refresh } = useResource<T>(
    resource,
    resourceId
  );

  const handleSubmit = async (values: T, actions: FormikHelpers<T>) => {
    actions.setSubmitting(true);
    const res = await update(values);
    if (res !== null) {
      actions.resetForm({ values: res });
      actions.setStatus({ success: true });
    }
    actions.setSubmitting(false);
  };

  if (loading) {
    return <Loader />;
  }

  const defaultV = {};

  return (
    <Formik
      initialValues={data || (defaultV as T)}
      onSubmit={handleSubmit}
      validate={validate}
      validationSchema={validationSchema}
    >
      {(fp: FormikProps<T>) => {
        return (
          <div>
            {fp.isSubmitting && (
              <Backdrop open={true} style={{ zIndex: 3000 }}>
                <Loader />
              </Backdrop>
            )}
            <FormikLocalStorage id={resourceId} {...fp} refresh={refresh}>
              {(p: FormikEditResourceRenderProps<T>) => {
                return render(p);
              }}
            </FormikLocalStorage>
          </div>
        );
      }}
    </Formik>
  );
}
