import React, { Fragment } from 'react';

import { FieldSelectData } from '../common/fields';
import { ImageReference, Images } from '../../types/Image';
import { ImageTypes, PkpassPassStyleTypes } from '../../types/Template';
import PassImage from './PassImage';

export const passEditImageTypes = [
  ImageTypes.background,
  ImageTypes.footer,
  ImageTypes.logo,
  ImageTypes.strip,
  ImageTypes.thumbnail,
];

interface Props {
  languages: string[];
  projectId: string;
  images: Images;
  previewLanguage: string;
  passStyle: PkpassPassStyleTypes;
  onChangeLanguage: (event: FieldSelectData) => void;
  onChange: (images: Images) => void;
}

export default function PassImages(props: Props) {
  const { onChange, images, ...restProps } = props;

  return (
    <Fragment>
      {passEditImageTypes.map((imageType) => {
        const handleChangeImage = (i: ImageReference) => {
          onChange({ ...images, [imageType]: i });
        };
        return (
          <PassImage
            key={imageType}
            imageType={imageType}
            image={images ? images[imageType] : undefined}
            onChange={handleChangeImage}
            {...restProps}
          />
        );
      })}
    </Fragment>
  );
}
