import React from 'react';
import { FormattedMessage } from 'react-intl';

import Page from '../../components/common/page/Page';
import { withReport } from '../../context/';
import BasePassOverview from '../../components/report/BasePassOverview';

const Report = withReport(BasePassOverview, 'at_least_once_register_in_wallet');

export const PassAtLeastOnceRegisterInWalletOverviewPage = (): JSX.Element => {
  return (
    <Page
      content={<Report />}
      title={
        <FormattedMessage
          id='PassAtLeastOnceRegisterInWalletOverviewPage.title'
          defaultMessage='Passes at least once registered in wallet'
        />
      }
    />
  );
};
