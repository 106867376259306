import React, { Fragment, FunctionComponent } from 'react';
import { FormattedDate, FormattedMessage, FormattedTime } from 'react-intl';

import Paper from '@material-ui/core/Paper';
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Page from '../../components/common/page/Page';
import {
  APP_VERSION,
  BUILD_TIMESTAMP,
  CORE_API_URL,
  ENVIRONMENT,
  ENVIRONMENT_COLOR,
  ISSUE_URL,
  LOGIN_URL,
  YOURPASS_VERSION,
} from '../../utils/services/environment';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      margin: theme.spacing(4),
    },
  })
);

const AboutPageContent = (): JSX.Element => {
  const theme = useTheme();
  const classes = useStyles(theme);
  return (
    <Fragment>
      <Paper className={classes.container}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell colSpan={2}>Yourpass</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Version</TableCell>
              <TableCell>{`${YOURPASS_VERSION}`}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>
      <Paper className={classes.container}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell colSpan={2}>Console</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Version</TableCell>
              <TableCell>{`${APP_VERSION}`}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Build</TableCell>
              <TableCell>
                {BUILD_TIMESTAMP && <FormattedDate value={BUILD_TIMESTAMP} />}
                <span />
                {BUILD_TIMESTAMP && <FormattedTime value={BUILD_TIMESTAMP} />}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Environment</TableCell>
              <TableCell>{`${ENVIRONMENT}`}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Environment color</TableCell>
              <TableCell>{`${ENVIRONMENT_COLOR}`}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Core API URL</TableCell>
              <TableCell>{`${CORE_API_URL}`}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Login URL</TableCell>
              <TableCell>{`${LOGIN_URL}`}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Issue URL - generate new pass from template</TableCell>
              <TableCell>{`${ISSUE_URL}`}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>
    </Fragment>
  );
};

export const AboutPage: FunctionComponent = (): JSX.Element => {
  return (
    <Page
      content={<AboutPageContent />}
      title={<FormattedMessage id='AboutPage.title' defaultMessage='About' />}
    />
  );
};
