import React from 'react';
import {
  defineMessages,
  FormattedMessage,
  useIntl,
  defineMessage,
} from 'react-intl';

import { FieldSelectOption } from '../../../../../../common/fields';
import { PkpassTextAlignTypes } from '../../../../../../../types/Template';
import { PkpassSelect } from '../../../../inputs';
import FormattedHTMLMessage from '../../../../../../common/FormattedHTMLMessage';

const messages = defineMessages({
  PKTextAlignmentLeft: {
    defaultMessage: 'Left',
    id: 'pkpass.field.textAlignment.PKTextAlignmentLeft',
  },

  PKTextAlignmentCenter: {
    defaultMessage: 'Center',
    id: 'pkpass.field.textAlignment.PKTextAlignmentCenter',
  },

  PKTextAlignmentRight: {
    defaultMessage: 'Right',
    id: 'pkpass.field.textAlignment.PKTextAlignmentRight',
  },

  PKTextAlignmentNatural: {
    defaultMessage: 'Natural',
    id: 'pkpass.field.textAlignment.PKTextAlignmentNatural',
  },
});

export default function FieldAlignmentDropDown(): JSX.Element {
  const intl = useIntl();

  const options: FieldSelectOption[] = [
    {
      key: PkpassTextAlignTypes.PKTextAlignmentLeft,
      text: intl.formatMessage(
        messages[PkpassTextAlignTypes.PKTextAlignmentLeft]
      ),
      value: PkpassTextAlignTypes.PKTextAlignmentLeft,
    },
    {
      key: PkpassTextAlignTypes.PKTextAlignmentCenter,
      text: intl.formatMessage(
        messages[PkpassTextAlignTypes.PKTextAlignmentCenter]
      ),
      value: PkpassTextAlignTypes.PKTextAlignmentCenter,
    },
    {
      key: PkpassTextAlignTypes.PKTextAlignmentRight,
      text: intl.formatMessage(
        messages[PkpassTextAlignTypes.PKTextAlignmentRight]
      ),
      value: PkpassTextAlignTypes.PKTextAlignmentRight,
    },
    {
      key: PkpassTextAlignTypes.PKTextAlignmentNatural,
      text: intl.formatMessage(
        messages[PkpassTextAlignTypes.PKTextAlignmentNatural]
      ),
      value: PkpassTextAlignTypes.PKTextAlignmentNatural,
    },
  ];

  return (
    <PkpassSelect
      name={`properties.textAlignment`}
      options={options}
      label={
        <FormattedMessage
          id='pkpass.field.textAlignment.label'
          defaultMessage='Text alignment'
        />
      }
      helpText={
        <FormattedHTMLMessage
          descriptor={defineMessage({
            id: 'pkpass.field.textAlignment.helpText',
            defaultMessage: `Alignment for the field’s contents. Must be one of the following values:
  <ul>
  <li>PKTextAlignmentLeft</li>
  <li>PKTextAlignmentCenter</li>
  <li>PKTextAlignmentRight</li>
  <li>PKTextAlignmentNatural</li>
  </ul>
  <p>The default value is natural alignment, which aligns the text appropriately based on its script direction.</p>
  <p>This key is not allowed for primary fields or back fields.</p>`,
          })}
        />
      }
    />
  );
}
