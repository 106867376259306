import React from 'react';
import { FormattedMessage } from 'react-intl';

import Icon from '@material-ui/icons/Cancel';

import { Button, ButtonProps } from './Button';

export const CancelButton = (props: ButtonProps) => {
  return (
    <Button type='button' {...props} startIcon={<Icon />}>
      <FormattedMessage
        id={'CancelButton.button.text'}
        defaultMessage={'Cancel'}
      />
    </Button>
  );
};
