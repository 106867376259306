import React from 'react';
import { FormattedMessage } from 'react-intl';

import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';

export default function NoData() {
  return (
    <Alert severity='warning'>
      <AlertTitle>
        <FormattedMessage id='NoDataMessage.title' defaultMessage='No data' />
      </AlertTitle>
      <FormattedMessage
        id='NoDataMessage.infoText'
        defaultMessage='There are no relevant data for the specified parameters'
      />
    </Alert>
  );
}
