import { FormikErrors } from 'formik';
import React, { FunctionComponent } from 'react';

import { ResourceTypes } from '../../../utils/services/api';
import Drawer from '../drawers/Drawer';
import FormikEditResource, {
  FormikEditResourceRenderProps,
} from './FormikEditResource';
import FormikLocalStorage from './FormikLocalStorage';

export default function FormikEditDrawer<
  T extends Record<string, unknown>
>(props: {
  id: string;
  open: boolean;
  onClose: () => void;
  Component: FunctionComponent<FormikEditResourceRenderProps<any>>;
  resource: ResourceTypes;
  validate?: (
    values: T
  ) => void | Record<string, unknown> | Promise<FormikErrors<T>>;
  validationSchema?: any;
}): JSX.Element {
  const { id, resource, Component, validate, validationSchema } = props;

  return (
    <Drawer
      open={props.open}
      onClose={props.onClose}
      content={
        <FormikEditResource
          resource={resource}
          resourceId={id}
          validate={validate}
          validationSchema={validationSchema}
          render={(fp: FormikEditResourceRenderProps<T>) => {
            return (
              <FormikLocalStorage id={id} {...fp}>
                {(fp: FormikEditResourceRenderProps<T>) => (
                  <Component {...fp} />
                )}
              </FormikLocalStorage>
            );
          }}
        />
      }
    />
  );
}
