import { defineMessages } from 'react-intl';

import {} from '../../../types/Pass';
import {
  PkpassBarcodeTypes,
  PkpassFieldsType,
  PkpassPassStyleTypes,
} from '../../../types/Template';

const pkpassMessages = defineMessages({
  [PkpassFieldsType.PrimaryFields]: {
    defaultMessage: 'Primary fields',
    id: `pkpass.fields.primary`,
  },
  [PkpassFieldsType.BackFields]: {
    defaultMessage: 'Back fields',
    id: `pkpass.fields.back`,
  },
  [PkpassFieldsType.AuxiliaryFields]: {
    defaultMessage: 'Auxiliary fields',
    id: `pkpass.fields.auxiliary`,
  },
  [PkpassFieldsType.SecondaryFields]: {
    defaultMessage: 'Secondary fields',
    id: `pkpass.fields.secondary`,
  },
  [PkpassFieldsType.HeaderFields]: {
    defaultMessage: 'Header fields',
    id: `pkpass.fields.header`,
  },

  logoText: {
    defaultMessage: 'Logo text',
    id: `pkpass.logo.text`,
  },

  [PkpassPassStyleTypes.BoardingPass]: {
    defaultMessage: 'Boarding pass',
    id: 'pkpass.style.boardingPass',
  },
  [PkpassPassStyleTypes.Coupon]: {
    defaultMessage: 'Coupon',
    id: 'pkpass.style.coupon',
  },
  [PkpassPassStyleTypes.EventTicket]: {
    defaultMessage: 'Event ticket',
    id: 'pkpass.style.eventTicket',
  },
  [PkpassPassStyleTypes.Generic]: {
    defaultMessage: 'Generic',
    id: 'pkpass.style.generic',
  },
  [PkpassPassStyleTypes.StoreCard]: {
    defaultMessage: 'Store card',
    id: 'pkpass.style.storeCard',
  },

  [PkpassBarcodeTypes.PKBarcodeFormatAztec]: {
    defaultMessage: 'Aztec',
    id: 'pkpass.barcode.aztec',
  },
  [PkpassBarcodeTypes.PKBarcodeFormatCode128]: {
    defaultMessage: 'Code 128',
    id: 'pkpass.barcode.code128',
  },
  [PkpassBarcodeTypes.PKBarcodeFormatPDF417]: {
    defaultMessage: 'PDF 417',
    id: 'pkpass.barcode.pdf417',
  },
  [PkpassBarcodeTypes.PKBarcodeFormatQR]: {
    defaultMessage: 'QR code',
    id: 'pkpass.barcode.qr',
  },
});

export const pkpassAddFieldHintMessages = defineMessages({
  [PkpassFieldsType.PrimaryFields]: {
    defaultMessage: 'Add primary field.',
    id: `pkpass.fields.add.primary.hint`,
  },
  [PkpassFieldsType.BackFields]: {
    defaultMessage: 'Add back field.',
    id: `pkpass.fields.add.back.hint`,
  },
  [PkpassFieldsType.AuxiliaryFields]: {
    defaultMessage: 'Add auxiliary field.',
    id: `pkpass.fields.add.auxiliary.hint`,
  },
  [PkpassFieldsType.SecondaryFields]: {
    defaultMessage: 'Add secondary field.',
    id: `pkpass.fields.add.secondary.hint`,
  },
  [PkpassFieldsType.HeaderFields]: {
    defaultMessage: 'Add header field.',
    id: `pkpass.fields.add.header.hint`,
  },
});

export default pkpassMessages;
