import React from 'react';
import { FormattedMessage } from 'react-intl';

import { FieldSwitch, FieldSwitchChange } from '../common/fields';
import Form from '../common/Form';
import useWizard from '../../hooks/useWizard';

export default function ProfileSettings(): JSX.Element {
  const { wizard, setWizard } = useWizard();

  const handleChange = (event: FieldSwitchChange) => {
    setWizard(!!event.value);
  };

  return (
    <Form>
      <FieldSwitch
        label={
          <FormattedMessage
            id='User.settings.wizard.label'
            defaultMessage='Use wizard on create template'
          />
        }
        helpText={
          <FormattedMessage
            id='User.settings.wizard.helpText'
            defaultMessage='This options switch between wizard and direct creation of template.'
          />
        }
        checked={wizard}
        name={'wizard'}
        onChange={handleChange}
      />
    </Form>
  );
}
