import React from 'react';

import { FieldSelectData, FieldSelectImageType } from '../fields';
import { StaticLabel } from '../labels';
import { FilterElementProps } from './Filter';

interface Props extends FilterElementProps {
  value: string[];
  onChange: (e: FieldSelectData) => void;
}

export default function FilterImageType(props: Props) {
  const { disabled, value, name } = props;
  const handleChange = (e: FieldSelectData) => {
    if (props.onChange) {
      props.onChange({ name: props.name, value: e.value });
    }
  };
  return (
    <FieldSelectImageType
      disabled={disabled}
      key={name}
      name={name}
      label={StaticLabel.ImageType}
      //  selection={true}
      multiple={true}
      onChange={handleChange}
      value={value}
    />
  );
}
