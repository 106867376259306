import React from 'react';
import { FormattedMessage } from 'react-intl';

import { CreateButton } from '../common/buttons';
import useWizard from '../../hooks/useWizard';
import TemplateCreateDrawer from './TemplateCreateDrawer';
import TemplateCreateWizardDrawer from './TemplateCreateWizardDrawer';

export default function TemplateCreate(): JSX.Element {
  const { wizard } = useWizard();
  const trigger = (
    <CreateButton>
      <FormattedMessage
        id={'TemplateNew.button.text'}
        defaultMessage={'Create new template'}
      />
    </CreateButton>
  );

  if (wizard === false) {
    return <TemplateCreateDrawer trigger={trigger} />;
  }
  return <TemplateCreateWizardDrawer trigger={trigger} />;
}
