import React, { useState } from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';

import Typography from '@material-ui/core/Typography';

import Filter, { FilterOptionsProps } from '../common/filter/Filter';
import { PassIcon } from '../common/icons';
import Loader from '../common/loader/Loader';
import { FilterKeyTypes } from '../../context/FilterContext/types';
import useFilter from '../../hooks/useFilter';
import useResourcesCount from '../../hooks/useResourcesCount';
import { ResourceTypes } from '../../utils/services/api';
import { prepareFilter } from '../../utils/services/filter';

const filterProps = [
  {
    columnName: 'projectId',
    name: FilterKeyTypes.PROJECT,
    type: 'inUuid',
  },
  {
    columnName: 'templateId',
    name: FilterKeyTypes.TEMPLATE,
    type: 'inUuid',
  },
  {
    columnName: 'id',
    name: FilterKeyTypes.ID,
    type: 'like',
  },
  {
    columnName: 'dynamicData',
    name: FilterKeyTypes.DYNAMIC_DATA,
    type: 'like',
  },
  {
    columnName: 'createdAt',
    name: FilterKeyTypes.CREATED_AT_FROM,
    type: 'gt',
  },
  {
    columnName: 'createdAt',
    name: FilterKeyTypes.CREATED_AT_TO,
    type: 'lt',
  },
  {
    columnName: 'devices.total',
    name: FilterKeyTypes.DEVICES,
    type: 'gtOrEq',
  },
  {
    columnName: 'voided',
    name: FilterKeyTypes.VOIDED,
    type: 'eq',
  },
  {
    columnName: 'deletedAt',
    name: FilterKeyTypes.WITH_DELETED,
    type: 'resetDefaultQuery',
  },
];

export default function PassCount() {
  const filterOptions: FilterOptionsProps = {
    createdFrom: true,
    createdTo: true,
    devices: true,
    dynamicData: true,
    id: true,
    project: true,
    template: true,
    voided: true,
    withDeleted: true,
  };

  const { filter } = useFilter();
  const [query, setQuery] = useState(prepareFilter(filter, filterProps));

  const p = useResourcesCount(ResourceTypes.PASS, {
    query,
  });

  const handleFilter = () => {
    const q = prepareFilter(filter, filterProps);
    setQuery(q);
  };

  return (
    <div>
      {filterOptions && <Filter {...filterOptions} onFilter={handleFilter} />}
      <div style={styles.passCountContainer}>
        {p.loading && <Loader />}
        {!p.loading && (
          <Typography variant='h3'>
            <PassIcon fontSize={'large'} />
            <FormattedNumber value={p.data || 0} />
            <span>{'  '}</span>
            <FormattedMessage id='PassCount.passes' defaultMessage='Passes' />
          </Typography>
        )}
      </div>
    </div>
  );
}

const styles = {
  passCountContainer: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 100,
    textAlign: 'center' as const,
  },
};
