import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import List from '@material-ui/core/List';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { SectionInterface } from '../../Sidebar';
import Section from './Section';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    column: {
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    root: {},
    item: {
      color: 'rgba(255,255,255,0.6)',
      '&:hover': {
        color: '#FFF',
        '.MuiSvgIcon-root': {
          color: '#FFF',
        },
      },
      '& .MuiSvgIcon-root': {
        color: 'rgba(255,255,255,0.6)',
      },

      '& .MuiListItemSecondaryAction-root': {
        color: 'rgba(255,255,255,0.6)',
      },
    },
    button: {
      color: 'rgba(255,255,255,0.6)',
      padding: '10px 8px',
      justifyContent: 'flex-start',
      textTransform: 'none',
      letterSpacing: 0,
      width: '100%',
      fontWeight: theme.typography.fontWeightMedium,
      '&:hover': {
        // backgroundColor: grey[400],
        color: '#FFF',
      },
    },
  })
);

interface SidebarNavProps {
  className: string;
  sections: SectionInterface[];
}

const SidebarNav = (props: SidebarNavProps) => {
  const { sections, className, ...rest } = props;
  const classes = useStyles();

  return (
    <div className={classes.column}>
      <List
        key={'main-nav'}
        component='nav'
        aria-labelledby='nested-list-subheader'
        {...rest}
        className={clsx(classes.root, className)}
      >
        {sections.map((section, index) => {
          return <Section key={index} section={section} index={index} />;
        })}
      </List>
    </div>
  );
};

SidebarNav.propTypes = {
  className: PropTypes.string,
  sections: PropTypes.array.isRequired,
};

export default SidebarNav;
