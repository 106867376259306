import React, { FunctionComponent } from 'react';

import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import { FieldLabel } from './FieldLabel';
import { InputProps as StandardInputProps } from '@material-ui/core/Input/Input';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      minWidth: 120,
    },
  })
);

export interface FieldNumberInputData {
  name: string;
  // TODO - string or number or undefined
  value?: unknown;
}
export interface FieldNumberProps extends FieldNumberInputData {
  onChange: (event: FieldNumberInputData) => void;
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  label?: React.ReactNode;
  helpText?: React.ReactNode;
  disabled?: boolean;
  required?: boolean;
  placeholder?: string;
  error?: string;
  endAdornment?: React.ReactNode;
  readOnly?: boolean;
  InputProps?: Partial<StandardInputProps>;
}

export const FieldNumberInput: FunctionComponent<FieldNumberProps> = (
  props: FieldNumberProps
) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const handleChange = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown | undefined;
    }>
  ) => {
    props.onChange({ name: props.name, value: event.target.value });
  };

  return (
    <TextField
      id={props.name}
      variant='outlined'
      margin='dense'
      className={classes.formControl}
      required={props.required}
      disabled={props.disabled}
      error={!!props.error}
      label={
        (props.label || props.helpText) && (
          <FieldLabel label={props.label} helpText={props.helpText} />
        )
      }
      name={props.name}
      value={props.value}
      onChange={handleChange}
      onBlur={props.onBlur}
      InputProps={{
        ...props.InputProps,
        endAdornment: props.endAdornment,
        readOnly: props.readOnly,
      }}
      type='number'
      helperText={props.error}
    />
  );
};
