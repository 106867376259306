import React from 'react';

import { linkStyle } from '../../../styles';

interface Props {
  label: JSX.Element | string;
  value: JSX.Element | string;
  link?: boolean;
}

export const LabelValueTitle = (props: Props): JSX.Element => {
  return (
    <span style={props.link ? linkStyle : undefined}>
      {props.label}: {props.value}
    </span>
  );
};
