import { defineMessages } from 'react-intl';

import AccessRights from '../../constants/accessRights';
export const accessRightsMessages = defineMessages({
  [AccessRights.PROJECT_ACCESS]: {
    defaultMessage: 'Project access',
    id: 'AccessRights.PROJECT_ACCESS',
  },
  [AccessRights.PASS_CREATE]: {
    defaultMessage: 'Pass create',
    id: 'AccessRights.PASS_CREATE',
  },
  [AccessRights.PASS_READ]: {
    defaultMessage: 'Pass read',
    id: 'AccessRights.PASS_READ',
  },
  [AccessRights.PASS_UPDATE]: {
    defaultMessage: 'Pass update',
    id: 'AccessRights.PASS_UPDATE',
  },
  [AccessRights.PASS_DELETE]: {
    defaultMessage: 'Pass delete',
    id: 'AccessRights.PASS_DELETE',
  },
  [AccessRights.TEMPLATE_CREATE]: {
    defaultMessage: 'Template create',
    id: 'AccessRights.TEMPLATE_CREATE',
  },
  [AccessRights.TEMPLATE_READ]: {
    defaultMessage: 'Template read',
    id: 'AccessRights.TEMPLATE_READ',
  },
  [AccessRights.TEMPLATE_UPDATE]: {
    defaultMessage: 'Template update',
    id: 'AccessRights.TEMPLATE_UPDATE',
  },
  [AccessRights.TEMPLATE_DELETE]: {
    defaultMessage: 'Template delete',
    id: 'AccessRights.TEMPLATE_DELETE',
  },

  [AccessRights.IMAGE_MANAGE]: {
    defaultMessage: 'Manage images',
    id: 'AccessRights.IMAGE_MANAGE',
  },

  [AccessRights.STAMP_MANAGE]: {
    defaultMessage: 'Stampcard',
    id: 'AccessRights.STAMP_MANAGE',
  },

  [AccessRights.USER_UPDATE]: {
    defaultMessage: 'User update',
    id: 'AccessRights.USER_UPDATE',
  },
  [AccessRights.PROJECT_CREATE]: {
    defaultMessage: 'Project create',
    id: 'AccessRights.PROJECT_CREATE',
  },
  [AccessRights.PROJECT_UPDATE]: {
    defaultMessage: 'Project update',
    id: 'AccessRights.PROJECT_UPDATE',
  },
});

export const accessRightsDescriptionMessages = defineMessages({
  [AccessRights.PROJECT_ACCESS]: {
    defaultMessage: 'Project access description',
    id: 'AccessRights.PROJECT_ACCESS.descrtiption',
  },
  [AccessRights.PASS_CREATE]: {
    defaultMessage: 'Pass create description',
    id: 'AccessRights.PASS_CREATE.descrtiption',
  },
  [AccessRights.PASS_READ]: {
    defaultMessage: 'Pass read description',
    id: 'AccessRights.PASS_READ.descrtiption',
  },
  [AccessRights.PASS_UPDATE]: {
    defaultMessage: 'Pass update description',
    id: 'AccessRights.PASS_UPDATE.descrtiption',
  },
  [AccessRights.PASS_DELETE]: {
    defaultMessage: 'Pass delete description',
    id: 'AccessRights.PASS_DELETE.descrtiption',
  },
  [AccessRights.TEMPLATE_CREATE]: {
    defaultMessage: 'Template create description',
    id: 'AccessRights.TEMPLATE_CREATE.descrtiption',
  },
  [AccessRights.TEMPLATE_READ]: {
    defaultMessage: 'Template read description',
    id: 'AccessRights.TEMPLATE_READ.descrtiption',
  },
  [AccessRights.TEMPLATE_UPDATE]: {
    defaultMessage: 'Template update description',
    id: 'AccessRights.TEMPLATE_UPDATE.descrtiption',
  },
  [AccessRights.TEMPLATE_DELETE]: {
    defaultMessage: 'Template delete description',
    id: 'AccessRights.TEMPLATE_DELETE.descrtiption',
  },

  [AccessRights.IMAGE_MANAGE]: {
    defaultMessage:
      'Manage images (can list/upload images to project or in template editor)',
    id: 'AccessRights.IMAGE_MANAGE.description',
  },

  [AccessRights.STAMP_MANAGE]: {
    defaultMessage: 'Manage stampcards (see overview, call api)',
    id: 'AccessRights.STAMP_MANAGE.description',
  },

  [AccessRights.USER_UPDATE]: {
    defaultMessage: 'Can drawers users rights on projects',
    id: 'AccessRights.USER_UPDATE.description',
  },
  [AccessRights.PROJECT_CREATE]: {
    defaultMessage: 'Can create sub project',
    id: 'AccessRights.PROJECT_CREATE.description',
  },
  [AccessRights.PROJECT_UPDATE]: {
    defaultMessage: 'Can update project',
    id: 'AccessRights.PROJECT_UPDATE.description',
  },
});

export const messages = defineMessages({
  removeUserMessage: {
    id: 'access.rights.remove.info',
    defaultMessage:
      '{user} will no longer have access to the {project} project. Are you sure?',
  },
  removeUserProjectAccessSuccessNotification: {
    id: 'access.rights.remove.notification.success',
    defaultMessage: 'Access rights changed',
  },
  removeUserProjectAccessErrorNotification: {
    id: 'access.rights.remove.notification.error',
    defaultMessage: 'Failed change access rights',
  },

  changeUserProjectAccessSuccessNotification: {
    id: 'access.rights.change.notification.success',
    defaultMessage: 'Access rights successfully changed.',
  },
  changeUserProjectAccessErrorNotification: {
    id: 'access.rights.change.notification.error',
    defaultMessage: 'Failed change access rights.',
  },
});
