import React, { CSSProperties, useState } from 'react';
import Lottie from 'lottie-react';

import animationData from './HelpAnimation.json';

export default function HelpAnimation(props: {
  style: CSSProperties;
  onComplete?: () => void;
}): JSX.Element | null {
  const [completed, setCompleted] = useState(false);
  if (completed) {
    return null;
  }
  return (
    <div
      style={{
        height: 200,
        position: 'absolute',
        width: 200,
        ...props.style,
        zIndex: 1000,
      }}
    >
      <Lottie
        animationData={animationData}
        loop={false}
        height={200}
        width={200}
        onComplete={() => {
          setCompleted(true);
          if (props.onComplete) {
            props.onComplete();
          }
        }}
      />
    </div>
  );
}
