import React from 'react';

import Tooltip from '@material-ui/core/Tooltip';
import Icon from '@material-ui/icons/Check';

import { Field } from './DataGrid';
import DataGridCellDate from './DataGridCellDate';
import { DataGridCellObject } from './DataGridCellObject';

export interface DataGridCellProps<T> {
  field: Field<T>;
  listLoad: () => void;
  value: T;
  property: any;
}

export default function DataGridCell<T>(props: DataGridCellProps<T>) {
  const { listLoad, field, property, value } = props;

  if (field.render) {
    return field.render(value, listLoad);
  }

  if (property === null) {
    return '';
  }

  if (field.type === 'date') {
    return <DataGridCellDate value={property} />;
  }

  const dataType = typeof property;

  if (dataType === 'boolean' || field.type === 'boolean') {
    return property ? <Icon /> : null;
  }

  if (!Number.isNaN(parseFloat(property))) {
    return `${property}`;
  }

  if (dataType === 'object') {
    return <DataGridCellObject data={property} />;
  }

  return (
    <Tooltip title={property}>
      <div
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {property || null}
      </div>
    </Tooltip>
  );
}
