import { FormikProps } from 'formik';
import React, { useState } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Alert from '@material-ui/lab/Alert';

import {
  FieldTextInput,
  FieldTextInputData,
} from '../../components/common/fields';
import Form from '../../components/common/Form';
import { AddIcon } from '../../components/common/icons';
import { StaticLabel } from '../../components/common/labels';
import {
  findUserByEmail,
  setUserProjectAccessRights,
} from '../../utils/services/api';
import { Project } from './types';
import { User } from '../../types/User';
import { ProjectUserRightsRow } from './ProjectUserRightsRow';
import useNotification from '../../hooks/useNotification';
import { Box } from '@material-ui/core';

type Props = FormikProps<Project>;

const messages = defineMessages({
  userEmailPlaceholder: {
    defaultMessage: 'Fill user email',
    id: 'project.user.add.placeholder',
  },
  successAddUser: {
    defaultMessage: 'User was added to project',
    id: 'project.user.add.success',
  },
});

export const ProjectUserRights = (props: Props): JSX.Element => {
  const { values } = props;
  const [email, setEmail] = useState('');
  const [pending, setPending] = useState(false);
  const { addSuccessNotification, addErrorNotification } = useNotification();
  const [error, setError] = useState(0);
  const intl = useIntl();

  const refreshUsers = (users: { [p: string]: number }) => {
    props.setFieldValue('users', users);
  };

  const setUser = (e: FieldTextInputData) => {
    setEmail(e.value as string);
  };

  const addUser = async (
    e:
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
      | React.FormEvent<HTMLFormElement>
  ) => {
    e.preventDefault();
    e.stopPropagation();
    setPending(true);
    setError(0);
    try {
      const json: User = await findUserByEmail(email);
      await saveRights(values.id, json.id, 0);
      addSuccessNotification(intl.formatMessage(messages.successAddUser));
    } catch (e) {
      if (e.status !== 404) {
        addErrorNotification(e);
      } else {
        setError(e.status);
      }
    }

    setPending(false);
  };

  const saveRights = async (
    projectId: string,
    userId: string,
    accessRights: number | null
  ) => {
    try {
      const json = await setUserProjectAccessRights(
        projectId,
        userId,
        accessRights
      );
      const users = values.users || {};
      if (json.accessRights === null) {
        delete users[userId];
      } else {
        users[userId] = json.accessRights;
      }
      refreshUsers(users);
    } catch (e) {
      addErrorNotification(e);
    }
  };

  return (
    <Card>
      <CardHeader
        title={
          <FormattedMessage
            id='project.user.rights.header'
            defaultMessage='Users with access to project'
          />
        }
      />
      <CardContent>
        <Form onSubmit={addUser}>
          <FieldTextInput
            disabled={pending}
            //error={error}
            name='email'
            label={
              <FormattedMessage
                id='project.user.add.rights.label'
                defaultMessage='Add user to project'
              />
            }
            placeholder={intl.formatMessage(messages.userEmailPlaceholder)}
            onChange={setUser}
            value={email || ''}
            endAdornment={
              <InputAdornment position='end'>
                <IconButton onClick={addUser} edge='end' disabled={pending}>
                  <AddIcon />
                </IconButton>
              </InputAdornment>
            }
          />
          {error === 404 && (
            <Alert severity='error'>
              <FormattedMessage
                id='project.user.add.not.found'
                defaultMessage='User with {email} was not found.'
                values={{
                  email: email,
                }}
              />
            </Alert>
          )}
        </Form>
        <Box p={2} />
        {values && values.users && (
          <Table size={'small'}>
            <TableHead>
              <TableRow>
                <TableCell>{StaticLabel.User}</TableCell>
                <TableCell>{StaticLabel.AccessRights}</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(values.users).map((i) => {
                return (
                  <ProjectUserRightsRow
                    key={i}
                    projectId={values.id}
                    accessRights={values.users[i]}
                    userId={i}
                    saveRights={saveRights}
                  />
                );
              })}
            </TableBody>
          </Table>
        )}
      </CardContent>
    </Card>
  );
};
