import {
  FieldArray,
  FieldArrayRenderProps,
  getIn,
  useFormikContext,
} from 'formik';
import React, { Fragment, FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import List from '@material-ui/core/List';
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';

import { AddIcon, IBeaconIcon } from '../../../../common/icons';
import { PkpassBeacon, Template } from '../../../../../types/Template';
import BeaconListItem from './BeaconListItem';
import BeaconNewModal from './BeaconModal';

const path = 'jsonTemplate.beacons.items';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(1),
    },
  })
);

export const Beacons: FunctionComponent = (): JSX.Element => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { values, setFieldValue, errors } = useFormikContext<Template>();

  const renderArray = (arrayHelper: FieldArrayRenderProps) => {
    const items = getIn(values, path);
    const add = (beacon: PkpassBeacon) => {
      if (items) {
        arrayHelper.push(beacon);
      } else {
        const beacons = {
          items: [beacon],
          type: 'array',
        };
        const jsonTemplate = getIn(values, `jsonTemplate`);
        setFieldValue(`jsonTemplate`, { ...jsonTemplate, beacons });
      }
    };

    const handleChange = (index: number) => {
      return (b: PkpassBeacon) => {
        const jsonTemplate = getIn(values, `jsonTemplate`);
        const beacons = getIn(values, `jsonTemplate.beacons`);
        beacons[index] = b;
        setFieldValue(`jsonTemplate`, { ...jsonTemplate, beacons });
      };
    };

    const remove = (index: number) => {
      return () => {
        if (items && items.length === 1) {
          const jsonTemplate = getIn(values, `jsonTemplate`);
          delete jsonTemplate.beacons;
          setFieldValue(`jsonTemplate`, jsonTemplate);
          // this.forceUpdate();
        } else {
          arrayHelper.remove(index);
          // this.forceUpdate();
        }
      };
    };

    return (
      <Fragment>
        <CardContent>
          <List>
            {items &&
              items.map((beacon: PkpassBeacon, index: number) => {
                return (
                  <BeaconListItem
                    key={index}
                    onChange={handleChange(index)}
                    onRemove={remove(index)}
                    beacon={beacon}
                  />
                );
              })}
          </List>
        </CardContent>
        <CardActions>
          <BeaconNewModal
            onChange={add}
            trigger={
              <Button
                disabled={items && items.length >= 10}
                startIcon={<AddIcon />}
              >
                <FormattedMessage
                  id='Beacons.add.title'
                  defaultMessage='New iBeacon'
                />
              </Button>
            }
          />
        </CardActions>
      </Fragment>
    );
  };

  const error = getIn(errors, `jsonTemplate.beacons.items`, undefined);
  let errorEl = null;
  if (error && typeof error === 'string') {
    errorEl = <Alert severity='error'>{error}</Alert>;
  }
  return (
    <Card className={classes.root}>
      <CardHeader
        title={
          <Fragment>
            <FormattedMessage
              id='Template.pkpass.beacons.title'
              defaultMessage='iBeacons'
            />
          </Fragment>
        }
        titleTypographyProps={{
          variant: 'h5',
        }}
        avatar={
          <Avatar sizes={'large'}>
            <IBeaconIcon />
          </Avatar>
        }
        subheaderTypographyProps={{ variant: 'body1' }}
        subheader={
          <FormattedMessage
            id='Template.pkpass.beacons.info'
            defaultMessage='Beacons marking locations where the pass is relevant. You can configure 10 iBeacons. If you need more, please re-configure your iBeacon to use same proximity UUID.'
          />
        }
      />
      <CardContent>{errorEl}</CardContent>
      <FieldArray name={path} render={renderArray} />
    </Card>
  );
};
