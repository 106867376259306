import { FormikProps } from 'formik';
import React from 'react';

import { ImageFormInterface } from './ImageForm';
import { ImageCropDialog, ImageCropDialogData } from './ImageCropDialog';

export default function ImageUploadDialogContentImageEdit(
  props: FormikProps<ImageFormInterface>
): JSX.Element {
  const onChange = (d: ImageCropDialogData) => {
    props.setFieldValue('width', d.width);
    props.setFieldValue('height', d.height);
    props.setFieldValue('url', d.url);
  };

  return (
    <ImageCropDialog
      url={props.values.url}
      type={props.values.type}
      onChange={onChange}
    />
  );
}
