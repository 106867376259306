import clsx from 'clsx';
import React from 'react';

import { red } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles';

import { Image } from '../../types/Image';
import { ImageTypes, PkpassPassStyleTypes } from '../../types/Template';
import { RemoveIcon } from '../common/icons';
import TriggerButton from './TriggerButton';

export interface PreviewProps {
  passStyle?: PkpassPassStyleTypes;
  imageType: ImageTypes;
  onClick?: (
    event:
      | React.KeyboardEvent<HTMLDivElement>
      | React.MouseEvent<HTMLDivElement>
  ) => void;
  onRemove?: () => void;
  triggerButton?: boolean;
  disabled?: boolean;
}

interface Props extends PreviewProps {
  data?: Image;
  size: React.CSSProperties;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    button: {
      color: theme.palette.getContrastText(red[500]),
      backgroundColor: red[500],
      position: 'absolute',
      top: -12,
      right: -12,
      '&:hover': {
        backgroundColor: red[700],
      },
    },
  })
);

export default function Preview(props: Props) {
  const theme = useTheme();
  const classes = useStyles(theme);

  const { data, size, onClick, triggerButton, disabled } = props;

  const style: React.CSSProperties = {
    ...size,
    backgroundImage: `url(${data && data.url})`,
    backgroundRepeat: 'no-repeat',
    position: 'relative',
  };

  const handleRemove =
    disabled !== true
      ? (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
          event.preventDefault();
          event.stopPropagation();
          props.onRemove && props.onRemove();
        }
      : undefined;

  let className = '';
  if (disabled !== true) {
    className = 'designerHoverItem';
  }

  return (
    <div
      className={clsx(className, classes.root)}
      onClick={onClick}
      onKeyDown={onClick}
    >
      <div style={style}>
        {handleRemove && (
          <IconButton
            size={'small'}
            onClick={handleRemove}
            type='button'
            className={classes.button}
          >
            <RemoveIcon fontSize={'small'} />
          </IconButton>
        )}
      </div>

      {triggerButton && <TriggerButton />}
    </div>
  );
}
