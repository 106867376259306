import PropTypes from 'prop-types';
import React, { FunctionComponent } from 'react';
import { ResourceTypes } from '../../../utils/services/api';
import { Props } from './FieldAutocomplete';
import { FieldAutocompleteCacheResource } from './FieldAutocompleteCacheResource';
import { StaticLabel } from '../labels';

export const FieldAutocompleteTemplate: FunctionComponent<Props<string>> = (
  props: Props<string>
): JSX.Element => {
  return (
    <FieldAutocompleteCacheResource
      {...props}
      label={props.label || StaticLabel.Template}
      resource={ResourceTypes.TEMPLATE}
    />
  );
};

FieldAutocompleteTemplate.propTypes = {
  label: PropTypes.node,
  required: PropTypes.bool,
  error: PropTypes.string,
};
