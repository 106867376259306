import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  row: {
    alignItems: 'center',
    display: 'flex',
    height: '42px',
    marginTop: theme.spacing(1),
  },
  spacer: {
    flexGrow: 1,
  },
}));

const PageToolbar = (props: any) => {
  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <div className={classes.row}>
        <span className={classes.spacer} />
        {props.children}
      </div>
    </div>
  );
};

PageToolbar.propTypes = {
  className: PropTypes.string,
};

export default PageToolbar;
