import { FormikProps } from 'formik';
import React, { Component } from 'react';

import { FieldSelect, FieldSelectData } from '../../../../../../common/fields';
import {
  PkpassField,
  PkpassFieldsType,
} from '../../../../../../../types/Template';
import {
  changeFieldType,
  getFieldType,
  PkpassFieldTypes,
} from '../../../../../../../utils/validation/fieldType';

interface Props extends FormikProps<PkpassField> {
  fieldsType: PkpassFieldsType;
}

export default class FieldTypeDropdown extends Component<Props> {
  public onChange = (event: FieldSelectData) => {
    const { values, setValues, fieldsType } = this.props;
    const newField = changeFieldType(
      values,
      event.value as PkpassFieldTypes,
      fieldsType
    );
    setValues(newField);
  };

  public render() {
    const { values } = this.props;
    const fieldType = getFieldType(values);
    return (
      <FieldSelect
        name='fieldType'
        onChange={this.onChange}
        label='Field Type'
        placeholder='Select field type'
        value={fieldType}
        options={[
          {
            key: PkpassFieldTypes.Text as string,
            text: 'Text',
            value: PkpassFieldTypes.Text as string,
          },
          {
            key: PkpassFieldTypes.Date as string,
            text: 'Date',
            value: PkpassFieldTypes.Date as string,
          },
          {
            key: PkpassFieldTypes.Number as string,
            text: 'Number',
            value: PkpassFieldTypes.Number as string,
          },
          {
            key: PkpassFieldTypes.Currency as string,
            text: 'Currency',
            value: PkpassFieldTypes.Currency as string,
          },
        ]}
      />
    );
  }
}
