import React from 'react';
import { Switch } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import {
  AboutPage,
  ActiveCardsOverviewPage,
  PkpassCertificatePage,
  ErrorPage,
  HomePage,
  ImagePage,
  LinksPage,
  PassAtLeastOnceRegisterInWalletOverviewPage,
  PassCountPage,
  PassDownloadRequestByTagsOverviewPage,
  PassInWalletOverviewPage,
  PassPage,
  PassPreviewRequestByTagsOverviewPage,
  ProfilePage,
  ProjectPage,
  TemplatePage,
  UserPage,
  DesignPkpassPage,
  DesignGooglePayPage,
} from '../views';

export default function Routes(): JSX.Element {
  return (
    <Switch>
      <PrivateRoute path='/' exact={true} component={HomePage} />
      <PrivateRoute path='/about' component={AboutPage} />
      <PrivateRoute path='/error/:statusCode' component={ErrorPage} />
      <PrivateRoute path='/identity/project/:id?' component={ProjectPage} />
      <PrivateRoute path='/identity/user/:id?' component={UserPage} />

      <PrivateRoute
        path='/passes/certificate/:id?'
        component={PkpassCertificatePage}
      />

      <PrivateRoute path='/passes/image/:id?' component={ImagePage} />

      <PrivateRoute path='/passes/pass/:id?' component={PassPage} />
      <PrivateRoute path='/passes/template/:id?' component={TemplatePage} />

      <PrivateRoute
        path='/passes/design/pkpass/:id?'
        component={DesignPkpassPage}
      />

      <PrivateRoute
        path='/passes/design/googlepay/:id?'
        component={DesignGooglePayPage}
      />

      <PrivateRoute
        path='/report/passPreviewRequestByTagsOverview'
        exact={true}
        component={PassPreviewRequestByTagsOverviewPage}
      />
      <PrivateRoute
        path='/report/passDownloadRequestByTagsOverview'
        exact={true}
        component={PassDownloadRequestByTagsOverviewPage}
      />
      <PrivateRoute
        path='/report/passInWalletOverview'
        exact={true}
        component={PassInWalletOverviewPage}
      />
      <PrivateRoute
        path='/report/passAtLeastOnceRegisterInWalletOverview'
        exact={true}
        component={PassAtLeastOnceRegisterInWalletOverviewPage}
      />
      <PrivateRoute
        path='/report/activeCardsOverview'
        exact={true}
        component={ActiveCardsOverviewPage}
      />
      <PrivateRoute path='/report/links' exact={true} component={LinksPage} />
      <PrivateRoute
        path='/report/passcount'
        exact={true}
        component={PassCountPage}
      />

      <PrivateRoute path='/settings' component={ProfilePage} />
    </Switch>
  );
}
