import { Formik, FormikProps } from 'formik';
import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';

import { AddButton, Button } from '../../buttons';
import { CancelButton } from '../../buttons';
import { JsonSchemaTypes } from '../types';
import schema, { JsonSchemaProperty } from '../form/JsonSchemaProperty';
import Dialog from '../../dialog';
import { OpenInterface } from '../../../../hooks/';
import JsonSchemaEditorAddDialogForm from './JsonSchemaEditorAddDialogForm';

interface OuterProps {
  onSubmit: (name: string, p: JsonSchemaProperty, required: boolean) => void;
  jsonSchema: Record<string, unknown>;
}

const initialValues = {
  type: JsonSchemaTypes.String,
};

export default function DynamicDataEditorPropertyModal(
  props: OuterProps
): JSX.Element {
  // const [required, setRequired] = useState(false);

  const handleSubmit = async (formData: JsonSchemaProperty) => {
    const value: any = {
      description: formData.description,
      title: formData.title,
      type: formData.type,
    };

    if (formData.type === JsonSchemaTypes.String) {
      value.enum =
        formData.enum && formData.enum.length > 0 ? formData.enum : undefined;
      value.format = formData.format ? formData.format : undefined;
      value.pattern = formData.pattern ? formData.pattern : undefined;
    }

    if (
      formData.type === JsonSchemaTypes.Integer ||
      formData.type === JsonSchemaTypes.Number
    ) {
      value.minimum = formData.minimum ? formData.minimum : undefined;
      value.maximum = formData.maximum ? formData.maximum : undefined;
      value.exclusiveMinimum = formData.exclusiveMinimum
        ? formData.exclusiveMinimum
        : undefined;
      value.exclusiveMaximum = formData.exclusiveMaximum
        ? formData.exclusiveMaximum
        : undefined;
    }

    props.onSubmit(formData.name, value, /*required */ false);
  };

  const conf = {
    initialValues,
    onSubmit: handleSubmit,
    validationSchema: schema,
  };

  return (
    <Formik {...conf}>
      {(p: FormikProps<JsonSchemaProperty>) => {
        const renderActions = (open: OpenInterface) => {
          const handleFormikSubmit = () => {
            p.submitForm();
            open.handleClose();
            setTimeout(p.resetForm, 1000);
          };

          const handleCancel = () => {
            p.resetForm();
            open.handleClose();
          };

          return (
            <Fragment>
              <CancelButton onClick={handleCancel} />
              <AddButton
                color='primary'
                onClick={handleFormikSubmit}
                disabled={!p.isValid}
              />
            </Fragment>
          );
        };

        return (
          <Dialog
            fullWidth={true}
            maxWidth={'md'}
            trigger={
              <Button color='primary'>
                <FormattedMessage
                  id='Template.DynamicDataEditorForm.Modal.Trigger'
                  defaultMessage='Add dynamic property'
                />
              </Button>
            }
            title={
              <FormattedMessage
                id='Template.DynamicDataEditorForm.Modal.Header'
                defaultMessage='Add new dynamic property'
              />
            }
            content={
              <JsonSchemaEditorAddDialogForm
                {...p}
                jsonSchema={props.jsonSchema}
              />
            }
            renderActions={renderActions}
          />
        );
      }}
    </Formik>
  );
}
