import React from 'react';
import { defineMessage, FormattedMessage } from 'react-intl';

import { grey } from '@material-ui/core/colors';
import Hidden from '@material-ui/core/Hidden';
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import useViewer from '../../hooks/useViewer';
import { Permission } from '../../types/common/viewer';
import PassCreateDrawer from '../pass/PassCreateDrawer';
import TemplateCreate from '../template/TemplateCreate';
import { Grid } from '@material-ui/core';
import introImg from '../../assets/intro.png';
import Box from '@material-ui/core/Box';
import FormattedHTMLMessage from '../common/FormattedHTMLMessage';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      marginTop: 64,
    },
    subheader: {
      color: grey[600],
    },
    content: {
      flexGrow: 1,
      paddingTop: 60,
      '@media (max-width: 1240px)': {
        paddingTop: 10,
      },
    },
    btnContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      flexWrap: 'wrap',
      width: '100%',
      marginTop: theme.spacing(3),
      '& > *': {
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
      },
      marginBottom: theme.spacing(1),
    },
    intoImg: {
      height: 160,
      width: 200,
      marginBottom: 16,
      [theme.breakpoints.up('md')]: {
        width: 200,
        height: 240,
        marginBottom: 16,
      },

      [theme.breakpoints.up('lg')]: {
        height: 280,
        width: 300,
        marginRight: 32,
        marginBottom: 0,
      },

      [theme.breakpoints.up('xl')]: {
        height: 330,
        width: 360,
        marginRight: 128,
      },
      backgroundSize: 'cover',
      backgroundImage: `url(${introImg})`,
    },
    intoContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
    },
  })
);

const HomePageHeader = (): JSX.Element => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const viewer = useViewer();
  const canCreateTemplate =
    viewer && viewer.hasPermission(Permission.TEMPLATE_CREATE);
  const canCreatePass = viewer && viewer.hasPermission(Permission.PASS_CREATE);

  return (
    <div className={classes.root}>
      <Grid container={true}>
        <Grid item={true} xs={12} md={8} lg={6}>
          <div className={classes.content}>
            <Typography variant='h4' component={'h1'}>
              <FormattedMessage
                defaultMessage='Welcome to Yourpass Console'
                id='home.header'
              />
            </Typography>
            <Box p={1} />
            <Typography variant='body1' className={classes.subheader}>
              <FormattedHTMLMessage
                descriptor={defineMessage({
                  id: 'home.core.no.project.message',
                  defaultMessage: `This application allows you to view and manage templates, passes, and reports. You can also find the API documentation for YourPass services in the navigation bar. If you need any help, you can visit our <a class="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary" href="https://yourpass.zendesk.com/" target="_blank">knowledge base</a> or <a class="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary" href="https://yourpass.zendesk.com/hc/en-us/requests/new" target="_blank">submit a ticket</a>.`,
                })}
              />
            </Typography>
            <div className={classes.btnContainer}>
              {canCreateTemplate && <TemplateCreate />}
              {canCreatePass && <PassCreateDrawer />}
            </div>
          </div>
        </Grid>
        <Hidden smDown={true} implementation='js'>
          <Grid item={true} md={4} lg={6} className={classes.intoContainer}>
            <div className={classes.intoImg} />
          </Grid>
        </Hidden>
      </Grid>
    </div>
  );
};

export default HomePageHeader;
