import { FormikProps } from 'formik';
import PropTypes from 'prop-types';
import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';

import EditDrawerInnerLayout from '../common/drawers/EditDrawerInnerLayout';
import FormikEditDrawer from '../common/formik/FormikEditDrawer';
import { LabelProject, LabelTemplate } from '../common/labels';
import Loader from '../common/loader/Loader';
import useResource from '../../hooks/useResource';
import { ResourceTypes } from '../../utils/services/api';
import { Template } from '../../types/Template';
import schema from '../../utils/validation/template';
import PassForm from './PassEditForm';
import PassFormControls from './PassFormControls';
import PassFormInterface from './PassFormInterface';

interface PassEditDrawerProps {
  id: string;
  open: boolean;
  onClose: () => void;
}

const PassEditDrawer: FunctionComponent<PassEditDrawerProps> = (
  props: PassEditDrawerProps
) => {
  const Edit: FunctionComponent<FormikProps<PassFormInterface>> = (
    p: FormikProps<PassFormInterface>
  ) => {
    const { loading, data } = useResource<Template>(
      ResourceTypes.TEMPLATE,
      p.values.templateId || ''
    );

    if (loading) {
      return <Loader />;
    }
    if (!data) {
      return <span>{`Error read template ID: ${p.values.templateId}`}</span>;
    }

    return (
      <EditDrawerInnerLayout
        onClose={props.onClose}
        title={
          <span>
            <FormattedMessage id='PassEdit.title' defaultMessage='Edit pass' />
            <Breadcrumbs aria-label='breadcrumb'>
              <LabelProject resourceId={data.projectId} />
              <LabelTemplate resourceId={p.values.templateId || ''} />
              <span>{p.values.id}</span>
            </Breadcrumbs>
          </span>
        }
        content={<PassForm template={data} {...p} />}
        controls={<PassFormControls template={data} {...p} />}
      />
    );
  };

  return (
    <FormikEditDrawer
      onClose={props.onClose}
      resource={ResourceTypes.PASS}
      id={props.id}
      validationSchema={schema}
      open={props.open}
      Component={Edit}
    />
  );
};

PassEditDrawer.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default PassEditDrawer;
