import { useEffect, useReducer } from 'react';

import { listComplete, ListParams, ResourceTypes } from '../utils/services/api';
import { API_SERVICE_TIMEOUT } from '../utils/services/environment';
import { ListComplete } from '../types/common/list';
import { asyncReducer, State, ActionType, Action } from './asyncReducer';

export default function useResources<T>(
  resource: ResourceTypes,
  opts?: ListParams
) {
  const reducer = (
    state: State<ListComplete<T>>,
    action: Action<ListComplete<T>>
  ) => asyncReducer<ListComplete<T>>(state, action);

  const [state, dispatch] = useReducer(reducer, {
    loading: true,
    data: null,
    error: null,
  } as State<ListComplete<T>>);

  useEffect(() => {
    const abortController: AbortController = new AbortController();
    dispatch({ type: ActionType.PENDING });
    const t = setTimeout(() => {
      if (!abortController.signal.aborted) {
        abortController.abort();
      }
    }, API_SERVICE_TIMEOUT);
    const fetchData = async () => {
      try {
        const response = await listComplete<T>(resource, opts, {
          signal: abortController.signal,
        });
        dispatch({ type: ActionType.SUCCESS, payload: response });
      } catch (err) {
        dispatch({ type: ActionType.FAILURE, payload: err });
      }
    };
    fetchData().finally(() => {
      clearTimeout(t);
    });
    return function cancel() {
      if (abortController) {
        if (!abortController.signal.aborted) {
          abortController.abort();
        }
      }
    };
  }, [resource, opts]);

  return {
    loading: state.loading,
    data: state.data ? state.data.data : [],
    error: state.error,
    addItem: (item: T) => {
      dispatch({
        type: ActionType.SET,
        payload: {
          ...state.data,
          data: state.data ? [...state.data.data, item] : [item],
        },
      });
    },
  };
}
