import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import PropTypes from 'prop-types';
import { get } from 'lodash';

export interface LabelProps {
  resourceId: string;
}

interface Props extends LabelProps {
  labelProperty?: string;
  loading: boolean;
  instance: unknown;
}

export const Label = (props: Props): JSX.Element => {
  const { instance, labelProperty, loading, resourceId } = props;

  if (loading) {
    return <span />;
  }

  const name = get(instance, labelProperty || 'name') || resourceId;

  return (
    <Tooltip title={resourceId}>
      <span>{name}</span>
    </Tooltip>
  );
};

Label.propTypes = {
  resourceId: PropTypes.string.isRequired,
};
