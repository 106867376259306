import React, { Fragment } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Line } from 'react-chartjs-2';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { getColorForIndex } from '../../constants/colors';
import Spacer from './Spacer';

// TODO - removes any
const messages: any = defineMessages({
  daily: {
    defaultMessage: 'Daily difference',
    id: 'BasePassOverview.daily',
  },
  weekly: {
    defaultMessage: 'Weekly difference',
    id: 'BasePassOverview.weekly',
  },
  monthly: {
    defaultMessage: 'Monthly difference',
    id: 'BasePassOverview.monthly',
  },
  lineChart: {
    defaultMessage: 'Line chart',
    id: 'BasePassOverview.lineChart',
  },
  dataTable: {
    defaultMessage: 'Data table',
    id: 'BasePassOverview.dataTable',
  },
  period: {
    defaultMessage: 'Period',
    id: 'BasePassOverview.period',
  },
  count: {
    defaultMessage: 'count',
    id: 'BasePassOverview.count',
  },
});

const useStyles = makeStyles({
  container: {
    maxHeight: 440,
  },
});

export default function BasePassOverview(props: any) {
  const classes = useStyles();

  const { data, query, getLabel } = props;

  const interval = query.interval;

  let sorted: any = [];
  if (data) {
    sorted = [].concat(data);
    sorted.sort((a: any, b: any) => {
      const c = new Date(a.from);
      const d = new Date(b.from);
      return c.getTime() - d.getTime();
    });

    sorted
      .map((d: any) => {
        const b = d;
        b.formatedLabel = getLabel(d.from, interval);
        return b;
      })
      .slice(0)
      .reverse();
  }

  return (
    <Fragment>
      <Card>
        <CardHeader title={<FormattedMessage {...messages.lineChart} />} />
        <CardContent>
          <Line
            data={{
              labels: sorted.map((s: any) => s.formatedLabel),
              datasets: [
                {
                  label: '',
                  data: sorted.map((s: any) => s.value),
                  borderColor: getColorForIndex(0),
                  backgroundColor: getColorForIndex(0),
                  fill: false,
                },
              ],
            }}
            options={{ legend: { display: false } }}
          />
        </CardContent>
      </Card>
      <Spacer />
      <Card>
        <CardHeader title={<FormattedMessage {...messages.dataTable} />} />
        <CardContent>
          <TableContainer className={classes.container}>
            <Table stickyHeader={true} aria-label='sticky table'>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <FormattedMessage {...messages.period} />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage {...messages.count} />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage {...messages[interval]} />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sorted.map((v: any, i: number) => (
                  <TableRow key={v.formatedLabel}>
                    <TableCell>{v.formatedLabel}</TableCell>
                    <TableCell>{`${v.value}`}</TableCell>
                    <TableCell>
                      {sorted[i - 1]
                        ? sorted[i].value - sorted[i - 1].value
                        : '-'}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </Fragment>
  );
}
