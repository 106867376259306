import { FormikProps } from 'formik';
import React from 'react';

import FormikResetButton from '../../components/common/formik/FormikResetButton';
import FormikSaveButton from '../../components/common/formik/FormikSaveButton';
import { Project } from './types';

export const ProjectEditFormControls = (
  props: FormikProps<Project>
): JSX.Element => {
  return (
    <div>
      <FormikSaveButton {...props} />
      <FormikResetButton {...props} />
    </div>
  );
};
