import React from 'react';
import { FormattedMessage } from 'react-intl';

import Page from '../../components/common/page/Page';
import PassCount from '../../components/pass/PassCount';

export const PassCountPage = (): JSX.Element => {
  return (
    <Page
      content={<PassCount />}
      title={
        <FormattedMessage
          id='PassCountPage.title'
          defaultMessage='Count of passes'
        />
      }
    />
  );
};
