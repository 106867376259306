import Bowser from 'bowser';
import React, { PropsWithChildren } from 'react';
import { FormattedMessage } from 'react-intl';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { Button } from '../components/common/buttons';
import useOpen from '../hooks/useOpen';

const browser = Bowser.getParser(window.navigator.userAgent);
const isValidBrowser = browser.satisfies({
  // declare browsers per OS
  // windows: {
  //   "internet explorer": ">10",
  // },
  // macos: {
  //   safari: '>13.0',
  // },

  // per platform (mobile, desktop or tablet)
  // mobile: {
  //   safari: '>=9',
  //   'android browser': '>3.10',
  // },

  // or in general
  chrome: '>80',
  safari: '>=13.0',
  // firefox: '>31',
  // opera: '>=22',

  // also supports equality operator
  // chrome: "=20.1.1432", // will match particular build only

  // // and loose-equality operator
  // chrome: "~20",        // will match any 20.* sub-version
  // chrome: "~20.1"       // will match any 20.1.* sub-version (20.1.19 as well as 20.1.12.42-alpha.1)
});

export interface BrowserContextInterface {
  browser: string;
  isValidBrowser?: boolean;
}

export const BrowserContext = React.createContext<BrowserContextInterface>({
  browser: browser.getBrowserName(),
  isValidBrowser,
});

export function BrowserContextProvider(
  props: PropsWithChildren<unknown>
): JSX.Element {
  const { open, handleClose } = useOpen(true);

  return (
    <BrowserContext.Provider
      value={{ browser: browser.getBrowserName(), isValidBrowser }}
    >
      <Dialog open={open && !isValidBrowser} onClose={handleClose}>
        <DialogTitle>
          <FormattedMessage
            id='BrowserContextProvider.modal.header'
            defaultMessage='Unsupported browser was detected'
          />
        </DialogTitle>
        <DialogContent>
          <h3>
            <FormattedMessage
              id='BrowserContextProvider.modal.content'
              defaultMessage='Your browser ({browser}/{version}) is not fully supported. The application may exhibit unexpected behavior. We recommend using the latest version of Chrome or Safari.'
              values={{
                browser: browser.getBrowserName(),
                version: browser.getBrowserVersion(),
              }}
            />
          </h3>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>
            <FormattedMessage
              id='BrowserContextProvider.modal.action.continue'
              defaultMessage='Continue'
            />
          </Button>
        </DialogActions>
      </Dialog>

      {props.children}
    </BrowserContext.Provider>
  );
}
