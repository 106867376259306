import React, { Fragment, ReactNode } from 'react';

import { default as MUIDialog } from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import useOpen, { OpenInterface } from '../../../hooks/useOpen';

interface OuterProps {
  title?: ReactNode;
  content?: ReactNode;
  renderActions: (oi: OpenInterface) => ReactNode;
  renderContent?: (oi: OpenInterface) => ReactNode;
  trigger: ReactNode;
  open?: OpenInterface;
  fullWidth?: boolean;
  maxWidth?: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | undefined;
}

export default function Dialog(props: OuterProps) {
  const open = useOpen(false);
  if (props.open !== undefined) {
    return (
      <Fragment>
        <span onClick={props.open.handleOpen}>{props.trigger}</span>
        <MUIDialog
          open={props.open.open}
          onClose={props.open.handleClose}
          fullWidth={props.fullWidth}
          maxWidth={props.maxWidth}
        >
          <DialogTitle>{props.title}</DialogTitle>
          {props.content && <DialogContent>{props.content}</DialogContent>}
          {props.renderContent && (
            <DialogContent>{props.renderContent(props.open)}</DialogContent>
          )}
          <DialogActions>{props.renderActions(props.open)}</DialogActions>
        </MUIDialog>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <span onClick={open.handleOpen}>{props.trigger}</span>
      <MUIDialog
        open={open.open}
        onClose={open.handleClose}
        fullWidth={props.fullWidth}
        maxWidth={props.maxWidth}
      >
        {props.title && <DialogTitle>{props.title}</DialogTitle>}
        {props.content && <DialogContent>{props.content}</DialogContent>}
        {props.renderContent && (
          <DialogContent>{props.renderContent(open)}</DialogContent>
        )}
        <DialogActions>{props.renderActions(open)}</DialogActions>
      </MUIDialog>
    </Fragment>
  );
}
