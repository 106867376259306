import { array, boolean, number, object, string } from 'yup'; // for only what you need

export type JsonSchemaProperty = any;
export default object().shape({
  description: string(),
  name: string()
    .matches(/^[a-zA-Z_0123456789-]*$/)
    .required(),
  title: string().required(),
  type: string().required(),

  // string props
  enum: array(string()),
  format: string(),
  pattern: string(),

  // number integer props
  exclusiveMaximum: boolean(),
  exclusiveMinimum: boolean(),
  maximum: number(),
  minimum: number(),
});

// {"description":"","enum":[],"format":null,"name":"","pattern":null,"title":"","type":"string"}
