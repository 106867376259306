import React from 'react';

import { Template } from '../../../../../types/Template';
import { getLabelColor } from '../../helpers/color';

const BoardingPassIcon = (props: {
  transitType: string;
  template: Template;
}): JSX.Element => {
  const { transitType, template } = props;
  const labelColor = getLabelColor(template);
  let d = '';

  switch (transitType) {
    case 'PKTransitTypeAir':
      d =
        'M24.2 74.6l3.7 0.1c2.1 0 3.5-2.2 3.5-2.2l4.6-6 5.3-0.1c2.8 0 2-3.4 0.7-3.6l-2.9-0.2 4.6-6.7 4.2 0.3c4.5 0.4 3.8-3.4 1.6-3.7l-2.8-0.3 7.4-9.6c14.3-0.4 20.2-0.5 20.4-4.1 0.2-3.7-6.7-3.7-20.2-3.8l-7.5-9.9 3.3-0.3c1.9-0.2 2.4-3.5-1.3-3.3l-4.8 0.2 -4.6-6.7 2.7-0.4c2.4-0.3 2.7-3.4-1.1-3.5l-4.9 0c-0.2 0-4.2-5.8-4.2-5.8 -1.9-3-4.1-2.5-4.1-2.5l-3.1 0.2 11.4 24.2c1 2.1 1.4 5.1-1 7.2 -1.3 1.1-6.3 2.1-7.2 2l-13.5 0.4c-0.3 0.1-7.5-9.6-7.5-9.6 -0.2-0.2-5.3-0.9-5.3-0.9l3.3 12.1 -3.2 1.2 3.2 0.7L1.5 52l5.1-0.8c1.1-1.7 7.7-9.9 7.9-10 0 0 11.9-0.1 12.6-0.1 4 0.1 5.5 0.2 6.8 0.9 1.3 0.7 2.3 2.7 1.1 7.7C33.3 56.6 24.2 74.6 24.2 74.6L24.2 74.6z';
      break;
    case 'PKTransitTypeTrain':
      d =
        'M53.5 57.8l-0.2-0.3c0-0.1 0-0.2 0.1-0.2 0.1 0 0.1 0 0.1-0.2 1.6-0.6 3.1-1.4 4.4-2.4 1.3-1 2.2-2.5 2.7-4.4l0.1-0.5c0.1-0.4 0.1-0.7 0.2-1V9.6c0-1-0.3-2-0.8-3 -0.6-1-1.3-1.9-2.1-2.7 -0.9-0.8-1.8-1.5-2.9-2C54.1 1.3 53.1 1 52.2 1H24c-0.9 0-1.9 0.3-2.9 0.8 -1.1 0.5-2 1.2-2.9 2s-1.6 1.7-2.1 2.7c-0.6 1-0.8 2-0.8 2.9v39.9c0 0.7 0.2 1.4 0.5 2.1s0.7 1.4 1.2 2c0.5 0.7 1.1 1.3 1.7 1.8s1.2 1 1.8 1.3c0.3 0.1 0.8 0.3 1.4 0.5 0.7 0.3 1 0.4 1 0.5L11.2 75h6.8l8.4-12.2h23.2L58 75h6.8L53.5 57.8zM31.4 4.5c0-0.3 0.1-0.6 0.4-0.9 0.3-0.3 0.5-0.5 0.8-0.5h10.9c0.1 0 0.3 0.1 0.6 0.3s0.5 0.4 0.5 0.6v4.2c0 0.3-0.1 0.6-0.4 0.8 -0.3 0.2-0.5 0.3-0.7 0.3H32.6c-0.2-0.1-0.4-0.2-0.5-0.3 -0.2-0.1-0.4-0.3-0.5-0.5 -0.2-0.2-0.3-0.4-0.3-0.6V4.5zM20.5 15.9c0-0.5 0.1-1.1 0.3-1.7 0.3-0.5 0.7-1 1.1-1.5 0.3-0.4 0.7-0.8 1.3-1.1 0.6-0.3 1.1-0.5 1.7-0.5h26.2c0.4 0 0.9 0.2 1.5 0.5 0.4 0.2 0.9 0.5 1.4 0.9 0.4 0.3 0.7 0.7 0.9 1.2 0.3 0.4 0.5 0.9 0.5 1.5v8.9c0 0.5-0.2 1-0.5 1.4s-0.7 0.9-1.1 1.3c-0.5 0.4-1 0.7-1.5 0.9 -0.4 0.2-0.9 0.3-1.5 0.3H25c-0.1 0-0.3 0-0.5-0.1s-0.4-0.1-0.5-0.2c-1-0.1-1.9-0.6-2.6-1.4 -0.7-0.9-1.1-1.8-1.1-2.8V15.9zM28.3 50.7c-0.9 0.9-1.9 1.4-3.2 1.4s-2.4-0.5-3.2-1.4 -1.2-2-1.2-3.3c0-1.2 0.4-2.2 1.3-3.1 0.9-0.9 1.9-1.3 3.1-1.3 1.3 0 2.4 0.4 3.2 1.2 0.9 0.8 1.3 1.9 1.3 3.2S29.1 49.8 28.3 50.7zM47.6 50.7c-0.9-0.9-1.3-2-1.3-3.3s0.5-2.4 1.4-3.2 2-1.2 3.2-1.2c1.3 0 2.4 0.4 3.2 1.3 0.8 0.9 1.2 1.9 1.2 3.1 0 1.3-0.4 2.4-1.2 3.3s-1.9 1.4-3.2 1.4C49.5 52.1 48.4 51.6 47.6 50.7z';
      break;

    case 'PKTransitTypeBus':
      d =
        'M5 56c0 3.6 2.6 4.6 5 6.8V70c0 2.2 1.8 4 4 4h4c2.2 0 4-1.8 4-4v-4h32v4c0 2.2 1.8 4 4 4h4c2.2 0 4-1.8 4-4v-7.2c2.4-2.2 5-3.4 5-6.8V17.8C71 3.8 55.6 2 38 2S5 3.8 5 17.8V56zM20 59c-3.4 0-6-2.6-6-6 0-3.4 2.6-6 6-6s6 2.6 6 6C26 56.4 23.4 59 20 59zM56 59c-3.4 0-6-2.6-6-6 0-3.4 2.6-6 6-6s6 2.6 6 6C62 56.4 59.4 59 56 59zM62 36H14V16h48V36z';
      break;

    case 'PKTransitTypeBoat':
      d =
        'M52 16H24v-1.4c0-1.4 0.8-2.6 2.2-2.6h6.2l1.1-10h9.1l1.1 10h6.2C51.2 12 52 13.2 52 14.6V16zM63 38.3v-17C63 19.8 61.8 19 60.4 19h-7.9H23.6h-7.9C14.2 19 13 19.8 13 21.2v17l25-11.9L63 38.3zM65.8 74L75 47 39.5 30 38 65.7 36.5 30 1 47 10.2 74H65.8z';
      break;

    default:
      d = 'M0 33h56L35 12h15l26 26.5L50 65H35l21-21H0L0 33z';
  }

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 76 76'
      style={{ width: 38, height: 38 }}
    >
      <path fill={labelColor} d={d} />
    </svg>
  );
};

export default BoardingPassIcon;
