import React, { Fragment, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';

import { Button, NextButton } from '../../common/buttons';
import BulkResultExportButton from '../../common/bulk/BulkResultExportButton';
import BulkResultTable from '../../common/bulk/BulkResultTable';
import Drawer from '../../common/drawers/Drawer';
import EditDrawerInnerLayout from '../../common/drawers/EditDrawerInnerLayout';
import FieldFileDropzone from '../../common/fields/FieldFileDropzone';
import { useVerticalStepper, VerticalStepper } from '../../common/stepper';
import useOpen from '../../../hooks/useOpen';
import {
  batch,
  ResourceTypes,
  ProgressStats,
} from '../../../utils/services/api';
import fileToBatch from '../../../utils/services/batch';
import PassBulkHelp from './PassBulkHelp';
import PassBulkStepCheckTable from './PassBulkStepCheckTable';
import { MAX } from '../../common/dataGrid/ExportButton';
import {
  LinearProgress,
  Card,
  CardContent,
  CardHeader,
  Grid,
} from '@material-ui/core';

export default function PassBulkDrawer() {
  const { open, handleClose, handleOpen } = useOpen(false);
  const [loading, setLoading] = useState(false);
  const stepper = useVerticalStepper();
  const [error, setError] = useState<null | string>(null);
  const [batches, setBatches] = useState<any[]>([]);
  const [result, setResult] = useState<any[] | null>(null);
  const [file, setFile] = useState<File | null>(null);
  const [progress, setProgres] = useState<ProgressStats>({
    current: 0,
    total: 0,
  });

  const onClose = () => {
    setLoading(false);
    setFile(null);
    stepper.reset();
    setBatches([]);
    setError(null);
    handleClose();
  };

  const handleSelectFile = (f: File) => {
    setFile(f);
  };

  const handleParseFile = async () => {
    if (file) {
      setLoading(true);
      setError(null);
      try {
        const rows: any[] = await fileToBatch(file);

        if (rows.length > MAX) {
          throw new Error(
            `Max number of lines exceeded. The file contains ${rows.length} lines. The maximum size allowed is ${MAX}.`
          );
        }

        setBatches(rows);
        stepper.next();
      } catch (e) {
        setError(e.message);
      }
      setLoading(false);
    }
  };

  const proceed = async () => {
    setLoading(true);

    try {
      const response = await batch(ResourceTypes.PASS, batches, setProgres);
      setResult(response);
    } catch (e) {
      setError(e);
    }
    stepper.next();
    setLoading(false);
  };

  const steps = [
    {
      title: (
        <FormattedMessage
          id='PassBulkSteps.upload.title'
          defaultMessage='Select file'
        />
      ),
      subtitle: (
        <FormattedMessage
          id='PassBulkSteps.upload.description'
          defaultMessage='Select csv or excel file with passes.'
        />
      ),
      content: loading ? (
        <CircularProgress />
      ) : (
        <div>
          <FieldFileDropzone
            multiple={false}
            accept={
              'text/csv, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
            }
            onSelect={handleSelectFile}
            infoText={
              <FormattedMessage
                defaultMessage='Click here or drag & drop csv or excel file to this area.'
                id='PassBulkModal.upload.helpText'
              />
            }
          />
          {error && (
            <Alert severity={'error'}>
              <AlertTitle>Error</AlertTitle>
              {error}
            </Alert>
          )}
        </div>
      ),
      actions: (
        <NextButton onClick={handleParseFile} disabled={!file || loading} />
      ),
    },
    {
      title: (
        <FormattedMessage
          id='PassBulkSteps.execute.title'
          defaultMessage='Check and execute'
        />
      ),
      subtitle: (
        <FormattedMessage
          id='PassBulkSteps.execute.description'
          defaultMessage='Check and execute prepared operations from file.'
        />
      ),
      content: loading ? (
        <Card>
          <CardHeader title='Execute operations' />
          <CardContent>
            <LinearProgress
              variant='buffer'
              value={
                progress.total > 0
                  ? (progress.current * 100) / progress.total
                  : 0
              }
              valueBuffer={progress.total > 0 ? 100 / progress.total : 100}
            />

            <p style={{ textAlign: 'center' }}>
              {progress.total === 0 && (
                <FormattedMessage
                  id='PassBulk.preparing'
                  defaultMessage='Preparing operation'
                />
              )}
              {progress.total > 0 && (
                <FormattedMessage
                  id='PassBulk.content.progress'
                  defaultMessage='{percent}% of operations are completed'
                  values={{
                    ...progress,
                    percent: (
                      (100 * progress.current) /
                      progress.total
                    ).toFixed(0),
                  }}
                />
              )}
            </p>
          </CardContent>
        </Card>
      ) : (
        <Card>
          <CardHeader title='Pass operations to check' />
          <CardContent>
            <PassBulkStepCheckTable batches={batches} />
          </CardContent>
        </Card>
      ),
      actions: (
        <Button
          color='primary'
          variant={'contained'}
          onClick={proceed}
          disabled={loading}
        >
          Proceed
        </Button>
      ),
    },
    {
      title: (
        <FormattedMessage
          id='PassBulkSteps.results.title'
          defaultMessage='Results'
        />
      ),
      subtitle: (
        <FormattedMessage
          id='PassBulkSteps.results.description'
          defaultMessage='See operation results'
        />
      ),
      content: result && <BulkResultTable results={result} />,
      actions: result && (
        <BulkResultExportButton
          results={result}
          prefix={`pass-bulk-`}
          onClose={onClose}
        />
      ),
    },
  ];

  return (
    <Fragment>
      <Button
        color={'secondary'}
        onClick={handleOpen}
        tooltip={
          <FormattedMessage
            id='PassBulkModal.action.button.helpText'
            defaultMessage={
              'With this automatic Bulk tool you can generate or change from 1 to 1000 passes with dynamic data, which you have specified on your template.'
            }
          />
        }
      >
        <FormattedMessage
          id='PassBulkModal.action.button.title'
          defaultMessage='Bulk operation'
        />
      </Button>
      <Drawer
        open={open}
        onClose={!loading ? onClose : undefined}
        content={
          <EditDrawerInnerLayout
            onClose={!loading ? onClose : undefined}
            content={
              <Grid container={true}>
                <Grid item={true} xs={12} md={6}>
                  <VerticalStepper activeStep={stepper.step} steps={steps} />
                </Grid>
                <Grid item={true} xs={12} md={6}>
                  <PassBulkHelp />
                </Grid>
              </Grid>
            }
            title={
              <FormattedMessage
                id='PassNewModal.header'
                defaultMessage='Bulk operation with passes'
              />
            }
          />
        }
      />
    </Fragment>
  );
}
