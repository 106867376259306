import PropTypes from 'prop-types';
import React, { FunctionComponent, ReactNode } from 'react';

import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { createStyles, makeStyles, useTheme } from '@material-ui/core/styles';

import { FieldLabel } from './FieldLabel';

export type FieldSelectData = {
  name: string;
  value?: string | string[] | boolean;
};

const useStyles = makeStyles(() =>
  createStyles({
    formControl: {
      minWidth: 120,
    },
    select: {
      paddingTop: 14,
      paddingBottom: 14,
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 2,
    },
  })
);

export interface FieldSelectOption {
  key: string;
  value: string | undefined;
  text: React.ReactNode;
}

export interface FieldSelectProps {
  value?: unknown | unknown[];
  onChange: (change: FieldSelectData) => void;
  label?: React.ReactNode;
  helpText?: React.ReactNode;
  options?: FieldSelectOption[];
  disabled?: boolean;
  multiple?: boolean;
  name: string;
  required?: boolean;
  placeholder?: string;
  error?: string;
  displayEmpty?: boolean;
}

export const FieldSelect: FunctionComponent<FieldSelectProps> = (
  props: FieldSelectProps
) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const handleChange = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => {
    props.onChange({
      name: props.name,
      value: event.target.value ? (event.target.value as string) : undefined,
    });
  };

  const handleChangeMultiple = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => {
    props.onChange({ name: props.name, value: event.target.value as string[] });
  };

  const handleRemoveMultiple = (arr: string[]) => {
    props.onChange({ name: props.name, value: arr as string[] });
  };

  const renderChip = (selected: unknown): ReactNode => (
    <div className={classes.chips}>
      {(selected as string[]).map((value: string) => {
        let label: ReactNode = value;
        const arr =
          props.options &&
          props.options?.filter((i: FieldSelectOption) => i.value === value);
        if (arr && arr.length > 0) {
          label = arr[0].text;
        }
        return (
          <Chip
            size={'small'}
            key={value}
            label={label}
            className={classes.chip}
            onDelete={() => {
              const values = props.value as [];
              const filtered = values.filter(
                (entry: FieldSelectOption) => entry.value !== value
              );
              handleRemoveMultiple(filtered);
            }}
          />
        );
      })}
    </div>
  );

  return (
    <FormControl
      variant='outlined'
      className={classes.formControl}
      required={props.required}
      disabled={props.disabled}
      error={!!props.error}
      margin='dense'
    >
      <InputLabel id={`select-outlined-label-${props.name}`}>
        <FieldLabel label={props.label} helpText={props.helpText} />
      </InputLabel>
      <Select
        displayEmpty={props.displayEmpty}
        required={props.required}
        disabled={props.disabled}
        error={!!props.error}
        variant={'outlined'}
        labelId={`select-outlined-label-${props.name}`}
        id={`select-outlined-${props.name}`}
        label={<FieldLabel label={props.label} helpText={props.helpText} />}
        value={props.value ? props.value : ''}
        onChange={props.multiple ? handleChangeMultiple : handleChange}
        multiple={props.multiple}
        renderValue={props.multiple ? renderChip : undefined}
        inputProps={{
          className:
            props.multiple && Array.isArray(props.value) && props.value.length
              ? classes.select
              : undefined,
        }}
      >
        {props.options &&
          props.options.map((o: FieldSelectOption) => {
            if (!props.multiple) {
              return (
                <MenuItem key={o.key} value={o.value}>
                  {o.text}
                </MenuItem>
              );
            }
            return (
              <MenuItem key={o.key} value={o.value}>
                {props.value && props.multiple && (
                  <Checkbox
                    checked={
                      !!o.value &&
                      Array.isArray(props.value) &&
                      props.value.indexOf(o.value) > -1
                    }
                  />
                )}
                <ListItemText primary={o.text} />
              </MenuItem>
            );
          })}
      </Select>
      <FormHelperText error={true}>{props.error}</FormHelperText>
    </FormControl>
  );
};

FieldSelect.propTypes = {
  options: PropTypes.array,
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
};
