/* eslint-disable react/prop-types */
import React, { PropsWithChildren } from 'react';
import { useSnackbar } from 'notistack';
import { Viewer } from '../types/Viewer';
import { CacheContextProvider } from './CacheContext';
import { FilterContextProvider } from './FilterContext';

type State = {
  viewer: Viewer;
};

export type UserContextInterface = State & {
  signOut: () => void;
};

export const UserContext = React.createContext<UserContextInterface>(
  {} as UserContextInterface
);

export const UserProvider: React.FC<PropsWithChildren<{ viewer: Viewer }>> = ({
  children,
  viewer,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const signOut = () => {
    const xhr = new XMLHttpRequest();
    xhr.onload = () => {
      if (xhr.status === 200) {
        window.location.reload();
      } else {
        console.error(xhr.responseText);
        enqueueSnackbar('Logout failed', { variant: 'error' });
      }
    };
    xhr.open('POST', '/auth/logout');
    xhr.send();
  };
  return (
    <UserContext.Provider value={{ viewer, signOut }}>
      <CacheContextProvider viewer={viewer}>
        <FilterContextProvider viewer={viewer}>
          {children}
        </FilterContextProvider>
      </CacheContextProvider>
    </UserContext.Provider>
  );
};

export const useUserContext = (): { viewer: Viewer; signOut: () => void } =>
  React.useContext<UserContextInterface>(UserContext);
