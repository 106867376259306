import { FormikProps, getIn } from 'formik';
import React, { Component } from 'react';

import { ImageFormInterface } from './ImageForm';
import {
  FieldAutocompleteProject,
  FieldSelectData,
  FieldSelectImageType,
  FieldStatic,
  FieldTextInput,
  FieldTextInputData,
} from '../common/fields';
import Form from '../common/Form';
import { StaticLabel } from '../common/labels';

interface ImageUploadModalFormProps extends FormikProps<ImageFormInterface> {
  projectId?: string;
  type?: string;
}

class ImageUploadDialogContentForm extends Component<
  ImageUploadModalFormProps
> {
  public handleChange = (s: string | undefined) => {
    const { setFieldValue } = this.props;
    setFieldValue('projectId', s);
  };

  public handleSelectChange = (event: FieldSelectData) => {
    const { setFieldValue } = this.props;
    setFieldValue(event.name, event.value);
  };

  public render(): JSX.Element {
    const { values, setFieldValue, errors } = this.props;

    return (
      <Form>
        <FieldAutocompleteProject
          error={errors.projectId}
          disabled={!!this.props.projectId}
          name='projectId'
          value={values.projectId}
          onChange={this.handleChange}
          required={true}
          multiple={false}
        />
        <FieldSelectImageType
          disabled={!!this.props.type}
          name='type'
          value={values.type}
          onChange={this.handleSelectChange}
          required={true}
        />
        <FieldTextInput
          error={getIn(errors, 'name')}
          value={values.name}
          onChange={(d: FieldTextInputData) => {
            setFieldValue('name', d.value);
          }}
          name='name'
          label={StaticLabel.Name}
        />
        {values && values.url && (
          <FieldStatic
            name={'dimensions'}
            label={StaticLabel.Dimension}
            value={`${values.width} x ${values.height}`}
          />
        )}
      </Form>
    );
  }
}

export default ImageUploadDialogContentForm;
