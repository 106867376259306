import { getIn, setIn, useFormikContext } from 'formik';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import Form from '../../../../common/Form';
import {
  PkpassBarcode,
  PkpassBarcodes,
  PkpassBarcodeTypes,
} from '../../../../../types/Template';
import {
  barcodeFallbackOptions,
  barcodeTypeOptions,
} from '../../helpers/barcode';
import {
  PkpassSelect,
  PkpassSelectData,
  PkpassString,
  PkpassStringData,
} from '../../inputs';

export default function BarcodeForm(): JSX.Element {
  const { values, setValues } = useFormikContext<PkpassBarcodes>();

  const barcodeTypeValue = getIn(values, [
    'items',
    '0',
    'properties',
    'format',
    'value',
  ]);

  const handleChange = (d: PkpassStringData): void => {
    let barcodes: PkpassBarcodes =
      values && values.items
        ? values
        : {
            items: [],
            type: 'array',
          };

    barcodes.items.forEach((_: PkpassBarcode, index: number) => {
      const path = `${d.name.replace('items.0', `items.${index}`)}`;
      barcodes = setIn(barcodes, path, d.value);
    });

    setValues(barcodes);
  };

  const handleChangeFormat = (e: PkpassSelectData) => {
    const barcodes: PkpassBarcodes =
      values && values.items
        ? values
        : {
            items: [],
            type: 'array',
          };

    if (e.value) {
      if (barcodes.items.length > 0 && barcodes.items[0].properties) {
        barcodes.items[0].properties.format = e.value;
      } else {
        barcodes.items.push({
          properties: {
            format: e.value,
            messageEncoding: { type: 'string', value: 'UTF-8' },
          },
          type: 'object',
        });
      }
    }

    if (
      e.value &&
      e.value.value === PkpassBarcodeTypes.PKBarcodeFormatCode128
    ) {
      if (barcodes.items.length > 1) {
        barcodes.items[1] = { ...barcodes.items[0] };
      } else {
        const i = barcodes.items[0];
        barcodes.items.push({
          properties: {
            altText: { ...getIn(i, ['properties', 'altText']) },
            format: {
              type: 'string',
              value: PkpassBarcodeTypes.PKBarcodeFormatQR,
            },
            message: { ...getIn(i, ['properties', 'message']) },
            messageEncoding: { ...getIn(i, ['properties', 'messageEncoding']) },
          },
          type: 'object',
        });
      }
    } else {
      if (barcodes.items.length > 1) {
        barcodes.items = [barcodes.items[0]];
      }
    }

    setValues(barcodes);
  };

  return (
    <Form style={{ width: 400, marginBottom: 20 }}>
      <PkpassSelect
        options={barcodeTypeOptions}
        label={
          <FormattedMessage
            id='pkpass.barcodes.format.label'
            defaultMessage='Format'
          />
        }
        helpText={
          <FormattedMessage
            id='pkpass.barcodes.format.helpText'
            defaultMessage='Barcode format. For the barcode dictionary, you can use only the following values: PKBarcodeFormatQR, PKBarcodeFormatPDF417, or PKBarcodeFormatAztec. For dictionaries in the barcodes array, you may also use PKBarcodeFormatCode128.'
          />
        }
        name={'items.0.properties.format'}
        onChange={handleChangeFormat}
      />

      {barcodeTypeValue === 'PKBarcodeFormatCode128' && (
        <PkpassSelect
          options={barcodeFallbackOptions}
          label={
            <FormattedMessage
              id='pkpass.barcodes.format2.label'
              defaultMessage='Fallback format'
            />
          }
          helpText={
            <FormattedMessage
              id='pkpass.barcodes.format2.helpText'
              defaultMessage="Fallback format is used when device can't display barcode in format"
            />
          }
          name={'`items.1.properties.format'}
        />
      )}

      <PkpassString
        label={
          <FormattedMessage
            id='pkpass.barcodes.altText.label'
            defaultMessage='Alt text'
          />
        }
        helpText={
          <FormattedMessage
            id='pkpass.barcodes.altText.helpText'
            defaultMessage=' Text displayed near the barcode. For example, a human-readable version of the barcode data in case the barcode doesn’t scan.'
          />
        }
        name={'items.0.properties.altText'}
        onChange={handleChange}
      />

      <PkpassString
        required={true}
        label={
          <FormattedMessage
            id='pkpass.barcodes.message.label'
            defaultMessage='Message'
          />
        }
        helpText={
          <FormattedMessage
            id='pkpass.barcodes.message.helpText'
            defaultMessage='Message or payload to be displayed as a barcode.'
          />
        }
        name={'items.0.properties.message'}
        onChange={handleChange}
        multiline={true}
      />
      <PkpassString
        required={true}
        label={
          <FormattedMessage
            id='pkpass.barcodes.messageEncoding.label'
            defaultMessage='Encoding'
          />
        }
        helpText={
          <FormattedMessage
            id='pkpass.barcodes.messageEncoding.helpText'
            defaultMessage='Text encoding that is used to convert the message from the string representation to a data representation to render the barcode. The value is typically iso-8859-1, but you may use another encoding that is supported by your barcode scanning infrastructure.'
          />
        }
        name={'items.0.properties.messageEncoding'}
        onChange={handleChange}
      />
    </Form>
  );
}
