import React, { Fragment, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';

import { Button, NextButton, PreviousButton } from '../../common/buttons';
import BulkResultExportButton from '../../common/bulk/BulkResultExportButton';
import BulkResultTable from '../../common/bulk/BulkResultTable';
import { BulkResultItem } from '../../common/bulk/types';
import { ImageCropDialog, ImageCropDialogData } from '../ImageCropDialog';
import {
  FieldAutocompleteProject,
  FieldSelectData,
  FieldSelectImageType,
} from '../../common/fields';
import Form from '../../common/Form';
import { useVerticalStepper, VerticalStepper } from '../../common/stepper';
import Loader from '../../common/loader/Loader';
import { OpenInterface } from '../../../hooks';
import { create, ResourceTypes } from '../../../utils/services/api';
import {
  imagesConfig,
  ImageValidationErrorCodes,
  NewImage,
} from '../../../types/Image';
import { ImageTypes } from '../../../types/Template';
import messages, { pkpassImageSizeMessages } from '../messages';
import BatchUploadModalDropZone from './ImageBulkDropZone';

export const ImageBulkStepper = (props: {
  open: OpenInterface;
}): JSX.Element => {
  const [projectId, setProjectId] = useState<string | undefined>(undefined);
  const [imageType, setImageType] = useState<ImageTypes>(ImageTypes.strip);
  const [images, setImages] = useState<NewImage[]>([]);
  const stepper = useVerticalStepper();
  const [loading, setLoading] = useState<boolean>(false);
  const [result, setResult] = useState<BulkResultItem[]>([]);
  const [error, setError] = useState(null);
  const { open } = props;

  const handleCloseAndReset = () => {
    setImages([]);
    setError(null);
    setProjectId(undefined);
    stepper.reset();
    open.handleClose();
  };

  const handleSelectFiles = async (imgs: NewImage[]) => {
    const p: NewImage[] = imgs.map(
      (i) =>
        ({
          ...i,
          projectId: projectId,
          type: imageType,
        } as NewImage)
    );
    setImages(p);
  };

  const proceed = async () => {
    setLoading(true);

    const responses: any[] = [];
    for (const image of images) {
      try {
        const response = await create(ResourceTypes.IMAGE, image);
        responses.push({
          data: response,
          status: { code: 201, message: 'created' },
        });
      } catch (e) {
        responses.push({ status: { code: e.status, message: e.statusText } });
      }
    }

    setResult(responses);
    stepper.next();
    setLoading(false);
  };

  const handleProjectChange = (d: string | undefined) => {
    setProjectId(d);
  };

  const handleTypeChange = (d: FieldSelectData) => {
    setImageType(d.value as ImageTypes);
  };

  const canUpload = projectId && imageType;

  const steps = [
    {
      title: (
        <FormattedMessage
          id='ImageImportModal.step0.title'
          defaultMessage='Project and images specification'
        />
      ),
      subtitle: (
        <FormattedMessage
          id='ImageImportModal.step0.description'
          defaultMessage='Select project, image type'
        />
      ),
      content: (
        <Form>
          <FieldAutocompleteProject
            value={projectId}
            name='projectId'
            onChange={handleProjectChange}
            required={true}
            multiple={false}
          />
          <FieldSelectImageType
            value={imageType}
            name='imageType'
            onChange={handleTypeChange}
            required={true}
          />
        </Form>
      ),
      actions: (
        <Fragment>
          <PreviousButton disabled={true} onClick={stepper.prev} />
          <NextButton disabled={!canUpload} onClick={stepper.next} />
        </Fragment>
      ),
    },

    {
      title: (
        <FormattedMessage
          id='ImageImportModal.step1.title'
          defaultMessage='Images specification'
        />
      ),
      subtitle: (
        <FormattedMessage
          id='ImageImportModal.step1.description'
          defaultMessage='Select png files contains {imageType} images'
          values={{ imageType: imageType }}
        />
      ),
      content: (
        <div>
          <Alert severity='info' style={{ marginBottom: 16 }}>
            <FormattedMessage {...pkpassImageSizeMessages[imageType]} />
          </Alert>
          <BatchUploadModalDropZone
            disabled={!canUpload}
            onSelectFiles={handleSelectFiles}
          />
        </div>
      ),
      actions: (
        <Fragment>
          <PreviousButton onClick={stepper.prev} />

          <NextButton
            disabled={!(images && images.length > 0)}
            onClick={stepper.next}
          />
        </Fragment>
      ),
    },
    {
      title: (
        <FormattedMessage
          id='ImageImportModal.step2.title'
          defaultMessage='Check images'
        />
      ),
      subtitle: (
        <FormattedMessage
          id='ImageImportModal.step2.description'
          defaultMessage='Check images resolution.'
        />
      ),
      content: (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <FormattedMessage
                  id='ImageImportModal.preview'
                  defaultMessage='Preview'
                />
              </TableCell>
              <TableCell>
                <FormattedMessage
                  id='ImageImportModal.name'
                  defaultMessage='Name'
                />
              </TableCell>
              <TableCell>
                <FormattedMessage
                  id='ImageImportModal.size'
                  defaultMessage='Size'
                />
              </TableCell>
              <TableCell>
                <FormattedMessage
                  id='ImageImportModal.imageType'
                  defaultMessage='Image type'
                />
              </TableCell>
              <TableCell>
                <FormattedMessage
                  id='ImageImportModal.check'
                  defaultMessage='Image result'
                />
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {images &&
              images.map((data: any, index: number) => {
                const imageDef = imagesConfig[imageType];
                const sizeErrors = imageDef.validation(data.width, data.height);

                const handleCrop = (d: ImageCropDialogData) => {
                  images[index].url = d.url;
                  images[index].height = d.height;
                  images[index].width = d.width;
                  setImages([...images]);
                };

                return (
                  <TableRow key={`${index}`}>
                    <TableCell>
                      <img
                        src={data.url}
                        style={{ maxWidth: 100 }}
                        alt={data.name}
                      />
                    </TableCell>
                    <TableCell>{data.name}</TableCell>
                    <TableCell>
                      {data.width} x {data.height}
                    </TableCell>
                    <TableCell>{data.type}</TableCell>
                    <TableCell>
                      {sizeErrors.length > 0 &&
                        sizeErrors.map(
                          (err: {
                            code: ImageValidationErrorCodes;
                            params: any;
                          }) => (
                            <Alert severity='error' key={err.code}>
                              <FormattedMessage
                                {...messages[err.code]}
                                values={err.params}
                              />
                            </Alert>
                          )
                        )}
                    </TableCell>
                    <TableCell>
                      <IconButton>
                        <ImageCropDialog
                          url={data.url}
                          type={data.type}
                          onChange={handleCrop}
                        />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      ),
      actions: (
        <Fragment>
          <PreviousButton onClick={stepper.prev} />
          <Button color='primary' variant='contained' onClick={proceed}>
            <FormattedMessage
              id='ImageImportModal.proceed'
              defaultMessage='Import images'
            />
          </Button>
        </Fragment>
      ),
    },
    {
      title: (
        <FormattedMessage
          id='ImageImportModal.step3.title'
          defaultMessage='Import results'
        />
      ),
      subtitle: (
        <FormattedMessage
          id='ImageImportModal.step3.description'
          defaultMessage='See operation results and export images to csv'
        />
      ),
      content: !loading && !error && result && (
        <BulkResultTable results={result} />
      ),
      actions: !loading && !error && result && (
        <BulkResultExportButton
          results={result}
          onClose={handleCloseAndReset}
          prefix='images-bulk-'
        />
      ),
    },
  ];

  return (
    <div>
      {!loading && <VerticalStepper activeStep={stepper.step} steps={steps} />}
      {error && (
        <Alert severity='error'>
          <AlertTitle>Error</AlertTitle>
          {error}
        </Alert>
      )}
      {loading && <Loader />}
    </div>
  );
};
