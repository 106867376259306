import React from 'react';
import { defineMessages, useIntl } from 'react-intl';

import IconButton from '@material-ui/core/IconButton';
import CopyIcon from '@material-ui/icons/FileCopy';

import { copyToClipboard } from '../../../utils/services/clipboard';
import useNotification from '../../../hooks/useNotification';

const messages = defineMessages({
  copied: {
    defaultMessage: 'Copied',
    id: 'button.copy.to.clipboard.success',
  },
  copyFailure: {
    defaultMessage: 'Copy to clipboard fail',
    id: 'button.copy.to.clipboard.failure',
  },
});

export const CopyToClipboardButton = (props: {
  textToCopy: string;
  trigger?: JSX.Element;
}): JSX.Element => {
  const intl = useIntl();
  const { addErrorNotification, addSuccessNotification } = useNotification();

  const handleCopyToClipboard = async (
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>
  ) => {
    event.preventDefault();
    event.stopPropagation();
    const success = await copyToClipboard(props.textToCopy);
    if (success) {
      addSuccessNotification(intl.formatMessage(messages.copied));
    } else {
      addErrorNotification(intl.formatMessage(messages.copyFailure));
    }
  };

  let trigger = (
    <IconButton onClick={handleCopyToClipboard}>
      <CopyIcon fontSize={'small'} />
    </IconButton>
  );
  if (props.trigger) {
    trigger = (
      <span onClick={handleCopyToClipboard} style={{ cursor: 'pointer' }}>
        {props.trigger}
      </span>
    );
  }

  return trigger;
};
