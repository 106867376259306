import { useContext } from 'react';

import {
  FilterContext,
  FilterContextInterface,
} from '../context/FilterContext';

export default function useFilter(): FilterContextInterface {
  const f: FilterContextInterface = useContext(FilterContext);
  return f;
}
