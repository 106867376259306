import React from 'react';

import Checkbox from '@material-ui/core/Checkbox';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { AutocompleteRenderOptionState } from '@material-ui/lab/Autocomplete';

import { LabelProject } from '../labels';

export const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
export const checkedIcon = <CheckBoxIcon fontSize='small' />;

export type ListItem = {
  id: string;
  name: string;
  projectId?: string | null;
  parentId?: string | null;
};

export const useStyles = makeStyles(() =>
  createStyles({
    formControl: {
      minWidth: 252,
      '& .MuiAutocomplete-root': {
        '& .MuiFormControl-root': {
          marginTop: 0,
          marginBottom: 0,
          '& .MuiChip-root': {
            height: 20,
            fontSize: '0.65rem',
            '& .MuiChip-deleteIcon': {
              height: 16,
              width: 16,
            },
          },
        },
      },
    },
  })
);

export interface BaseProps<T> {
  disabled?: boolean;
  required?: boolean;
  name: string;
  onBlur?: (event: React.FocusEvent<HTMLDivElement>) => void;
  label?: React.ReactNode;
  placeholder?: string;
  helpText?: React.ReactNode;
  error?: string;
  filter?: any;
}

export interface MultipleProps<T> extends BaseProps<T> {
  multiple: true;
  value?: T[];
  onChange: (value: T[] | undefined) => void;
}

export interface SingleProps<T> extends BaseProps<T> {
  multiple: false | undefined;
  value?: T;
  onChange: (value: T | undefined) => void;
}

export type Props<T> = MultipleProps<T> | SingleProps<T>;

export const getOptionLabel = (option: ListItem) => {
  return option.name;
};

export const renderOption = (
  option: ListItem,
  state: AutocompleteRenderOptionState
): JSX.Element => {
  if (state.selected) {
    return (
      <span>
        <strong>{option.name}</strong>
      </span>
    );
  }
  return <span>{option.name}</span>;
};

export const renderOptionMultiple = (
  option: ListItem,
  state: AutocompleteRenderOptionState
): JSX.Element => {
  let secondary: undefined | React.ReactNode;

  if (option.parentId) {
    secondary = <LabelProject resourceId={option.parentId} />;
  }

  if (option.projectId) {
    secondary = <LabelProject resourceId={option.projectId} />;
  }

  return (
    <ListItem
      dense={true}
      disableGutters={true}
      style={{ whiteSpace: 'nowrap', padding: 0, margin: 0 }}
      component='div'
    >
      <ListItemIcon style={{ padding: 0, margin: 0, minWidth: 32 }}>
        <Checkbox
          edge='start'
          icon={icon}
          size={'small'}
          checkedIcon={checkedIcon}
          checked={state.selected}
        />
      </ListItemIcon>
      <ListItemText
        style={{ padding: 0, margin: 0 }}
        primary={option.name}
        secondary={secondary}
      />
    </ListItem>
  );
};

export const parseMultipleValue = (
  data: ListItem[],
  val?: string[]
): ListItem[] => {
  const res: ListItem[] = [];
  data.forEach((p: ListItem) => {
    if (val && val.indexOf(p.id) > -1) {
      res.push(p);
    }
  });
  return res;
};

export const parseValue = (
  data: ListItem[],
  val?: string
): ListItem | undefined => {
  let res: ListItem | undefined = undefined;
  data.forEach((p: ListItem) => {
    if (val && val === p.id) {
      res = p;
    }
  });
  return res;
};
