import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import Checkbox from '@material-ui/core/Checkbox';
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Alert from '@material-ui/lab/Alert';

import { ResourcePagedHookProps } from '../../../hooks/useResourcesPaged';
import { ResourceTypes } from '../../../utils/services/api';
import Filter, { FilterOptionsProps } from '../filter/Filter';
import DataGridCell from './DataGridCell';
import DataGridTableHeader from './DataGridTableHeader';
import EnhancedTableToolbar from './EnhancedTableToolbar';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      marginBottom: theme.spacing(2),
      width: '100%',
    },
    root: {
      width: '100%',
    },
    table: {
      // minWidth: 750,
      width: '100%',
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  })
);

export enum DataGridColumns {
  Actions = 'actions',
}

export interface Field<T> {
  key: string;
  title: string | JSX.Element;
  type?: 'boolean' | 'date' | 'object' | 'text';
  render?: (item: T, listLoad: () => void) => any;
  orderBy?: string;
}

export type Fields<T> = Array<Field<T>>;

export interface DataGridProps<T> {
  resource: ResourceTypes;
  fields: Fields<T>;
  filterOptions?: FilterOptionsProps;
  export?: boolean;
  exportHeaders?: string[];
  actionsWidth?: number;
  selectRow?: (i: number, value: boolean) => void;
  canBeDeleted?: (item: T) => boolean;
  selected: { [key: number]: boolean };
  title?: JSX.Element | string;
}

export default function DataGrid<T extends any>(
  props: DataGridProps<T> & ResourcePagedHookProps<T>
) {
  const classes = useStyles();
  const [dirty, setDirty] = useState(false);
  const {
    filterOptions,
    onFilterSubmit,
    selectRow,
    data,
    loading,
    fields,
    listLoad,
    selected,
    prevPage,
    nextPage,
    page,
  } = props;

  const handleSelect = (index: number) => {
    return (
      event:
        | React.FormEvent<HTMLInputElement>
        | React.MouseEvent<HTMLTableRowElement, MouseEvent>,
      checked: boolean
    ) => {
      if (selectRow) {
        selectRow(index, checked);
      }
    };
  };

  const handleChangeLimit = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    props.listSetLimit(parseInt(event.target.value, 10));
  };

  const renderTable = () => {
    const handlePageChanged = (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
      newPage: number
    ) => {
      if (newPage > page - 1) {
        if (nextPage) {
          nextPage();
        }
      }

      if (newPage < page - 1) {
        if (prevPage) {
          prevPage();
        }
      }
    };

    const renderDisplayedRows = () => {
      return `Page ${page}`;
    };

    return (
      <TableContainer>
        <Table
          className={classes.table}
          aria-labelledby='tableTitle'
          aria-label='enhanced table'
          size='small'
        >
          <DataGridTableHeader {...props} />
          <TableBody>
            {loading && (
              <TableRow>
                <TableCell colSpan={fields.length + (selectRow ? 1 : 0)}>
                  <LinearProgress />
                </TableCell>
              </TableRow>
            )}
            {!loading &&
              data &&
              Object.keys(data).map((row, index) => {
                const clickHandler = handleSelect(index);
                const isItemSelected = selected[index];
                const rowClickHandler = (
                  event: React.MouseEvent<HTMLTableRowElement, MouseEvent>
                ) => {
                  clickHandler(event, !isItemSelected);
                };

                return (
                  <TableRow
                    key={row}
                    hover={true}
                    onClick={rowClickHandler}
                    role='checkbox'
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    selected={isItemSelected}
                  >
                    {selectRow && (
                      <TableCell padding='checkbox'>
                        <Checkbox
                          key={isItemSelected ? 'true' : 'false'}
                          checked={isItemSelected}
                          onChange={clickHandler}
                          value={isItemSelected}
                          disabled={!!props.canBeDeleted}
                        />
                      </TableCell>
                    )}
                    {fields.map((field: Field<T>) => {
                      const _v: any = data[index];
                      return (
                        <TableCell
                          key={field.key}
                          style={{
                            width:
                              field.key === DataGridColumns.Actions
                                ? props.actionsWidth || 20
                                : undefined,
                          }}
                        >
                          <DataGridCell
                            field={field}
                            property={_v[field.key]}
                            value={data[index]}
                            listLoad={listLoad}
                          />
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          component='div'
          count={-1}
          rowsPerPage={props.limit}
          page={props.page - 1}
          onChangeRowsPerPage={handleChangeLimit}
          labelDisplayedRows={renderDisplayedRows}
          onChangePage={handlePageChanged}
        />
      </TableContainer>
    );
  };

  const autoLoadWarn = props.autoLoad === false && props.refreshCount === 0;
  if (filterOptions) {
    filterOptions.onFilter = () => {
      onFilterSubmit();
      setDirty(false);
    };
    filterOptions.onChange = () => {
      setDirty(true);
    };
    filterOptions.disabled = autoLoadWarn ? false : loading;
  }

  const filter = filterOptions ? <Filter {...filterOptions} /> : undefined;

  return (
    <div className={classes.root}>
      {filter}
      <Paper className={classes.paper}>
        {autoLoadWarn && (
          <Alert severity={'info'}>
            <FormattedMessage
              id={'DataGrid.autoload.warning'}
              defaultMessage={
                'Please set up filter and press Apply button to show results.'
              }
            />
          </Alert>
        )}
        {!autoLoadWarn && <EnhancedTableToolbar {...props} dirty={dirty} />}
        {!autoLoadWarn && renderTable()}
      </Paper>
    </div>
  );
}

DataGrid.propTypes = {
  limit: PropTypes.number,
  page: PropTypes.number,
  fields: PropTypes.array,
  actionsWidth: PropTypes.number,
};
