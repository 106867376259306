import React from 'react';
import { defineMessage, FormattedMessage } from 'react-intl';

import { PkpassNumberStyleTypes } from '../../../../../../../../types/Template';
import { PkpassSelect } from '../../../../../inputs';
import FormattedHTMLMessage from '../../../../../../../common/FormattedHTMLMessage';

const options = [
  {
    key: PkpassNumberStyleTypes.PKNumberStyleDecimal,
    text: PkpassNumberStyleTypes.PKNumberStyleDecimal,
    value: PkpassNumberStyleTypes.PKNumberStyleDecimal,
  },
  {
    key: PkpassNumberStyleTypes.PKNumberStylePercent,
    text: PkpassNumberStyleTypes.PKNumberStylePercent,
    value: PkpassNumberStyleTypes.PKNumberStylePercent,
  },
  {
    key: PkpassNumberStyleTypes.PKNumberStyleScientific,
    text: PkpassNumberStyleTypes.PKNumberStyleScientific,
    value: PkpassNumberStyleTypes.PKNumberStyleScientific,
  },
  {
    key: PkpassNumberStyleTypes.PKNumberStyleSpellOut,
    text: PkpassNumberStyleTypes.PKNumberStyleSpellOut,
    value: PkpassNumberStyleTypes.PKNumberStyleSpellOut,
  },
];
export default function FieldTypeNumberAdvanced(): JSX.Element {
  return (
    <PkpassSelect
      options={options}
      helpText={
        <FormattedHTMLMessage
          descriptor={defineMessage({
            id: 'pkpass.field.numberStyle.helpText',
            defaultMessage: ` Style of number to display. Must be one of the following values:
<ul>
<li>PKNumberStyleDecimal</li>
<li>PKNumberStylePercent</li>
<li>PKNumberStyleScientific</li>
<li>PKNumberStyleSpellOut</li>
</ul>
<p>Number styles have the same meaning as the Cocoa number formatter styles with corresponding names. For more information, see <a href='https://developer.apple.com/documentation/foundation/nsnumberformatterstyle'>NSNumberFormatterStyle</a>.</p>
            `,
          })}
        />
      }
      name={`properties.numberStyle`}
      label={
        <FormattedMessage
          id='pkpass.field.numberStyle.label'
          defaultMessage='Number style'
        />
      }
    />
  );
}
