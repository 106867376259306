import React, { FunctionComponent } from 'react';

import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

import { ChangeEvent } from './ChangeEvent';
import { FieldLabel } from './FieldLabel';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      minWidth: 120,
    },
  })
);

export interface FieldTextArea {
  value?: string;
  onChange: (event: ChangeEvent) => void;
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  label?: React.ReactNode;
  helpText?: React.ReactNode;
  disabled?: boolean;
  name: string;
  required?: boolean;
  placeholder?: string;
  error?: string;
}

export const FieldTextArea: FunctionComponent<FieldTextArea> = (
  props: FieldTextArea
) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const handleChange = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown | undefined;
    }>
  ) => {
    event.target.name = props.name;
    if (props.onChange) {
      props.onChange(event as ChangeEvent);
    }
  };

  return (
    <FormControl
      variant='outlined'
      className={classes.formControl}
      required={props.required}
      disabled={props.disabled}
      error={!!props.error}
      margin='dense'
    >
      <InputLabel id={`textarea-outlined-label-${props.name}`}>
        <FieldLabel label={props.label} helpText={props.helpText} />
      </InputLabel>
      <TextareaAutosize
        id={`textarea-outlined-${props.name}`}
        name={props.name}
        value={props.value ? props.value : ''}
        onChange={handleChange}
        onBlur={props.onBlur}
        required={props.required}
        disabled={props.disabled}
      />
      <FormHelperText error={true}>{props.error}</FormHelperText>
    </FormControl>
  );
};
