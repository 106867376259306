import React from 'react';

import { useCacheResource } from '../../../hooks/useCache';
import { ResourceTypes } from '../../../utils/services/api';
import { Label, LabelProps } from './Label';

export const LabelProject = (props: LabelProps): JSX.Element => {
  const { data, loading } = useCacheResource(
    ResourceTypes.PROJECT,
    props.resourceId
  );
  return <Label {...props} instance={data} loading={loading} />;
};
