import React, { Fragment, PropsWithChildren, useState } from 'react';

import { green } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles';

import { AddIcon } from '../../../../common/icons';
import {
  PkpassField,
  PkpassFieldsType,
  Template,
} from '../../../../../types/Template';
import shortid from 'shortid';
import { useFormikContext, FieldArray, FieldArrayRenderProps } from 'formik';
import Tooltip from '@material-ui/core/Tooltip';
import { pkpassAddFieldHintMessages } from '../../pkpass.messages';
import { useIntl } from 'react-intl';
import FieldFormikDialog from './field/edit/FieldFormikDialog';
import { getPassStyle } from '../../helpers/passStyle';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    addButton: {
      color: theme.palette.getContrastText(green[500]),
      backgroundColor: green[500],
      pointerEvents: 'auto',
      boxShadow:
        '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
      '&:hover': {
        backgroundColor: green[700],
      },
    },
  })
);

interface AddFieldButtonProps {
  fieldsType: PkpassFieldsType;
}

const AddFieldButtonWithHandler = (
  props: PropsWithChildren<AddFieldButtonProps> & {
    onAddField: (f: PkpassField) => void;
  }
): JSX.Element => {
  const { fieldsType, onAddField } = props;
  const theme = useTheme();
  const intl = useIntl();
  const classes = useStyles(theme);
  const { values } = useFormikContext<Template>();

  const [field, setField] = useState<PkpassField | null>(null);

  const handleSubmit = (f: PkpassField) => {
    onAddField(f);
  };

  const handleClose = () => {
    setField(null);
  };
  const handleOpen = () => {
    setField({
      properties: { key: { type: 'string', value: shortid.generate() } },
      type: 'object',
    });
  };

  return (
    <Fragment>
      <Tooltip
        title={intl.formatMessage(pkpassAddFieldHintMessages[fieldsType])}
      >
        <IconButton
          size={'small'}
          type='button'
          onClick={handleOpen}
          className={classes.addButton}
        >
          <AddIcon fontSize={'small'} />
        </IconButton>
      </Tooltip>
      {field && (
        <FieldFormikDialog
          field={field}
          onClose={handleClose}
          onSubmit={handleSubmit}
          languages={values.languages}
          fieldsType={fieldsType}
        />
      )}
    </Fragment>
  );
};

const AddFieldButton = (
  props: PropsWithChildren<AddFieldButtonProps>
): JSX.Element | null => {
  const { values } = useFormikContext<Template>();

  const { fieldsType } = props;
  const passStyle = getPassStyle(values);
  const path = `jsonTemplate.${passStyle}.properties.${fieldsType}.items`;

  return (
    <FieldArray name={path}>
      {(arrayHelpers: FieldArrayRenderProps) => {
        const handleAddField = (f: PkpassField) => {
          arrayHelpers.push(f);
        };
        return (
          <AddFieldButtonWithHandler {...props} onAddField={handleAddField} />
        );
      }}
    </FieldArray>
  );
};

export default AddFieldButton;
