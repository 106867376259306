import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import ResourcePage from '../../../components/common/page/ResourcePage';
import useViewer from '../../../hooks/useViewer';
import { ResourcePageProps } from '../../types';
import TemplateEditDrawer from '../../../components/template/TemplateEditDrawer';
import TemplateList from '../../../components/template/TemplateList';
import TemplateNewModal from '../../../components/template/TemplateCreate';

export const TemplatePage: FunctionComponent<ResourcePageProps> = (
  props: ResourcePageProps
) => {
  const viewer = useViewer();

  const canCreate = viewer && viewer.canCreateTemplate();
  return (
    <ResourcePage
      {...props}
      DrawerComponent={TemplateEditDrawer}
      content={<TemplateList autoLoad={!props.match.params.id} />}
      controls={canCreate ? <TemplateNewModal /> : undefined}
      title={
        <FormattedMessage
          id='TemplateListPage.title'
          defaultMessage='Templates'
        />
      }
      onCloseDrawer={() => {
        props.history.push('/passes/template');
      }}
    />
  );
};
