import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Formik, FormikHelpers, useFormikContext } from 'formik';

import { CancelButton, DeleteButton, DoneButton } from '../buttons';

interface Props<T> {
  content: React.ReactNode;
  onClose: () => void;
  onSubmit: (item: T) => void;
  onDelete?: (item: T) => void;
  onDeleteMessage?: string;
  isOpen: boolean;
  title?: JSX.Element | string;
  initialValues: T;
  validationSchema?: any;
  validateOnMount?: boolean;
  fullWidth?: boolean;
  maxWidth?: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl';
}

function FormikDeleteButton<T>(props: {
  onDelete: (item: T) => void;
  onDeleteMessage?: string;
}): JSX.Element {
  const { values } = useFormikContext<T>();
  const handleDelete = () => {
    props.onDelete(values);
  };

  return (
    <DeleteButton confirmText={props.onDeleteMessage} onDelete={handleDelete} />
  );
}

const FormikSubmitButton = (): JSX.Element => {
  const { submitForm, isValid, submitCount, isSubmitting } = useFormikContext();
  const handleClick = async () => {
    await submitForm();
  };
  return (
    <DoneButton
      onClick={handleClick}
      disabled={(submitCount > 0 && !isValid) || isSubmitting}
    />
  );
};

export default function FormikDialog<T>(props: Props<T>): JSX.Element {
  if (!props.isOpen) {
    return <span />;
  }
  const handleSubmit = (values: T, _formikHelpers: FormikHelpers<T>) => {
    props.onSubmit(values);
    props.onClose();
  };

  return (
    <Formik
      initialValues={props.initialValues}
      validationSchema={props.validationSchema}
      validateOnMount={
        props.validationSchema
          ? props.validationSchema.isValidSync(props.initialValues)
          : undefined
      }
      onSubmit={handleSubmit}
    >
      <Dialog
        open={props.isOpen}
        onClose={props.onClose}
        disableBackdropClick={true}
        fullWidth={props.fullWidth}
        maxWidth={props.maxWidth}
      >
        <DialogTitle>{props.title}</DialogTitle>
        <DialogContent>{props.content}</DialogContent>
        <DialogActions>
          {props.onDelete && (
            <FormikDeleteButton
              onDelete={props.onDelete}
              onDeleteMessage={props.onDeleteMessage}
            />
          )}
          {props.onClose && <CancelButton onClick={props.onClose} />}
          {props.onSubmit && <FormikSubmitButton />}
        </DialogActions>
      </Dialog>
    </Formik>
  );
}
