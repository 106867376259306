import { array, MixedSchema, object, string } from 'yup';

import {
  PkpassDataDetectorTypes,
  PkpassDateStyleTypes,
  PkpassField,
  PkpassNumberStyleTypes,
  PkpassTextAlignTypes,
  PkpassTransitTypes,
} from '../../types/Template';
import { getFieldType, PkpassFieldTypes } from './fieldType';
import {
  arrayTypeSchema,
  booleanValueSchema,
  dateValueSchema,
  joinPaths,
  localizableStringValueSchema,
  numberValueSchema,
  objectTypeSchema,
  stringTypeSchema,
  stringValueSchema,
} from './helper';
import {
  textAttributedValueSchema,
  textValueSchema,
} from './jsonTemplate.passStyle.field.values';

export const PkpassTransitTypesSchema = object()
  .shape({
    type: stringTypeSchema.required(),
    value: string()
      .oneOf([
        PkpassTransitTypes.PKTransitTypeAir,
        PkpassTransitTypes.PKTransitTypeBoat,
        PkpassTransitTypes.PKTransitTypeBus,
        PkpassTransitTypes.PKTransitTypeGeneric,
        PkpassTransitTypes.PKTransitTypeTrain,
      ])
      .required(),
  })
  .default(undefined);

export const PkpassTextAlignTypesSchema = object()
  .shape({
    type: stringTypeSchema.required(),
    value: string()
      .oneOf([
        PkpassTextAlignTypes.PKTextAlignmentCenter,
        PkpassTextAlignTypes.PKTextAlignmentLeft,
        PkpassTextAlignTypes.PKTextAlignmentNatural,
        PkpassTextAlignTypes.PKTextAlignmentRight,
      ])
      .required(),
  })
  .default(undefined);

export const PkpassNumberStyleTypesSchema = object()
  .shape({
    type: stringTypeSchema.required(),
    value: string()
      .oneOf([
        PkpassNumberStyleTypes.PKNumberStyleDecimal,
        PkpassNumberStyleTypes.PKNumberStylePercent,
        PkpassNumberStyleTypes.PKNumberStyleScientific,
        PkpassNumberStyleTypes.PKNumberStyleSpellOut,
      ])
      .required(),
  })
  .default(undefined);

export const PkpassDataDetectorTypesSchema = object()
  .shape({
    type: stringTypeSchema.required(),
    value: string()
      .oneOf([
        PkpassDataDetectorTypes.PKDataDetectorTypePhoneNumber,
        PkpassDataDetectorTypes.PKDataDetectorTypeLink,
        PkpassDataDetectorTypes.PKDataDetectorTypeAddress,
        PkpassDataDetectorTypes.PKDataDetectorTypeCalendarEvent,
      ])
      .required(),
  })
  .default(undefined);

export const PkpassDateStyleTypesSchema = object()
  .shape({
    type: stringTypeSchema.required(),
    value: string()
      .oneOf([
        PkpassDateStyleTypes.PKDateStyleNone,
        PkpassDateStyleTypes.PKDateStyleShort,
        PkpassDateStyleTypes.PKDateStyleMedium,
        PkpassDateStyleTypes.PKDateStyleLong,
        PkpassDateStyleTypes.PKDateStyleFull,
      ])
      .required(),
  })
  .default(undefined);

export const numberFieldSchema = object()
  .shape({
    properties: object()
      .shape({
        changeMessage: localizableStringValueSchema,
        dataDetectorTypes: PkpassDataDetectorTypesSchema,
        key: stringValueSchema.required(),
        label: localizableStringValueSchema.default(undefined),
        numberStyle: PkpassNumberStyleTypesSchema,
        textAlignment: PkpassTextAlignTypesSchema,
        value: numberValueSchema.required(),
      })
      .noUnknown(),
    type: objectTypeSchema,
  })
  .noUnknown();

export const currencyFieldSchema = object()
  .shape({
    properties: object()
      .shape({
        // TODO create list of currencies
        changeMessage: localizableStringValueSchema,
        currencyCode: stringValueSchema.default(undefined),
        dataDetectorTypes: PkpassDataDetectorTypesSchema,
        key: stringValueSchema.required(),
        label: localizableStringValueSchema.default(undefined),
        numberStyle: PkpassNumberStyleTypesSchema,
        textAlignment: PkpassTextAlignTypesSchema,
        value: numberValueSchema.required(),
      })
      .noUnknown(),
    type: objectTypeSchema,
  })
  .noUnknown();

export const dateFieldSchema = object()
  .shape({
    properties: object()
      .shape({
        changeMessage: localizableStringValueSchema,
        dataDetectorTypes: PkpassDataDetectorTypesSchema,
        dateStyle: PkpassDateStyleTypesSchema,
        ignoresTimeZone: booleanValueSchema.default(undefined),
        isRelative: booleanValueSchema.default(undefined),
        key: stringValueSchema.required(),
        label: localizableStringValueSchema.default(undefined),
        textAlignment: PkpassTextAlignTypesSchema,
        timeStyle: PkpassDateStyleTypesSchema,
        value: dateValueSchema.required(),
      })
      .noUnknown(),
    type: objectTypeSchema,
  })
  .noUnknown();

export const textFieldSchema = object()
  .shape({
    properties: object()
      .shape({
        attributedValue: textAttributedValueSchema,
        changeMessage: localizableStringValueSchema,
        dataDetectorTypes: PkpassDataDetectorTypesSchema,
        key: stringValueSchema.required(),
        label: localizableStringValueSchema.default(undefined),
        textAlignment: PkpassTextAlignTypesSchema,
        timeStyle: PkpassDateStyleTypesSchema,
        value: textValueSchema.required(),
      })
      .noUnknown(),
    type: objectTypeSchema,
  })
  .noUnknown();

export const simpleFieldSchema = object()
  .shape({
    properties: object()
      .shape({
        key: stringValueSchema.required(),
        label: localizableStringValueSchema.default(undefined),
        value: object().required(),
      })
      .noUnknown(),
    type: objectTypeSchema,
  })
  .noUnknown();

export const fieldSchema: MixedSchema = object().test({
  name: 'fieldSchema',
  test(f: PkpassField) {
    try {
      const type = getFieldType(f as PkpassField);
      switch (type) {
        case PkpassFieldTypes.Date: {
          dateFieldSchema.required().validateSync(f);
          break;
        }
        case PkpassFieldTypes.Text: {
          textFieldSchema.required().validateSync(f);
          break;
        }
        case PkpassFieldTypes.Number: {
          numberFieldSchema.required().validateSync(f);
          break;
        }
        case PkpassFieldTypes.Currency: {
          currencyFieldSchema.required().validateSync(f);
          break;
        }
        default:
          simpleFieldSchema.required().validateSync(f);
      }
    } catch (e) {
      return this.createError({
        path: joinPaths(this.path, e.path),
        message: e.toString(),
      });
    }

    return true;
  },
});

export const fieldsSchema = object({
  items: array().of(fieldSchema),
  type: arrayTypeSchema,
}).default(undefined);

export const passStyleSchema = object().shape({
  properties: object({
    auxiliaryFields: fieldsSchema,
    backFields: fieldsSchema,
    headerFields: fieldsSchema,
    primaryFields: fieldsSchema,
    secondaryFields: fieldsSchema,
  }),
  type: objectTypeSchema,
});

export const boardingPassStyleSchema = object().shape({
  properties: object({
    auxiliaryFields: fieldsSchema,
    backFields: fieldsSchema,
    headerFields: fieldsSchema,
    primaryFields: fieldsSchema,
    secondaryFields: fieldsSchema,
    transitType: PkpassTransitTypesSchema.required(),
  }),
  type: objectTypeSchema,
});
