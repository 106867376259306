import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Button } from '../common/buttons';

const TriggerButton = () => {
  return (
    <Button color='primary' style={{ marginLeft: 40 }}>
      <FormattedMessage
        id='pkpass.image.change.button'
        defaultMessage='Change'
      />
    </Button>
  );
};

export default TriggerButton;
