import 'cropperjs/dist/cropper.css';

import React, { Fragment, useRef } from 'react';
import Cropper from 'react-cropper';
import { FormattedMessage } from 'react-intl';

import IconButton from '@material-ui/core/IconButton';
import CropIcon from '@material-ui/icons/Crop';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';

import { OpenInterface } from '../../hooks/useOpen';
import { getImageProportion } from '../../utils/services/image';
import { ImageTypes } from '../../types/Template';
import { Button, CancelButton, ResetButton } from '../common/buttons';
import Dialog from '../common/dialog';

export interface ImageCropDialogProps {
  url: any;
  type: ImageTypes;
  onChange: (data: ImageCropDialogData) => void;
  trigger?: React.ReactNode;
}

export interface ImageCropDialogData {
  url: any;
  height: number;
  width: number;
}

export const ImageCropDialog = (props: ImageCropDialogProps) => {
  const { type, url, onChange, trigger } = props;
  const cropperRef = useRef<Cropper>(null);

  const getSizes = (): any => {
    if (type === ImageTypes.background) {
      return { maxHeight: 440, maxWidth: 360, minHeight: 440, minWidth: 360 };
    }

    if (type === ImageTypes.footer) {
      return { maxHeight: 30, maxWidth: 572, minHeight: 30, minWidth: 572 };
    }

    if (type === ImageTypes.logo) {
      return { maxHeight: 100, maxWidth: 320, minHeight: 100, minWidth: 0 };
    }

    if (type === ImageTypes.thumbnail) {
      return { maxHeight: 180, maxWidth: 180, minHeight: 120, minWidth: 120 };
    }

    if (type === ImageTypes.icon) {
      return {
        maxHeight: 58,
        maxWidth: 58,
        minHeight: 58,
        minWidth: 58,
      };
    }

    if (type === ImageTypes.strip) {
      return {
        maxHeight: 246,
        maxWidth: 640,
        minHeight: 168,
        minWidth: 640,
      };
    }
    return {};
  };

  const sizes = getSizes();

  const cropperProps: any = {
    minCanvasHeight: sizes.minHeight,
    minCanvasWidth: sizes.minWidth,
  };
  if (
    sizes.minWidth === sizes.maxWidth &&
    sizes.minHeight === sizes.maxHeight
  ) {
    cropperProps.aspectRatio = sizes.minWidth / sizes.minHeight;
  } else {
    if (type === ImageTypes.strip) {
      cropperProps.aspectRatio = sizes.maxWidth / sizes.minHeight;
    }
    if (type === ImageTypes.thumbnail) {
      cropperProps.aspectRatio = sizes.maxWidth / sizes.maxHeight;
    }
    if (type === ImageTypes.logo) {
      cropperProps.aspectRatio = sizes.maxWidth / sizes.maxHeight;
    }
    // crop={this.handleCrop}
  }

  const renderActions = (oi: OpenInterface) => {
    const handleApply = async () => {
      oi.handleClose();
      const s = getSizes();
      if (cropperRef && cropperRef.current != null) {
        const croppedImageUrl = cropperRef.current
          .getCroppedCanvas()
          .toDataURL();

        const img = document.createElement('img');
        img.onload = async () => {
          const canvas = document.createElement('canvas');
          canvas.width = s.maxWidth;
          canvas.height = s.maxHeight;

          const ctx = canvas.getContext('2d');
          if (ctx) {
            ctx.drawImage(img, 0, 0, s.maxWidth, s.maxHeight);
            const resizedUrl = canvas.toDataURL('image/png');
            const { width, height } = await getImageProportion(resizedUrl);
            onChange({
              width,
              height,
              url: resizedUrl,
            });
          }
        };

        img.onerror = (e) => {
          return console.error(e);
        };
        img.src = croppedImageUrl;
      }
    };

    const handleReset = () => {
      if (cropperRef && cropperRef.current) {
        cropperRef.current.reset();
      }
    };

    const handleZoomMinus = () => {
      if (cropperRef && cropperRef.current) {
        cropperRef.current.zoom(-0.1);
      }
    };

    const handleZoomPlus = () => {
      if (cropperRef && cropperRef.current) {
        cropperRef.current.zoom(0.1);
      }
    };

    return (
      <Fragment>
        <CancelButton onClick={oi.handleClose} />
        <ResetButton onClick={handleReset} />
        <IconButton onClick={handleZoomMinus}>
          <ZoomInIcon />
        </IconButton>
        <IconButton onClick={handleZoomPlus}>
          <ZoomOutIcon />
        </IconButton>

        <Button
          color={'primary'}
          startIcon={<CropIcon />}
          onClick={handleApply}
        >
          <FormattedMessage
            id='ImageUploadModalImageEdit.action'
            defaultMessage='Crop'
          />
        </Button>
      </Fragment>
    );
  };

  return (
    <Dialog
      title={
        <FormattedMessage
          id='ImageUploadModalImageEdit.modal.header'
          defaultMessage='Crop image'
        />
      }
      content={
        <Cropper
          ref={cropperRef}
          src={url}
          style={{ height: 400, width: '100%' }}
          // Cropper.js options
          guides={false}
          {...cropperProps}
        />
      }
      renderActions={renderActions}
      trigger={
        trigger || (
          <Button startIcon={<CropIcon />}>
            <FormattedMessage
              id='ImageUploadModalImageEdit.modal.trigger'
              defaultMessage='Crop image'
            />
          </Button>
        )
      }
    />
  );
};
