import { useContext } from 'react';

import {
  TimezoneContext,
  TimezoneContextInterface,
} from '../context/TimezoneContext';

export default function useTimezone(): TimezoneContextInterface {
  const ctx = useContext(TimezoneContext);
  return ctx;
}
