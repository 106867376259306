import React from 'react';
import { useIntl, MessageDescriptor } from 'react-intl';

declare type FormatXMLElementFn<T, R = string | Array<string | T>> = (
  parts: Array<string | T>
) => R;

const FormattedHTMLMessage = (props: {
  descriptor: MessageDescriptor;
  values?: Record<
    string,
    string | number | boolean | Date | FormatXMLElementFn<string, string>
  >;
}) => {
  const intl = useIntl();
  const html = intl.formatMessage(props.descriptor, props.values);
  return <span dangerouslySetInnerHTML={{ __html: html }} />;
};

export default FormattedHTMLMessage;
