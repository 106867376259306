import React from 'react';
import { defineMessages, useIntl } from 'react-intl';

import FormikDialog from '../../../../common/formik/FormikDialog';
import { PkpassBarcodes, Template } from '../../../../../types/Template';
import { barcodesSchema } from '../../../../../utils/validation/jsonTemplate.barcode';
import { useTemplateUI } from '../../useTemplateUI';
import BarcodesForm from './BarcodesForm';
import { getIn, useFormikContext } from 'formik';

const messages = defineMessages({
  deleteConfirm: {
    defaultMessage: 'Barcodes will be removed from template. Are you sure?',
    id: 'BarcodeDialog.delete.confirm.message',
  },
});

export default function BarcodeDialog(): JSX.Element {
  const templateUI = useTemplateUI();
  const intl = useIntl();

  const formikProps = useFormikContext<Template>();
  const { setFieldValue, values } = formikProps;
  const barcodes = getIn(values, 'jsonTemplate.barcodes', undefined);

  const handleBarcodeChange = (b: PkpassBarcodes | undefined) => {
    setFieldValue(`jsonTemplate.barcodes`, b);
  };

  const handleClose = () => {
    templateUI.uiSetTemplateForceOpenBarcode(false);
  };

  const handleSubmit = (p: PkpassBarcodes) => {
    handleBarcodeChange(p);
    templateUI.uiSetTemplateForceOpenBarcode(false);
  };

  if (!templateUI.openBarcodes) {
    return <div />;
  }

  const initialValues: PkpassBarcodes = barcodes
    ? JSON.parse(JSON.stringify(barcodes))
    : {
        type: 'array',
        items: [],
      };

  const handleRemove = () => {
    handleBarcodeChange(undefined);
    handleClose();
  };
  return (
    <FormikDialog
      onSubmit={handleSubmit}
      onClose={handleClose}
      initialValues={initialValues}
      validationSchema={barcodesSchema}
      title={'Barcode'}
      isOpen={templateUI.openBarcodes}
      onDelete={handleRemove}
      onDeleteMessage={intl.formatMessage(messages.deleteConfirm)}
      content={<BarcodesForm />}
    />
  );
}
