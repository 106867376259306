export type State<T> = {
  loading: boolean;
  data: T | null;
  error: Error | null;
};

export enum ActionType {
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
  SET = 'SET',
}

export type Action<T> =
  | {
      type: ActionType.PENDING;
    }
  | {
      type: ActionType.SUCCESS;
      payload: T | null;
    }
  | {
      type: ActionType.FAILURE;
      payload: Error;
    }
  | {
      type: ActionType.SET;
      payload: T | null;
    };

export const asyncReducer = <T>(
  state: State<T>,
  action: Action<T>
): State<T> => {
  switch (action.type) {
    case ActionType.PENDING:
      return { error: null, data: null, loading: true };
    case ActionType.SUCCESS:
      return { ...state, data: action.payload, loading: false };
    case ActionType.FAILURE:
      return { ...state, error: action.payload, loading: false };
    case ActionType.SET:
      return { ...state, data: action.payload };
    default:
      throw new Error('no such action type');
  }
};
