import React, { Fragment, FunctionComponent, useEffect } from 'react';

import Page, { PageProps } from './Page';
import useOpen from '../../../hooks/useOpen';
import { ResourcePageProps } from '../../../views/types';

interface DrawerProps {
  id: string;
  open: boolean;
  onClose: () => void;
}

export default function ResourcePage(
  props: ResourcePageProps & {
    DrawerComponent: FunctionComponent<DrawerProps>;
    onCloseDrawer: () => void;
  } & PageProps
): JSX.Element {
  const { DrawerComponent } = props;
  const open = useOpen(!!props.match.params.id);

  const handleClose = () => {
    props.onCloseDrawer();
  };

  useEffect(() => {
    if (props.match.params.id) {
      open.handleOpen();
    } else {
      open.handleClose();
    }
  }, [props.match.params.id, open]);

  return (
    <Fragment>
      <DrawerComponent
        id={props.match.params.id}
        open={open.open}
        onClose={handleClose}
      />

      <Page
        content={props.content}
        controls={props.controls}
        title={props.title}
      />
    </Fragment>
  );
}
