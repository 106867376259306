import { Field, FieldProps } from 'formik';
import React from 'react';

import {
  FieldSelect,
  FieldSelectData,
  FieldSelectOption,
} from '../../../common/fields';
import { errorsToString } from './helper';

type ValueType =
  | {
      type: 'string';
      value: string;
    }
  | undefined;

export interface PkpassSelectData {
  name: string;
  value: ValueType;
}

export interface PkpassSelectProps {
  name: string;
  label: React.ReactNode;
  helpText: React.ReactNode;
  required?: boolean;
  options: FieldSelectOption[];
  onChange?: (data: PkpassSelectData) => void;
}

export const PkpassSelect = (props: PkpassSelectProps): JSX.Element => {
  return (
    <Field name={props.name}>
      {(field: FieldProps) => {
        const { label, helpText, name, required, options, onChange } = props;

        const handleChange = (event: FieldSelectData) => {
          const newValue: {
            value: string;
            type: 'string';
          } = { value: event.value as string, type: 'string' };
          field.form.setFieldValue(name, newValue);
          if (onChange) {
            onChange({ name, value: newValue });
          }
        };

        const value = field.field.value?.value;
        const error = errorsToString(field.form.errors, [name]);

        return (
          <FieldSelect
            label={label}
            helpText={helpText}
            error={error}
            name={name}
            value={value}
            onChange={handleChange}
            options={options}
            required={required}
          />
        );
      }}
    </Field>
  );
};
