import React, { FunctionComponent } from 'react';

import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles';

import { FieldLabel } from './FieldLabel';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      minWidth: 120,
    },
  })
);

export interface FieldCheckboxProps {
  checked?: boolean | undefined;
  onChange?: (event: FieldCheckboxChange) => void;
  label?: React.ReactNode;
  helpText?: React.ReactNode;
  disabled?: boolean;
  multiple?: boolean;
  name: string;
  required?: boolean;
  placeholder?: string;
  error?: string;
  size?: 'small' | 'medium' | undefined;
}

export type FieldCheckboxChange = {
  name: string;
  value: boolean;
};

export const FieldCheckbox: FunctionComponent<FieldCheckboxProps> = (
  props: FieldCheckboxProps
) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const handleChange = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown | undefined;
    }>,
    checked: boolean
  ) => {
    if (props.onChange) {
      props.onChange({ name: props.name, value: checked });
    }
  };

  return (
    <FormControl
      className={classes.formControl}
      variant='outlined'
      required={props.required}
      disabled={props.disabled}
      margin='dense'
    >
      <FormControlLabel
        control={
          <Checkbox
            size={props.size}
            disabled={props.disabled}
            key={props.name}
            name={props.name}
            checked={props.checked}
            onChange={handleChange}
            required={props.required}
          />
        }
        label={<FieldLabel label={props.label} helpText={props.helpText} />}
      />
      <FormHelperText error={true}>{props.error}</FormHelperText>
    </FormControl>
  );
};
