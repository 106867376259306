import { FormikErrors, FormikHelpers, useFormik } from 'formik';
import { useHistory } from 'react-router';

import useNotification from '../../../hooks/useNotification';
import useOpen from '../../../hooks/useOpen';
import {
  create,
  getResourceRoutePrefix,
  ResourceTypes,
} from '../../../utils/services/api';

interface UseCreateFormikOpts<T> {
  resource: ResourceTypes;
  initialValues: T;
  validateOnBlur?: boolean;
  validateOnChange?: boolean;
  onCreate?: (t: any) => void;
  validate?: (
    values: T
  ) => void | Record<string, unknown> | Promise<FormikErrors<T>>;
  validationSchema?: any;
}

export function useCreateFormik<T>(opts: UseCreateFormikOpts<T>) {
  const { resource } = opts;
  const open = useOpen(false);
  const { addNotification } = useNotification();
  const history = useHistory();

  const handleSubmit = async (values: T, actions: FormikHelpers<T>) => {
    actions.setSubmitting(true);
    try {
      const r: T = await create(resource, values);
      if (opts.onCreate) {
        opts.onCreate(r);
      } else {
        addNotification({
          level: 'success',
          message: `${resource} was created`,
        });
        const a: any = r;
        if (a.id) {
          history.replace(
            `/${getResourceRoutePrefix(resource)}/${resource}/${a.id}`
          );
        }
      }
      open.handleClose();
    } catch (e) {
      addNotification({
        level: 'error',
        message: `Create ${resource} failure, because: ${e.message}`,
      });
    }
    actions.setSubmitting(false);
  };

  const {
    initialValues,
    validateOnBlur,
    validateOnChange,
    validate,
    validationSchema,
  } = opts;

  const formik = useFormik<T>({
    initialValues: initialValues as T,
    onSubmit: handleSubmit,
    validate: validate,
    validationSchema: validationSchema,
    validateOnMount: true,
    validateOnBlur: validateOnBlur,
    validateOnChange: validateOnChange,
  });

  const handleCancel = () => {
    formik.resetForm();
    open.handleClose();
  };

  const handleReset = () => {
    formik.resetForm();
  };

  const handleSubmitClick = () => {
    formik.submitForm();
  };

  return {
    handleCancel,
    handleReset,
    handleSubmitClick,
    open,
    formik,
  };
}
