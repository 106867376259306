import { array, boolean, object, string } from 'yup'; // for only what you need

import { jsonTemplateSchema } from './jsonTemplate';
import { localeSchema, localizableSchema } from './localizable';

export const imageSchema = localizableSchema;
export const languagesSchema = array().of(localeSchema).min(1).default([]);

export const imagesSchema = object({
  background: imageSchema,
  footer: imageSchema,
  icon: imageSchema,
  logo: imageSchema,
  strip: imageSchema,
  thumbnail: imageSchema,
});

export default object().shape({
  certificateId: string().required(),
  distributable: boolean(),
  images: imagesSchema,
  jsonTemplate: jsonTemplateSchema,
  languages: languagesSchema,
  name: string().required(),
  projectId: string().required(),
});
