import { FormikProps } from 'formik';
import React from 'react';

import { PreviewIcon } from '../../common/icons';
import { StaticLabel } from '../../common/labels/LabelStatic';
import { TabOption } from '../../common/Tabs';
import { Template } from '../../../types/Template';
import PreviewPageForm from './PreviewPageForm';

export default function creatPreviewPageTab(
  props: FormikProps<Template>
): TabOption {
  return {
    content: <PreviewPageForm {...props} />,
    error: false,
    icon: <PreviewIcon />,
    key: 'previewPage',
    name: StaticLabel.PreviewPage,
  };
}
