// import pjson from '../../../package.json';

export default (input: RequestInfo, init?: RequestInit): Promise<Response> => {
  const globalInit = init || {};

  // globalInit.headers = {
  //   ...globalInit.headers,
  //   // 'X-User-Agent': 'YourpassConsole/' + pjson.version,
  // };

  globalInit.credentials = 'include';
  return fetch(input, globalInit);
};
