import { useEffect, useReducer } from 'react';
import { read, ResourceTypes, update } from '../utils/services/api';
import { API_SERVICE_TIMEOUT } from '../utils/services/environment';
import { State, asyncReducer, ActionType } from './asyncReducer';
import useNotification from './useNotification';

interface Hook<T> extends State<T> {
  update: (d: T) => Promise<T | null>;
  refresh: () => Promise<T | null>;
}

export default function useResource<T>(
  resource: ResourceTypes,
  resourceId: string
): Hook<T> {
  const [state, dispatch] = useReducer(asyncReducer, {
    loading: true,
    data: null,
    error: null,
  });

  const { addErrorNotification, addSuccessNotification } = useNotification();

  useEffect(() => {
    const abortController: AbortController = new AbortController();
    dispatch({ type: ActionType.PENDING });
    const t = setTimeout(() => {
      if (!abortController.signal.aborted) {
        abortController.abort();
      }
    }, API_SERVICE_TIMEOUT);
    const fetchData = async () => {
      dispatch({ type: ActionType.PENDING });
      try {
        const response = await read(resource, resourceId, {
          signal: abortController.signal,
        });
        dispatch({ type: ActionType.SUCCESS, payload: response });
      } catch (err) {
        if (abortController.signal.aborted) {
          // canceled
        } else {
          throw err;
        }
        dispatch({ type: ActionType.FAILURE, payload: err });
      }
    };
    fetchData().finally(() => {
      clearTimeout(t);
    });
    return function cancel() {
      if (abortController) {
        if (!abortController.signal.aborted) {
          abortController.abort();
        }
      }
    };
  }, [resource, resourceId]);

  const updateFunction = async (newValues: T): Promise<T | null> => {
    try {
      dispatch({ type: ActionType.PENDING });
      const response: T = await update(resource, resourceId, newValues);
      addSuccessNotification(`${resource} ${resourceId} was updated`);
      dispatch({ type: ActionType.SUCCESS, payload: response });
      return response;
    } catch (err) {
      addErrorNotification(
        `Update ${resource} ${resourceId} failure, because: ${err.message}`
      );
      dispatch({ type: ActionType.FAILURE, payload: err });
    }
    return null;
  };

  const refreshFunction = async (): Promise<T | null> => {
    try {
      dispatch({ type: ActionType.PENDING });
      const response: T = await read(resource, resourceId);
      dispatch({ type: ActionType.SUCCESS, payload: response });
      return response;
    } catch (err) {
      dispatch({ type: ActionType.FAILURE, payload: err });
    }
    return null;
  };

  return {
    ...(state as State<T>),
    update: updateFunction,
    refresh: refreshFunction,
  };
}
