import React from 'react';

import useResource from '../../../hooks/useResource';
import { ResourceTypes } from '../../../utils/services/api';
import { Label, LabelProps } from './Label';

export const LabelUser = (props: LabelProps): JSX.Element => {
  const { resourceId } = props;
  const { data, loading } = useResource(ResourceTypes.USER, props.resourceId);
  return (
    <Label
      instance={data}
      loading={loading}
      resourceId={resourceId}
      labelProperty='email'
    />
  );
};
