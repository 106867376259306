import React from 'react';
import { FormattedMessage } from 'react-intl';

export default {
  helpText: (
    <FormattedMessage
      id='pkpass.field.value.helpText'
      defaultMessage=' Value of the field, for example, 42'
    />
  ),

  label: (
    <FormattedMessage id='pkpass.field.value.label' defaultMessage='Value' />
  ),
};
