import React from 'react';

import { PkpassNumber } from '../../../../../inputs';
import valueHelper from './valueHelper';

export default function FieldTypeNumberValue(): JSX.Element {
  return (
    <PkpassNumber required={true} name={`properties.value`} {...valueHelper} />
  );
}
