import React from 'react';
import { FormattedMessage } from 'react-intl';

import Form from '../../../../common/Form';
import { StaticLabel } from '../../../../common/labels';
import { PkpassNumber, PkpassString } from '../../inputs';

export default function BeaconForm(): JSX.Element {
  return (
    <Form>
      <PkpassString
        label={StaticLabel.RelevantText}
        helpText={
          <FormattedMessage
            id='Pkpass.RelevanceKeys.BeaconForm.relevantText.helpText'
            defaultMessage={`Text displayed on the lock screen when the pass is currently relevant. For example, a description of the nearby location such as “Store nearby on 1st and Main.”

            `}
          />
        }
        name={'properties.relevantText'}
      />
      <PkpassString
        required={true}
        label={StaticLabel.ProximityUUID}
        helpText={
          <FormattedMessage
            id='Pkpass.RelevanceKeys.BeaconForm.proximityUUID.helpText'
            defaultMessage={`Unique identifier of a Bluetooth Low Energy location beacon.`}
          />
        }
        name={'properties.proximityUUID'}
      />

      <PkpassNumber
        label={StaticLabel.Major}
        helpText={
          <FormattedMessage
            id='Pkpass.RelevanceKeys.BeaconForm.major.helpText'
            defaultMessage={`Major identifier of a Bluetooth Low Energy location beacon.`}
          />
        }
        name={'properties.major'}
      />
      <PkpassNumber
        label={StaticLabel.Minor}
        helpText={
          <FormattedMessage
            id='Pkpass.RelevanceKeys.BeaconForm.minor.helpText'
            defaultMessage={`Minor identifier of a Bluetooth Low Energy location beacon.`}
          />
        }
        name={'properties.minor'}
      />
    </Form>
  );
}
