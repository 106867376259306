import React, { FunctionComponent } from 'react';

import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import { ChangeEvent } from './ChangeEvent';
import { FieldLabel } from './FieldLabel';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      minWidth: 200,
    },
  })
);

export interface FieldInputProps {
  value?: unknown;
  onChange?: (event: ChangeEvent) => void;
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  label?: React.ReactNode;
  helpText?: React.ReactNode;
  disabled?: boolean;
  name: string;
  required?: boolean;
  readOnly?: boolean;
  placeholder?: string;
  error?: string;
  endAdornment?: React.ReactNode;
  type?: string;
}

export const FieldInput: FunctionComponent<FieldInputProps> = (
  props: FieldInputProps
) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const handleChange = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown | undefined;
    }>
  ) => {
    event.target.name = props.name;
    if (props.onChange) {
      props.onChange(event as ChangeEvent);
    }
  };

  return (
    <TextField
      id={props.name}
      variant='outlined'
      margin='dense'
      className={classes.formControl}
      required={props.required}
      disabled={props.disabled}
      error={!!props.error}
      label={<FieldLabel label={props.label} helpText={props.helpText} />}
      name={props.name}
      value={props.value ? props.value : ''}
      onChange={handleChange}
      onBlur={props.onBlur}
      InputProps={{
        endAdornment: props.endAdornment,
        readOnly: props.readOnly,
      }}
      type={props.type || 'text'}
      helperText={props.error}
    />
  );
};
