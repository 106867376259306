import moment, { Moment } from 'moment-timezone';
import React, { PropsWithChildren, useState } from 'react';

export type TimezoneContextInterface = {
  timezone: string;
  setTimezone: (t: string) => void;
  formatTimezone: (date: string | Date | Moment) => string;
  formatFullDateTime: (date: string | Date | Moment) => string;
  formatFullDate: (date: string | Date | Moment) => string;
  timezones: string[];
};

export const TimezoneContext = React.createContext<TimezoneContextInterface>({
  timezone: moment.tz.guess(),
  setTimezone: (_t: string) => {
    console.error('Timezone context is not initialized');
  },
  formatFullDate: (date: string | Date | Moment) =>
    moment.tz(date, moment.tz.guess()).format('L'),
  formatFullDateTime: (date: string | Date | Moment) =>
    moment.tz(date, moment.tz.guess()).format('lll Z z'),
  formatTimezone: (date: string | Date | Moment) =>
    moment.tz(date, moment.tz.guess()).format('Z z'),
  timezones: moment.tz.names(),
});

export function TimezoneContextProvider(
  props: PropsWithChildren<unknown>
): JSX.Element {
  const [timezone, setTimezone] = useState<string>(
    localStorage.getItem('yp-console-timezone') || moment.tz.guess()
  );

  const setTimezoneOverride = (l: string) => {
    setTimezone(l);
    localStorage.setItem('yp-console-timezone', l);
  };

  const ctx = {
    formatFullDate: (date: string | Date | Moment) =>
      moment.tz(date, timezone).format('L'),
    formatFullDateTime: (date: string | Date | Moment) =>
      moment.tz(date, timezone).format('lll Z z'),
    formatTimezone: (date: string | Date | Moment) =>
      moment.tz(date, timezone).format('Z z'),
    setTimezone: setTimezoneOverride,
    timezone,
    timezones: moment.tz.names(),
  };

  return (
    <TimezoneContext.Provider value={ctx}>
      {props.children}
    </TimezoneContext.Provider>
  );
}
