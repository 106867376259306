import React, { FunctionComponent, PropsWithChildren } from 'react';

import Box from '@material-ui/core/Box';
import Popover from '@material-ui/core/Popover';

interface PkpassInputSettingsPopUpProps {
  anchorEl: HTMLButtonElement | null;
  onClose: () => void;
}

export const PkpassInputSettingsPopUp: FunctionComponent<PropsWithChildren<
  PkpassInputSettingsPopUpProps
>> = (props: PropsWithChildren<PkpassInputSettingsPopUpProps>) => {
  const open = Boolean(props.anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return (
    <Popover
      id={id}
      open={open}
      anchorEl={props.anchorEl}
      onClose={props.onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      {open && <Box p={2}>{props.children}</Box>}
    </Popover>
  );
};

export const usePkpassInputSettingsPopup = () => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return { anchorEl, handleClose, handleOpen };
};
