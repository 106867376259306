import { FormikProps } from 'formik';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { convertImageFileToImage } from '../../utils/services/image';
import { ImageFormInterface } from './ImageForm';
import FieldFileDropzone from '../common/fields/FieldFileDropzone';

type Props = FormikProps<ImageFormInterface>;

export default function ImageUploadDialogContentDropZone(
  props: Props
): JSX.Element {
  const { setFieldValue, values } = props;

  const handleSelectFile = async (file: File) => {
    const image = await convertImageFileToImage(file);
    setFieldValue('mimeType', image.mimeType);
    setFieldValue('width', image.width);
    setFieldValue('height', image.height);
    setFieldValue('url', image.url);
    setFieldValue('name', image.name);
  };

  return (
    <FieldFileDropzone
      value={values.url}
      multiple={false}
      accept={'image/png'}
      onSelect={handleSelectFile}
      infoText={
        <FormattedMessage
          defaultMessage='Click here or drag & drop image to this area'
          id='ImageUploadModalDropZone.helpText'
        />
      }
    />
  );
}
