import React, { Fragment } from 'react';
import {
  defineMessages,
  FormattedMessage,
  injectIntl,
  WrappedComponentProps,
} from 'react-intl';

import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';

import { AddButton } from '../../../../../common/buttons';
import {
  FieldSelect,
  FieldSelectData,
  FieldTextInput,
  FieldTextInputData,
} from '../../../../../common/fields';
import { SearchIcon } from '../../../../../common/icons';
import COUNTRY_CODES from '../../../../../../constants/countryCodes';
import fetch from '../../../../../../utils/services/fetch';
import Button from '@material-ui/core/Button';

const options = Object.keys(COUNTRY_CODES)
  .map((key: string) => {
    return {
      flag: key.toLocaleLowerCase(),
      key: key.toLocaleLowerCase(),
      text: COUNTRY_CODES[key],
      value: key.toLocaleLowerCase(),
    };
  })
  .sort((a, b) => {
    return a.text.localeCompare(b.text);
  });

interface Props extends WrappedComponentProps {
  onAdd: (id: number) => void;
}

interface State {
  isFetching: boolean;
  searchQuery?: string;
  country: string;
  result: { results: [] } | null;
}

const messages = defineMessages({
  addIdPlaceholder: {
    defaultMessage: 'Add apllication ID',
    id: 'AppStoreAddModal.add.number.placeholder',
  },
  searchPlaceholder: {
    defaultMessage: 'Type app name and press search icon.',
    id: 'AppStoreAddModal.search.placeholder',
  },
});

class AppStoreAddSearchForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      country: 'us',
      isFetching: false,
      result: null,
      searchQuery: '',
    };
  }

  public render() {
    const { isFetching, searchQuery } = this.state;

    return (
      <Fragment>
        <h4>
          <FormattedMessage
            id='AppStoreAddModal.search.header'
            defaultMessage='Search in App Store'
          />
        </h4>
        <form
          onSubmit={this.handleFormSubmit}
          style={{ display: 'flex', flexDirection: 'row' }}
        >
          <div style={{ margin: 5 }}>
            <FieldSelect
              name='country'
              value={this.state.country}
              options={options}
              onChange={this.handleChangeCountry}
              label={
                <FormattedMessage
                  id='AppStoreAddModal.country.label'
                  defaultMessage='Country'
                />
              }
              helpText={
                <FormattedMessage
                  id='AppStoreAddModal.country.helptext'
                  defaultMessage='Specify country where application is available.'
                />
              }
            />
          </div>
          <div style={{ margin: 5 }}>
            <FieldTextInput
              label={
                <FormattedMessage
                  id='AppStoreAddModal.term'
                  defaultMessage='Term'
                />
              }
              helpText={
                <FormattedMessage
                  id='AppStoreAddModal.term.helptext'
                  defaultMessage='Write term and press search button.'
                />
              }
              name='searchQuery'
              value={searchQuery}
              placeholder={this.props.intl.formatMessage(
                messages.searchPlaceholder
              )}
              onChange={this.handleSearchTextChange}
              disabled={isFetching}
            />
          </div>
          <div style={{ margin: 5 }}>
            <Button
              variant={'contained'}
              color={'primary'}
              type='submit'
              size={'large'}
              style={{ height: 55 }}
              startIcon={<SearchIcon />}
            >
              Search
            </Button>
          </div>
        </form>
        <List>
          {this.state.result &&
            this.state.result.results &&
            this.state.result.results.map(
              (app: {
                trackId: string;
                artworkUrl512: string;
                trackName: string;
                sellerName: string;
              }) => {
                const handleAdd = () => {
                  this.props.onAdd(parseInt(app.trackId, 10));
                };
                return (
                  <ListItem key={app.trackId}>
                    <ListItemAvatar>
                      <Avatar src={app.artworkUrl512} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={app.trackName}
                      secondary={app.sellerName}
                    />
                    <ListItemSecondaryAction onClick={handleAdd}>
                      <AddButton color='primary' variant={'outlined'} />
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              }
            )}
        </List>
      </Fragment>
    );
  }
  private handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    this.search();
    return false;
  };

  private handleChangeCountry = (event: FieldSelectData) => {
    this.setState({ country: `${event.value}` });
  };

  private handleSearchTextChange = (event: FieldTextInputData) => {
    this.setState({ searchQuery: event.value });
  };

  private search = () => {
    this.setState({ isFetching: true });
    fetch(
      `https://itunes.apple.com/search?entity=software&limit=10&media=software&term=${this.state.searchQuery}&country=${this.state.country}`
    ).then((result) => {
      result.json().then((data) => {
        this.setState({ isFetching: false, result: data });
      });
    });
  };
}

export default injectIntl(AppStoreAddSearchForm);
