import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import ResourcePage from '../../../components/common/page/ResourcePage';
import { ResourcePageProps } from '../../types';
import UserEditDrawer from '../../../components/user/UserEditDrawer';
import UserList from '../../../components/user/UserList';

export const UserPage: FunctionComponent<ResourcePageProps> = (
  props: ResourcePageProps
) => {
  return (
    <ResourcePage
      {...props}
      DrawerComponent={UserEditDrawer}
      content={<UserList />}
      title={
        <FormattedMessage id='UserListPage.title' defaultMessage='Users' />
      }
      onCloseDrawer={() => {
        props.history.push('/identity/user');
      }}
    />
  );
};
