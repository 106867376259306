import React, { Fragment } from 'react';
import { FormattedMessage, FormattedRelativeTime } from 'react-intl';
import { Link as NavLink } from 'react-router-dom';

import { selectUnit } from '@formatjs/intl-utils';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Link from '@material-ui/core/Link';
import { useCacheResources } from '../../hooks';
import useResourcesPaged, {
  ResourcePagedHookProps,
} from '../../hooks/useResourcesPaged';
import { ResourceTypes } from '../../utils/services/api';
import { TemplateListItem } from '../../types/Template';
import TemplateNewModal from '../template/TemplateCreate';
import { ProjectLabelWithDrawer } from '../../domain/project/ProjectLabelWithDrawer';
import TemplateLabelWithDrawer from '../template/TemplateLabelWithDrawer';

interface Props extends ResourcePagedHookProps<TemplateListItem> {
  totalCount: number;
}

function RecentTemplates(props: Props) {
  return (
    <Card>
      <CardHeader
        title={
          <FormattedMessage
            defaultMessage='Recent Templates'
            id='RecentTemplates.header'
          />
        }
      />

      <CardContent>
        {props.data.length > 0 && (
          <Fragment>
            <div style={{ width: '100%' }}>
              {props.data.map((t: TemplateListItem, index: number) => {
                const { value, unit } = selectUnit(new Date(t.updatedAt));
                return (
                  <div
                    key={t.id}
                    style={{
                      borderBottom:
                        props.data.length - 1 === index
                          ? undefined
                          : '1px solid lightgrey',
                      display: 'flex',
                      flexDirection: 'row',
                      fontSize: 16,
                      paddingBottom: 10,
                      paddingTop: 10,
                      width: '100%',
                    }}
                  >
                    <div style={{ width: '40%' }}>
                      <TemplateLabelWithDrawer resourceId={t.id} />
                    </div>

                    <div
                      style={{ width: '30%', opacity: 0.8, textAlign: 'left' }}
                    >
                      <ProjectLabelWithDrawer resourceId={t.projectId} />
                    </div>

                    <div
                      style={{ width: '30%', opacity: 0.6, textAlign: 'right' }}
                    >
                      <FormattedRelativeTime
                        value={value}
                        unit={unit}
                        numeric='auto'
                        // eslint-disable-next-line
                        style='narrow'
                      />
                    </div>
                  </div>
                );
              })}
            </div>
            <table
              style={{
                borderTop: '2px solid darkgray',
                marginTop: 10,
                paddingTop: 10,
                width: '100%',
              }}
            >
              <tbody>
                <tr>
                  <td>
                    <label>
                      <FormattedMessage
                        id='RecentTemplates.total.count'
                        defaultMessage='Total {totalCount} templates'
                        values={{ totalCount: props.totalCount }}
                      />
                    </label>
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    <Link to='/passes/template' component={NavLink}>
                      <FormattedMessage
                        id='RecentTemplates.see.more.templates'
                        defaultMessage='See more templates'
                      />
                    </Link>
                  </td>
                </tr>
              </tbody>
            </table>
          </Fragment>
        )}

        {props.data.length === 0 && (
          <div
            style={{ marginTop: 10, marginBottom: 100, textAlign: 'center' }}
          >
            <h3>
              <FormattedMessage
                id='NoTemplates.title'
                defaultMessage='No templates'
              />
            </h3>
            <p>
              <FormattedMessage
                id='NoTemplates.text'
                defaultMessage='To create new template press button bellow:'
              />
            </p>
            <TemplateNewModal />
          </div>
        )}
      </CardContent>
    </Card>
  );
}

export default function HomePageTemplateSection(): JSX.Element | null {
  const d: ResourcePagedHookProps<TemplateListItem> = useResourcesPaged<
    TemplateListItem
  >(ResourceTypes.TEMPLATE, { limit: 7, orderBy: 'updateAt', filter: {} });
  const c = useCacheResources(ResourceTypes.TEMPLATE);

  if (c.loading || d.loading) {
    return null;
  }

  return <RecentTemplates {...d} totalCount={c.data.length} />;
}
