import React, { ChangeEvent } from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

import { DataGridCellObject } from '../../common/dataGrid';
import { LabelTemplate } from '../../common/labels';
import usePagination from '../../../hooks/usePagination';

export default function PassBulkStepCheckTable(props: { batches: any[] }) {
  const { setPage, page, data, setPageSize, pageSize } = usePagination(
    props.batches,
    5
  );

  const handlePageChange = (e: any, m: number) => {
    setPage(m);
  };

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Operation</TableCell>
            <TableCell>Template</TableCell>
            <TableCell>Data</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {data.map((item: any, index: number) => (
            <TableRow key={`${index}`}>
              <TableCell>
                {item.method === 'PUT' ? 'update' : 'create'}
              </TableCell>
              <TableCell>
                <LabelTemplate resourceId={item.data.templateId} />
              </TableCell>
              <TableCell>
                <DataGridCellObject data={item.data} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <TablePagination
        component='div'
        count={-1}
        rowsPerPage={pageSize}
        page={page - 1}
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        onChangeRowsPerPage={(
          event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
        ) => {
          setPageSize(event.target.value);
        }}
        onChangePage={handlePageChange}
      />
    </TableContainer>
  );
}
