import { FormikProps } from 'formik';
import React, { Fragment, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router';

import SendIcon from '@material-ui/icons/Send';

import {
  Button,
  CopyButton,
  ResetButton,
  SaveButton,
} from '../../common/buttons';
import { ResourceDeleteButton } from '../../common/ResourceDeleteButton';
import useNotification from '../../../hooks/useNotification';
import useViewer from '../../../hooks/useViewer';
import {
  create,
  ResourceTypes,
  templateUpdatePassesByPush,
} from '../../../utils/services/api';
import { Template } from '../../../types/Template';

type Props = FormikProps<Template>;

const DesignEditControls = (props: Props) => {
  const [pushLocked, setPushLocked] = useState(false);
  const history = useHistory();
  const { addNotification } = useNotification();
  const { values, dirty } = props;
  const viewer = useViewer();

  const handleDuplicate = async () => {
    const data = JSON.parse(JSON.stringify(props.values));
    data.name = `${data.name} - copy`;
    delete data.id;
    delete data.updatedAt;
    delete data.createdAt;
    delete data.deletedAt;

    try {
      const design = await create(ResourceTypes.TEMPLATE, data);
      addNotification({
        level: 'success',
        message: `Copy of design was created`,
      });
      history.push(`/passes/template/${design.id}`);
    } catch (e) {
      addNotification({
        level: 'error',
        message: e.message,
      });
    }
  };

  const handleReset = () => {
    props.resetForm({ values: props.initialValues });
  };

  const handlePush = async () => {
    const id = values.id;
    setPushLocked(true);
    try {
      await templateUpdatePassesByPush(id);
      addNotification({
        level: 'success',
        message: `Passes for design ${id} was notified to check updates. (by push notification)`,
      });
    } catch (e) {
      addNotification({
        level: 'error',
        message: `Design push failure, because: ${e.message}`,
      });
    }
    setTimeout(() => {
      setPushLocked(false);
    }, 5000);
  };

  const projectId = values.projectId;
  const canCreate = viewer && viewer.canCreateTemplateOnProject(projectId);
  const canUpdate = viewer && viewer.canUpdateTemplateOnProject(projectId);
  const canSendPush = viewer && viewer.canUpdateTemplateOnProject(projectId);
  const canDelete = viewer && viewer.canDeleteTemplateOnProject(projectId);

  return (
    <Fragment>
      {canUpdate && (
        <SaveButton
          color='primary'
          onClick={props.submitForm}
          disabled={!dirty}
          tooltip={
            <FormattedMessage
              id='DesignEditControls.save.tooltip'
              defaultMessage='Saves the current changes and creates a new version of the design. Use the push button to update existing passes.'
            />
          }
        />
      )}

      {canSendPush && (
        <Button
          color='secondary'
          onClick={handlePush}
          disabled={dirty || pushLocked}
          tooltip={
            <FormattedMessage
              id='DesignEditControls.push.tooltip'
              defaultMessage='Updates existing passes (on your phone).'
            />
          }
          startIcon={<SendIcon />}
        >
          <FormattedMessage
            id='DesignEditControls.push.label'
            defaultMessage='Push'
          />
        </Button>
      )}

      <ResetButton
        onClick={handleReset}
        disabled={!dirty}
        tooltip={
          <FormattedMessage
            id='DesignEditControls.reset.tooltip'
            defaultMessage='Sets the design to its original state before any changes were made.'
          />
        }
      />

      {canCreate && (
        <CopyButton
          onClick={handleDuplicate}
          disabled={dirty}
          tooltip={
            <FormattedMessage
              id='DesignEditControls.copy.tooltip'
              defaultMessage='A copy of the design is created.'
            />
          }
        />
      )}

      {canDelete && (
        <ResourceDeleteButton
          resource={ResourceTypes.TEMPLATE}
          resourceId={values.id}
          tooltip={
            <FormattedMessage
              id='DesignEditControls.delete.tooltip'
              defaultMessage='Performs irreversible deletion of the design (no longer offered in the console). Passes will be retained.'
            />
          }
        />
      )}
    </Fragment>
  );
};

export default DesignEditControls;
