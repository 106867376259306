// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import countryCodes from 'country-codes-list';

interface CountryCodes {
  [key: string]: string;
}

const myCountryCodesObject = countryCodes.customList(
  'countryCode',
  '{countryNameEn}'
);

const COUNTRY_CODES: CountryCodes = myCountryCodesObject;

export default COUNTRY_CODES;
