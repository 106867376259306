import React from 'react';

import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';

import VisibilityIcon from '@material-ui/icons/Visibility';

import Android from '@material-ui/icons/Android';
import Apple from '@material-ui/icons/Apple';
import CloudDownload from '@material-ui/icons/CloudDownload';
import Image from '@material-ui/icons/Image';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Print from '@material-ui/icons/Print';

import { LinkEditIconButton, ListDeleteButton } from '../common/buttons';
import { DataGridColumns, Fields } from '../common/dataGrid';
import DataGridActions, {
  DataGridActionProps,
} from '../common/dataGrid/DataGridActions';
import DataGridResources from '../common/dataGrid/DataGridResources';
import { FilterOptionsProps } from '../common/filter/Filter';
import { StaticLabel } from '../common/labels';
import { FilterKeyTypes } from '../../context';
import useViewer from '../../hooks/useViewer';
import { remove, ResourceTypes } from '../../utils/services/api';
import { PassListItem } from '../../types/Pass';
import { ProjectLabelWithDrawer } from '../../domain';
import TemplateLabelWithDrawer from '../template/TemplateLabelWithDrawer';

const fields: Fields<PassListItem> = [
  {
    key: 'projectId',
    title: 'Project',
    render(item: PassListItem) {
      return <ProjectLabelWithDrawer resourceId={item.projectId} />;
    },
  },
  {
    key: 'templateId',
    title: 'Template',
    render(item: PassListItem) {
      return <TemplateLabelWithDrawer resourceId={item.templateId} />;
    },
  },
  { key: 'voided', title: StaticLabel.Voided },
  { key: 'dynamicData', title: StaticLabel.DynamicData },
  {
    key: 'devices',
    orderBy: 'devices.total',
    title: StaticLabel.Devices,
    render(item: PassListItem) {
      if (!item.devices) {
        return null;
      }
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          <div style={{ minWidth: 40, opacity: item.devices.ios ? 1 : 0.3 }}>
            <Apple />
            {item.devices.ios ? item.devices.ios : '0'}
          </div>
          <div style={{ width: 16 }} />
          <div
            style={{ minWidth: 40, opacity: item.devices.android ? 1 : 0.3 }}
          >
            <Android />
            {item.devices.android ? item.devices.android : '0'}
          </div>
        </div>
      );
    },
  },
  { key: 'createdAt', title: StaticLabel.CreatedAt, type: 'date' },
  { key: 'updatedAt', title: StaticLabel.UpdatedAt, type: 'date' },
  {
    key: DataGridColumns.Actions,
    title: StaticLabel.Actions,
    render(item: PassListItem, listLoad: () => void) {
      return <Actions resource='pass' item={item} listLoad={listLoad} />;
    },
  },
];

const Actions = (props: DataGridActionProps<PassListItem>) => {
  const { item } = props;
  const viewer = useViewer();
  const canRead = viewer && viewer.canReadPass();
  const canUpdate = viewer && viewer.canUpdatePassOnProject(item.projectId);
  const canDelete = viewer && viewer.canDeletePassOnProject(item.projectId);

  const doDelete = async () => {
    await remove(ResourceTypes.PASS, item.id);
    await props.listLoad();
  };

  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <DataGridActions>
      <LinkEditIconButton
        path={`/passes/pass/${item.id}`}
        disabled={!(canRead || canUpdate)}
      />
      <IconButton
        ria-controls='simple-menu'
        aria-haspopup='true'
        onClick={handleClick}
      >
        <MoreHoriz />
      </IconButton>
      <Menu
        id='simple-menu'
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem>
          <ListDeleteButton
            onDelete={doDelete}
            noLabel={true}
            disabled={!canDelete}
          />
        </MenuItem>
        <MenuItem>
          <Link href={`${item.url}?tag=console`} target='_blank'>
            <IconButton focusRipple={false}>
              <VisibilityIcon />
            </IconButton>
          </Link>
        </MenuItem>
        <MenuItem>
          <Link href={`${item.url}?format=pdf&tag=console`} target='_blank'>
            <IconButton>
              <Print />
            </IconButton>
          </Link>
        </MenuItem>
        <MenuItem>
          <Link href={`${item.url}?format=pkpass&tag=console`} target='_blank'>
            <IconButton>
              <CloudDownload />
            </IconButton>
          </Link>
        </MenuItem>
        <MenuItem>
          <Link href={`${item.url}?format=qrcode&tag=console`} target='_blank'>
            <IconButton>
              <Image />
            </IconButton>
          </Link>
        </MenuItem>
      </Menu>
    </DataGridActions>
  );
};

const filterProps = [
  {
    columnName: 'projectId',
    name: FilterKeyTypes.PROJECT,
    type: 'inUuid',
  },
  {
    columnName: 'templateId',
    name: FilterKeyTypes.TEMPLATE,
    type: 'inUuid',
  },
  {
    columnName: 'id',
    name: FilterKeyTypes.ID,
    type: 'like',
  },
  {
    columnName: 'dynamicData',
    name: FilterKeyTypes.DYNAMIC_DATA,
    type: 'like',
  },
  {
    columnName: 'createdAt',
    name: FilterKeyTypes.CREATED_AT_FROM,
    type: 'gt',
  },
  {
    columnName: 'createdAt',
    name: FilterKeyTypes.CREATED_AT_TO,
    type: 'lt',
  },
  {
    columnName: 'devices.total',
    name: FilterKeyTypes.DEVICES,
    type: 'devices',
  },
  {
    columnName: 'voided',
    name: FilterKeyTypes.VOIDED,
    type: 'eq',
  },
];

export default function PassList(): JSX.Element {
  const viewer = useViewer();
  const filterOptions: FilterOptionsProps = {
    createdFrom: true,
    createdTo: true,
    devices: true,
    dynamicData: true,
    id: true,
    project: true,
    template: true,
    voided: true,
  };

  return (
    <DataGridResources
      resource={ResourceTypes.PASS}
      filterProps={filterProps}
      filterOptions={filterOptions}
      fields={fields}
      export={true}
      autoLoad={false}
      canBeDeleted={(i: PassListItem) => {
        return !!(viewer && viewer.canDeletePassOnProject(i.projectId));
      }}
      exportHeaders={[
        'id',
        'url',
        'templateId',
        'projectId',
        'createdAt',
        'updatedAt',
        'deletedAt',
        'expirationDate',
        'voided',
        'firstRegisterAt',
        'lastRegisterAt',
        'firstUnregisterAt',
        'lastUnregisterAt',
        // "devices.android",
        // "devices.ios",
        // "devices.other",
        'devices.total',
      ]}
    />
  );
}
