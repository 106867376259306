import { Moment } from 'moment-timezone';
import React, { FunctionComponent } from 'react';

import FormControl from '@material-ui/core/FormControl';
import { useTheme } from '@material-ui/core/styles';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { FieldLabel } from './FieldLabel';
import { useStyles } from './FieldDateTime';

export interface FieldDateChange {
  name: string;
  value?: Date | null | undefined;
  textValue?: string | null;
}

interface Props {
  name: string;
  label: React.ReactNode;
  placeholder?: string;
  onChange: (d: FieldDateChange) => void;
  disabled?: boolean | undefined;
  helpText?: React.ReactNode;
  value?: Date | string | null;
  startOfDay?: boolean;
  endOfDay?: boolean;
  error?: string;
}

export const FieldDate: FunctionComponent<Props> = (props: Props) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const { disabled, name, value, onChange, startOfDay, endOfDay } = props;

  const handleChange = (date: Moment | null, text?: string | null) => {
    if (onChange) {
      if (date) {
        if (startOfDay) {
          date = date.startOf('day');
        }
        if (endOfDay) {
          date = date.endOf('day');
        }

        onChange({ name, value: date.toDate(), textValue: text });
        return;
      }
      onChange({ name, value: date, textValue: text });
    }
  };

  return (
    <FormControl
      variant='outlined'
      disabled={props.disabled}
      className={classes.formControl}
      margin='dense'
    >
      <KeyboardDatePicker
        variant='dialog'
        inputVariant='outlined'
        disabled={disabled}
        error={!!props.error}
        id='date-picker-dialog'
        label={<FieldLabel label={props.label} helpText={props.helpText} />}
        format='DD-MM-yyyy'
        value={value === undefined ? null : value}
        onChange={handleChange}
        InputAdornmentProps={{ position: 'start' }}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        helperText={props.error}
        clearable={true}
        InputProps={{ margin: 'dense' }}
      />
      {/* <FormHelperText>{helpText}</FormHelperText> */}
    </FormControl>
  );
};
