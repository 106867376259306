export const validatePassword = (pwd: string) => {
  const computed = {
    length: 0,
    numbers: 0,
    lowercase: 0,
    uppercase: 0,
    symbols: 0,
  };

  for (let i = 0; i < pwd.length; i++) {
    const r = pwd.charCodeAt(i);
    computed.length++;
    if (r >= 48 && r <= 57) {
      // 0-9
      computed.numbers++;
    } else if (r >= 65 && r <= 90) {
      // A-Z
      computed.uppercase++;
    } else if (r >= 97 && r <= 122) {
      // a-z
      computed.lowercase++;
    } else {
      computed.symbols++;
    }
  }

  const valid =
    computed.length >= 8 &&
    computed.length <= 128 &&
    computed.lowercase > 0 &&
    computed.uppercase > 0 &&
    computed.symbols > 0;
  return valid;
};
