import { UUID } from './common/uuid';
import {
  Permission,
  Viewer as ViewerBase,
  ViewerOptions as ViewerOptionsBase,
} from './common/viewer';

export interface ViewerOptions extends ViewerOptionsBase {
  displayName: string;
}

export class Viewer extends ViewerBase {
  public displayName: string;

  constructor(viewer: ViewerOptions) {
    super(viewer);
    this.displayName = viewer.displayName;
  }

  public hasAnyProject(): boolean {
    return (
      this.isAdmin || (this.projects && Object.keys(this.projects).length > 0)
    );
  }

  // CORE RIGHTS
  public showPassMenu(): boolean {
    return this.showPassList() || this.showTemplateList();
  }

  public canReadTemplateOnProject(projectId: UUID): boolean {
    return this.hasPermissionOnProject(projectId, Permission.TEMPLATE_READ);
  }

  public canUpdateTemplateOnProject(projectId: UUID): boolean {
    return this.hasPermissionOnProject(projectId, Permission.TEMPLATE_UPDATE);
  }

  public canCreateTemplateOnProject(projectId: UUID): boolean {
    return this.hasPermissionOnProject(projectId, Permission.TEMPLATE_CREATE);
  }

  public canDeleteTemplateOnProject(projectId: UUID): boolean {
    return this.hasPermissionOnProject(projectId, Permission.TEMPLATE_DELETE);
  }

  public canEditBackfields(projectId: UUID): boolean {
    return this.canUpdateTemplateOnProject(projectId);
  }

  public canUpdatePassOnProject(projectId: UUID): boolean {
    return this.hasPermissionOnProject(projectId, Permission.PASS_UPDATE);
  }
  public canDeletePassOnProject(projectId: UUID): boolean {
    return this.hasPermissionOnProject(projectId, Permission.PASS_DELETE);
  }

  public canCreatePass(): boolean {
    return this.hasPermission(Permission.PASS_CREATE);
  }

  public canReadPass(): boolean {
    return this.hasPermission(Permission.PASS_READ);
  }
  public canUpdatePass(): boolean {
    return this.hasPermission(Permission.PASS_UPDATE);
  }
  public canUpdateCreate(): boolean {
    return this.hasPermission(Permission.PASS_CREATE);
  }
  public showPassList(): boolean {
    return this.canReadPass();
  }

  public showTemplateList(): boolean {
    return this.canReadTemplate();
  }
  public showCertificateList(): boolean {
    return this.isAdmin;
  }

  public showImagesList(): boolean {
    return (
      this.hasPermission(Permission.IMAGE_MANAGE) ||
      this.hasPermission(Permission.TEMPLATE_READ) ||
      this.hasPermission(Permission.TEMPLATE_UPDATE) ||
      this.hasPermission(Permission.TEMPLATE_CREATE)
      // TODO add pass_update/create in future, when pass dynamic images will be added into console
    );
  }
  public canReadTemplate(): boolean {
    return this.hasPermission(Permission.TEMPLATE_READ);
  }

  public canCreateTemplate(): boolean {
    return this.hasPermission(Permission.TEMPLATE_CREATE);
  }

  public canManageImages(): boolean {
    return this.hasPermission(Permission.IMAGE_MANAGE);
  }

  public canManageImagesOnProject(projectId: UUID): boolean {
    return this.hasPermissionOnProject(projectId, Permission.IMAGE_MANAGE);
  }
  // IDM

  public showIdentityProjects(): boolean {
    return (
      this.hasPermission(Permission.PROJECT_CREATE) ||
      this.hasPermission(Permission.PROJECT_UPDATE) ||
      this.hasPermission(Permission.USER_UPDATE)
    );
  }
  public showIdentityUsers(): boolean {
    return this.hasPermission(Permission.USER_UPDATE);
  }

  public canCreateSubProject(projectId: UUID): boolean {
    return this.hasPermissionOnProject(projectId, Permission.PROJECT_CREATE);
  }

  public canEditProject(projectId: UUID): boolean {
    return this.hasPermissionOnProject(projectId, Permission.PROJECT_UPDATE);
  }

  public canManageUsersOnProject(projectId: UUID): boolean {
    return this.hasPermissionOnProject(projectId, Permission.USER_UPDATE);
  }

  public showIdentityManagementMenu(): boolean {
    return this.showIdentityProjects() || this.showIdentityUsers();
  }

  // TICKET MENU
  public showTicketValidation(): boolean {
    return this.isAdmin;
  }

  public showTicketOrderItem(): boolean {
    return this.showTicketAccounting() || this.showTicketValidation();
  }

  public showTicketMenu(): boolean {
    return (
      this.showTicketAccounting() ||
      this.showTicketConfig() ||
      this.showTicketValidation() ||
      this.showTicketProduct()
    );
  }

  public showTicketAccounting(): boolean {
    return this.hasPermission(Permission.TICKET_ACCOUNTING);
  }

  public showTicketConfig(): boolean {
    return this.hasPermission(Permission.TICKET_ESHOP_READ);
  }

  public showTicketProduct(): boolean {
    return (
      this.isAdmin ||
      (this.hasPermission(Permission.TICKET_PRODUCT_MANAGE) &&
        this.hasPermission(Permission.TICKET_ESHOP_READ))
    );
  }

  public canCreateTicketProduct(): boolean {
    return (
      this.isAdmin ||
      (this.hasPermission(Permission.TICKET_PRODUCT_MANAGE) &&
        this.hasPermission(Permission.TICKET_ESHOP_READ))
    );
  }

  public canEditTicketProduct(): boolean {
    return (
      this.isAdmin ||
      (this.hasPermission(Permission.TICKET_PRODUCT_MANAGE) &&
        this.hasPermission(Permission.TICKET_ESHOP_READ))
    );
  }

  // REPORT RIGHTS
  public showReportMenu(): boolean {
    return this.showPassList();
  }

  // STAMPCARD RIGHTS
  public showStampCard(): boolean {
    return this.hasPermission(Permission.STAMP_MANAGE);
  }

  // DOC
  public showDocs(): boolean {
    return !!this;
  }
}
