import React from 'react';

import Box from '@material-ui/core/Box';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import TabsMUI from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';

import useTabs from '../../hooks/useTabs';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
  icon?: string | React.ReactElement;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component='div'
      role='tabpanel'
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

function a11yProps(index: any) {
  return {
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
    id: `scrollable-auto-tab-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    flexGrow: 1,
    width: '100%',
  },
}));

export interface TabOption {
  key: string;
  error?: boolean;
  icon?: string | React.ReactElement;
  name?: string | JSX.Element;
  content: JSX.Element;
}

interface Props {
  tabOptions: TabOption[];
  formName: string;
  defaultTab: string;
  primary?: boolean;
  secondary?: boolean;
  pointing?: boolean;
}

export default function Tabs(props: Props) {
  const { tabOptions, defaultTab, formName } = props;
  const t = useTabs(formName);

  const classes = useStyles();
  // const [value, setValue] = React.useState(0);

  const handleChange = (
    event: React.ChangeEvent<unknown>,
    newValue: number
  ) => {
    // setValue(newValue);
    t.setTab(tabOptions[newValue].key);
  };

  let value = 0;

  tabOptions.forEach((to: TabOption, index: number) => {
    if (t.tab === defaultTab) {
      value = index;
    }
  });
  tabOptions.forEach((to: TabOption, index: number) => {
    if (t.tab === to.key) {
      value = index;
    }
  });

  return (
    <div className={classes.root}>
      <TabsMUI
        value={value}
        onChange={handleChange}
        indicatorColor='primary'
        textColor='primary'
        variant='scrollable'
        scrollButtons='auto'
        aria-label='scrollable auto tabs example'
      >
        {tabOptions.map((tabOption: TabOption, index: number) => (
          <Tab
            key={index}
            label={
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                {tabOption.icon}
                <div style={{ width: 8 }} />
                {tabOption.name}
              </div>
            }
            fullWidth={false}
            {...a11yProps(index)}
          />
        ))}
      </TabsMUI>
      {tabOptions.map((tabOption: TabOption, index: number) => (
        <TabPanel key={index} value={value} index={index}>
          {tabOption.content}
        </TabPanel>
      ))}
    </div>
  );
}
