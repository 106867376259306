import { getIn, useFormikContext } from 'formik';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { createStyles, makeStyles, useTheme } from '@material-ui/core/styles';

import Form from '../../../common/Form';
import { ILocalizable, Template } from '../../../../types/Template';
import { useTemplateUI } from '../useTemplateUI';
import { PkpassLocalizedString } from '../inputs';
import IconButton from '@material-ui/core/IconButton';
import { EditIcon } from '../../../common/icons';
import useOpen from '../../../../hooks/useOpen';
import FormikDialog from '../../../common/formik/FormikDialog';
import TemplatePreviewLanguageSelect from '../../../template/TemplatePreviewLanguageSelect';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      maxWidth: 300,
    },
    preview: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontSize: 16,
      fontWeight: 'bolder',
      cursor: 'pointer',
      '&:hover': {
        opacity: 0.5,
      },
    },
    icon: {
      height: '0.8em',
      width: '0.8em',
    },
  })
);

export const EditDescription = (): JSX.Element => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const templateUI = useTemplateUI();
  const formikProps = useFormikContext<Template>();
  const oi = useOpen(false);

  let preview = getIn(
    formikProps.values,
    'jsonTemplate.description.value',
    'Description'
  );

  if (typeof preview === 'object') {
    preview = preview[templateUI.templatePreviewLanguage];
  }

  const onChange = (v: {
    description:
      | {
          type: 'string';
          value: ILocalizable;
          fallback?: string;
        }
      | undefined;
  }) => {
    formikProps.setFieldValue('jsonTemplate.description', v.description);
  };

  return (
    <div className={classes.root}>
      <div className={classes.preview} onClick={oi.handleOpen}>
        {preview}
        <IconButton size={'small'}>
          <EditIcon className={classes.icon} />
        </IconButton>
      </div>
      <FormikDialog
        isOpen={oi.open}
        onClose={oi.handleClose}
        onSubmit={onChange}
        content={<Content languages={formikProps.values.languages} />}
        title={
          <div>
            Description
            <span style={{ float: 'right' }}>
              <TemplatePreviewLanguageSelect
                languages={formikProps.values.languages}
              />
            </span>
          </div>
        }
        initialValues={{
          description: formikProps.values.jsonTemplate.description,
        }}
      />
    </div>
  );
};

const Content = (props: { languages: string[] }) => {
  return (
    <Form>
      <PkpassLocalizedString
        languages={props.languages}
        label={
          <FormattedMessage
            id='pkpass.description.title'
            defaultMessage='Description'
          />
        }
        helpText={
          <FormattedMessage
            id='pkpass.description.helpText'
            defaultMessage='Brief description of the pass, used by the iOS accessibility technologies. Don’t try to include all of the data on the pass in its description, just include enough detail to distinguish passes of the same type.'
          />
        }
        name='description'
      />
    </Form>
  );
};
