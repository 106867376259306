import { getIn, setIn } from 'formik';
import { unset } from 'lodash';

import {
  PkpassDateStyleTypes,
  PkpassField,
  PkpassFieldsType,
} from '../../types/Template';

export enum PkpassFieldTypes {
  Number = 'number',
  Date = 'date',
  Currency = 'currency',
  Text = 'text',
}

export const getFieldType = (field: PkpassField): PkpassFieldTypes => {
  if (getIn(field, ['properties', 'currencyCode', 'value'])) {
    return PkpassFieldTypes.Currency;
  }

  if (getIn(field, ['properties', 'numberStyle', 'value'])) {
    return PkpassFieldTypes.Number;
  }
  if (
    getIn(field, ['properties', 'dateStyle', 'value']) ||
    getIn(field, ['properties', 'timeStyle', 'value'])
  ) {
    return PkpassFieldTypes.Date;
  }

  return PkpassFieldTypes.Text;
};

export const changeFieldType = (
  field: PkpassField,
  value: PkpassFieldTypes,
  fieldsType: PkpassFieldsType
): PkpassField => {
  switch (value) {
    case PkpassFieldTypes.Number:
      field = setIn(field, 'properties.value', {
        type: 'number',
        value: undefined,
      });
      field = setIn(field, 'properties.numberStyle', {
        type: 'string',
        value: 'PKNumberStyleDecimal',
      });
      unset(field, 'properties.ignoresTimeZone');
      unset(field, 'properties.dateStyle');
      unset(field, 'properties.timeStyle');
      unset(field, 'properties.currencyCode');
      break;

    case PkpassFieldTypes.Currency:
      field = setIn(field, 'properties.value', {
        type: 'number',
        value: undefined,
      });
      field = setIn(field, 'properties.numberStyle', {
        type: 'string',
        value: 'PKNumberStyleDecimal',
      });
      field = setIn(field, 'properties.currencyCode', {
        type: 'string',
        value: 'CZK',
      });
      unset(field, 'properties.ignoresTimeZone');
      unset(field, 'properties.dateStyle');
      unset(field, 'properties.timeStyle');
      break;
    case 'date':
      field = setIn(field, 'properties.value', {
        type: 'string',
        value: undefined,
      });
      field = setIn(field, 'properties.ignoresTimeZone', {
        type: 'boolean',
        value: true,
      });
      field = setIn(field, 'properties.dateStyle', {
        type: 'string',
        value: PkpassDateStyleTypes.PKDateStyleShort,
      });
      field = setIn(field, 'properties.timeStyle', {
        type: 'string',
        value: PkpassDateStyleTypes.PKDateStyleShort,
      });
      unset(field, 'properties.numberStyle');
      unset(field, 'properties.currencyCode');
      break;
    case PkpassFieldTypes.Text:
    default:
      field = setIn(field, 'properties.value', {
        type: 'string',
        value: undefined,
      });
      unset(field, 'properties.ignoresTimeZone');
      unset(field, 'properties.numberStyle');
      unset(field, 'properties.dateStyle');
      unset(field, 'properties.timeStyle');
      unset(field, 'properties.currencyCode');
  }

  if (
    fieldsType === PkpassFieldsType.BackFields &&
    value !== PkpassFieldTypes.Text
  ) {
    unset(field, 'properties.attributedValue');
  }
  return field;
};
