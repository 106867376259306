import { FormikProps } from 'formik';
import PropTypes from 'prop-types';
import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import EditDrawerInnerLayout from '../common/drawers/EditDrawerInnerLayout';
import FormikEditDrawer from '../common/formik/FormikEditDrawer';
import { ResourceTypes } from '../../utils/services/api';
import { Image } from '../../types/Image';
import ImageForm from './ImageForm';
import ImageFormControls from './ImageFormControls';

interface ImageEditDrawerProps {
  id: string;
  open: boolean;
  onClose: () => void;
}
const ImageEditDrawer: FunctionComponent<ImageEditDrawerProps> = (
  props: ImageEditDrawerProps
): JSX.Element => {
  const Edit: FunctionComponent<FormikProps<Image>> = (
    fp: FormikProps<Image>
  ) => {
    return (
      <EditDrawerInnerLayout
        onClose={props.onClose}
        title={
          <FormattedMessage id='ImageEdit.title' defaultMessage='Edit image' />
        }
        content={<ImageForm {...fp} />}
        controls={<ImageFormControls {...fp} />}
      />
    );
  };

  return (
    <FormikEditDrawer
      onClose={props.onClose}
      resource={ResourceTypes.IMAGE}
      id={props.id}
      open={props.open}
      Component={Edit}
    />
  );
};

ImageEditDrawer.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default ImageEditDrawer;
