import React from 'react';
import { FormattedMessage } from 'react-intl';

import Icon from '@material-ui/icons/Refresh';

import { Button, ButtonProps } from './Button';

export const RefreshButton = (props: ButtonProps): JSX.Element => {
  return (
    <Button type='button' {...props} startIcon={<Icon />}>
      <FormattedMessage
        id={'RefreshButton.button.text'}
        defaultMessage={'Refresh'}
      />
    </Button>
  );
};
