import React, { useLayoutEffect, useState } from 'react';
import { RouteProps, useHistory } from 'react-router-dom';

import { LinearProgress } from '@material-ui/core';

import { Login } from '../components/Login';
import { Viewer } from '../types/Viewer';
import { getCsrf } from '../utils/csrf';
import { getViewer } from '../utils/services/api';
import { UserProvider } from '../context';
import { RouteWithLayout } from './RouteWithLayout';

function PrivateRoute({ path, component }: RouteProps): JSX.Element {
  const history = useHistory();
  const [pending, setPending] = useState(true);
  const [viewer, setViewer] = useState<Viewer | null>(null);

  const csrf = getCsrf();

  useLayoutEffect(() => {
    let didCancel = false;
    // check if user is signed in
    if (!viewer) {
      const check = async () => {
        try {
          // if no csrf -> is not signed
          if (csrf != null && csrf !== '') {
            const viewer = await getViewer();
            if (!didCancel) {
              setViewer(new Viewer(viewer));
            }
          }
        } catch (e) {
          console.error(e);
        }
      };
      check().finally(() => {
        if (!didCancel) {
          setPending(false);
        }
      });
    } else {
      if (!didCancel) {
        setPending(false);
      }
    }
    return () => {
      didCancel = true;
    };
  }, [history, csrf, viewer]);

  if (pending) return <LinearProgress color='secondary' />;

  return viewer != null ? (
    <UserProvider viewer={viewer}>
      <RouteWithLayout path={path} component={component} />
    </UserProvider>
  ) : (
    <Login />
  );
}

export default PrivateRoute;
