import * as Yup from 'yup';

import { Resource, ResourceOpts } from '../../types/Resource';

export interface CertificateOpts extends ResourceOpts {
  name: string;
  projectId: string;
  expiration: string | null;
  passTypeId: string | null;
}

export type CertificateListItem = CertificateOpts;

export interface Certificate extends Resource {
  name: string;
  projectId: string;
  expiration: Date | null;
  passTypeId: string | null;
}

export const CertificateSchema = Yup.object().shape({
  name: Yup.string().min(3).required('Required'),
  projectId: Yup.string().required(),
});
