import React from 'react';
import { defineMessages, injectIntl, WrappedComponentProps } from 'react-intl';

import Filter from '../common/filter/Filter';
import Loader from '../common/loader/Loader';
import { reportFetch } from '../../utils/services/api';
import { API_SERVICE_TIMEOUT } from '../../utils/services/environment';
import { inQueryBuilder } from '../../utils/services/filter';
import NoData from './NoData';
import { InjectedFilterProps, withFilter } from '../../context/FilterContext';
import {
  InjectedNotificationProps,
  withNotification,
} from '../../context/NotificationContext';

const messages = defineMessages({
  at_least_once_register_in_wallet: {
    defaultMessage:
      'This dashboard count passes which has been registered before end of period and hs not been deleted before start period',
    id: 'ReportProvider.at_least_once_register_in_wallet.title',
  },
  download: {
    defaultMessage: 'Shows statistic about pass downloads by tags.',
    id: 'ReportProvider.download.title',
  },
  noDataMessage: {
    defaultMessage: 'There are no relevant data for the specified parameters',
    id: 'ReportProvider.no.data.message',
  },
  noDataTitle: {
    defaultMessage: 'No data',
    id: 'ReportProvider.no.data.title',
  },
  preview: {
    defaultMessage: 'Shows statistic about pass previews by tags.',
    id: 'ReportProvider.preview.title',
  },
  register_in_wallet: {
    defaultMessage:
      'This dashboard shows which registration has been intersection with selected period',
    id: 'ReportProvider.register_in_wallet.title',
  },
  reportUpdatedMessage: {
    defaultMessage: `Graph has been updated`,
    id: 'ReportProvider.report.updated',
  },
});

const reportTypeFilter: any = {
  activity: {
    from: true,
    project: true,
    to: true,
  },
  at_least_once_register_in_wallet: {
    from: true,
    interval: true,
    project: true,
    template: true,
    to: true,
  },

  register_in_wallet: {
    from: true,
    interval: true,
    project: true,
    template: true,
    to: true,
  },

  download: {
    from: true,
    interval: true,
    project: true,
    template: true,
    to: true,
  },

  preview: {
    from: true,
    interval: true,
    project: true,
    template: true,
    to: true,
  },

  links: {
    from: true,
    project: true,
    template: true,
    to: true,
  },
};

interface AllProps
  extends InjectedFilterProps,
    InjectedNotificationProps,
    WrappedComponentProps {}

// TODO - remove any types
export function withReport<T>(
  Wrapped: React.ComponentType<any>,
  reportType: string
) {
  class ReportProvider extends React.Component<
    AllProps,
    { data: T | null; loading: boolean; error?: any; query: any }
  > {
    private abortController: AbortController;

    constructor(props: AllProps) {
      super(props);
      this.state = {
        data: null,
        loading: true,
        query: null,
      };
      this.abortController = new AbortController();
      this.load();
    }

    public componentWillUnmount() {
      this.abortController.abort();
    }

    public load = () => {
      const { filter } = this.props;
      let where = {};

      if (reportTypeFilter[reportType].project) {
        if (filter.project && filter.project) {
          where = inQueryBuilder(where, filter.project, 'projectId');
        }
      }

      if (reportTypeFilter[reportType].template) {
        if (filter.template && filter.template) {
          where = inQueryBuilder(where, filter.template, 'templateId');
        }
      }

      const query: any = {
        from: filter.from,
        to: filter.to,
        where,
      };

      if (reportTypeFilter[reportType].interval) {
        query.interval = filter.interval;
      }

      this.fetchData(reportType, query);
    };

    public fetchData = async (serviceType: string, query: any) => {
      const { addNotification, intl } = this.props;
      this.setState({ data: null, loading: true, query });
      const signal = this.abortController.signal;
      try {
        const t = setTimeout(() => {
          this.abortController.abort();
        }, API_SERVICE_TIMEOUT);
        const data = await reportFetch(serviceType, query, { signal });
        clearTimeout(t);
        if (
          data &&
          (('total' in data && data.total && data.period) || !('total' in data))
        ) {
          const firstTime = this.state.data === null;
          if (!firstTime) {
            addNotification({
              level: 'success',
              message: intl.formatMessage(messages.reportUpdatedMessage),
            });
          }
          this.setState({ data, error: null, loading: false, query });
        } else {
          addNotification({
            level: 'error',
            message: intl.formatMessage(messages.noDataMessage),
            title: intl.formatMessage(messages.noDataTitle),
          });
          this.setState({ error: data, data: null, loading: false, query });
        }
      } catch (e) {
        this.setState({ error: e, loading: false });
      }
    };

    public getWeekNumber = (input: any) => {
      let d = input;
      // Copy date so don't modify original
      d = new Date(+d);
      d.setHours(0, 0, 0, 0);
      // Set to nearest Thursday: current date + 4 - current day number
      // Make Sunday's day number 7
      d.setDate(d.getDate() + 4 - (d.getDay() || 7));
      // Get first day of year
      const yearStart = new Date(d.getFullYear(), 0, 1);
      // Calculate full weeks to nearest Thursday
      const weekNo = Math.ceil(((d - yearStart.getTime()) / 86400000 + 1) / 7);
      // Return array of year and week number
      return weekNo;
    };

    public getLabel = (from: any, interval: any) => {
      if (interval === 'daily') {
        return this.props.intl.formatDate(from);
      }
      if (interval === 'weekly') {
        const date = new Date(from);
        return `${this.getWeekNumber(date)}/${date.getFullYear()}`;
      }
      if (interval === 'monthly') {
        return this.props.intl.formatDate(from, {
          month: 'short',
          year: 'numeric',
          // 'numeric' | '2-digit' | 'narrow' | 'short' | 'long',
        });
      }
      return this.props.intl.formatDate(from);
    };

    public renderContent() {
      const props = {
        ...this.state,
        getLabel: this.getLabel,
        getWeekNumber: this.getWeekNumber,
      };

      if (this.state.loading) {
        return <Loader />;
      }
      if (this.state.data == null) {
        return <NoData />;
      }

      const data: T = this.state.data;
      return <Wrapped data={data} {...this.props} {...props} />;
    }

    public render() {
      return (
        <div>
          <Filter
            {...reportTypeFilter[reportType]}
            onFilter={this.load}
            disabled={this.state.loading}
          />
          {this.renderContent()}
        </div>
      );
    }
  }

  return withNotification(withFilter(injectIntl(ReportProvider)));
}
