import React from 'react';
import { Link } from 'react-router-dom';

import IconButton from '@material-ui/core/IconButton';
import Edit from '@material-ui/icons/Edit';

import { DataGridColumns, Fields } from '../../components/common/dataGrid';
import DataGridActions from '../../components/common/dataGrid/DataGridActions';
import DataGridResources from '../../components/common/dataGrid/DataGridResources';
import { StaticLabel } from '../../components/common/labels';
import { ResourceTypes } from '../../utils/services/api';
import { CertificateListItem } from './types';
import { ProjectLabelWithDrawer } from '../project';

const fields: Fields<CertificateListItem> = [
  {
    key: 'projectId',
    title: StaticLabel.Project,
    render(item: CertificateListItem) {
      return <ProjectLabelWithDrawer resourceId={item.projectId} />;
    },
  },
  { key: 'passTypeId', title: StaticLabel.PassTypeIdentifier },
  { key: 'name', title: StaticLabel.Name },

  { key: 'expiration', title: StaticLabel.Expiration, type: 'date' },

  {
    key: DataGridColumns.Actions,
    title: StaticLabel.Actions,
    render(item: CertificateListItem) {
      return (
        <DataGridActions>
          <Link to={`/passes/${ResourceTypes.CERTIFICATE}/${item.id}`}>
            <IconButton>
              <Edit />
            </IconButton>
          </Link>
        </DataGridActions>
      );
    },
  },
];

const filterProps = [
  {
    columnName: 'projectId',
    name: 'project',
    type: 'inUuid',
  },
  {
    columnName: 'name',
    name: 'name',
    type: 'like',
  },
  {
    columnName: 'passTypeId',
    name: 'passTypeId',
    type: 'like',
  },
];

export const PkpassCertificateList = (): JSX.Element => {
  return (
    <DataGridResources
      filterProps={filterProps}
      filterOptions={{ project: true, name: true, passTypeId: true }}
      resource={ResourceTypes.CERTIFICATE}
      fields={fields}
    />
  );
};
