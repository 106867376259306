import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import { PageInterface, SectionInterface } from '../../Sidebar';
import Page from './Page';
import { useStyles } from './styles';

const Section = (props: { section: SectionInterface; index: number }) => {
  const { section, index } = props;
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };
  const classes = useStyles();

  if (section.expandable) {
    return (
      <Fragment key={index}>
        <ListItem
          key={`${index}`}
          button
          className={classes.section}
          alignItems='flex-start'
          disableGutters={true}
          onClick={handleClick}
        >
          <ListItemIcon className={classes.icon}>{section.icon}</ListItemIcon>
          <ListItemText primary={section.title} />
          <ListItemSecondaryAction className={classes.expandable}>
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItemSecondaryAction>
        </ListItem>
        <Collapse in={open} timeout='auto' unmountOnExit>
          <List component='div' className={classes.sectionItems}>
            {section.pages.map((page: PageInterface, i: number) => (
              <Page key={`${index}.${i}`} page={page} />
            ))}
          </List>
        </Collapse>
      </Fragment>
    );
  }

  return (
    <Fragment key={index}>
      {section.pages.map((page: PageInterface, i: number) => (
        <Page key={`${index}.${i}`} page={page} />
      ))}
    </Fragment>
  );
};

Section.propTypes = {
  section: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};

export default Section;
