import React from 'react';
import { getColorForIndex } from '../../../constants/colors';
import { ChartDataSets } from 'chart.js';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Theme } from '@material-ui/core/styles';

export const DoughnutLegend = ({ chart }: { chart: Chart }) => {
  const dataset: ChartDataSets =
    chart?.data?.datasets && chart?.data?.datasets.length > 0
      ? chart.data.datasets[0]
      : { data: [] };
  const labels = chart.data.labels as [];
  const colors = dataset.backgroundColor as [];

  const legendData: LegendItem[] = [];

  if (dataset?.data) {
    dataset.data.forEach(
      (
        value: number | number[] | null | undefined | Chart.ChartPoint,
        index: number
      ) => {
        legendData.push({
          v: `${value}`,
          l: `${labels[index]}`,
          c: `${colors ? colors[index] : getColorForIndex(index)}`,
        });
      }
    );
  }

  return <Legend items={legendData} />;
};

export const LineLegend = ({ chart }: { chart: Chart }) => {
  let legendData: LegendItem[] = [];

  if (chart?.data?.datasets) {
    legendData = chart?.data?.datasets?.map((ds, index) => ({
      v: `${ds.label}`,
      l: `${ds.label}`,
      c: `${ds.backgroundColor ? ds.backgroundColor : getColorForIndex(index)}`,
    }));
  }

  return <Legend items={legendData} />;
};

export type LegendItem = {
  v: string;
  l: string;
  c: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  legend: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    marginTop: theme.spacing(2),
    flexWrap: 'wrap',
  },
  legendItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flex: '1 1 50%',
  },
  legendItemColorBox: {
    width: 12,
    height: 12,
    marginRight: theme.spacing(1),
    borderRadius: 2,
  },
}));

export const Legend = ({ items }: { items: LegendItem[] }) => {
  const classes = useStyles();
  return (
    <div className={classes.legend}>
      {items.map((d) => (
        <div key={d.l} className={classes.legendItem}>
          <div
            className={classes.legendItemColorBox}
            style={{
              backgroundColor: d.c,
            }}
          />
          {d.l}
        </div>
      ))}
    </div>
  );
};
