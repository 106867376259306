import 'emoji-mart/css/emoji-mart.css';

import { BaseEmoji, Picker } from 'emoji-mart';
import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';

import { FieldLabel } from '../fields';
import Form from '../Form';
import { EmojiIcon } from '../icons';
import { WysiwygPopUp } from './WysiwygPopUp';

interface Props {
  onSelect: (emoji: string) => void;
}

export default function WysiwygPopUpEmoji(props: Props) {
  return (
    <WysiwygPopUp
      icon={<EmojiIcon style={{ height: '0.7em', width: '0.7em' }} />}
      renderContent={(handleClose: () => void) => {
        const onEmojiClick = (emojiObject: BaseEmoji) => {
          props.onSelect(emojiObject.native);
          handleClose();
        };
        return (
          <Fragment>
            <Form>
              <FieldLabel
                label={
                  <FormattedMessage
                    id='WysiwygEmojiPicker.title'
                    defaultMessage='Emoji'
                  />
                }
                helpText={
                  <FormattedMessage
                    id='WysiwygShortCodes.helpText'
                    defaultMessage='Find and click on emoji to add in text field'
                  />
                }
              />
            </Form>
            <Picker onSelect={onEmojiClick} native={true} />
          </Fragment>
        );
      }}
    ></WysiwygPopUp>
  );
}
