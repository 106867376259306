import { ImageReferenceMap } from './Image';

export interface TemplateListItem {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date | null;
  name: string;
  projectId: string;
  certificateId: string;
  distributable: boolean;
}

export enum PkpassPassStyleTypes {
  Coupon = 'coupon',
  BoardingPass = 'boardingPass',
  EventTicket = 'eventTicket',
  Generic = 'generic',
  StoreCard = 'storeCard',
}

export const passStyles = [
  PkpassPassStyleTypes.BoardingPass,
  PkpassPassStyleTypes.Coupon,
  PkpassPassStyleTypes.EventTicket,
  PkpassPassStyleTypes.Generic,
  PkpassPassStyleTypes.StoreCard,
];

export enum PkpassBarcodeTypes {
  PKBarcodeFormatAztec = 'PKBarcodeFormatAztec',
  PKBarcodeFormatCode128 = 'PKBarcodeFormatCode128',
  PKBarcodeFormatPDF417 = 'PKBarcodeFormatPDF417',
  PKBarcodeFormatQR = 'PKBarcodeFormatQR',
}

export enum PkpassFieldsType {
  AuxiliaryFields = 'auxiliaryFields',
  BackFields = 'backFields',
  HeaderFields = 'headerFields',
  PrimaryFields = 'primaryFields',
  SecondaryFields = 'secondaryFields',
}

export enum PkpassTransitTypes {
  PKTransitTypeAir = 'PKTransitTypeAir',
  PKTransitTypeBoat = 'PKTransitTypeBoat',
  PKTransitTypeBus = 'PKTransitTypeBus',
  PKTransitTypeGeneric = 'PKTransitTypeGeneric',
  PKTransitTypeTrain = 'PKTransitTypeTrain',
}

export enum PkpassDataDetectorTypes {
  PKDataDetectorTypePhoneNumber = 'PKDataDetectorTypePhoneNumber',
  PKDataDetectorTypeLink = 'PKDataDetectorTypeLink',
  PKDataDetectorTypeAddress = 'PKDataDetectorTypeAddress',
  PKDataDetectorTypeCalendarEvent = 'PKDataDetectorTypeCalendarEvent',
}

export enum PkpassTextAlignTypes {
  PKTextAlignmentLeft = 'PKTextAlignmentLeft',
  PKTextAlignmentCenter = 'PKTextAlignmentCenter',
  PKTextAlignmentRight = 'PKTextAlignmentRight',
  PKTextAlignmentNatural = 'PKTextAlignmentNatural',
}

export enum PkpassDateStyleTypes {
  PKDateStyleNone = 'PKDateStyleNone',
  PKDateStyleShort = 'PKDateStyleShort',
  PKDateStyleMedium = 'PKDateStyleMedium',
  PKDateStyleLong = 'PKDateStyleLong',
  PKDateStyleFull = 'PKDateStyleFull',
}

export enum PkpassNumberStyleTypes {
  PKNumberStyleDecimal = 'PKNumberStyleDecimal',
  PKNumberStylePercent = 'PKNumberStylePercent',
  PKNumberStyleScientific = 'PKNumberStyleScientific',
  PKNumberStyleSpellOut = 'PKNumberStyleSpellOut',
}

export enum ImageTypes {
  background = 'background',
  footer = 'footer',
  icon = 'icon',
  logo = 'logo',
  strip = 'strip',
  thumbnail = 'thumbnail',
}

export const imageTypes = [
  ImageTypes.background,
  ImageTypes.footer,
  ImageTypes.icon,
  ImageTypes.logo,
  ImageTypes.strip,
  ImageTypes.thumbnail,
];

export interface PkpassAssociatedApp {
  type: 'object';
  properties: { idGooglePlay: { value: string; type: 'string' } };
}

export interface PkpassAssociatedStoreIdentifier {
  value: number;
  type: 'number';
}

export interface Beacon {
  type: 'object';
  properties: {
    // TODO
  };
}

export type PkpassColor =
  | {
      type: 'string';
      value: string;
      fallback?: string;
    }
  | undefined;

export interface PkpassBarcodes {
  type: 'array';
  items: PkpassBarcode[];
}

interface JsonTemplateBase {
  organizationName?: {
    value: string;
    type: 'string';
    fallback?: string;
  };
  barcodes?: PkpassBarcodes;
  beacons?: {
    type: 'array';
    items: PkpassBeacon[];
  };
  location?: {
    type: 'array';
    items: PkpassLocation[];
  };
  description?: {
    type: 'string';
    value: ILocalizable;
    fallback?: string;
  };
  logoText?: {
    type: 'string';
    value: ILocalizable;
    fallback?: string;
  };
  associatedStoreIdentifiers?: {
    type: 'array';
    items: PkpassAssociatedStoreIdentifier[];
  };
  associatedApps?: {
    type: 'array';
    items: PkpassAssociatedApp[];
  };
  backgroundColor?: PkpassColor;
  labelColor?: PkpassColor;
  foregroundColor?: PkpassColor;
}

export type JsonTemplate = JsonTemplateBase & {
  [PkpassPassStyleTypes.BoardingPass]?: PkpassStyleBoardingPass;
  [PkpassPassStyleTypes.Coupon]?: PkpassStyle;
  [PkpassPassStyleTypes.EventTicket]?: PkpassStyle;
  [PkpassPassStyleTypes.Generic]?: PkpassStyle;
  [PkpassPassStyleTypes.StoreCard]?: PkpassStyle;
};

export interface Template {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date | null;
  name: string;
  distributable: boolean;
  dynamicDataJsonSchema: Record<string, unknown>;
  images: ImageReferenceMap;
  languages: string[];
  projectId: string;
  saveToGooglePayObjectTemplate?: string;
  jsonTemplate: JsonTemplate;
  certificateId: string;
  previewLogoUrl?: string;
}

export interface PkpassStyleBoardingPass {
  type: 'object';
  properties: {
    [PkpassFieldsType.AuxiliaryFields]: PkpassFields;
    [PkpassFieldsType.BackFields]: PkpassFields;
    [PkpassFieldsType.HeaderFields]: PkpassFields;
    [PkpassFieldsType.PrimaryFields]: PkpassFields;
    [PkpassFieldsType.SecondaryFields]: PkpassFields;
    transitType: { type: 'string'; value: PkpassTransitTypes };
  };
}

export interface PkpassStyle {
  type: 'object';
  properties: {
    [PkpassFieldsType.AuxiliaryFields]: PkpassFields;
    [PkpassFieldsType.BackFields]: PkpassFields;
    [PkpassFieldsType.HeaderFields]: PkpassFields;
    [PkpassFieldsType.PrimaryFields]: PkpassFields;
    [PkpassFieldsType.SecondaryFields]: PkpassFields;
  };
}

export type ILocalizable = string | { [key: string]: string };

export interface PkpassFields {
  type: 'array';
  items: PkpassField[];
}

export interface PkpassField {
  type: 'object';
  properties: {
    key: {
      type: 'string';
      value: string;
    };
    attributedValue?: {
      type: 'string';
      value: ILocalizable | number | Date;
      fallback?: string;
    };
    value?: {
      type: 'string';
      value: ILocalizable | number | Date;
      fallback?: string;
    };
    changeMessage?: {
      type: 'string';
      value: ILocalizable;
      fallback?: string;
    };
    dataDetectorTypes?: {
      type: 'array';
      items: PkpassDataDetectorTypes[];
    };
    label?: {
      type: 'string';
      value: ILocalizable;
      fallback?: string;
    };
    textAlignment?: {
      type: 'string';
      value: PkpassTextAlignTypes;
    };
    // Date Style Keys
    dateStyle?: {
      type: 'string';
      value: PkpassDateStyleTypes;
    };
    timeStyle?: {
      type: 'string';
      value: PkpassDateStyleTypes;
    };
    ignoresTimeZone?: {
      type: 'boolean';
      value: boolean;
    };
    isRelative?: {
      type: 'boolean';
      value: boolean;
    };

    // Number Style Keys
    currencyCode?: {
      type: 'string';
      value: string;
    };
    numberStyle?: {
      type: 'string';
      value: PkpassNumberStyleTypes;
    };
  };
}

export interface PkpassLocation {
  type: 'object';
  properties: {
    altitude?: {
      type: 'number';
      value: number;
    };
    latitude: {
      type: 'number';
      value: number;
    };
    longitude: {
      type: 'number';
      value: number;
    };
    relevantText?: {
      type: 'string';
      value: string;
    };
  };
}

export interface PkpassBeacon {
  type: 'object';
  properties: {
    proximityUUID: {
      type: 'string';
      value: string;
    };
    major?: {
      type: 'number';
      value: number;
    };
    minor?: {
      type: 'number';
      value: number;
    };
    relevantText?: {
      type: 'string';
      value: string;
    };
  };
}

export interface PkpassBarcode {
  type: 'object';
  properties: {
    format: {
      type: 'string';
      value: string;
    };
    altText?: {
      type: 'string';
      value: string;
    };
    message?: {
      type: 'string';
      value: string;
    };
    messageEncoding?: {
      type: 'string';
      value: string;
    };
  };
}

export const TEMPLATE = 'template';
