import React, { Fragment, PropsWithChildren } from 'react';

import { green } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles';

import { ImageIcon } from '../../../common/icons';

import Tooltip from '@material-ui/core/Tooltip';
import { useIntl } from 'react-intl';
import messages from '../../../image/messages';
import { ImageTypes } from '../../../../types/Template';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    addButton: {
      color: theme.palette.getContrastText(green[500]),
      backgroundColor: green[500],
      pointerEvents: 'auto',
      boxShadow:
        '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
      '&:hover': {
        backgroundColor: green[700],
      },
    },
  })
);

interface EditImageButtonProps {
  onClick: () => void;
  imageType: ImageTypes;
  disabled?: boolean;
}

const EditImageButton = (
  props: PropsWithChildren<EditImageButtonProps>
): JSX.Element => {
  const theme = useTheme();
  const intl = useIntl();
  const classes = useStyles(theme);

  return (
    <Fragment>
      <Tooltip title={intl.formatMessage(messages[props.imageType])}>
        <IconButton
          disabled={props.disabled}
          size={'small'}
          type='button'
          onClick={props.onClick}
          className={classes.addButton}
        >
          <ImageIcon fontSize={'small'} />
        </IconButton>
      </Tooltip>
    </Fragment>
  );
};

export default EditImageButton;
