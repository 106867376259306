export const getInvertColor = (color: string): string => {
  if (color) {
    const [r, g, b] = color
      .replace(/\)|rgb\(/g, '')
      .split(',')
      .map((value) => parseInt(value, 10));
    return (r + r + g + g + g + b) / 6 > 128 ? 'black' : 'white';
  }
  return 'white';
};

export default { getInvertColor };
