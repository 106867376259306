import PropTypes from 'prop-types';
import React, { FunctionComponent, ReactNode } from 'react';

import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { useTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete, {
  AutocompleteRenderInputParams,
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import { FilterOptionsState } from '@material-ui/lab/useAutocomplete';
import { FieldAutocompleteListboxComponent } from './FieldAutocompleteListboxComponent';
import { FieldLabel } from './FieldLabel';
import { useStyles } from './FieldAutocomplete';

interface Props {
  options: string[];
  label?: string;
  helpText?: ReactNode;
  value?: string[] | undefined;
  onChange?: (e: FieldAutocompleteCreatableChange) => void;
  name: string;
  placeholder?: ReactNode;
  error?: string;
}

export type FieldAutocompleteCreatableChange = {
  name: string;
  value: string[] | undefined;
};

interface OptionType {
  inputValue: string;
  title: string;
}

const filter = createFilterOptions<OptionType>();

export const FieldAutocompleteCreatable: FunctionComponent<Props> = (
  props: Props
): JSX.Element => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [value, setValue] = React.useState<(string | OptionType)[] | undefined>(
    props.value
      ? props.value.map((s) => ({ inputValue: s, title: s }))
      : undefined
  );

  function renderInput(params: AutocompleteRenderInputParams): JSX.Element {
    return (
      <TextField
        {...params}
        variant={'outlined'}
        margin='dense'
        label={<FieldLabel label={props.label} helpText={props.helpText} />}
        error={!!props.error}
      />
    );
  }

  FieldAutocompleteCreatable.propTypes = {
    label: PropTypes.string,
    placeholder: PropTypes.string,
    error: PropTypes.string,
    helpText: PropTypes.element,
  };

  const filterOptions = (
    options: OptionType[],
    state: FilterOptionsState<OptionType>
  ) => {
    const filtered = filter(options, state) as OptionType[];

    // Suggest the creation of a new value
    if (state.inputValue !== '') {
      filtered.push({
        inputValue: state.inputValue,
        title: `Add "${state.inputValue}"`,
      });
    }
    return filtered;
  };

  const handleChange = (
    event: React.ChangeEvent<unknown>,
    newValue: (string | OptionType)[] | undefined
  ) => {
    setValue(newValue);
    if (props.onChange) {
      props.onChange({
        name: props.name,
        value: newValue
          ? newValue.map((o) => {
              if (typeof o === 'string') {
                return o;
              }
              return o.inputValue;
            })
          : undefined,
      });
    }
  };

  const getOptionLabel = (option: OptionType): string => {
    // Value selected with enter, right from the input
    if (typeof option === 'string') {
      return option;
    }
    // Add "xxx" option created dynamically
    if (option.inputValue) {
      return option.inputValue;
    }
    // Regular option
    return option.title;
  };

  return (
    <FormControl className={classes.formControl} variant='outlined'>
      <Autocomplete
        size='small'
        multiple={true}
        value={value}
        onChange={handleChange}
        filterOptions={filterOptions}
        selectOnFocus
        clearOnBlur
        options={
          props.options
            ? props.options.map((o: string) => ({ inputValue: o, title: o }))
            : []
        }
        getOptionLabel={getOptionLabel}
        renderOption={(option) => option.title}
        freeSolo
        renderInput={renderInput}
        ListboxComponent={
          FieldAutocompleteListboxComponent as React.ComponentType<
            React.HTMLAttributes<HTMLElement>
          >
        }
      />
      <FormHelperText error={true}>{props.error}</FormHelperText>
    </FormControl>
  );
};
