import React, { FunctionComponent } from 'react';

import { FieldAutocompleteProject } from './index';

interface FieldStaticProjectProps {
  projectId: string;
  title?: string;
}

export const FieldStaticProject: FunctionComponent<FieldStaticProjectProps> = (
  props: FieldStaticProjectProps
) => {
  return (
    <FieldAutocompleteProject
      name={'projectId'}
      label={props.title}
      value={props.projectId}
      multiple={false}
      disabled={true}
      onChange={() => {}}
    />
  );
};
