import { DB } from './db';
import { init } from './init';
import { ResourceTypes } from '../../utils/services/api';
import { Viewer } from '../../types/Viewer';
import isEqual from 'react-fast-compare';

const prepareDB = async (
  user: Viewer,
  abortController: AbortController,
  dbName: string
) => {
  const db = new DB(dbName);
  const storedUser = await db.get(ResourceTypes.USER, user.id);
  if (storedUser && !isEqual(user, storedUser)) {
    await db.clearDB();
  }
  await db[ResourceTypes.USER].put(user);
  await init(db, { signal: abortController.signal });
  return db;
};

export const createDB = async (
  viewer: Viewer,
  abortController: AbortController
): Promise<DB> => {
  const user = JSON.parse(JSON.stringify(viewer));
  const dbName = `YP-console-db-${user.id}`;
  try {
    return await prepareDB(user, abortController, dbName);
  } catch (e) {
    /* this is fallback to recreate db with new schema */
    await DB.delete(dbName);
    return await prepareDB(user, abortController, dbName);
  }
};
