import { get } from 'lodash';
import { JsonSchemaFieldProps, JSONSchema, JsonSchemaTypes } from './types';

const colorPattern =
  '^rgb\\(?(1?\\d?\\d|2[0-4]\\d|25[0-5]),(1?\\d?\\d|2[0-4]\\d|25[0-5]),(1?\\d?\\d|2[0-4]\\d|25[0-5])\\)$';

const path = (d: any): any => {
  return Object.keys(d.properties).reduce(
    (acc: any, key: any) =>
      acc.concat(
        d.properties[key].type !== 'object'
          ? key
          : path(d.properties[key]).map((p: any) => `${key}.${p}`)
      ),
    []
  );
};

export const getDynamicDataNames = (jsonTemplate: JSONSchema) => {
  return path(jsonTemplate);
};

export const getDynamicDataFields = (
  jsonSchema: JSONSchema
): JsonSchemaFieldProps[] => {
  const names = getDynamicDataNames(jsonSchema);
  if (names) {
    return names.map((fieldName: string) => ({
      name: fieldName,
      ...getDynamicDataFieldDefinition(jsonSchema, fieldName),
    }));
  }
  return [];
};

export const getNumberDynamicDataFields = (jsonSchema: JSONSchema) => {
  return getDynamicDataFields(jsonSchema).filter((fp: JsonSchemaFieldProps) => {
    return (
      fp.type === JsonSchemaTypes.Integer || fp.type === JsonSchemaTypes.Number
    );
  });
};

export const getDateDynamicDataFields = (jsonSchema: JSONSchema) => {
  return getDynamicDataFields(jsonSchema).filter((fp: JsonSchemaFieldProps) => {
    return fp.type === JsonSchemaTypes.String && fp.format === 'date-time';
  });
};

export const getColorDynamicDataFields = (jsonSchema: JSONSchema) => {
  return getDynamicDataFields(jsonSchema).filter((fp: JsonSchemaFieldProps) => {
    return fp.type === JsonSchemaTypes.String && fp.pattern === colorPattern;
  });
};

export const getDynamicDataFieldDefinition = (
  jsonSchema: JSONSchema,
  fieldName: string
): JSONSchema => {
  const replaced = fieldName.split('.').join('.properties.');
  return get(jsonSchema, `properties.${replaced}`) as JSONSchema;
};

export const getTextDynamicDataFields = (jsonSchema: JSONSchema) => {
  const shortCodes = getDynamicDataFields(jsonSchema);

  shortCodes.push({
    name: 'pid',
    title: 'Pass ID',
    type: JsonSchemaTypes.String,
  });

  shortCodes.push({
    name: 'tid',
    title: 'Template ID',
    type: JsonSchemaTypes.String,
  });
  return shortCodes;
};
