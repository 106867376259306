import React from 'react';

import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreHoriz from '@material-ui/icons/MoreHoriz';

import { LinkEditIconButton } from '../../components/common/buttons';
import DataGridActions, {
  DataGridActionProps,
} from '../../components/common/dataGrid/DataGridActions';
import useViewer from '../../hooks/useViewer';
import { ProjectListItem } from './types';
import { ProjectCreateDrawer } from './ProjectCreateDrawer';

export const ProjectListActions = (
  props: DataGridActionProps<ProjectListItem> & { onCreate: () => void }
): JSX.Element => {
  const { item, resource } = props;
  const viewer = useViewer();

  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget);
    event.preventDefault();
    event.stopPropagation();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <DataGridActions>
      <LinkEditIconButton
        path={`/identity/${resource}/${item.id}`}
        disabled={
          !(
            viewer &&
            (viewer.canEditProject(item.id) ||
              viewer.canManageUsersOnProject(item.id))
          )
        }
      />

      <IconButton
        ria-controls='simple-menu'
        aria-haspopup='true'
        onClick={handleClick}
      >
        <MoreHoriz />
      </IconButton>
      <Menu
        id='simple-menu'
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem>
          <ProjectCreateDrawer
            parentId={item.id}
            disabled={!(viewer && viewer.canCreateSubProject(item.id))}
            onCreate={props.onCreate}
          />
        </MenuItem>
      </Menu>
    </DataGridActions>
  );
};

export default ProjectListActions;
