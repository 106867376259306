import React from 'react';
import { defineMessage, FormattedMessage } from 'react-intl';
import FormattedHTMLMessage from '../../common/FormattedHTMLMessage';

export const dynamicDataEditorTitle = (
  <FormattedMessage
    id='DynamicDataEditor.title'
    defaultMessage='Dynamic properties'
  />
);

export const uniqueNameHelpText = (
  <FormattedHTMLMessage
    descriptor={defineMessage({
      id: 'Template.dynamicData.name.helpText.html',
      defaultMessage: `<p>Unique name of property iny dynamic data. Name property is also used as property of dynamic data for create, update pass operations.</p> 
    <p>For example <i>firstName</i>, <i>age</i>, <i>contractNumber</i>.</p>
    <p><b>Please do not use whitespaces and diacritics.</b></p>
    `,
    })}
  />
);

export const titleHelpText = (
  <FormattedMessage
    id='Template.dynamicData.title.helpText'
    defaultMessage='This text is used as label for input in dynamic data form.'
  />
);

export const descHelpText = (
  <FormattedMessage
    id='Template.dynamicData.description.helpText'
    defaultMessage='This text is used as help text for input in dynamic data form.'
  />
);

export const patternHelpText = (
  <FormattedMessage
    id='Template.dynamicData.pattern.helpText'
    defaultMessage='Regular expresion to validate text input.'
  />
);

export const typeHelpText = (
  <FormattedHTMLMessage
    descriptor={defineMessage({
      id: 'Template.dynamicData.title.helpText.html',
      defaultMessage: `Type of dynamic data property. Each of the type can have following values:
       <ul>
           <li><b>string</b> - value can contains text (i.e. "John Snow")</li>
           <li><b>number</b> - value can be any number (i.e. 3.14 or 129)</li>
           <li><b>integer</b> - value can be whole number (i.e. 129)</li>
           <li><b>boolean</b> - allowed values are true and false</li>
       </ul>`,
    })}
  />
);

export const dynamicDataHelpText = (
  <FormattedHTMLMessage
    descriptor={defineMessage({
      id: 'Template.DynamicData.helpText.html',
      defaultMessage: `
      <p>Dynamic properties is a set of properties which are different for single passes. For example it can be name, age, date of birth. </p>
      <p>Dynamic data property name can be used in template template definition as placeholders/shorcodes, which are replaced by value from pass dynamic data, when pass is created/updated.</p>
      <ul>
      <li>Dynamic property definition contains title, description, which are used to create pass form.</li>
      <li>Each dynamic property can have type and advanced validation (pattern, min, max...).</li>
      </ul>
      <p>This property specification are used for validation pass API calls.</p>
  `,
    })}
  />
);
