import React from 'react';

import { JsonSchemaFieldProps } from '../jsonSchema';
import { ShortCodeIcon } from '../icons';
import { WysiwygPopUp } from './WysiwygPopUp';
import WysiwygPopUpShortCodesContent from './WysiwygPopUpShortCodesContent';

interface Props {
  shortCodes: JsonSchemaFieldProps[];
  onSelect: (value: string) => void;
}

export default function WysiwygPopUpShortCodes(props: Props): JSX.Element {
  return (
    <WysiwygPopUp
      icon={<ShortCodeIcon style={{ height: '0.7em', width: '0.7em' }} />}
      renderContent={(handleClose: () => void) => {
        const insertShortCode = (shortCode: string) => {
          props.onSelect(shortCode);
          handleClose();
        };
        return (
          <WysiwygPopUpShortCodesContent
            shortCodes={props.shortCodes}
            onSelect={insertShortCode}
          />
        );
      }}
    />
  );
}
