import React, { FunctionComponent, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import ResourcePage from '../../../components/common/page/ResourcePage';
import { ResourcePageProps } from '../../types';
import PassEditDrawer from '../../../components/pass/PassEditDrawer';
import PassList from '../../../components/pass/PassList';
import PassListControls from '../../../components/pass/PassListControls';
import useNotification from '../../../hooks/useNotification';
import { useHistory } from 'react-router';

export const PassPage: FunctionComponent<ResourcePageProps> = (
  props: ResourcePageProps
): JSX.Element => {
  const [key, setKey] = useState(0);
  const { addSuccessNotification } = useNotification();
  const history = useHistory();

  return (
    <ResourcePage
      {...props}
      DrawerComponent={PassEditDrawer}
      content={<PassList key={`${key}`} />}
      controls={
        <PassListControls
          onCreate={(r: unknown) => {
            setKey(key + 1);
            addSuccessNotification(`Pass created was created`);
            const a = r as { id: string };
            if (a.id) {
              history.replace(`/passes/pass/${a.id}`);
            }
          }}
        />
      }
      title={
        <FormattedMessage id='PassListPage.title' defaultMessage='Passes' />
      }
      onCloseDrawer={() => {
        props.history.push('/passes/pass');
      }}
    />
  );
};
