import { useContext } from 'react';

import {
  TemplateUIContext,
  TemplateUIContextInterface,
} from './TemplateUIContext';

export function useTemplateUI() {
  return useContext<TemplateUIContextInterface>(TemplateUIContext);
}
