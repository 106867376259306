import React from 'react';
import { defineMessage, FormattedMessage } from 'react-intl';

import { FieldLabel } from '../../../common/fields';
import Form from '../../../common/Form';
import { ColorsDialog } from '../../../design/pkpass/designer/colors/ColorsDialog';
import DesignerFrontSide from '../../../design/pkpass/designer/layout/DesignerFrontSide';
import PassStyleSwitcher from '../../../design/pkpass/designer/passStyle/switcher/PassStyleSwitcher';
import HelpAnimation from '../HelpAnimation';
import StepGrid from '../StepGrid';
import FormattedHTMLMessage from '../../../common/FormattedHTMLMessage';
import { useTemplateUI } from '../../../design/pkpass/useTemplateUI';
import { usePreview } from '../../../design/pkpass/usePreview';

export const StepPassStyle = (): JSX.Element => {
  const { templatePreviewLanguage } = useTemplateUI();
  const { pass, images } = usePreview();

  return (
    <StepGrid
      col1={
        <DesignerFrontSide
          pass={pass}
          images={images}
          language={templatePreviewLanguage}
          scanDisabled={true}
          disableBarcodes={true}
          disableFields={true}
          disableImages={true}
        />
      }
      col2={
        <div style={{ width: 320, position: 'relative' }}>
          <HelpAnimation style={{ top: -25, left: 60 }} />
          <Form>
            <FieldLabel
              label={
                <FormattedMessage
                  id='TemplateNewModalContent.StepPassStyle.Layout.ReadMore.Title'
                  defaultMessage='Pass layout'
                />
              }
              helpText={
                <FormattedHTMLMessage
                  descriptor={defineMessage({
                    id:
                      'TemplateNewModalContent.StepPassStyle.Layout.ReadMore.Content.html',
                    defaultMessage: `<p>For more to pass layout please visit:</p>
                      <a target="_blank" href="https://developer.apple.com/library/archive/documentation/UserExperience/Conceptual/PassKit_PG/Creating.html#//apple_ref/doc/uid/TP40012195-CH4-SW1">Pass Style Sets the Overall Visual Appearance</a>`,
                  })}
                />
              }
            />
          </Form>
          <PassStyleSwitcher isNew={true} />
          <ColorsDialog />
        </div>
      }
    />
  );
};
