import * as api from '../../utils/services/api';
import { ResourceTypes } from '../../utils/services/api';
import { CacheResourceRecord, DB, SupportedTypes } from './db';
import { CertificateListItem } from '../../domain/certificate';
import { ProjectListItem } from '../../domain/project';
import { TemplateListItem } from '../../types/Template';
import moment from 'moment';

const findNewItems = async <T>(
  db: DB,
  resource: SupportedTypes,
  o?: { signal: AbortSignal }
) => {
  let opts;
  const list = await db.list(resource);
  if (list.length > 0) {
    const updatedAt = moment
      .max(list.map((i: CacheResourceRecord) => moment(i.changeTime)))
      .toDate();
    opts = {
      query: {
        $or: {
          deletedAt: {
            $gt: updatedAt,
          },
          updatedAt: {
            $gt: updatedAt,
          },
        },
      },
    };
  }

  const newItems = await api.listComplete<T>(resource, opts, o);
  return newItems.data;
};

export const init = async (
  db: DB,
  o?: { signal: AbortSignal }
): Promise<DB> => {
  const certificates = await findNewItems<CertificateListItem>(
    db,
    api.ResourceTypes.CERTIFICATE,
    o
  );
  await db.bulkPut(
    ResourceTypes.CERTIFICATE,
    certificates.map((c: CertificateListItem) => ({
      id: c.id,
      name: c.passTypeId || c.name,
      projectId: c.projectId,
      changeTime: moment
        .max([moment(c.updatedAt), moment(c.deletedAt)])
        .toDate(),
    }))
  );

  const projects = await findNewItems<ProjectListItem>(
    db,
    api.ResourceTypes.PROJECT,
    o
  );
  await db.bulkPut(
    ResourceTypes.PROJECT,
    projects.map((p: ProjectListItem) => ({
      id: p.id,
      name: p.name,
      projectId: p.parentId ? p.parentId : '',
      changeTime: moment
        .max([moment(p.updatedAt), moment(p.deletedAt)])
        .toDate(),
    }))
  );

  const templates = await findNewItems<TemplateListItem>(
    db,
    api.ResourceTypes.TEMPLATE,
    o
  );
  await db.bulkPut(
    ResourceTypes.TEMPLATE,
    templates.map((p: TemplateListItem) => ({
      id: p.id,
      name: p.name,
      projectId: p.projectId ? p.projectId : '',
      changeTime: moment
        .max([moment(p.updatedAt), moment(p.deletedAt)])
        .toDate(),
    }))
  );
  return db;
};
