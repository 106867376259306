import { getIn, useFormikContext } from 'formik';
import React from 'react';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';

import { Template } from '../../../../../types/Template';
import BoardingPassIcon from './BoardingPassIcon';
import { transitTypes } from './passConstants';
import { useTemplateUI } from '../../useTemplateUI';

const BoardingPassIconPopUp = (props: {
  anchorEl: HTMLDivElement | null;
}): JSX.Element => {
  const { values, setFieldValue } = useFormikContext<Template>();
  const templateUI = useTemplateUI();

  const handleClose = () => {
    templateUI.uiSetTemplateForceOpenBoardingPassIcon(false);
  };

  const setTransitType = (value: string) => {
    return () => {
      setFieldValue(`jsonTemplate.boardingPass.properties.transitType`, {
        type: 'string',
        value,
      });
      templateUI.uiSetTemplateForceOpenBoardingPassIcon(false);
    };
  };

  const transitType = getIn(values, [
    `jsonTemplate`,
    `boardingPass`,
    `properties`,
    `transitType`,
    `value`,
  ]);

  return (
    <Popover
      open={templateUI.openBoardingPassIcon}
      anchorEl={props.anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <Grid container={true}>
        {Object.keys(transitTypes).map((type) => {
          return (
            <IconButton
              key={type}
              disabled={type === transitType}
              onClick={setTransitType(type)}
            >
              <BoardingPassIcon template={values} transitType={type} />
            </IconButton>
          );
        })}
      </Grid>
    </Popover>
  );
};

export default BoardingPassIconPopUp;
