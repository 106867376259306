import React, { Fragment } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Paper from '@material-ui/core/Paper';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import Form from '../common/Form';
import useViewer from '../../hooks/useViewer';
import { ProjectLabelWithDrawer } from '../../domain/project';

const messages = defineMessages({
  displayName: {
    defaultMessage: 'Display name',
    id: 'Profile.display.name',
  },
  email: {
    defaultMessage: 'Email',
    id: 'Profile.email',
  },
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    papper: {
      marginBottom: theme.spacing(1),
      padding: theme.spacing(3),
    },
  })
);

export default function Profile() {
  const intl = useIntl();
  const viewer = useViewer();
  const classes = useStyles();
  return (
    <Fragment>
      <Paper className={classes.papper}>
        <Typography variant='h4'>
          <FormattedMessage id='UserProfile.profile' defaultMessage='Profile' />
        </Typography>
        <Form>
          <TextField
            name='displayName'
            variant='outlined'
            label={intl.formatMessage(messages.displayName)}
            disabled={true}
            placeholder={intl.formatMessage(messages.displayName)}
            value={viewer && viewer.displayName}
          />
          <TextField
            variant='outlined'
            name='email'
            label={intl.formatMessage(messages.email)}
            disabled={true}
            placeholder={intl.formatMessage(messages.email)}
            value={viewer && viewer.email}
          />
        </Form>
      </Paper>

      {viewer && (
        <Paper className={classes.papper}>
          <Typography variant='h4'>
            <FormattedMessage
              id='UserProfile.project.list'
              defaultMessage='Available projects'
            />
          </Typography>

          <List dense={true}>
            {Object.keys(viewer.projects).map((key) => (
              <ListItem key={key}>
                <ProjectLabelWithDrawer resourceId={key} />
              </ListItem>
            ))}
          </List>
        </Paper>
      )}
    </Fragment>
  );
}
