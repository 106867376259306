import { FormikProps } from 'formik';
import React from 'react';

import { SaveButton } from '../buttons';

const FormikSaveButton = (props: FormikProps<any>): JSX.Element => {
  return <SaveButton onClick={props.submitForm} disabled={!props.dirty} />;
};

export default FormikSaveButton;
