import { bool, mixed, MixedSchema, object, string, StringSchema } from 'yup';

import { isColor, isDate, isNumber, isPlaceholder } from './jsonTemplateHelper';

export const joinPaths = (
  p1: string | undefined,
  p2: string | undefined
): string => {
  return (p1 ? p1 : '') + (p1 && p2 ? '.' : '') + (p2 ? `${p2}` : ``);
};

export const stringTypeSchema: StringSchema = string()
  .required()
  .oneOf(['string']);

export const objectTypeSchema: StringSchema = string()
  .required()
  .oneOf(['object']);

export const numberTypeSchema: StringSchema = string()
  .required()
  .oneOf(['number']);

export const arrayTypeSchema: StringSchema = string()
  .required()
  .oneOf(['array']);

export const boolTypeSchema: StringSchema = string()
  .required()
  .oneOf(['boolean']);

export const colorValueSchema: MixedSchema = mixed().test({
  name: 'ColorValueSchema',
  test(value: { type: string; value: string; fallback?: string }) {
    if (value === undefined) {
      return true;
    }
    if (typeof value !== 'object') {
      return this.createError({
        message: 'Color value object must be typeof object',
        path: this.path,
      });
    }
    if (value.type !== 'string') {
      return this.createError({
        message: `Color value object invalid type: ${value.type}`,
        path: joinPaths(this.path, 'type'),
      });
    }

    if (value.type === 'string' && value.value.length === 0) {
      return this.createError({
        message: `Color value cannot be empty`,
        path: joinPaths(this.path, 'value'),
      });
    }

    const hasFallback = isPlaceholder('color', value);

    // check if is set
    if (value) {
      // if is has fallback, check if fallback is valid string date
      if (hasFallback && !value.fallback) {
        return this.createError({
          message: 'Fallback value cannot be empty.',
          path: joinPaths(this.path, 'fallback'),
        });
      }
      if (hasFallback && !isColor(value.fallback)) {
        return this.createError({
          message: 'Fallback value is not valid rgb color.',
          path: joinPaths(this.path, 'fallback'),
        });
      }

      if (!hasFallback && !isColor(value.value)) {
        return this.createError({
          message: `Color is not valid rgb color or placeholder.`,
          path: joinPaths(this.path, 'value'),
        });
      }
    }
    return true;
  },
});

export const booleanValueSchema: MixedSchema = object({
  type: boolTypeSchema.required(),
  value: bool().required(),
});

export const stringValueSchema: MixedSchema = mixed().test({
  name: 'StringValueSchema',
  test(value: { type: string; value: string; fallback?: string }) {
    if (value === undefined) {
      return true;
    }
    if (typeof value !== 'object') {
      return this.createError({
        message: 'String value object must be typeof object',
        path: this.path,
      });
    }
    if (value.type !== 'string') {
      return this.createError({
        message: `String value object invalid type: ${value.type}`,
        path: joinPaths(this.path, 'type'),
      });
    }

    if (value.value === 'string' && value.value.length === 0) {
      return this.createError({
        message: `String value cannot be empty`,
        path: joinPaths(this.path, 'value'),
      });
    }

    const hasFallback = isPlaceholder('string', value);

    // check if is set
    if (value) {
      // if is has fallback, check if fallback is valid string date
      if (hasFallback && !value.fallback) {
        return this.createError({
          message: 'Fallback value is not string',
          path: joinPaths(this.path, 'fallback'),
        });
      }
    }
    return true;
  },
});

export const localizableStringValueSchema: MixedSchema = object().test({
  name: 'localizableStringValueSchema',
  test(value: {
    value: string | { [key: string]: string };
    fallback: string | undefined;
    type: string;
  }) {
    if (value === undefined) {
      return true;
    }
    if (typeof value !== 'object') {
      return this.createError({
        message: 'String value object must be typeof object',
        path: this.path,
      });
    }
    if (value.type !== 'string') {
      return this.createError({
        message: `String value object invalid type: ${value.type}`,
        path: joinPaths(this.path, 'type'),
      });
    }

    if (value.value === 'string' && value.value.length === 0) {
      return this.createError({
        message: `String value cannot be empty`,
        path: joinPaths(this.path, 'value'),
      });
    }

    // If is localized and contains placeholder do not need fallback
    const isLocalized = typeof value.value === 'object';
    const hasFallback = isPlaceholder('string', value) && !isLocalized;

    // check if is set
    if (value) {
      // if is has fallback, check if fallback is valid string date
      if (hasFallback && !value.fallback) {
        return this.createError({
          message: 'Fallback value is not string',
          path: joinPaths(this.path, 'fallback'),
        });
      }
    }
    return true;
  },
});

export const numberValueSchema: MixedSchema = mixed().test({
  name: 'NumberValueSchema',
  test(value: { type: string; value: string | number; fallback?: string }) {
    if (value === undefined) {
      return true;
    }
    if (typeof value !== 'object') {
      return this.createError({
        message: 'Number value object must be typeof object',
        path: this.path,
      });
    }
    if (value.type !== 'number') {
      return this.createError({
        message: `Number value object invalid type: ${value.type}`,
        path: joinPaths(this.path, 'type'),
      });
    }
    const hasFallback = isPlaceholder('number', value);
    if (value) {
      // is valid date string or valid placeholder or
      if (!(isNumber(value.value) || hasFallback)) {
        return this.createError({
          message: 'Value is not valid number or valid placeholder.',
          path: joinPaths(this.path, 'value'),
        });
      }
      // if is has fallback, check if fallback is valid string date
      if (hasFallback && !isNumber(value.fallback)) {
        return this.createError({
          message: 'Fallback value is not number',
          path: joinPaths(this.path, 'fallback'),
        });
      }
    }
    return true;
  },
});

export const dateValueSchema: MixedSchema = mixed().test({
  name: 'DateValueSchema',
  test(value: { type: string; value: string; fallback?: string }) {
    if (value === undefined) {
      return true;
    }
    if (typeof value !== 'object') {
      return this.createError({
        message: 'Date value object must be typeof object',
        path: this.path,
      });
    }
    if (value.type !== 'string') {
      return this.createError({
        message: `Date value object invalid type: ${value.type}`,
        path: joinPaths(this.path, 'type'),
      });
    }

    const hasFallback = isPlaceholder('date', value);

    if (typeof value === 'object') {
      // is valid date string or valid placeholder or
      if (!(isDate(value.value) || hasFallback)) {
        return this.createError({
          message: 'Value is not valid date or valid placeholder.',
          path: joinPaths(this.path, 'value'),
        });
      }
      // if is has fallback, check if fallback is valid string date
      if (hasFallback && !isDate(value.fallback)) {
        return this.createError({
          message: 'Fallback value is not date',
          path: joinPaths(this.path, 'fallback'),
        });
      }
    }
    return true;
  },
});

export function isValidHref(href?: string): boolean {
  const hrefRegexp = /(mailto:\w+|tel:w+|http:\/\/\w+|https:\/\/\w+).+/;
  return !!href && href.match(hrefRegexp) != null;
}
