import React, { FunctionComponent } from 'react';

import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles';

import { FieldLabel } from './FieldLabel';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      minWidth: 120,
    },
  })
);

export interface FieldStaticProps {
  value?: React.ReactNode;
  label?: React.ReactNode;
  helpText?: React.ReactNode;
  disabled?: boolean;
  name: string;
  required?: boolean;
  placeholder?: string;
  error?: string;
  endAdornment?: React.ReactNode;
}

export const FieldStatic: FunctionComponent<FieldStaticProps> = (
  props: FieldStaticProps
) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <FormControl
      variant='outlined'
      className={classes.formControl}
      required={props.required}
      disabled={props.disabled}
      error={!!props.error}
      margin='dense'
    >
      <FormLabel id={`text-outlined-label-${props.name}`}>
        <FieldLabel label={props.label} helpText={props.helpText} />
      </FormLabel>
      {props.value}
      <FormHelperText error={true}>{props.error}</FormHelperText>
    </FormControl>
  );
};
