import useLocalStorage from './useLocalStorage';

interface HookProps {
  wizard: boolean;
  setWizard: (t: boolean) => void;
}

export default function useWizard(): HookProps {
  const { data, setData } = useLocalStorage<boolean>('useWizard');

  const setWizard = (value: boolean) => {
    setData(value);
  };
  const wizard = data === null ? true : data;
  return {
    setWizard,
    wizard,
  };
}
