import { isArray, isString, isNumber } from 'lodash';

import { FilterValues } from '../../context/FilterContext';
import { FilterProps } from '../../types/common/filter';
import { Query } from '../../types/common/query';

function convertValue(item: any): string {
  if (typeof item === 'object' && item.id) {
    return item.id;
  }
  return item;
}

function convertValueArray(l: any[]): string[] {
  return l.map(convertValue);
}

export function eqQueryBuilder(
  where: Query,
  value: FilterValues,
  columnName: string
): Query {
  const result = where;
  if (!where[columnName]) {
    result[columnName] = {};
  }
  result[columnName].$eq = convertValue(value);
  return result;
}

export function inUuidQueryBuilder(
  where: Query,
  value: FilterValues,
  columnName: string
): Query {
  const result = where;
  if (isArray(value) && value.length > 0) {
    if (!where[columnName]) {
      result[columnName] = {};
    }
    result[columnName].$inUuid = convertValueArray(value);
  }
  return where;
}

export function inQueryBuilder(
  where: Query,
  value: FilterValues,
  columnName: string
): Query {
  const result = where;
  if (isArray(value) && value.length > 0) {
    if (!where[columnName]) {
      result[columnName] = {};
    }
    result[columnName].$in = convertValueArray(value);
  }
  return result;
}

export function likeQueryBuilder(
  where: Query,
  value: FilterValues,
  columnName: string
): Query {
  const result = where;
  if (isString(value) && value.length > 0) {
    if (!where[columnName]) {
      result[columnName] = {};
    }
    result[columnName].$like = `%${value}%`;
  }
  return result;
}
export function gtQueryBuilder(
  where: Query,
  value: FilterValues,
  columnName: string
) {
  const result = where;

  if (!where[columnName]) {
    result[columnName] = {};
  }
  result[columnName].$gt = convertValue(value);
  return result;
}

export function ltQueryBuilder(
  where: Query,
  value: FilterValues,
  columnName: string
): Query {
  const result = where;

  if (!where[columnName]) {
    result[columnName] = {};
  }

  result[columnName].$lt = convertValue(value);
  return result;
}

export function prepareFilter(
  filter: any,
  filterProps: FilterProps[] = [],
  defaultQuery: Query = {
    deletedAt: null,
  }
): Query {
  let where: Query = defaultQuery;

  filterProps.forEach((item) => {
    const filterPropName = item.name;
    const filterPropType = item.type;
    const filterPropColumnName = item.columnName;
    if (filterPropType === 'inUuid') {
      if (filter && filter[filterPropName]) {
        where = inUuidQueryBuilder(
          where,
          filter[filterPropName],
          filterPropColumnName
        );
      }
    }
    if (filterPropType === 'in') {
      if (filter && filter[filterPropName]) {
        where = inQueryBuilder(
          where,
          filter[filterPropName],
          filterPropColumnName
        );
      }
    }
    if (filterPropType === 'like') {
      if (filter && filter[filterPropName]) {
        where = likeQueryBuilder(
          where,
          filter[filterPropName],
          filterPropColumnName
        );
      }
    }
    if (filterPropType === 'eq') {
      if (filter && filter[filterPropName]) {
        where = eqQueryBuilder(
          where,
          filter[filterPropName],
          filterPropColumnName
        );
      }
    }
    if (filterPropType === 'gt') {
      if (filter && filter[filterPropName]) {
        where = gtQueryBuilder(
          where,
          filter[filterPropName],
          filterPropColumnName
        );
      }
    }
    if (filterPropType === 'gtOrEq') {
      if (filter && filter[filterPropName]) {
        if (filter[filterPropName] === 'eq') {
          where = eqQueryBuilder(
            where,
            filter[filterPropName],
            filterPropColumnName
          );
        } else {
          where = gtQueryBuilder(
            where,
            filter[filterPropName],
            filterPropColumnName
          );
        }
      }
    }
    if (filterPropType === 'lt') {
      if (filter && filter[filterPropName]) {
        where = ltQueryBuilder(
          where,
          filter[filterPropName],
          filterPropColumnName
        );
      }
    }
    if (filterPropType === 'day') {
      if (filter && filter[filterPropName]) {
        const date = filter[filterPropName];
        if (isNumber(date) || isString(date)) {
          const startOfDay = new Date(date);
          const endOfDay = new Date(date);
          endOfDay.setDate(endOfDay.getDate() + 1);
          where = gtQueryBuilder(where, startOfDay, filterPropColumnName);
          where = ltQueryBuilder(where, endOfDay, filterPropColumnName);
        }
      }
    }

    if (filterPropType === 'devices') {
      if (filter && filter[filterPropName]) {
        const value = filter[filterPropName];
        where = {
          ...where,
          [filterPropColumnName]: value === 'in' ? { $gt: 0 } : { $eq: 0 },
        };
      }
    }

    if (filterPropType === 'resetDefaultQuery') {
      if (filter && filter[filterPropName]) {
        delete where[filterPropColumnName];
      }
    }
  });
  return where;
}
