import React from 'react';
import { FormattedMessage, defineMessage } from 'react-intl';

import Grid from '@material-ui/core/Grid';

import Form from '../../../common/Form';
import { PkpassDate, PkpassNumber } from '../inputs';
import { Beacons } from './iBeacon/Beacons';
import { Locations } from './location/Locations';
import { makeStyles } from '@material-ui/core/styles';
import FormattedHTMLMessage from '../../../common/FormattedHTMLMessage';

const useStyles = makeStyles(() => ({
  gridItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    paddingBottom: 20,
    flexGrow: 1,
    height: '100%',
  },
}));

export const RelevanceKeys = (): JSX.Element => {
  const classes = useStyles();
  return (
    <div>
      <Grid container={true}>
        <Grid item={true} xl={4} lg={12} sm={12}>
          <Form>
            <PkpassNumber
              label={
                <FormattedMessage
                  id='Pkpass.RelevanceKeys.maxDistance.label'
                  defaultMessage={`Max distance (in meters)`}
                />
              }
              helpText={
                <FormattedMessage
                  id='Pkpass.RelevanceKeys.maxDistance.helpText'
                  defaultMessage={`Maximum distance in meters from a relevant latitude and longitude that the pass is relevant. This number is compared to the pass’s default distance and the smaller value is used.`}
                />
              }
              name='jsonTemplate.maxDistance'
            />
            <PkpassDate
              label={
                <FormattedMessage
                  id='Pkpass.RelevanceKeys.relevantDate.label'
                  defaultMessage={`Relevant date`}
                />
              }
              helpText={
                <FormattedHTMLMessage
                  descriptor={defineMessage({
                    id: 'Pkpass.RelevanceKeys.relevantDate.helpText',
                    defaultMessage: `<i>Recommended for event tickets and boarding passes; otherwise optional.</i></br></br><p>Date and time when the pass becomes relevant. For example, the start time of a movie.
                    The value must be a complete date with hours and minutes, and may optionally include seconds.</p>`,
                  })}
                />
              }
              name='jsonTemplate.relevantDate'
            />
          </Form>
        </Grid>
      </Grid>
      <Grid container={true}>
        <Grid item={true} xl={4} lg={6} md={12}>
          <div className={classes.gridItem}>
            <Locations />
          </div>
        </Grid>

        <Grid item={true} xl={4} lg={6} md={12}>
          <div className={classes.gridItem}>
            <Beacons />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
