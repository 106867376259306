import pjson from '../../../package.json';

interface ConfigFromBackend {
  yourpassVersion: string;
  environment: string;
  environmentColor: string;
  docUrl: string;
  loginPageURL: string;
  coreApiUrl: string;
  issueUrl: string;
  imageBaseUrl: string;
  googleMapApiKey: string;
  fathomCode: string;
  apiServiceTimeoutMs: number;
  cacheWarningTimeoutMs: number;
}

interface MyWindow extends Window {
  APP_CONFIG?: ConfigFromBackend;
}

declare const window: MyWindow;

export const CORE_API_URL =
  process.env.REACT_APP_CORE_API_URL || window.APP_CONFIG?.coreApiUrl;

export const LOGIN_URL =
  process.env.REACT_APP_LOGIN_PAGE_URL || window.APP_CONFIG?.loginPageURL;

export const DOC_API_URL =
  process.env.REACT_APP_DOC_API_URL || window.APP_CONFIG?.docUrl;

export const ISSUE_URL =
  process.env.REACT_APP_ISSUE_URL || window.APP_CONFIG?.issueUrl;

export const GOOGLE_MAP_API_KEY =
  process.env.REACT_APP_GOOGLE_MAP_API_KEY ||
  window.APP_CONFIG?.googleMapApiKey;

export const FATHOM_CODE =
  process.env.REACT_APP_FATHOM_CODE || window.APP_CONFIG;

export const ENVIRONMENT =
  process.env.REACT_APP_ENVIRONMENT ||
  window.APP_CONFIG?.environment ||
  'development';

export const ENVIRONMENT_COLOR =
  process.env.REACT_APP_ENVIRONMENT_COLOR ||
  window.APP_CONFIG?.environmentColor ||
  'black';

export const BUILD_TIMESTAMP = process.env.REACT_APP_BUILD_TIMESTAMP
  ? new Date(parseInt(process.env.REACT_APP_BUILD_TIMESTAMP, 10) * 1000)
  : process.env.REACT_APP_BUILD_TIMESTAMP;

export const YOURPASS_VERSION =
  process.env.REACT_APP_YOURPASS_VERSION ||
  window.APP_CONFIG?.yourpassVersion ||
  `v${pjson.version}`;

export const APP_VERSION = pjson.version;

export const API_SERVICE_TIMEOUT =
  (process.env.REACT_APP_API_SERVICE_TIMEOUT &&
    parseInt(process.env.REACT_APP_API_SERVICE_TIMEOUT, 10)) ||
  window.APP_CONFIG?.apiServiceTimeoutMs ||
  60 * 1000;

export const CACHE_WARNING_TIMEOUT =
  (process.env.REACT_APP_CACHE_WARNING_TIMEOUT &&
    parseInt(process.env.REACT_APP_CACHE_WARNING_TIMEOUT, 10)) ||
  window.APP_CONFIG?.apiServiceTimeoutMs ||
  7 * 1000;

export const IMAGE_BASE_URL =
  process.env.REACT_APP_IMAGE_BASE_URL || window.APP_CONFIG?.imageBaseUrl;
