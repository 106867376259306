import React from 'react';
import { FormattedMessage } from 'react-intl';

import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';

import useViewer from '../../hooks/useViewer';
import HomePageHeader from '../../components/home/HomePageHeader';
import HomePageTemplateSection from '../../components/home/HomePageTemplateSection';

export const HomePage = (): JSX.Element => {
  const viewer = useViewer();
  const hasProjects = viewer && viewer.hasAnyProject();

  return (
    <Container fixed={true} maxWidth={'xl'}>
      <HomePageHeader />
      {hasProjects && <HomePageTemplateSection />}
      {!hasProjects && (
        <Paper>
          <p style={{ fontSize: 35, fontWeight: 'bold' }}>
            <FormattedMessage
              id='HomePage.no.access.title'
              defaultMessage={`Your account is not active`}
            />
          </p>
          <p style={{ fontSize: 20, fontWeight: 'bold' }}>
            <FormattedMessage
              id='HomePage.no.access.message'
              defaultMessage={`To start with Yourpass Console, you need to activate your account. To do so, please contact our technical support at`}
            />
            <Link href='mailto:support@yourpass.eu'>support@yourpass.eu</Link>
          </p>
        </Paper>
      )}
    </Container>
  );
};
