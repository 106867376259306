import fetchJsonp from 'fetch-jsonp';
import React, { useEffect, useState } from 'react';

import Avatar from '@material-ui/core/Avatar';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';

import { DeleteIcon } from '../../../../../common/icons';

interface Props {
  id: number;
  onRemove: (id: number) => void;
}

const AppStoreAppDetail = (props: Props) => {
  const remove = () => {
    props.onRemove(props.id);
  };

  const [pending, setPending] = useState(false);
  const [result, setResult] = useState<any>(null);

  useEffect(() => {
    let unMounted = false;
    const read = async () => {
      try {
        setPending(true);
        const res = await fetchJsonp(
          `https://itunes.apple.com/lookup?id=${props.id}`
        );
        const json = await res.json();
        if (!unMounted) {
          setResult(json);
          setPending(false);
        }
      } catch (e) {
        if (!unMounted) {
          setPending(false);
        }
      }
    };
    read();
    return () => {
      unMounted = true;
    };
  }, [props.id]);

  let header = <ListItemText>{props.id}</ListItemText>;
  let icon = null;

  if (pending) {
    icon = <CircularProgress />;
  } else if (result && result.results && result.results.length > 0) {
    const app = result.results[0];
    header = (
      <ListItemText primary={app.trackName} secondary={app.sellerName} />
    );
    icon = (
      <ListItemAvatar>
        <Avatar src={app.artworkUrl512} alt='App icon' />
      </ListItemAvatar>
    );
  }

  return (
    <ListItem dense={true} alignItems='flex-start'>
      {icon}
      {header}
      <ListItemSecondaryAction>
        <IconButton onClick={remove}>
          <DeleteIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default AppStoreAppDetail;
