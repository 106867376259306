import React, { FunctionComponent } from 'react';

import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';

interface WysiwygPopUpProps {
  icon: React.ReactNode;
  renderContent: (handleClose: () => void) => React.ReactNode;
  onOpen?: () => void;
}

export const WysiwygPopUp: FunctionComponent<WysiwygPopUpProps> = (
  props: WysiwygPopUpProps
) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    if (props.onOpen) {
      props.onOpen();
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return (
    <React.Fragment>
      <IconButton size={'small'} aria-describedby={id} onClick={handleClick}>
        {props.icon}
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box p={2}>{props.renderContent(handleClose)}</Box>
      </Popover>
    </React.Fragment>
  );
};
