import { ClientReadableStream, Error, Status } from 'grpc-web';
import { Record, RecordKind, SubscribeRequest } from '../../proto/naming_pb';
import { NamingServiceClient } from '../../proto/NamingServiceClientPb';
import { DB, SupportedTypes } from './db';
import { getCsrf } from '../../utils/csrf';
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb';
import { ResourceTypes } from '../../utils/services/api';

export class CacheSubscribeStream {
  private stream: ClientReadableStream<Record>;

  constructor(db: DB, since: Date | null) {
    const client = new NamingServiceClient('', null, null);
    const req = new SubscribeRequest();

    if (since) {
      const f = new Timestamp();
      f.fromDate(since);
      req.setSince(f);
    }

    this.stream = client.subscribe(req, {
      'x-csrf-token': getCsrf() || '',
    });
    this.stream.on('data', (record: Record) => {
      const kind = record.getKind();
      const resource = CacheSubscribeStream.kindToResourceType(kind);
      if (resource) {
        const timestamp = record.getChangeTime();
        db.bulkPut(resource, [
          {
            id: record.getId(),
            name: record.getName(),
            changeTime: timestamp ? timestamp.toDate() : new Date(0),
            projectId: record.getProjectId(),
          },
        ]);
      }
    });
    this.stream.on('error', (err: Error) => {
      console.error(err);
    });
    this.stream.on('status', (status: Status) => {
      console.info(status);
    });
    this.stream.on('end', () => {
      console.warn('end');
    });
  }

  cancel() {
    return this.stream.cancel();
  }

  static kindToResourceType(kind: RecordKind): SupportedTypes | null {
    switch (kind) {
      case RecordKind.RECORD_KIND_CERTIFICATE:
        return ResourceTypes.CERTIFICATE;
      case RecordKind.RECORD_KIND_PROJECT:
        return ResourceTypes.PROJECT;
      case RecordKind.RECORD_KIND_TEMPLATE:
        return ResourceTypes.TEMPLATE;
      default:
        return null;
    }
  }
}
