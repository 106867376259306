import React, { FunctionComponent, useState } from 'react';

// eslint-disable-next-line import/order
import AceEditor from 'react-ace';
import ace from 'ace-builds';
import 'ace-builds/src-min-noconflict/mode-json';
import 'ace-builds/src-min-noconflict/theme-textmate';
// eslint-disable-next-line import/order

import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles';

ace.config.set(
  'basePath',
  'https://cdn.jsdelivr.net/npm/ace-builds@1.4.3/src-noconflict/'
);
ace.config.setModuleUrl(
  'ace/mode/json_worker',
  'https://cdn.jsdelivr.net/npm/ace-builds@1.4.3/src-noconflict/worker-json.js'
);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {},
  })
);

export interface FieldJsonProps {
  value: unknown;
  onChange: (data: { name: string; value: unknown }) => void;
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  label?: React.ReactNode;
  helpText?: React.ReactNode;
  disabled?: boolean;
  name: string;
  required?: boolean;
  placeholder?: string;
  error?: string;
}

export const FieldJSON: FunctionComponent<FieldJsonProps> = (
  props: FieldJsonProps
) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [dataStr, setDataStr] = useState<string>(
    JSON.stringify(props.value || {}, null, 2)
  );
  const handleChangeEditor = (newValue: string) => {
    setDataStr(newValue);
    try {
      let value;
      if (newValue) {
        value = JSON.parse(newValue);
      }
      props.onChange({ name: props.name, value });
    } catch (ex) {
      //TODO  setError
    }
  };

  return (
    <AceEditor
      className={classes.formControl}
      style={{ width: '100%', minWidth: 200, minHeight: '75vh' }}
      placeholder='Placeholder Text'
      mode='json'
      theme='textmate'
      name={props.name}
      // onLoad={onLoad}
      onChange={handleChangeEditor}
      // onSelectionChange={onSelectionChange}
      // onCursorChange={onCursorChange}
      // onValidate={onValidate}
      fontSize={12}
      showPrintMargin={true}
      showGutter={true}
      highlightActiveLine={true}
      value={dataStr}
      setOptions={{
        enableBasicAutocompletion: true,
        enableLiveAutocompletion: true,
        enableSnippets: true,
        showLineNumbers: true,
        tabSize: 2,
        useWorker: true,
        maxLines: 1000,
      }}
    />
  );
};
