import { Formik, FormikHelpers, FormikProps } from 'formik';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  CancelButton,
  DeleteButton,
  DoneButton,
} from '../../../../common/buttons';
import Dialog from '../../../../common/dialog';
import { OpenInterface } from '../../../../../hooks/useOpen';
import { PkpassBeacon } from '../../../../../types/Template';
import { beaconSchema } from '../../../../../utils/validation/jsonTemplate.iBeacon';
import BeaconForm from './BeaconForm';
import { messages } from './messages';

interface Props {
  initialValues?: PkpassBeacon;
  onChange: (beacon: PkpassBeacon) => void;
  onRemove?: () => void;
  trigger?: React.ReactNode;
}

const defaultValue: PkpassBeacon = {
  properties: {
    proximityUUID: {
      type: 'string',
      value: '',
    },
  },
  type: 'object',
};

const BeaconModal = (props: Props): JSX.Element => {
  const intl = useIntl();

  const handleSubmit = (
    b: PkpassBeacon,
    actions: FormikHelpers<PkpassBeacon>
  ) => {
    props.onChange(b);
    if (!props.initialValues) {
      actions.resetForm({ values: defaultValue });
    }
  };

  const header = props.initialValues ? (
    <FormattedMessage id='BeaconModal.header' defaultMessage='Edit iBeacon' />
  ) : (
    <FormattedMessage
      id='BeaconNewModal.add.title'
      defaultMessage='New iBeacon'
    />
  );

  return (
    <Formik
      initialValues={props.initialValues || defaultValue}
      validateOnMount={beaconSchema.isValidSync(
        props.initialValues || defaultValue
      )}
      onSubmit={handleSubmit}
      validationSchema={beaconSchema}
    >
      {(p: FormikProps<PkpassBeacon>) => {
        const renderActions = (open: OpenInterface) => {
          const handleSubmit = async () => {
            await p.submitForm();
            open.handleClose();
          };

          return (
            <Fragment>
              {props.onRemove && (
                <DeleteButton
                  confirmText={intl.formatMessage(
                    messages.deleteConfirmationText
                  )}
                  onDelete={() => {
                    props.onRemove && props.onRemove();
                    open.handleClose();
                  }}
                />
              )}
              <CancelButton onClick={open.handleClose} />
              <DoneButton
                disabled={!p.isValid}
                color='primary'
                onClick={handleSubmit}
              />
            </Fragment>
          );
        };

        return (
          <Dialog
            trigger={props.trigger}
            title={header}
            content={<BeaconForm />}
            renderActions={renderActions}
          />
        );
      }}
    </Formik>
  );
};

BeaconModal.propTypes = {
  initialValues: PropTypes.any,
  onRemove: PropTypes.func,
};

export default BeaconModal;
