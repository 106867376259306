import { blue } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core';

// A custom theme for this app
const theme = createMuiTheme({
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  palette: {
    // background: {
    //   default: '#fff',
    // },
    // error: {
    //   main: red.A400,
    // },
    primary: {
      main: blue[600],
    },
    // secondary: {
    //   main: '#19857b',
    // },
  },
});

export default theme;
