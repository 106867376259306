import React, { Fragment } from 'react';

import AppBar from '@material-ui/core/AppBar';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import { CloseIcon } from '../icons';
import PageToolbar from '../page/PageToolbar';

interface EditDrawerInnerLayout {
  onClose?: () => void;
  content: React.ReactNode;
  controls?: React.ReactNode;
  title: React.ReactNode;
}

const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    backgroundColor: '#fff',
    zIndex: 4000,
    position: 'fixed',
    [theme.breakpoints.up('md')]: {
      width: 'calc(100vw - 240px)',
    },
  },
  content: {
    [theme.breakpoints.up('md')]: {
      width: 'calc(100vw - 240px)',
    },
  },
  spacer: {
    height: 64 + theme.spacing(1),
    width: '100%',
  },
  title: { flexGrow: 1, color: '#000' },
  controls: {
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
      justifyContent: 'space-between',
    },
  },
}));

const EditDrawerInnerLayout = (props: EditDrawerInnerLayout): JSX.Element => {
  const classes = useStyles();
  return (
    <Fragment>
      <AppBar position='relative' className={classes.appBar} elevation={1}>
        <Toolbar>
          <IconButton onClick={props.onClose} disabled={!props.onClose}>
            <CloseIcon />
          </IconButton>
          <Typography variant='h6' className={classes.title}>
            {props.title}
          </Typography>
          <PageToolbar>{props.controls}</PageToolbar>
        </Toolbar>
      </AppBar>
      <DialogContent className={classes.content}>
        <div className={classes.spacer} />
        {props.content}
      </DialogContent>
    </Fragment>
  );
};

export default EditDrawerInnerLayout;
