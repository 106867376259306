export const transitTypes = {
  PKTransitTypeAir: {
    img: 'plane',
  },
  PKTransitTypeBoat: {
    img: 'ship',
  },
  PKTransitTypeBus: {
    img: 'bus',
  },
  PKTransitTypeGeneric: {
    img: 'arrow right',
  },
  PKTransitTypeTrain: {
    img: 'train',
  },
};
