import React from 'react';

import Tooltip from '@material-ui/core/Tooltip';

import useTimezone from '../../../hooks/useTimezone';

export default function DataGridCellDate(props: {
  value: string | Date | undefined | null;
}): JSX.Element {
  const { formatFullDate, formatFullDateTime } = useTimezone();

  if (!props.value) {
    return <span />;
  }

  return (
    <Tooltip
      style={{ padding: 10 }}
      title={<div>{formatFullDateTime(props.value)}</div>}
    >
      <span style={{ cursor: 'pointer' }}>{formatFullDate(props.value)}</span>
    </Tooltip>
  );
}
