import React from 'react';

import useOpen from '../../../hooks/useOpen';
import DesignPkpassEditDrawer from './DesignPkpassEditDrawer';
import {
  EditLinkButton,
  EditLinkButtonChildrenProps,
} from '../../common/buttons';

const DesignPkpassEditDrawerButton = (props: {
  resourceId: string;
  onClose: () => void;
  disabled?: boolean;
}): JSX.Element => {
  const oi = useOpen(false);

  return (
    <EditLinkButton
      disabled={props.disabled}
      oi={oi}
      path={'/passes/design/pkpass/' + props.resourceId}
    >
      {(p: EditLinkButtonChildrenProps) => {
        return (
          <DesignPkpassEditDrawer
            id={props.resourceId}
            open={oi.open}
            onClose={() => {
              p.onClose();
              props.onClose();
            }}
          />
        );
      }}
    </EditLinkButton>
  );
};

export default DesignPkpassEditDrawerButton;
