import { array, object } from 'yup';

import {
  arrayTypeSchema,
  numberValueSchema,
  objectTypeSchema,
  stringValueSchema,
} from './helper';

export const beaconSchema = object().shape({
  properties: object().shape({
    major: numberValueSchema,
    minor: numberValueSchema,
    proximityUUID: stringValueSchema.required(),
    relevantText: stringValueSchema,
  }),
  type: objectTypeSchema,
});

export const beaconsSchema = object()
  .shape({
    items: array().of(beaconSchema).min(0).max(10),
    type: arrayTypeSchema,
  })
  .default(undefined);
