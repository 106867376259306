import { FormikProps } from 'formik';
import PropTypes from 'prop-types';
import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';

import EditDrawerInnerLayout from '../../common/drawers/EditDrawerInnerLayout';
import FormikEditDrawer from '../../common/formik/FormikEditDrawer';
import { LabelProject } from '../../common/labels';
import { ResourceTypes } from '../../../utils/services/api';
import { Template } from '../../../types/Template';
import schema from '../../../utils/validation/template';
import DesignEditControls from './DesignGooglePayEditControls';
import SaveToGooglePayForm from './SaveToGooglePayForm';

interface TemplateEditDrawerProps {
  id: string;
  open: boolean;
  onClose: () => void;
}

const TemplateEditDrawer: FunctionComponent<TemplateEditDrawerProps> = (
  props: TemplateEditDrawerProps
): JSX.Element => {
  const Edit: FunctionComponent<FormikProps<Template>> = (
    p: FormikProps<Template>
  ) => {
    return (
      <EditDrawerInnerLayout
        onClose={props.onClose}
        content={
          <div>
            <SaveToGooglePayForm {...p} />
          </div>
        }
        controls={<DesignEditControls {...p} />}
        title={
          <span>
            <FormattedMessage
              id='DesignEdit.title'
              defaultMessage='Edit Google Pay design'
            />
            <Breadcrumbs aria-label='breadcrumb'>
              <LabelProject resourceId={p.values.projectId} />
              <span>{p.values.id}</span>
            </Breadcrumbs>
          </span>
        }
      />
    );
  };

  return (
    <FormikEditDrawer
      onClose={props.onClose}
      resource={ResourceTypes.TEMPLATE}
      id={props.id}
      validationSchema={schema}
      open={props.open}
      Component={Edit}
    />
  );
};

TemplateEditDrawer.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default TemplateEditDrawer;
