import { FormikProps } from 'formik';
import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';

import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';

import { ImageValidationErrorCodes } from '../../types/Image';
import { ImageFormInterface } from './ImageForm';
import messages, { pkpassImageSizeMessages } from './messages';
import ImageUploadDialogContentDropZone from './ImageUploadDialogContentDropZone';
import ImageUploadDialogContentForm from './ImageUploadDialogContentForm';
import ImageUploadDialogContentImageEdit from './ImageUploadDialogContentImageEdit';
import { ImageTypes } from '../../types/Template';

interface Props extends FormikProps<ImageFormInterface> {
  projectId?: string;
  type?: ImageTypes;
}

export default function ImageUploadDialogContent(props: Props): JSX.Element {
  const { errors } = props;
  return (
    <Fragment>
      {props.type && (
        <Alert severity='info' style={{ marginBottom: 16 }}>
          <FormattedMessage {...pkpassImageSizeMessages[props.type]} />
        </Alert>
      )}

      {errors && errors.url && (
        <Alert severity='error'>
          {JSON.parse(errors.url).map(
            (err: { code: ImageValidationErrorCodes; params: any }) => (
              <p key={err.code}>
                <FormattedMessage {...messages[err.code]} values={err.params} />
              </p>
            )
          )}
        </Alert>
      )}

      <Grid container={true}>
        <Grid item={true} xs={12} md={6}>
          <ImageUploadDialogContentForm {...props} />
        </Grid>
        <Grid item={true} xs={12} md={6}>
          <ImageUploadDialogContentDropZone {...props} />
          {props.values.url && <ImageUploadDialogContentImageEdit {...props} />}
        </Grid>
      </Grid>
    </Fragment>
  );
}
