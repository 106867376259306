import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

const messages = defineMessages({
  ERROR_400_HEADER: { id: 'ERROR_400_HEADER', defaultMessage: 'Bad request' },
  ERROR_400_TEXT: {
    defaultMessage:
      'Probably you do not have enough rights. Please contact the administrator.',
    id: 'ERROR_400_TEXT',
  },
  ERROR_403_HEADER: {
    defaultMessage: 'Forbidden: Access is denied',
    id: 'ERROR_403_HEADER',
  },
  ERROR_403_TEXT: {
    defaultMessage:
      'Probably you do not have enough rights. Please contact the administrator.',
    id: 'ERROR_403_TEXT',
  },
  ERROR_404_HEADER: { id: 'ERROR_404_HEADER', defaultMessage: 'Not found' },
  ERROR_404_TEXT: {
    defaultMessage:
      'Page or resource was not found. Please check your url or contact administrartor',
    id: 'ERROR_404_TEXT',
  },
  ERROR_500_HEADER: {
    defaultMessage: 'Internal error',
    id: 'ERROR_500_HEADER',
  },
  ERROR_500_TEXT: {
    defaultMessage:
      'If the error is repeated, please contact the administrator.',
    id: 'ERROR_500_TEXT',
  },
});

export const ErrorPage = (
  props: RouteComponentProps<{ statusCode: string }>
): JSX.Element => {
  const statusCode = props.match.params.statusCode || '500';

  let header;
  let text;

  switch (statusCode) {
    case '400': {
      header = <FormattedMessage {...messages.ERROR_400_HEADER} />;
      text = <FormattedMessage {...messages.ERROR_400_TEXT} />;
      break;
    }

    case '403': {
      header = <FormattedMessage {...messages.ERROR_403_HEADER} />;
      text = <FormattedMessage {...messages.ERROR_403_TEXT} />;
      break;
    }

    case '404': {
      header = <FormattedMessage {...messages.ERROR_404_HEADER} />;
      text = <FormattedMessage {...messages.ERROR_404_TEXT} />;
      break;
    }

    default:
      header = <FormattedMessage {...messages.ERROR_500_HEADER} />;
      text = <FormattedMessage {...messages.ERROR_500_TEXT} />;
  }

  return (
    <Paper>
      <Typography variant='h3'>
        {`${statusCode}`} - {header}
      </Typography>
      <p>{text}</p>
    </Paper>
  );
};
