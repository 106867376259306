import React, { Fragment } from 'react';
import { ImageReference } from '../../types/Image';
import { ImageTypes, PkpassPassStyleTypes } from '../../types/Template';
import { FieldSelectData } from '../common/fields';
import ImagePreview from '../image/ImagePreview';
import useOpen from '../../hooks/useOpen';
import ImageSelectDialog from '../image/ImageSelectDialog';

export interface ImageSelectProps {
  disabled?: boolean;
  imageType: ImageTypes;
  previewColor?: string;
  projectId: string;
  image: ImageReference;
  previewStyle?: any;
  previewLanguage: string;
  languages: string[];
  onChange: (newImage: ImageReference) => void;
  onChangeLanguage: (event: FieldSelectData) => void;
  passStyle: PkpassPassStyleTypes;
  triggerButton?: boolean;
}

const ImageSelect = (props: ImageSelectProps) => {
  const open = useOpen(false);
  const {
    passStyle,
    imageType,
    previewLanguage,
    languages,
    projectId,
    onChange,
    image,
    disabled,
  } = props;

  const handleRemoveImage = () => {
    onChange(undefined);
  };

  const handleImageChange = (i: ImageReference) => {
    onChange(i);
  };

  let selectedId = image;
  if (selectedId && typeof selectedId !== 'string') {
    selectedId = selectedId[previewLanguage];
  }

  return (
    <Fragment>
      <ImagePreview
        onRemove={handleRemoveImage}
        imageType={imageType}
        passStyle={passStyle}
        image={image}
        language={previewLanguage}
        triggerButton={props.triggerButton}
        onClick={disabled === true ? undefined : open.handleOpen}
        disabled={disabled === true}
      />
      <ImageSelectDialog
        key={`${open}`}
        image={image}
        imageType={imageType}
        languages={languages}
        onChange={handleImageChange}
        onChangeLanguage={props.onChangeLanguage}
        open={open.open}
        handleClose={open.handleClose}
        previewLanguage={previewLanguage}
        projectId={projectId}
      />
    </Fragment>
  );
};

export default ImageSelect;
