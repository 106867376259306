import { FormikProps, getIn } from 'formik';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import Grid from '@material-ui/core/Grid';
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles';

import { FieldStatic } from '../../../../common/fields';
import FieldGoogleMap from '../../../../common/fields/FieldGoogleMap';
import Form from '../../../../common/Form';
import { LabelGPS, StaticLabel } from '../../../../common/labels';
import { PkpassLocation } from '../../../../../types/Template';
import { PkpassNumber, PkpassString } from '../../inputs';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    mapColumn: {
      display: 'flex',
      flexDirection: 'column',
      width: 400,
      '& > *': {
        marginBottom: theme.spacing(1),
      },
    },
  })
);

export default function LocationForm(
  p: FormikProps<PkpassLocation> & { radius?: number }
): JSX.Element {
  const theme = useTheme();
  const classes = useStyles(theme);

  const handleLatLng = (latitude: number, longitude: number) => {
    p.setFieldValue('properties.latitude' as never, {
      value: latitude,
      type: 'number',
    });
    p.setFieldValue('properties.longitude' as never, {
      type: 'number',
      value: longitude,
    });
  };

  const lat =
    parseFloat(getIn(p.values, 'properties.latitude.value')) ||
    parseFloat(getIn(p.values, 'properties.latitude.fallback')) ||
    0;
  const lng =
    parseFloat(getIn(p.values, 'properties.longitude.value')) ||
    parseFloat(getIn(p.values, 'properties.latitude.fallback')) ||
    0;

  return (
    <Grid container={true} alignContent={'center'} className={classes.root}>
      <Grid item={true}>
        <div className={classes.mapColumn}>
          {/* <GooglePlaceSelect onChange={handleLatLng} /> */}
          <FieldGoogleMap
            lat={lat}
            lng={lng}
            onChange={handleLatLng}
            radius={p.radius}
          />
          <FieldStatic
            name='gps'
            label={'GPS'}
            value={<LabelGPS lat={lat} lng={lng} />}
          />
        </div>
      </Grid>
      <Grid item={true}>
        <Form>
          <PkpassString
            label={StaticLabel.RelevantText}
            helpText={
              <FormattedMessage
                id='Pkpass.RelevanceKeys.LocationForm.relevantText.helpText'
                defaultMessage={`Text displayed on the lock screen when the pass is currently relevant. For example, a description of the nearby location such as “Store nearby on 1st and Main.`}
              />
            }
            name={'properties.relevantText'}
          />

          <PkpassNumber
            label={StaticLabel.Altitude}
            helpText={
              <FormattedMessage
                id='Pkpass.RelevanceKeys.LocationForm.altitude.helpText'
                defaultMessage={`Altitude, in meters, of the location.`}
              />
            }
            name={'properties.altitude'}
          />
          <PkpassNumber
            required={true}
            label={StaticLabel.Latitude}
            helpText={
              <FormattedMessage
                id='Pkpass.RelevanceKeys.LocationForm.latitude.helpText'
                defaultMessage={`Latitude, in degrees, of the location.`}
              />
            }
            name={'properties.latitude'}
          />
          <PkpassNumber
            required={true}
            label={StaticLabel.Longitude}
            helpText={
              <FormattedMessage
                id='Pkpass.RelevanceKeys.LocationForm.longitude.helpText'
                defaultMessage={`Longitude, in degrees, of the location.`}
              />
            }
            name={'properties.longitude'}
          />
        </Form>
      </Grid>
    </Grid>
  );
}
