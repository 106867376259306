import React from 'react';

import { Line, Doughnut } from '../common/charts';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { getColorForIndex } from '../../constants/colors';
import { getTagName } from './BasePassRequestByTagsOverview';
import NoData from './NoData';

// TODO - remove any types
export default function BasePassRequestByTagsOverviewPeriod(props: any) {
  const { data, getLabel } = props;
  const interval = props.query.interval;
  const dounughtPeriodData = {
    labels: [] as string[],
    datasets: [
      {
        data: [] as number[],
        backgroundColor: [] as string[],
      },
    ],
  };
  data.period.forEach((i: { tag: string; count: number }, index: number) => {
    dounughtPeriodData.labels.push(getTagName(i.tag));
    dounughtPeriodData.datasets[0].data.push(i.count);
    dounughtPeriodData.datasets[0].backgroundColor.push(
      getColorForIndex(index)
    );
  });

  let tags: string[] = [];
  let sorted: any[] = [];

  tags = data.period.map((p: any) => {
    return p.tag;
  });

  let i = 0;
  while (i < data.data.length) {
    let j = 0;
    while (j < tags.length) {
      if (typeof data.data[i].tags[tags[j]] === 'undefined') {
        data.data[i].tags[tags[j]] = 0;
      }
      j += 1;
    }
    i += 1;
  }

  sorted = data.data.sort((a: any, b: any) => {
    const c = new Date(a.from);
    const d = new Date(b.from);
    return c.getTime() - d.getTime();
  });

  sorted = sorted.map((d: any) => {
    d.label = getLabel(d.from, interval);
    return d;
  });

  return (
    <Grid container={true} spacing={2}>
      <Grid item={true} xs={12} sm={8}>
        <div>
          <h3>Interval graph</h3>
          {!data && <NoData />}
          {data && (
            <Line
              data={{
                labels: sorted.map((s: any) => s.label),
                datasets: tags.map((tag, index) => ({
                  label: getTagName(tag),
                  data: sorted.map((s: any) => s.tags[tag]),
                  borderColor: getColorForIndex(index),
                  backgroundColor: getColorForIndex(index),
                  fill: false,
                })),
              }}
            />
          )}
        </div>
      </Grid>
      <Grid item={true} xs={12} sm={4}>
        <div>
          <h3>Period summary</h3>
          <Doughnut data={dounughtPeriodData} />
        </div>
      </Grid>

      <Grid item={true} xs={12}>
        <h3>Tag by interval data table</h3>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>interval</TableCell>
              {tags.map((tag) => (
                <TableCell key={getTagName(tag)}>{getTagName(tag)}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sorted
              .slice(0)
              .reverse()
              .map((v: any) => (
                <TableRow key={v.label}>
                  <TableCell>{v.label}</TableCell>
                  {tags.map((tag) => (
                    <TableCell key={tag}>{`${v.tags[tag]}`}</TableCell>
                  ))}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  );
}
